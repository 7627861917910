import styled from 'styled-components';

import { colors, mediaQueriesSizes, bgGradients } from '../../../GlobalStyles';

export const FeaturedNavigationStyles = styled.div`
  background: ${bgGradients.highlight};
  padding: 60px 0;
  position: relative;
  overflow: hidden;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 160%;
      background-image: url('/assets/images/backgrounds/background-circles.png');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      top: -90%;
      right: -20%;
      position: absolute;
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &.Theme-gradient-green {
    background: ${bgGradients.controlx};
  }

  &.Theme-gradient-orange {
    background: ${bgGradients.wrap};
  }

  &.Theme-3dxpert {
    background: ${bgGradients.threeDXpert};
  }

  &.Theme-freeform {
    background: ${colors.solidFreeform};
  }

  &.Theme-manufacturing-os {
    background: ${bgGradients.mos};
  }

  &.Theme-geomagicSolidWorks {
    background: ${bgGradients.geomagicSolidWorks};
  }

  &.additional-margin-bottom {
    padding-bottom: 64px;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      padding-bottom: 168px;
    }
  }

  &.additional-padding-top {
    padding-top: 80px;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      padding-top: 168px;
    }
  }

  &.annoucment-at-top {
    padding-top: 120px;
    margin-top: -75px;
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      margin-top: -150px;

    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin-top: -75px;
    }
  }

  &.max-skull {
    img {
      max-height: 450px;
      display: block;
      margin: 0 auto;
    }
  }
`;

export const MainWrap = styled.div`
  h2 {
    font-size: 24px;
    color: white;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 32px;
  }

  h3 {
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    margin-bottom: 32px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    &.half-size {
      width: 50%;
      padding-right: 32px;
    }

    h2 {
      font-size: 32px;
    }

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 20px;
    }
  }
`;

export const ImgWrap = styled.div`
  @media (min-width: ${mediaQueriesSizes.md}px) {
    width: 50%;
    align-items: center;
    padding-left: 32px;
    text-align: right;
  }

  img {
    width: auto;
    height: auto;

    max-width: 100%;
    max-height: 800px;

    position: relative;
    z-index: 15;
  }
`;

export const LowerContentWrapper = styled.div`
  @media (min-width: ${mediaQueriesSizes.md}px) {
    display: flex;
    align-items: center;
  }
`;

export const NavigationWrap = styled.div`
  margin-bottom: 32px;

  a {
    color: white;
    padding-bottom: 8px;
    border-bottom: solid 1px white;
    text-decoration: none;
    margin-right: 16px;

    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    transition: 0.35s;

    margin-bottom: 16px;
    display: inline-block;

    &:hover {
      opacity: 0.4;
    }
  }
`;
