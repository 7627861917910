import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { createMarkUp } from '../helpers';

import { TextBlockStyles } from './styles.jsx';

interface Props {
  Title?: string;
  MicroTitle?: string;
  List?: string;
  Copy?: string;
  FullCopy?: string;
  ShowHR?: boolean;
}

export const TextBlock = ({ List, ShowHR = true, Title, MicroTitle, Copy, FullCopy, ...props }: Props) => {
  return (
    <>
      <TextBlockStyles {...props}>
        <GlobalStyle />
        <div className={'container'}>
          {MicroTitle && <h4 dangerouslySetInnerHTML={createMarkUp(MicroTitle)}></h4>}
          <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
          {ShowHR && <hr className={'divider'} />}
          {Copy && <p dangerouslySetInnerHTML={createMarkUp(Copy)}></p>}
          {FullCopy && <div dangerouslySetInnerHTML={createMarkUp(FullCopy)}></div>}
          {List && (
            <ol>
              {List.map((item, index) => (
                <li dangerouslySetInnerHTML={createMarkUp(item)}></li>
              ))}
            </ol>
          )}
        </div>
      </TextBlockStyles>
    </>
  );
};
