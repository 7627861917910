import React from 'react';

import { Styles, ProductLink } from './styles.jsx';
import BlogCategories from '../../../../../data/blog-categories.json';
import { ProductLogo } from '../../ProductLogo/ProductLogo';
import { createMarkUp } from '../../helpers';

interface Props {
  Categories?: object;
  Links?: boolean;
}

export const TagsWrap = ({ Categories = [], Links = true, ...props }: Props) => {
  if (Categories[0] && !Categories[0].slug) {
    return (
      <Styles>
        <h3>Tags</h3>
        {Categories.map((item, index) => {
          let slug = item.toLowerCase().replace(/\s+/g, '-');

          if (BlogCategories.products.includes(slug)) {
            return (
              <a href={'/news/?products=' + slug + '&page=1&search='} key={index}>
                {item}
              </a>
            );
          } else if (BlogCategories.categories.includes(slug)) {
            return (
              <a href={'/news/?category=' + slug + '&page=1&search='} key={index}>
                {item}
              </a>
            );
          } else {
            return (
              <a href={'/news/?topics=' + slug + '&page=1&search='} key={index}>
                {item}
              </a>
            );
          }
        })}
      </Styles>
    );
  }

  if (Categories.length > 0) {
    if (Links) {
      return (
        <Styles>
          <h3>Related Tags & Featured Products</h3>
          {Categories.map((item, index) => {
            if (item.name) {
              item.title = item.name;
            }

            if (BlogCategories.products.includes(item.slug)) {
              return '';
            } else if (BlogCategories.categories.includes(item.slug)) {
              return (
                <a href={'/news/?category=' + item.slug + '&page=1&search='} key={index}>
                  <span dangerouslySetInnerHTML={createMarkUp(item.title)}></span>
                </a>
              );
            } else {
              return (
                <a href={'/news/?topics=' + item.slug + '&page=1&search='} key={index}>
                  <span dangerouslySetInnerHTML={createMarkUp(item.title)}></span>
                </a>
              );
            }
          })}
          <br />
          <div className={'product-links'}>
            {Categories.map((item, index) => {
              if (BlogCategories.products.includes(item.slug)) {
                return (
                  <ProductLink href={'/news/?products=' + item.slug + '&page=1&search='} key={index}>
                    <ProductLogo Logo={item.slug} />
                  </ProductLink>
                );
              } else {
                return '';
              }
            })}
          </div>
        </Styles>
      );
    } else {
      return (
        <Styles>
          <h3>Tags</h3>
          {Categories.map((item, index) => {
            return (
              <div className={'tag'} key={index}>
                {item}
              </div>
            );
          })}
        </Styles>
      );
    }
  } else {
    return '';
  }
};
