import React, { useEffect, useState } from 'react';

interface Props {
  touchedFields: object;
  formData: object;
  copy: object;
  handleTouch: () => void;
  handleChange: () => void;
  fieldName: '';
  fieldType: '';
  required: boolean;
}

export const InputField = ({
  touchedFields,
  formData,
  copy,
  handleTouch,
  handleChange,
  fieldName,
  fieldType,
  required,
  ...props
}: Props) => {
  const [error, setError] = useState(false);
  const [emailValid, setEmailValid] = useState(false);

  const showError = () => {
    if (required && formData[fieldName].length < 1) {
      setError(true);
    } else {
      if (fieldName === 'email') {
        if (isValidEmail(formData[fieldName])) {
          setEmailValid(false);
          setError(false);
        } else {
          setEmailValid(true);
          setError(true);
        }
      } else {
        setError(false);
      }
    }
  };

  const isValidEmail = email => {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Test the email against the pattern
    return emailPattern.test(email);
  };

  return (
    <label className={`${error ? 'errored' : ''}  form-label-${fieldName}`}>
      {copy.fields[fieldName].label}
      {required && (
        <>
          <span className={'required'}>*</span>
          <span
            className={`error required ${
              touchedFields[fieldName] === true && formData[fieldName].length < 1 ? '' : 'hidden'
            }`}
          >
            {copy.fields[fieldName].error.required}
          </span>
        </>
      )}
      {fieldName === 'email' && (
        <>
          <span
            className={`error invalid ${
              touchedFields[fieldName] === true && formData[fieldName].length > 0 && emailValid ? '' : 'hidden'
            }`}
          >
            {copy.fields[fieldName].error.invalid}
          </span>
        </>
      )}
      <input
        type={fieldType}
        name={fieldName}
        value={formData[fieldName]}
        onBlur={e => {
          showError();
          handleTouch(e);
        }}
        onChange={handleChange}
      />
    </label>
  );
};
