import styled from 'styled-components';
import { colors, media, spacing, typography } from '../../foundations';

export const ContentContainer = styled.section`
  margin: 150px auto;
  font-size: ${typography.fontSizes.s}px;

  color: ${colors.secondary};

  section {
    width: 555px;
    margin: 0 auto;
  }

  article {
    margin-top: ${spacing.xxxl}px;
  }

  h3 {
    font-weight: ${typography.fontWeights.medium};
    font-size: ${typography.fontSizes.l}px;
  }
  p {
    font-size: ${typography.fontSizes.l}px;
  }
  p,
  li {
    margin-top: ${spacing.l}px;

    color: ${colors.tertiary};
  }

  @media screen and (max-width: ${media.lg}px) {
    width: auto;

    section {
      width: auto;
    }
  }
`;

export const Title = styled.h1`
  color: ${colors.secondary};
  font-size: ${typography.fontSizes.xxxxl}px;
  font-weight: ${typography.fontWeights.medium};
`;

export const Sub = styled.sub`
  color: ${colors.tertiary};

  font-size: ${typography.fontSizes.xxxl}px;
  font-weight: normal;

  &:after {
    display: block;

    content: '';

    width: 360px;
    height: 2px;

    margin-top: ${spacing.xxl}px;
    margin-bottom: 120px;

    background-color: ${colors.quaternaryAccent};
  }
`;

export const Table = styled.table`
  margin-top: ${spacing.xxxl}px;
`;

export const TableHeader = styled.th`
  padding-right: ${spacing.m}px;
  padding-bottom: ${spacing.s}px;

  text-align: left;

  font-size: ${typography.fontSizes.m}px;
  font-weight: ${typography.fontWeights.medium};
`;

export const TableCell = styled.td`
  padding-right: ${spacing.m}px;
  padding-top: ${spacing.l}px;

  color: ${colors.tertiary};

  font-size: ${typography.fontSizes.s}px;
  font-weight: ${typography.fontWeights.thin};
  line-height: 1.6;
`;

export const TableRow = styled.tr`
  border-bottom: 2px solid ${colors.quaternaryAccent};
`;
