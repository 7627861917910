import React, { useState } from 'react';
import { ButtonComponent } from '../../Button/Button';
import styled from 'styled-components';
import { colors, mediaQueriesSizes } from '../../../../GlobalStyles';
import { Modal } from './GridItemHelper';

interface Props {
  Item?: object;
}

const BGImage = styled.div`
  position: absolute;
  width: 100%;
  height: 50%;
  background-position: bottom center;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Para = styled.p`
  font-style: normal;
  font-family: centrano2-book;
  font-weight: 400;
  font-size: 1.125em;
  line-height: 1.555555556em;
  text-align: center;
  letter-spacing: 0.02em;
  color: #59747a;
`;

const GridItemStyle = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column !important;
  height: auto;

  h3,
  p,
  a,
  button {
    position: relative;
    z-index: 1;
  }

  h3 {
    word-break: break-word;
  }

  p {
  }

  img.img {
    width: 100%;
  }

  a.custom-button {
    background: white;
  }
`;

export const GridItem3 = ({ Item, ...props }: Props) => {
  const handleClick = () => {
    setModalActive(true);
  };

  const [modalActive, setModalActive] = useState(null);

  return (
    <>
      {modalActive ? (
        <Modal
          close={() => {
            setModalActive(false);
          }}
          video={Item.Copy.Video}
        />
      ) : null}
      <GridItemStyle className={'item ' + Item.ComponentSize} {...props}>
        <div>
          <h3>{Item.Copy.Title}</h3>
          <Para>{Item.Copy.Copy}</Para>
          <ButtonComponent
            ClickFunction={handleClick}
            Theme={'transparent-with-grey-outline'}
            Icon={Item.Copy.Button.Icon}
            Label={Item.Copy.Button.Label}
          />
        </div>
        <BGImage style={{ backgroundImage: 'url(' + Item.Copy.BackgroundImage + ')' }} />
      </GridItemStyle>
    </>
  );
};
