import React from 'react';

import DownloadTemplate from '../../../templates/DownloadTemplateG4SW';

const DownloadTemplateG4SW = () => {
  const Lang = 'ja';

  return <DownloadTemplate Lang={Lang} />;
};

export default DownloadTemplateG4SW;
