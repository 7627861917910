import React from 'react';

import AdditiveTemplate from './../../templates/AdditivePageTemplate';

const Additive = () => {
  const Lang = 'de';

  return <AdditiveTemplate Lang={Lang} />;
};

export default Additive;
