import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const ComponentStyles = styled.div`

    padding: 48px 0 0;

    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.33;
        letter-spacing: 0.02em;
        color: ${colors.solidGeomagicDesignx};
        margin-bottom: 8px;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: 18px;
        }
    }

    h2 {
        font-size: 26px;
        line-height: 1.3;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: 40px;

        }
    }

`;


export const ItemContainer = styled.div`
    margin-top: 64px;
    display: grid;
    column-gap: 16px;
    row-gap: 16px;
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: ${mediaQueriesSizes.md}px) {
        grid-template-columns: repeat(2, 1fr);
        .item:nth-child(3) {
            grid-column: span 2;
        }

    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        grid-template-columns: repeat(3, 1fr);
        .item:nth-child(3) {
            grid-column: span 1;
        }
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
        column-gap: 52px;
    }
    
    a.item-top{
        text-decoration: none;
        transition: 0.25s;
        &:hover{
            transform: scale(1.025);
        }
    }

    .item {
        position: relative;
        overflow: hidden;
        height: 100%;
        padding: 24px;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            padding: 56px 32px 180px;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            padding: 56px 32px 150px;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            padding: 56px 32px 200px;
        }
        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            padding: 56px 32px 250px;
        }
        background: #FFFFFF;
        box-shadow: 0px 91px 36px rgba(35, 64, 87, 0.01), 0px 51px 31px rgba(35, 64, 87, 0.04), 0px 23px 23px rgba(35, 64, 87, 0.06), 0px 6px 12px rgba(35, 64, 87, 0.07), 0px 0px 0px rgba(35, 64, 87, 0.07);
        border-radius: 16px;

        .Component-ProductLogo {
            margin-bottom: 16px;
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                margin-bottom: 24px;
            }

            img {
                height: 28px;
                @media (min-width: ${mediaQueriesSizes.lg}px) {
                    height: 40px;
                }
            }
        }

        p {
            font-family: centrano2-book;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.8;
            letter-spacing: 0.02em;
            color: ${colors.UILightSecondary};
            margin-bottom: 32px;
            
            span{
                display: none;
            }
            
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                span{
                    display: block;
                }
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                font-size: 18px;
            }


        }

        a {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.35;
            letter-spacing: 0.01em;
            color: ${colors.primary};
            text-decoration: underline !important;
            text-decoration-color: ${colors.solidGeomagicDesignx} !important;
            text-decoration-thickness: 2px !important;
            text-underline-offset: 6px !important;
            display: inline-block;
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                font-size: 18px;
            }
        }

        span {
            display: block;
            font-style: normal;
            font-weight: 300;
            height: 24px;
            margin-top: 12px;
            font-size: 16px;
            line-height: 1.13;
            letter-spacing: 0.01em;
            color: ${colors.primary};
        }

        a.custom-button {
            font-weight: 600;
            padding-bottom: 3px;
            border-bottom: solid 2px ${colors.solidGeomagicDesignx};
        }

        .image-wrap {
            margin-top: 16px;
            width: 100%;
            background: ${colors.UILightBackground};
            display: flex;
            align-content: center;
            align-items: center;
            @media (min-width: ${mediaQueriesSizes.md}px) {
                position: absolute;
                width: calc(100% - (32px * 2));
                bottom: 32px;
            }

            img {
                width: auto;
                max-width: 100%;
                max-height: 100%;
                height: auto;
                display: block;
                margin: 0 auto;
                @media (min-width: ${mediaQueriesSizes.md}px) {
                    max-height: 210px;
                }
            }

        }
    }


`;