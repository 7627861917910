import styled from 'styled-components';

import { boxShadows, colorsRD, media, spacing } from '../../foundations';
import { RDButtonSmall, RDH2Bold, RDH4, RDPL, RDSmallCaps, RDButton } from '../../../styles/typography';
import { Box } from '../../containers/Box';
import { Position } from '../../subComponents/Card/types';

export const Container = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${spacing.xxxxxl}px;
  background-color: ${colorsRD.background.primary};

  @media screen and (max-width: ${media['xxl++']}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${spacing.xl}px;

    padding: ${spacing.xxxxxl}px ${spacing.xxl}px;
  }

  @media screen and (max-width: ${media.xl}px) {
    padding: ${spacing.xxxxxl}px 0;
    align-items: center;
  }
`;

export const ContentContainer = styled.div`
  @media screen and (max-width: ${media.xl}px) {
    text-align: center;
  }
`;

export const Title = styled.h1`
  ${RDH2Bold};
  color: ${colorsRD.primary};

  margin-bottom: ${spacing.l}px;
`;

export const Description = styled.p`
  ${RDPL};
  color: ${colorsRD.primary};

  width: 330px;
`;

export const ResourcesContainer = styled.section`
  display: flex;
  gap: ${spacing['xl+']}px;
  margin-left: ${spacing['xl+']}px;
  flex-wrap: wrap;

  @media screen and (max-width: ${media['xxl++']}px) {
    margin-left: 0;
  }

  @media screen and (max-width: ${media.xl}px) {
    justify-content: center;
  }

  @media screen and (max-width: ${media.md}px) {
    flex-direction: column;
  }
`;

export const ResourceContainer = styled.article<{ withBackground?: boolean }>`
  display: flex;
  align-self: flex-end;
  flex-direction: column;

  border-radius: ${spacing.xs}px;
  box-shadow: ${boxShadows.primary};

  background-color: ${colorsRD.white};

  ${({ withBackground }) =>
    withBackground ? `background-image: url('/assets/images/backgrounds/resource.png');` : ''};
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column-reverse;

  padding: ${spacing.xxxl}px ${spacing.l}px ${spacing.l}px ${spacing.l}px;
  border-bottom: 1px solid ${colorsRD.primary}20;
`;
export const ResourceTitle = styled.h3`
  ${RDH4};
  color: ${colorsRD.primary};

  width: 282px;
`;
export const ResourceLabel = styled.p`
  width: min-content;

  ${RDSmallCaps};
  color: ${colorsRD.primary};

  border-radius: ${spacing.xxxs}px;
  border: 1px solid ${colorsRD.accent.fossilGrey30};

  padding: ${spacing.xxs}px ${spacing.xs}px;
  margin-bottom: ${spacing.xs}px;
`;

export const ResourceButton = styled.button`
  ${RDButtonSmall};
  color: ${colorsRD.primary};

  cursor: pointer;

  background: none;
  border-radius: ${spacing.xxs}px;
  border: solid 2px ${colorsRD.accent.fossilGrey50};

  margin: ${spacing.l}px;
  padding: ${spacing.xs}px ${spacing.s}px;

  width: fit-content;
  height: min-content;
`;

export const Button = styled.button`
  background: ${colorsRD.white};
  padding: ${spacing.s}px ${spacing['m+']}px;
  border: solid 2px ${colorsRD.secondary};
  border-radius: ${spacing.xxs}px;

  display: flex;
  align-items: center;
  margin: ${spacing['xxs+']}px 0;
`;

export const ButtonText = styled.p`
  ${RDButton};
  color: ${colorsRD.primary};
`;

export const ButtonIcon = styled(Box)<{ _position?: Position }>`
  color: ${colorsRD.primary};
`;
