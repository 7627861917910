import React, { useState } from 'react';
import { ButtonComponent } from '../../Button/Button';
import styled from 'styled-components';
import { colors, mediaQueriesSizes } from '../../../../GlobalStyles';
import { Modal } from './GridItemHelper';

interface Props {
  Item?: object;
}

const BGImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: bottom center;
  top: 0;
  left: -30px;
  z-index: 0;
  background-size: contain;
  background-repeat: no-repeat;
`;

const GridItemStyle = styled.div`
  position: relative;
  text-align: center;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    text-align: right;
  }

  h3,
  p,
  a,
  button {
    text-align: center;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      text-align: right;
    }
    position: relative;
    z-index: 1;
  }

  p {
    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 100%;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 100%;
    }
  }
`;

export const GridItem5 = ({ Item, ...props }: Props) => {
  const handleClick = () => {
    setModalActive(true);
  };

  const [modalActive, setModalActive] = useState(null);

  return (
    <>
      {modalActive ? (
        <Modal
          close={() => {
            setModalActive(false);
          }}
          video={Item.Copy.Video}
        />
      ) : null}
      <GridItemStyle className={'item ' + Item.ComponentSize} {...props}>
        <BGImage style={{ backgroundImage: 'url(' + Item.Copy.BackgroundImage + ')' }} />
        <h3>{Item.Copy.Title}</h3>
        <p>{Item.Copy.Copy}</p>
        <ButtonComponent ClickFunction={handleClick} Theme={'stripped'} Label={Item.Copy.Button.Label} />
      </GridItemStyle>
    </>
  );
};
