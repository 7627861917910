import styled from 'styled-components';

import { colorsRD, media, spacing } from '../../foundations';
import { RDH2Bold, RDH3 } from '../../../styles/typography';

export const HeaderBackground = styled.div<{ screenWidth: number }>`
  background-image: url('/assets/images/pages/iamIntroPackages/background-3369.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;

  @media screen and (max-width: 1628px) {
    background-image: url('/assets/images/pages/iamIntroPackages/background-3369.png');
  }

  @media screen and (max-width: 1400px) {
    background-image: url('/assets/images/pages/iamIntroPackages/background-3256.png');
  }

  @media screen and (max-width: 1280px) {
    background-image: url('/assets/images/pages/iamIntroPackages/background-2560.png');
  }

  @media screen and (max-width: 1024px) {
    background-image: url('/assets/images/pages/iamIntroPackages/background-2048.png');
  }
  @media screen and (max-width: 300px) {
    background-image: unset;
    background-size: unset;
    background-repeat: unset;
    background-position: unset;
    background-color: ${colorsRD.background.tertiary};
  }
`;
export const IntroContainer = styled.article`
  display: flex;
  flex-direction: row;
  margin: 10px auto 100px;
  @media screen and (max-width: ${media.xl}px) {
    flex-direction: column;
  }
`;
export const IntroFormContainer = styled.iframe`
  height: 1000px;
  width: 350px;
  margin: 35px 0 0 80px;
  overflow: hidden;
  @media screen and (max-width: ${media.xl}px) {
    margin: ${spacing.xxxl}px 0 0 0;
    width: 100%;
  }
`;
export const TestDrive = styled.div`
  width: 350px;
  align-self: center;
  @media screen and (max-width: ${media.md}px) {
    width: 100%;
    margin: 0 0 0 0;
  }
  background-image: ${colorsRD.gradients.primary};
  color: #ffffff;
  padding: ${spacing.m}px;
  border-radius: 8px;
  margin: 30px 0 0 80px;
`;
export const SectionTitle = styled.h2`
  padding: 2em 0 1em 0;
  font-size: 1.3em;
`;
export const ArticleContent = styled.div`
  width: 700px;
  align-self: center;
  @media screen and (max-width: ${media.md}px) {
    width: auto;
  }
`;
export const PackageContainer = styled.div`
  padding: ${spacing.xxxl}px;
  background-image: ${colorsRD.gradients.primary};
  width: 100%;
`;
export const TrioDisplay = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  max-width: ${media.lg}px;
  margin: auto;
  @media screen and (max-width: ${media.lg}px) {
    flex-wrap: wrap;
  }
`;

export const TrioLeft = styled.div`
  width: 50%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  margin-right: ${spacing.xxxl}px;
  padding: ${spacing.xxl}px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #f3f5f6;
  box-shadow: 0 91px 36px rgba(35, 64, 87, 0.01), 0 51px 31px rgba(35, 64, 87, 0.04), 0 23px 23px rgba(35, 64, 87, 0.06),
    0 6px 12px rgba(35, 64, 87, 0.07), 0 0 0 rgba(35, 64, 87, 0.07);
  @media screen and (max-width: ${media.lg}px) {
    margin-right: 0;
    width: 100%;
    padding: ${spacing.l}px;
  }
  @media screen and (max-width: ${media.md}px) {
    margin-right: 0;
    width: 100%;
    padding: ${spacing.l}px;
  }
`;

export const TrioRight = styled.div`
  width: 50%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: ${spacing.xxl}px;
  margin-left: ${spacing.xxxl}px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #f3f5f6;
  box-shadow: 0 91px 36px rgba(35, 64, 87, 0.01), 0 51px 31px rgba(35, 64, 87, 0.04), 0 23px 23px rgba(35, 64, 87, 0.06),
    0 6px 12px rgba(35, 64, 87, 0.07), 0 0 0 rgba(35, 64, 87, 0.07);
  @media screen and (max-width: ${media.lg}px) {
    margin-left: 0;
    width: 100%;
    padding: ${spacing.l}px;
    margin-top: ${spacing.xxxxl}px;
  }
  @media screen and (max-width: ${media.md}px) {
    margin-left: 0;
    width: 100%;
    padding: ${spacing.l}px;
    margin-top: ${spacing.xxxxl}px;
  }
`;

export const TrioBottom = styled.div`
  max-width: ${media.lg}px;
  height: 100%;
  margin-top: ${spacing.xxxxl}px;
  margin: ${spacing.xxxxl}px auto 0 auto;
  padding: ${spacing.xxxl}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #f3f5f6;
  box-shadow: 0 91px 36px rgba(35, 64, 87, 0.01), 0 51px 31px rgba(35, 64, 87, 0.04), 0 23px 23px rgba(35, 64, 87, 0.06),
    0 6px 12px rgba(35, 64, 87, 0.07), 0 0 0 rgba(35, 64, 87, 0.07);
`;
export const Title = styled.h1`
  ${RDH2Bold};
  text-align: center;
  margin-bottom: ${spacing.xxl}px;
  color: ${colorsRD.white};
  @media screen and (max-width: ${media.md}px) {
    ${RDH3};
  }
`;
export const TinyBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
`;
export const TinyBlock = styled.div`
  width: 25%;
  margin-right: 2em;
`;
export const ListContainerCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
