import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const SplitColumnStyles = styled.div`
    padding: 32px 0;

    .row {
        align-items: center;
        margin: 0 -16px;
        justify-content: space-between;

        .col {
            flex: 1 0 0%;
            padding: 0 16px;
            align-items: center;

            &.divider {
                max-width: 64px;
            }

            &.copy-wrap {
                max-width: 450px;
            }
        }
    }

    h1, h2, h3, h4, h5, h6, p, li {
        color: ${colors.primary};
        text-wrap: balance;
    }

    h2 {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 16px;
    }

    h3 {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 16px;

        img {
            margin: 8px 0;
            display: block;
            height: 1.2em;
            width: auto;
        }
    }

    h4 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.375;
        letter-spacing: 0.02em;
        text-align: left;
        margin-bottom: 16px;
    }

    h5 {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.04em;
        text-align: left;
        text-transform: uppercase;
        color: ${colors.teal};
        margin-bottom: 16px;
    }

    h6 {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.04em;
        text-align: left;
        color: ${colors.teal};
    }

    p {
        margin-bottom: 16px;
    }

    p, li {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.4;
        color: ${colors.UILightSecondary};

        a {
            &:hover {
                color: ${colors.teal};
            }
        }
    }

    li {
        line-height: 0.95;
    }

    .copy-wrap.TextSize-S {
        p, li {
            font-size: 12px;
        }
    }

    .copy-wrap.TextColor-Grey {
        p, li {
            color: ${colors.light};

            &.colour-alt {
                color: ${colors.primary};
            }
        }
    }

    .ButtonWrap {
        margin-top: 16px;

        a, button {
            margin-right: 16px;
            margin-bottom: 16px;
        }
    }

    ul {
        padding: 0 0 0 16px;

        li {
            padding: 6px 0;
            list-style-type: disc;
            color: ${colors.primary};
        }
    }

    .Component-ProductLogo {
        margin-bottom: 32px;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 40px 0;

        .row {
            display: flex;
            padding-left: 16px;
            padding-right: 16px;
        }

        h2 {
            font-size: 32px;
        }

        h3 {
            font-size: 28px;
        }

        h4 {
            font-size: 24px;
        }

        h5 {
            font-size: 24px;
        }

        p, li {
            font-size: 18px;
        }

        .copy-wrap.TextSize-S {
            p, li {
                font-size: 14px;
            }
        }

        .ButtonWrap {
            margin-top: 32px;
        }

    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 32px 0;

        h2 {
            font-size: 48px;
        }

        h3 {
            font-size: 34px;
        }

        h4 {
            font-size: 32px;
        }

        p {
            margin-bottom: 24px;
        }

        p, li {
            font-size: 20px;
        }

        .copy-wrap.TextSize-S {
            p, li {
                font-size: 18px;
            }
        }

    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        .copy-wrap.TextSize-M {
            padding: 60px 0;
        }
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        .row {
            .col {
                &.copy-wrap {
                    max-width: 650px;
                }
            }
        }
    }

    &.ImagePosition-Left {
        @media (min-width: ${mediaQueriesSizes.md}px) {
            .row {
                flex-direction: row-reverse;
            }
        }
    }

    &.Background-Red {
        background: ${bgGradients.highlight};

        h1, h2, h3, h4, h5, h6, p {
            color: ${colors.white};
        }
    }

    &.Background-Off-White {
        background: #F8F7F6;
    }

    &.Background-White {
        background: #FFFFFF;
    }

    &.Background-Teal {
        background: ${bgGradients.threeDXpert};

        h1, h2, h3, h4, h5, h6, p {
            color: ${colors.white};
        }
    }

    &.Background-Primary {
        background: ${bgGradients.primary};

        h1, h2, h3, h4, h5, h6, p {
            color: ${colors.white};
        }
    }

    &.Background-Green {
        background: ${bgGradients.amphyon};

        h1, h2, h3, h4, h5, h6, p {
            color: ${colors.white};
        }
    }

    &.Background-3DXpert-Circles {
        background: ${bgGradients.threeDXpert};

        h1, h2, h3, h4, h5, h6, p {
            color: ${colors.white};
        }
    }

    &.polymers-video {
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            padding: 54px 0;
        }
    }

`;

export const ImageWrap = styled.div`
    max-width: 450px;

    &.polymers-video {
        position: relative;

        &:before {
            pointer-events: none;
            @media (min-width: ${mediaQueriesSizes.md}px) {
                content: "";
                display: block;
                position: absolute;
                width: 200%;
                background-image: url(/assets/images/backgrounds/circles-white.png);
                top: -189%;
                left: -80%;
                
                opacity: 0.5;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                z-index: 1;
                aspect-ratio: 1 / 1;
            }
            @media (min-width: ${mediaQueriesSizes.md}px) {
                top: -130%;
                left: -50%;
            }
        }
    }

    iframe {
        border-radius: 6px;
    }

    .inner {
        width: 100%;
        background: white;
        border-radius: 8px;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
            transform: scale(1.35);
        }
    }

    &.add-shadow {
        .inner {
            box-shadow: 0px 91px 36px rgba(145, 145, 145, 0.01), 0px 23px 23px rgba(145, 145, 145, 0.06), 0px 6px 12px rgba(145, 145, 145, 0.07), 0px 0px 0px rgba(145, 145, 145, 0.07);
        }
    }

    &.no-background {
        .inner {
            background: transparent;
        }
    }

    &.no-overlap {
        .inner {
            img {
                transform: scale(1);
            }
        }
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
        .inner {
            height: 250px;
        }
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        .inner {
            height: 300px;
        }
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
        max-width: 650px;
        .inner {
            height: 350px;
        }
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        max-width: 650px;
        .inner {
            height: 400px;
        }
    }

`;
