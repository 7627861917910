import React from 'react';
import { Footer } from '../../components/layouts/Footer';
import { Hero } from '../../components/layouts/Hero';
import { MediaComponent } from '../../components/layouts/MediaComponent';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { RDBold } from '../../styles/typography';
import { Features } from '../../components/layouts/FeatureContainer';
import { ListContainer } from '../../components/pageComponents/Styles/style';

import { HeaderSubtitle } from '../../components/subComponents/HeaderSubtitle';
import { HeaderBackground } from '../../components/pageComponents/header';
import { Header } from '../../components/layouts/HeaderComponent';
import { PageContainer } from '../../components/containers/PageContainer';

const GeomagicDesignXFreeTrial = () => {
  return (
    <PageContainer>
      <HelmetContainer
        image="/assets/images/pages/geomagicDesignX/hero.png"
        title="Geomagic Design X Free Trial"
        pageDescription="The industry standard for reverse engineering software. See how you can quickly go from scan to CAD with a free trial of Geomagic Design X. | Developed by Oqton"
        description="The industry standard for reverse engineering software. See how you can quickly go from scan to CAD with a free trial of Geomagic Design X. | Developed by Oqton"
        noindex
      />
      <HeaderBackground>
        <Header hideNavigation />
        <Hero
          title="<b>Trial for free </b> now"
          subtitle={<HeaderSubtitle icon="DesignX" boldText="Geomagic" text="Design X" />}
          image={{ src: '/assets/images/pages/geomagicDesignX/hero.png', alt: 'Design X Hero' }}
          description="The industry standard for reverse engineering software. See how you can quickly go from scan to CAD with a free trial of Geomagic Design X."
          buttons={[{ text: 'Download Now', link: '/geomagic-designx/download' }]}
          vertical="designX"
        />
      </HeaderBackground>
      <MediaComponent
        decreasedPadding
        articles={[
          {
            title: '<b>Discover what you can achieve</b> with the Geomagic Design X free trial',
            content: (
              <React.Fragment>
                <p>
                  Get access to the complete reverse engineering capabilities of Geomagic Design X with a 14-day free
                  trial and discover how you can:
                </p>
                <br />

                <ListContainer>
                  <li>
                    Convert 3D scan data into high-quality, <RDBold>feature-based CAD</RDBold> models.
                  </li>
                  <li>
                    <RDBold>Rebuild</RDBold> CAD data for broken tools and molds.
                  </li>
                  <li>
                    <RDBold>Recreate</RDBold> lost CAD data for parts and molds.
                  </li>
                  <li>
                    <RDBold>Design</RDBold> custom products.
                  </li>
                  <li>
                    Convert physical parts into CAD for <RDBold>new product designs</RDBold>.
                  </li>
                  <li>Make existing parts fit with new parts.</li>
                  <li>
                    Design Utilize <RDBold>exact surface fitting</RDBold> to organic 3D scans, as well as mesh editing
                    and point-cloud processing.
                  </li>
                </ListContainer>
              </React.Fragment>
            ),
            image: { src: '/assets/images/pages/geomagicDesignX/media_4.png', alt: 'Placeholder', imagePos: 'left' }
          }
        ]}
      />
      <Features
        title="Geomagic Design X Free Trial FAQs"
        columns="1"
        featureCards={[
          {
            heading: 'How do I download and install my Geomagic Design X free trial?',
            description:
              'Request a free trial and fill in your contact details. A member of our team will then contact you with the installer and install information. The Geomagic Design X <a href="https://softwaresupport.oqton.com/s/article/Getting-Started-DXCX-Installation?language=en_US&r=403&ui-knowledge-components-aura-actions.KnowledgeArticleVersionCreateDraftFromOnlineAction.createDraftFromOnlineArticle=1">step-by-step installation</a> guide can also be found on our support site.'
          },
          {
            heading: 'How long does my free trial of Geomagic Design X last?',
            description:
              'Once you have received your installer download link, your free trial will last 14 days from the day of activation.'
          },
          {
            heading: 'What are the minimum system requirements to run Geomagic Design X?',
            description:
              '<ul><li>Windows 8.1 (64-bit)</li><li>Intel or AMDS processor with a minimum 2.0 GHz</li><li>8GB RAM</li><li>30GB of available hard-disc space (additional space may be required for cache)</li><li>32-bit true colour display, screen resolution 1280x960</li><li>GPU - OpenGL 3.0 or above enabled graphics card with 4GB of RAM or more</li><li>See <a href="https://softwaresupport.oqton.com/s/article/Geomagic-Control-X?language=en_US">full system requirements</a> here.</li></ul>'
          },
          {
            heading: 'Where can I get technical support during my trial of Geomagic Design X?',
            description:
              '<span> A comprehensive list of FAQs can be found on our<a href="https://softwaresupport.oqton.com/s/article/Design-X-Knowledge-Base?language=en_US"> Geomagic Design X Knowledge Base </a>. If you can’t find what you are looking for, you can submit a case to our support team </span>'
          },
          {
            heading: 'Where can I learn the basics to help me with my Geomagic Design X free trial?',
            description:
              '<span> We have put together a series of <a href="https://softwaresupport.oqton.com/s/article/Design-X-Introductory-Tutorial-Start-Page?language=en_US"> Geomagic Design X tutorial videos </a> to get you up and running during your free trial. </span>'
          },
          {
            heading: 'What languages are supported in the free trial of Geomagic Design X?',
            description:
              'The Geomagic Design X free trial supports the following languages:\n' +
              'English, Chinese, Japanese, Korean, German, Italian, Russian, Portuguese, Spanish, Polish, Turkish, French and Czech.'
          },
          {
            heading: 'How do I cancel my Geomagic Design X free trial?',
            description: 'Your Geomagic Design X free trial will automatically end after the 14 day period.'
          }
        ]}
      />
      <Footer />
    </PageContainer>
  );
};

export default GeomagicDesignXFreeTrial;
