import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const ItemWrapper = styled.div`
  margin-top: 32px;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    margin-top: 0;
  }
`;

export const DownloadBlock = styled.a`
  width: 100%;
  box-shadow: 0px 4px 16px 0px #E9E5E24D;
  padding: 16px 16px 16px 8px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  transition: 0.25s;
  background: white;
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    color: ${colors.primary};
  }

  .card-inner {
    display: flex;
    align-items: center;

    img {
      margin-right: 15px;
      height: 34px;
    }
  }

  &:hover {
    transform: scale(1.05);
  }


  @media (min-width: ${mediaQueriesSizes.md}px) {
    width: 360px;
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 32px;
    width: 600px;
    margin-bottom: 24px;

    p {
      font-size: 24px;
    }
    
    .card-inner{
      img{
        margin-right: 16px;
        height: 56px;
      }
    }
  }


`;
export const ResourceDownloadStyles = styled.div`

  padding: 32px 0;

  h2 {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.25;
    margin-top: 32px;
    b {
      font-weight: 700;
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 64px 0;
    
    h2{
      font-size: 24px;
      width: 40%;
    }
    
    .inner {
      display: flex;
      justify-content: space-between;
    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    h2{
      width: auto;
      font-size: 32px;
      max-width: 460px;
    }
  }

`;