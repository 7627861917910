import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { GridStyles, GridWrap, Item } from './styles.jsx';
import { createMarkUp } from '../helpers';

interface Props {
  Title?: string;
  ID?: string;
  Copy?: string;
  Items: object;
}

export const BasicTextGrid = ({ Title = '', Copy = '', ID = '', Items = [], ...props }: Props) => {
  return (
    <div {...props} id={ID}>
      <GlobalStyle />
      <div className={'container'}>
        <GridStyles>
          <h2>{Title}</h2>
          <hr className={'divider teal'} />
          <p>{Copy}</p>

          <GridWrap>
            {Items.map((item, index) => {
              return (
                <Item key={index} className={`item-${index}`}>
                  <h3>{item.title}</h3>
                  <div className={'copy'} dangerouslySetInnerHTML={createMarkUp(item.copy)}></div>
                </Item>
              );
            })}
          </GridWrap>
        </GridStyles>
      </div>
    </div>
  );
};
