import React from 'react';

export const Amphyon = (
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="2" fill="url(#paint0_linear_10628_835)" />
    <path
      d="M7.96028 10L3.125 21.8761H5.21181L6.39942 18.8732H11.1838L12.3884 21.8761H14.5261L9.6908 10H7.96028ZM8.79161 12.6806L10.5391 17.0917H7.06109L8.79161 12.6806Z"
      fill="white"
    />
    <path
      d="M18.0615 17.465C18.0615 16.0568 18.7741 15.1576 19.8429 15.1576C20.8778 15.1576 21.4377 15.9381 21.4377 17.2105V21.8761H23.4057V17.3802C23.4227 16.0229 24.1353 15.1576 25.1872 15.1576C26.2221 15.1576 26.782 15.9211 26.782 17.1426V21.8761H28.75V16.7694C28.75 14.6996 27.6472 13.3592 25.7301 13.3592C24.3389 13.3592 23.4397 14.1057 22.9646 14.9031C22.5065 13.9361 21.6243 13.3592 20.4367 13.3592C19.317 13.3592 18.5026 13.8682 18.0615 14.6656V13.5628H16.0934V21.8761H18.0615V17.465Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10628_835"
        x1="1.96061e-07"
        y1="32"
        x2="25.7255"
        y2="5.68753"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#134D13" />
        <stop offset="1" stop-color="#52A043" />
      </linearGradient>
    </defs>
  </svg>
);
