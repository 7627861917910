import React, { useState } from 'react';
import { StickyHeaderNavigation } from '../../V2/StickyHeaderNavigation/StickyHeaderNavigation';

export const Header = ({
  lang,
  demoVertical,
  demoTitle,
  hideNavigation,
  whiteStripped
}: {
  lang?: string;
  demoVertical?: string;
  demoTitle?: string;
  inverted?: boolean;
  hideNavigation?: boolean;
  whiteStripped?: boolean;
}) => {
  if (!lang) {
    lang = 'en';
  }
  if (!hideNavigation) {
    return (
      <StickyHeaderNavigation
        whiteStripped={whiteStripped}
        lang={lang}
        demoVertical={demoVertical}
        demoTitle={demoTitle}
      />
    );
  } else {
    return <></>;
  }
};
