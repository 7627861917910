import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const TimelineFooter = styled.div`
  background: #1A3041;
  position: relative;

  &:before {
    content: "";
    display: block;
    background: #1A3041;
    width: 100%;
    height: calc(100% - 170px);
    bottom: 0;
    z-index: 15;
    position: absolute;
    background-image: url('/assets/images/history/gradient.png');
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  .container {
    position: relative;
    padding-top: 220px;
    padding-bottom: 64px;
    position: relative;
    z-index: 20;
    text-align: left;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      text-align: center;
    }
  }

  .center-line {
    position: absolute;
    top: 0;
    left: 20px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      left: calc(50% - 2px);
    }
    width: 4px;
    background: white;
    height: 180px;

    &:before {
      position: absolute;
      bottom: -5px;
      left: -10px;
      width: 16px;
      height: 16px;
      content: "";
      border-radius: 50%;
      border: solid 5px ${colors.teal};
      z-index: 15;
      transform: scale(1.1);
    }

    &:after {
      position: absolute;
      bottom: -5px;
      left: -10px;
      width: 16px;
      height: 16px;
      background: ${colors.teal};
      content: "";
      border-radius: 50%;
      border: solid 5px #1A3041;
      z-index: 15;
    }
  }

  .end {
    color: ${colors.teal};
    font-size: 24px;
    border: solid 2px ${colors.teal};
    @media (min-width: ${mediaQueriesSizes.md}px) {
      border: solid 4px ${colors.teal};
      font-size: 36px;
    }
    line-height: 1.3;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    border: solid 4px ${colors.teal};
    border-radius: 2px;
    display: inline-block;
    padding: 3px 12px;
    font-weight: 500;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      margin: 0 auto;
    }
  }

  h4 {
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    text-align: left;
    letter-spacing: 0.01em;
    color: white;
    line-height: 1.5;
    margin: 32px 0;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      margin: 0;
      text-align: center;
      line-height: 3;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    font-size: 32px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      text-align: center;
      font-size: 50px;
      max-width: 700px;
      margin: 0 auto;
    }

    b {
      color: ${colors.teal};
    }
  }

  .custom-button {
    margin-top: 64px;
  }




`;
export const TimelineStyles = styled.div`
  padding: 64px 0 0;
  text-align: center;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 46.8613px;
    line-height: 0.8;
    letter-spacing: 0.01em;
    color: ${colors.primary};
    text-align: center;
  }

  .start-year {
    color: ${colors.teal};
    font-size: 28px;
    line-height: 1.3;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    border: 4px solid rgb(71, 186, 206);
    display: inline-block;
    padding: 6px 12px;
    margin: 0px auto;
    font-weight: 500;
    border-radius: 2px;
  }

  hr.divider.teal {
    margin-top: 32px !important;
    margin-bottom: 64px !important;
  }

  .timeline-wrap {
    margin: 24px 0 0;
    position: relative;

    #timeline-line {
      position: absolute;
      width: 4px;
      background: ${colors.teal};
      left: 0;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        left: calc(50% - 2px);
      }
      z-index: 1;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: -8px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${colors.teal};
      }
    }

  }


  @media (min-width: ${mediaQueriesSizes.md}px) {

  }

`;

export const TimelineRow = styled.div`
  display: flex;
  justify-content: space-between;

  filter: grayscale(1);

  @media (min-width: ${mediaQueriesSizes.md}px) {
    justify-content: center;
    &:nth-of-type(odd) {
      flex-direction: row-reverse;
      .image-wrap {
        .image {
          margin-left: 0;
          margin-right: auto;
        }
      }
      .text-wrap {
        text-align: right;
      }
    }

    &:nth-of-type(even) {
      .text-wrap {
        text-align: left;
      }
    }

    &.key-0{
      .image-wrap {
        .image {
          margin-top: 36px !important;
        }
      }

    }
  }

  &:last-of-type {
    .text-wrap {
      padding-bottom: 64px;
    }
  }

  .spacing {
    width: 4px;
    position: relative;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 160px;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 4px;
      height: 100%;
      background: #E3E5E6;
      left: calc(50% - 2px);
    }

    &.mobile {
      display: block;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        display: none;
      }
    }

    &.md-up {
      display: none;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        display: block;
      }
    }

  }

  .text-wrap,
  .image-wrap {
    width: 90%;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 50%;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 35%;
    }
  }

  .image-wrap {
    display: none;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      display: block;
    }

    .image {
      margin-left: auto;
      width: 100%;
      max-width: 350px;
      aspect-ratio: 3 / 4;
      background-position: center center;
      background-size: cover;
      margin-top: 104px;
      margin-bottom: 100px;
    }
  }

  .text-wrap {
    text-align: left;
    margin-bottom: 64px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      margin-bottom: 0;
    }

    h4 {
      margin-bottom: 32px;
      color: ${colors.teal};
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      border: solid 1px ${colors.teal};
      display: inline;
      padding: 0 5px;
      font-weight: 500;
    }

    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 1.3;
      letter-spacing: 0.02em;
      color: ${colors.primary};
      margin-top: 32px;
      margin-bottom: 32px;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        margin-top: 64px;
        font-size: 32px;
      }
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        font-size: 42px;
      }
    }

    img {
      margin-top: 32px;
      width: 100%;
      height: auto;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        display: none;
      }
    }

    p {
      font-family: centrano2-book;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.3;
      letter-spacing: 0.02em;
      color: ${colors.UILightSecondary};
      margin-bottom: 32px;
      text-wrap: balance;
      a {
        text-decoration: none;
        color: ${colors.teal};
        font-weight: bold;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

  }



`;