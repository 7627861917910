import styled from 'styled-components';
import { RDPL, RDButton, RDSubtitle } from '../../../styles/typography';
import { colorsRD, fontSizes, media, spacing } from '../../foundations';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${spacing.l}px 0;

  @media screen and (max-width: ${media.md}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Button = styled.button<{ inverted?: boolean }>`
  grid-area: button;

  background-color: ${colorsRD.accent.passion};
  color: ${colorsRD.white};
  ${({ inverted }) => (inverted ? `background: inherit` : `background: ${colorsRD.accent.passion}`)};
  ${({ inverted }) => (inverted ? `border: 1px solid ${colorsRD.white}` : `border: none`)};

  ${RDButton};

  padding: ${spacing.s}px ${spacing['m+']}px;
  border-radius: ${spacing.xxs}px;
  margin-right: ${spacing.l}px;
  cursor: pointer;
`;

export const WhiteButton = styled.button`
  grid-area: button;

  background-color: ${colorsRD.white};
  color: ${colorsRD.black};

  ${RDButton};

  padding: ${spacing.s}px ${spacing['m+']}px;
  border: ${spacing.xxxs}px solid ${colorsRD.accent.fossilGrey100};
  border-radius: ${spacing.xxs}px;
  cursor: pointer;
`;

export const ArticleText = styled.p`
  ${RDPL};
`;

export const ArticleContent = styled.div`
  width: 700px;
  align-self: center;

  @media screen and (max-width: ${media.md}px) {
    width: auto;
  }
`;

export const InheritBgButton = styled.button`
  grid-area: button;

  background-color: inherit;
  color: ${colorsRD.white};
  ${RDButton};

  padding: ${spacing.s}px ${spacing['m+']}px;
  border: 1px solid ${colorsRD.white};
  border-radius: ${spacing.xxs}px;
  margin-right: ${spacing.l}px;
  cursor: pointer;
`;

export const SubTitle = styled.div`
  ${RDSubtitle};
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const ProductSubTitleContainer = styled.div`
  height: 250px;
  width: 100%;
  margin: 0;
  background-image: ${colorsRD.gradients.primary};
  font-size: ${fontSizes.l};
  color: ${colorsRD.white};
  display: table;
`;
export const ProductSubTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1646px;
  padding: ${spacing.xxxxxxxl}px 0 ${spacing.xxxxxxxl}px 0;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${media['xxl+++']}px) {
    padding-left: 3%;
    padding-right: 3%;
  }
  @media screen and (max-width: ${media['xxl++']}px) {
    padding-left: 3.5%;
    padding-right: 3.5%;
  }
  @media screen and (max-width: ${media['xxl+']}px) {
    padding-left: 4%;
    padding-right: 4%;
  }
`;
export const ProductSubTitleIcon = styled.span`
  padding-right: ${spacing.l}px;
`;
export const ProductSubTitleLeft = styled.div`
  display: flex;
  flex-basis: 100%;
  flex: 1;
`;
export const Light = styled.span`
  font-weight: 250;
  padding-left: 0.3em;
`;
export const ProductSubTitleRight = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;
