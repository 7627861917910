import React from 'react';
import { RDBold } from '../../../styles/typography';
import { spacing } from '../../foundations';
import { Icon, IconName } from '../Icon';
import { SubtitleText, SubtitleWithIcon } from './styles';

export const HeaderSubtitle = ({
  icon,
  text,
  boldText
}: {
  icon?: IconName;
  text: React.ReactNode;
  boldText?: React.ReactNode;
}) => {
  if (!icon) return <SubtitleText>{text}</SubtitleText>;
  return (
    <SubtitleWithIcon>
      <Icon name={icon} mr={`${spacing.xs}px`} />
      <RDBold>{boldText}</RDBold> {text}
    </SubtitleWithIcon>
  );
};
