import React, { useEffect } from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { ProductSlider } from '../components/V2/ProductSlider/ProductSlider';

import { BackgroundGDX, BackgroundGrey } from '../GlobalStyles';
import { SocialFollow } from '../components/V2/SocialFollow/SocialFollow';
import { MobileDownloadHero } from '../components/V2/MobileDownloadHero/MobileDownloadHero';

interface Props {
  Lang?: string;
}

const DownloadTemplateDX = ({ Lang = 'en', ...props }: Props) => {
  const data = require(`../../data/content/Pages/products/geomagic-designx/download/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);

  const vertical = '';

  const isMobileDevice = () => {
    // Check if navigator is available (i.e., if we're in a browser environment)
    if (typeof navigator !== 'undefined') {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    // Return false if navigator is not available (for example, during SSR)
    return false;
  };

  useEffect(() => {
    if (window) {
      if (!isMobileDevice()) {
        const newWindow = window.open(data.download_link, '_blank');
        if (newWindow) {
          newWindow.opener = null; // This is for security to ensure the new page can't access the original window object
        }
      }
    }
  }, []);

  return (
    <>
      <HelmetContainer currentPage={'geomagic-designx-download'} lang={Lang} title={data.helmet.title} />
      <PageContainer>
        <Header demoVertical={vertical} lang={Lang} />

        {!isMobileDevice() && (
          <LargeHero
            BackgroundStyle={'image'}
            AdditionalClasses={'thankyou-gdx'}
            Country={Lang}
            Logo={'geomagic-designx'}
            Copy={data.content.hero.copy}
            Background={'/assets/images/download/thank-you-gdx.png'}
          />
        )}

        {isMobileDevice() && (
          <MobileDownloadHero
            BackgroundStyle={'image'}
            AdditionalClasses={'thankyou-gdx'}
            Country={Lang}
            Logo={'geomagic-designx'}
            Content={data.content.hero}
            Background={'/assets/images/download/thank-you-gdx.png'}
          />
        )}

        <BackgroundGDX>
          <div className={'spacing-xl'}></div>
          <ProductSlider
            Lang={Lang}
            IgnoreProduct={'geomagic-designx'}
            Theme={'light'}
            Title={data.content.slider.title}
            Copy={data.content.slider.copy}
          />
          <div className={'spacing-xl'}></div>
        </BackgroundGDX>

        <SocialFollow Theme={'gdx'} Title={`<p>${data.content.social.title}</p>`} />

        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default DownloadTemplateDX;
