import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';

import ThankYouPageTemplate from './../../../templates/ThankYouPageTemplate';

const ThankYou = () => {
  const location = useLocation();

  const [lang, setLang] = useState('en');

  useEffect(() => {
    // Get the search string from window.location
    const searchParams = new URLSearchParams(window.location.search);

    // Get the values of the 'name' and 'lang' parameters
    const langParam = searchParams.get('lang');

    // Update the component state with the parameter values
    setLang(langParam || 'en');
  }, []);

  return (
    <>
      <ThankYouPageTemplate Product={'manufacturing-os'} Lang={lang} Location={location.pathname} />
    </>
  );
};

export default ThankYou;
