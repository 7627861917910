import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../../GlobalStyles";

export const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  position: relative;
  margin-bottom: 64px;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    grid-template-columns: repeat(3, 1fr);
  }

`;

export const DisplayResults = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;

    .results {
        font-style: normal;
        font-size: 15px;
        line-height: 1;
        letter-spacing: 0.02em;
        color: ${colors.primary};
    }

    .display-type {
        display: flex;
        align-items: center;

        hr {
            display: inline-block;
            height: 30px;
            border: ${colors.border} solid 1px;
            margin: 0 12px;
        }

        span {
            padding: 6px 12px;
            align-items: center;
            display: flex;
            background: transparent;
            border-radius: 6px;

            &.active {
                background: ${colors.border};
            }

            &:hover{
                cursor: pointer;
            }
            
            img {
                cursor: pointer;
                opacity: 0.4;
                transition: 0.35s;
                height: 16px;
                margin-right: 6px;

                &:hover {
                    transform: scale(1.1);
                }
            }

        }

    }

`;


export const FilterWrap = styled.div`
    margin: 32px 0;
    display: flex;
    align-items: center;
    
    div.filter-date{
        width: 100%;
        border-bottom: solid 1px ${colors.border};
    }
    
    button {
        background: none;
        display: flex;
        padding: 12px 16px;
        border-radius: 3px;
        border-color: ${colors.border};
        border-width: 1px;
        margin: 0 0 -1px 16px;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.02em;
        color: ${colors.primary};
        cursor: pointer;
        img {
            width: 16px;
        }
    }

    span {
        margin: 0 16px -1px;
        padding: 16px 0;
        font-size: 16px;
        display: inline-block;
        line-height: 1;
        letter-spacing: 0.02em;
        color: ${colors.primary};
        opacity: 0.5;

        &:hover {
            cursor: pointer;
            opacity: 1;
        }

        &.active {
            opacity: 1;
            border-bottom: solid 1px ${colors.primary};
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }
    }
`;

export const SingleUpcomingEventStyles = styled.a`
  background: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  padding: 32px;
  border-radius: 8px;
  text-decoration: none;
  transition: 0.35s;
  
  &:hover{
    h4.category{
      color: white;
      span{
        background: ${colors.highlight};
      }
    }
    
    h3{
      color: ${colors.highlight};
    }
    
    &.category-conference{
      h4.category{
        color: white;
        span{
          background: ${colors.teal};
        }
      }
      h3{
        color: ${colors.teal};
      }
    }
    
    &.category-partner-event{
      h4.category{
        color: white;
        span{
          background: ${colors.tertiary};
        }
      }
      h3{
        color: ${colors.tertiary};
      }

    }
    
  }
  p{
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: ${colors.primary};
    margin:32px 0 16px;
  }
  
  .logo-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
      position: relative;
      
    img.logo{
      max-height: 60px;
      max-width: 100px;
    }
    svg{
        position: absolute;
        top:0; 
        right: 0;
      color: ${colors.primary};
    }
  }
  
  h4.category {
    transition: 0.25s;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: 0.11em;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: ${colors.highlight};
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 16px;
    span {
      transition: 0.25s;
      border: solid 1px ${colors.border};
      padding: 3px 6px;
      border-radius: 4px;
    }

    &.category-conference{
      color: ${colors.teal};
      span{
        border-color: ${colors.teal};
      }
    }

    &.category-partner-event{
      color: ${colors.tertiary};
      span{
        border-color: ${colors.tertiary};
      }
    }



  }


  h3 {
    transition: 0.25s;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.01em;
    color: ${colors.primary};

    @media (min-width: ${mediaQueriesSizes.md}px) {
      height: 50px;
      font-size: 22px;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      font-size: 26px;
      height: 72px;
    }
  }
  
`;

export const EventWrap = styled.section`
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    display: flex;
    gap: 62px;
    margin-top: 62px;
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    gap: 100px;
    margin-top: 100px;
  }
`;

export const DataBlock = styled.section`
  margin: 42px 0;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    justify-content: space-between;
  }

  p, a {
    font-family: centrano2-book;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.01em;
    color: ${colors.primary};
    min-width: calc(50% - 10px); // Assuming a little gap for space-between
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    img {
      display: inline-block;
      width: 16px;
      margin: 0 6px 0 0;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 18px;
      margin-right: 8px;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      min-width: 0;
      img{
        width: 20px;
      }
    }


  }

`;

export const EventTitleStyles = styled.section`

  @media (min-width: ${mediaQueriesSizes.md}px) {
    width: 60%;
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    width: 70%;
  }

  h1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 1.2;
    color: ${colors.primary};
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 36px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 48px;
    }
  }

  h3 {
    font-family: centrano2-book;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.6;
    color: ${colors.light};
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 24px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 35px;
    }

  }

  p {
    font-family: centrano2-book;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: ${colors.light};
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 18px;
    }

  }

  h4.category {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: 0.11em;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: ${colors.highlight};
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 36px 0;

    span {
      border: solid 1px ${colors.border};
      padding: 3px 6px;
      border-radius: 4px;
    }
    
    &.conference{
      color: ${colors.teal};
    }
    
    &.partner-event{
      span{
        width: 175px;
        text-align: center;
        color: ${colors.tertiary};
      }
    }

    &:after {
      content: "";
      display: block;
      height: 1px;
      background: ${colors.border};
      width: 100%;
    }
  }

  img {
    width: 100%;
    margin: 40px 0;
  }


`;
