import React from 'react';
import { Footer } from '../../components/layouts/Footer';
import { Hero } from '../../components/layouts/Hero';
import { MediaComponent } from '../../components/layouts/MediaComponent';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { RDBold } from '../../styles/typography';
import { Features } from '../../components/layouts/FeatureContainer';
import { ListContainer } from '../../components/pageComponents/Styles/style';

import { HeaderSubtitle } from '../../components/subComponents/HeaderSubtitle';
import { HeaderBackground } from '../../components/pageComponents/header';
import { Header } from '../../components/layouts/HeaderComponent';
import { ThreeDXpert } from '../../components/icons/3dXpert';

import { PageContainer } from '../../components/containers/PageContainer';

const threedXpertXFreeTrial = () => {
  return (
    <PageContainer>
      <HelmetContainer
        image="/assets/images/pages/3DXpert/hero.png"
        title="3DXpert Free Trial"
        pageDescription="Prepare, optimize, and 3D print high-quality parts in record time. See how you can streamline the design to print workflow with a free trial of 3DXpert. | Developed by Oqton"
        description="Prepare, optimize, and 3D print high-quality parts in record time. See how you can streamline the design to print workflow with a free trial of 3DXpert. | Developed by Oqton"
        noindex
      />
      <HeaderBackground>
        <Header hideNavigation />
        <Hero
          title="<b>Free 15 day trial"
          subtitle={<HeaderSubtitle icon="ThreeDXpert" boldText="3DXpert" text="" />}
          image={{ src: '../../../assets/images/pages/3DXpert/hero.png', alt: '3Dxpert' }}
          description="Prepare, optimize, and 3D print high-quality parts in record time. See how you can streamline the design to print workflow with a free trial of 3DXpert."
          buttons={[{ text: 'Request a free trial', vertical: '3dXpert' }]}
          vertical="3dXpert"
        />
      </HeaderBackground>
      <MediaComponent
        decreasedPadding
        articles={[
          {
            title: '<b>Discover what you can achieve</b> with the 3DXpert free trial',
            content: (
              <React.Fragment>
                <p>
                  Get a 15-day trial access to 3DXpert and discover its capability for every additive manufacturing
                  application and process, including:
                </p>
                <br />

                <ListContainer>
                  <li>Import any CAD format, maintain CAD integrity, and analyze for printability</li>
                  <li>Set part orientation with real-time analysis of support and down-facing areas.</li>
                  <li>
                    Use Topology Optimization, Lattice design, Implicit Modeling, and FEA to lightweight and optimize
                    structures.
                  </li>
                  <li>Analyze parts to automatically or manually create supports for overhanging regions.</li>
                  <li>Perform end-to-end fault prediction within the design environment to verify part builds.</li>
                  <li>
                    Simulate the build process using mechanical and thermal simulation to ensure the highest printing
                    success rates.
                  </li>
                  <li>Shorten printing time and ensure high quality surface finish.</li>
                  <li>Optimize slicing and hatching to ensure repeatability and quality.</li>
                </ListContainer>
              </React.Fragment>
            ),
            image: {
              src: '/assets/images/pages/3DXpert/additive-design-for-additive-manufacturing.webp',
              alt: '3D Printed Metal Part',
              imagePos: 'left'
            }
          }
        ]}
      />
      <Features
        title="3DXpert Free Trial FAQs"
        columns="1"
        featureCards={[
          {
            heading: 'How do I download and install my 3DXpert free trial?',
            description:
              'Request a free trial and fill in your contact details. A member of our team will then contact you with the installer and install information. The 3DXpert <a href="https://oqton.ent.box.com/s/k4nvhf0kgcy9pku685udm2clc7oyyi9h" target="blank">step-by-step installation</a> guide can also be found on our support site.'
          },
          {
            heading: 'How long does my free trial of 3DXpert last?',
            description:
              'Once you have received your emailed link, your free trial will last 14 days from the day of activation.'
          },
          {
            heading: 'Where can I get technical support during my trial of 3DXpert?',
            description:
              'Comprehensive documentation is embedded in 3DXpert; you can access it quickly by pressing F1, and the relevant help for the tool you are currently using will open up. If you can’t find what you are looking for, you can submit a case to our support team.'
          },
          {
            heading: 'What languages are supported in the free trial of 3DXpert?',
            description: '3DXpert supports the following languages: English, German, Chinese, Korean, Japanese.'
          },
          {
            heading: 'How do I cancel my 3DXpert free trial?',
            description: 'Your 3DXpert free trial will automatically end after the 14 day period.'
          }
        ]}
      />
      <Footer />
    </PageContainer>
  );
};

export default threedXpertXFreeTrial;
