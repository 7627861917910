import React from 'react';
import { Card } from '../../subComponents/Card';
import { MaxContentContainer } from '../../subComponents/ContentContainer';
import { Icon } from '../../subComponents/Icon';
import { useGetInnerWidth } from '../../../utils/hooks';
import { media, spacing } from '../../foundations';
import { createMarkUp } from '../../V2/helpers';
import {
  Container,
  Button,
  ButtonIcon,
  ButtonText,
  Title,
  CardContainer,
  Description,
  ListContainer,
  StyledLink
} from './styles';

export const PrinterOverview = ({ lang }) => {
  const { innerWidth } = useGetInnerWidth();

  let data;
  if (lang) {
    data = require(`../../../../data/content/Pages/products/d2p/${lang}.json`);
  } else {
    data = require(`../../../../data/content/Pages/products/d2p/en.json`);
  }

  const printerData = [
    {
      title: 'ProJet CJP 660Pro',
      description: (
        <Description>
          {data.content.PrinterData.projet660Pro.description}
          <ListContainer
            dangerouslySetInnerHTML={createMarkUp(data.content.PrinterData.projet660Pro.list)}
          ></ListContainer>
        </Description>
      ),
      image: { src: '/assets/images/printers/CJP.png', alt: 'cjp' }
    },
    {
      title: 'ProJet 7000 HD',
      description: (
        <Description>
          {data.content.PrinterData.projet7000.description}
          <ListContainer
            dangerouslySetInnerHTML={createMarkUp(data.content.PrinterData.projet7000.list)}
          ></ListContainer>
        </Description>
      ),
      image: { src: '/assets/images/printers/HD.png', alt: 'hd' }
    },
    {
      title: 'ProJet MJP 5600',
      description: (
        <Description>
          {data.content.PrinterData.projet5600.description}
          <ListContainer
            dangerouslySetInnerHTML={createMarkUp(data.content.PrinterData.projet5600.list)}
          ></ListContainer>
        </Description>
      ),
      image: { src: '/assets/images/printers/MJP.png', alt: 'mjp' }
    },
    {
      title: 'ProX SLS 6100',
      description: (
        <Description>
          {data.content.PrinterData.projet6100.description}
          <ListContainer
            dangerouslySetInnerHTML={createMarkUp(data.content.PrinterData.projet6100.list)}
          ></ListContainer>
        </Description>
      ),
      image: { src: '/assets/images/printers/SLS.png', alt: 'sls' }
    }
  ];

  return (
    <MaxContentContainer>
      <Container>
        <Title>510k Cleared 3D Printers</Title>
        <StyledLink href="https://www.3dsystems.com/dicom-to-print/regulatory" target="_blank" rel="noreferrer">
          <Button>
            <ButtonText>See all regulatory information</ButtonText>
            <ButtonIcon ml={`${spacing.s}px`}>
              <Icon name="Arrow" />
            </ButtonIcon>
          </Button>
        </StyledLink>
        <CardContainer>
          {printerData.map((printer, index) => (
            <Card
              {...printer}
              key={index}
              image={
                printer.image
                  ? {
                      ...printer.image,
                      position: innerWidth > media.lg ? 'left' : 'top',
                      margin: innerWidth > media.lg ? undefined : '0 auto'
                    }
                  : undefined
              }
              hideLink
            />
          ))}
        </CardContainer>
      </Container>
    </MaxContentContainer>
  );
};
