import React from 'react';
import { createMarkUp } from '../../helpers';

import { ComponentStyles, ItemContainer } from './styles.jsx';
import { ProductLogo } from '../../V2/ProductLogo/ProductLogo';
import { ButtonComponent } from '../../V2/Button/Button';
import { Ribbon } from '../PlanComparisonTable/styles';

interface Props {
  Title?: string;
  Subtitle?: string;
  Items?: object;
}

export const PlansTable = ({ Title = '', Subtitle, Items, ...props }: Props) => {
  return (
    <ComponentStyles className={'Component-Plans'} {...props}>
      <div className={'container'}>
        <h3>{Subtitle}</h3>
        <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>

        <ItemContainer>
          {Items.map((item, index) => (
            <a href={item.cta.link} className={'item-top'}>
              <div className={'item'} key={index}>
                {item.ribbon && <Ribbon>{item.ribbon}</Ribbon>}
                <ProductLogo Logo={item.product} />
                <p dangerouslySetInnerHTML={createMarkUp(item.copy)}></p>

                {item.cta.link && <a href={item.cta.link}>{item.cta.copy}</a>}
                {item.cta.model && (
                  <ButtonComponent
                    Label={item.cta.copy}
                    Theme={'stripped'}
                    OpenDemoModel={true}
                    ModelVertical={'NewForm-GDX'}
                    ModelTitle={item.cta.copy}
                  />
                )}
                <span>{item.note}</span>

                <div className={'image-wrap'}>
                  <img src={item.img} alt={''} />
                </div>
              </div>
            </a>
          ))}
        </ItemContainer>
      </div>
    </ComponentStyles>
  );
};
