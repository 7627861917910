import React from 'react';

import { LocationCard } from '../../layouts/LocationCard';
import { createMarkUp } from '../../V2/helpers';
import { LocationsContainer, LocationsIntroContainer, LocationsTitle, LocationsSub, Text } from './ContactStyles';

export const Locations = props => {
  if (props.content) {
    return (
      <LocationsContainer>
        <LocationsIntroContainer>
          <LocationsTitle dangerouslySetInnerHTML={createMarkUp(props.content.title)}></LocationsTitle>
          <LocationsSub>{props.content.sub}</LocationsSub>
          <Text>{props.content.copy}</Text>
        </LocationsIntroContainer>
        <LocationCard
          city="Ghent"
          country={props.content.belgium}
          address="Sint-Jacobsnieuwstr 17"
          postalCode="9000 Ghent"
          image={<img src="../../../assets/images/locations/GH.png" alt="Illustration of Ghent" />}
        />
        <LocationCard
          country={props.content.usa}
          city="San Francisco"
          address="345 California St, Suite 600"
          postalCode="San Francisco, CA 94104"
          image={<img src="../../../assets/images/locations/SF.png" alt="Illustration of San Francisco" />}
        />
        <LocationCard
          country={props.content.china}
          city="Shanghai"
          address="93 Huaihai Middle Rd, #1406-07"
          postalCode="Shanghai 200021"
          image={<img src="../../../assets/images/locations/SH.png" alt="Illustration of Shanghai" />}
        />
        <LocationCard
          country={props.content.denmark}
          city="Copenhagen"
          address="Lyngby Hovedgade 49A, 1 tv"
          postalCode="2800 Kongens Lyngby"
          image={<img src="../../../assets/images/locations/CPH.png" alt="Illustration of Copenhagen" />}
        />
      </LocationsContainer>
    );
  } else {
    return (
      <LocationsContainer>
        <LocationsIntroContainer>
          <LocationsTitle>
            The Oqton <b>offices across the world</b>
          </LocationsTitle>
          <LocationsSub>Our locations</LocationsSub>
          <Text>
            Manufacturing is a global industry. Having a global mindset and presence is a critical component to
            understanding and addressing our customers' needs.
          </Text>
        </LocationsIntroContainer>
        <LocationCard
          city="Ghent"
          country="Belgium"
          address="Sint-Jacobsnieuwstr 17"
          postalCode="9000 Ghent"
          image={<img src="../../../assets/images/locations/GH.png" alt="Illustration of Ghent" />}
        />
        <LocationCard
          city="San Francisco"
          country="USA"
          address="345 California St, Suite 600"
          postalCode="San Francisco, CA 94104"
          image={<img src="../../../assets/images/locations/SF.png" alt="Illustration of San Francisco" />}
        />
        <LocationCard
          city="Shanghai"
          country="China"
          address="93 Huaihai Middle Rd, #1406-07"
          postalCode="Shanghai 200021"
          image={<img src="../../../assets/images/locations/SH.png" alt="Illustration of Shanghai" />}
        />
        <LocationCard
          city="Copenhagen"
          country="Denmark"
          address="Lyngby Hovedgade 49A, 1 tv"
          postalCode="2800 Kongens Lyngby"
          image={<img src="../../../assets/images/locations/CPH.png" alt="Illustration of Copenhagen" />}
        />
      </LocationsContainer>
    );
  }
};
