import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../../GlobalStyles";

export const FeaturedPartnerStyles = styled.div`
    a,p{
        display: flex;
        align-items: center;
        text-decoration: none;
        margin: 16px 0 0;
        font-family: centrano2-book;
        font-size: 16px;
    }
    a{
        transition: 0.25s;
        &:hover{
            color: ${colors.highlight};
        }
    }
    img{
        height: auto;
        width: auto;
        max-width: 50px;
        max-height: 50px;
        margin-left: 16px;
    }
`;