import styled from 'styled-components';

import {colors,mediaQueriesSizes,bgGradients} from "../../../GlobalStyles";

export const GalleryStyles = styled.div`
    background-image: url('/assets/2024/polymers/background-circles.png');
    background-size: cover;
    background-position: center center;
    padding: 100px 0;

    h2 {
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 1.2;
        letter-spacing: 0.01em;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            font-size: 32px;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: 48px;
        }
    }

    hr.divider.teal {
        margin: 24px 0 64px;
    }

    .img-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            grid-template-columns: repeat(3, 1fr);

        }

        p {
            font-family: centrano2-book;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 110%;
            letter-spacing: 0.01em;

            span {
                color: ${colors.teal};
            }
        }

        .grid-item {
            background: white;
            background: linear-gradient(53.01deg, #F8F7F6 0%, #FFFFFF 96.45%);
            border: 1.5px solid #EBE8E5;
            box-shadow: 0px 4px 16px rgba(9, 9, 9, 0.3);
            border-radius: 16px;
            padding: 36px;

            .img-outer {
                height: 180px;
                @media (min-width: ${mediaQueriesSizes.md}px) {
                    img{
                        max-width: 320px;
                        max-height: 320px;
                    }
                }
                @media (min-width: ${mediaQueriesSizes.lg}px) {
                    height: 280px;
                    img{
                        max-width: 320px;
                        max-height: 320px;
                    }
                }
                display: flex;
                align-items: center;

                img {
                    width: auto;
                    height: auto;
                    max-width: 90%;
                    max-height: 90%;
                    margin: 0 auto;
                }
            }
        }

    }
`;
