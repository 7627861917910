import React from 'react';
import moment from 'moment';

import { PageContainer } from '../../../components/containers/PageContainer';
import { Footer } from '../../../components/layouts/Footer';
import { HelmetContainer } from '../../../components/pageComponents/helmet';
import { Header } from '../../../components/layouts/HeaderComponent';
import { LargeHero } from '../../../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundHalfGrey, BackgroundAI, BackgroundWhite } from '../../../GlobalStyles';
import { PlanComparisonTable } from '../../../components/V2024/PlanComparisonTable/PlanComparisonTable';
import { Accordion } from '../../../components/V2/Accordion/Accordion';
import GeomagicDesignXPlansTemplate from '../../../templates/GDXPlansPage';

const GeomagicDesignXPlans = () => {
  const Lang = 'ja';

  return <GeomagicDesignXPlansTemplate Lang={Lang} />;
};

export default GeomagicDesignXPlans;
