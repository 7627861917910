import React from 'react';
import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { DXFreeTrial } from '../../components/V2/GeomagicDX-FreeTrial/FreeTrial';
import { BackgroundGDX, BackgroundGrey } from '../../GlobalStyles';

const geomagicDXFreeTrial = () => {
  const vertical = 'Geomagic-DesignX';
  const pageDescription = '';

  return (
    <PageContainer>
      <HelmetContainer
        showChatlio={false}
        title="Geomagic Design X Free Trial"
        pageDescription={pageDescription}
        description={pageDescription}
      />

      <DXFreeTrial />

      <Footer />
    </PageContainer>
  );
};

export default geomagicDXFreeTrial;
