export const CampaignIDs = {
  'geomagic-designx': '701Us000003ev4KIAQ',
  'geomagic-controlx': '701Us000003ev4KIAQ',
  'geomagic-wrap': '701Us000003ev4KIAQ',
  'geomagic-for-solidworks': '701Us000003ev4KIAQ',
  additive: '701Us000003T5aXIAS',
  dental: '701Us000003TlEjIAK',
  healthcare: '701Us000003TlEjIAK',
  '3dxpert': '701Us000003ThCjIAK',
  freeform: '701Us000003Tk5mIAC',
  d2p: '701Us000003Tks9IAC'
};
