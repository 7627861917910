import React from 'react';

import AdditiveTemplate from './../templates/AdditivePageTemplate';

const Additive = () => {
  const Lang = 'en';

  return <AdditiveTemplate Lang={Lang} />;
};

export default Additive;
