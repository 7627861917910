import React from 'react';

import { Main } from '../styles/background';

import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PrivacyPolicyContainer } from '../components/pageComponents/PrivacyPolicy/PrivacyPolicyContainer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

const PrivacyPolicy = () => {
  return (
    <>
      <HelmetContainer title="Privacy Policy" />
      <PageContainer>
        <Main noPadding>
          <Header />
          <Content>
            <h1 hidden>Terms of use</h1>
            <PrivacyPolicyContainer />
          </Content>
          <Footer />
        </Main>
      </PageContainer>
    </>
  );
};

export default PrivacyPolicy;
