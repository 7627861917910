import styled from 'styled-components';

export const LogoWrap = styled.div`
  display: block;
  img{
    height: 24px;
    max-width: 100%;
  }
  
  &.size-sm{
    img{
      height: 32px;
    }
  }
  &.size-md{
    img{
      height: 40px;
    }
  }
  &.size-lg{
    img{
      height: 48px;
    }
  }
  &.size-xl{
    img{
      height: 52px;
    }
  }
  
  
  
  


`;

