import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const IconGridComponentStyles = styled.div`
    padding: 64px 0;

    h4 {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.02em;
        color: ${colors.highlight};
        margin-bottom: 16px;
    }

    h2 {
        font-weight: 500;
        letter-spacing: 0.02em;
        color: ${colors.primary};
        font-size: 24px;
        margin: 0;
        line-height: 1.3;
    }

    hr.divider {
        margin: 24px 0 40px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        margin: 0 0 40px;
        font-family: centrano2-book;
        line-height: 1.7;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 72px 0;
        h4 {
            font-size: 18px;
        }
        h2 {
            font-size: 42px;
        }
        p{
            font-size: 22px;
        }
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
        padding: 80px 0;
    }

    &.theme-dark {
        background: ${colors.primary};

        h2 {
            color: ${colors.white};
        }
    }


`;

export const TableWrap = styled.div`
  display: grid;
  grid-column-gap: 64px;
  grid-row-gap: 0;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  &.counter-3,&.counter-6,&.counter-9 {
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

export const ItemStyle = styled.div`
    border-top: 1px solid rgba(35, 64, 88, 0.2);
    border-bottom: 1px solid rgba(35, 64, 88, 0.2);
    padding: 24px 0;

    img {
        height: 48px;
        margin-bottom: 4px;
    }

    h5 {
        color: ${colors.primary};
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 8px;
    }

    p {
        color: ${colors.UILightSecondary};
        font-size: 18px;
        font-weight: 300;
        line-height: 1.7;
        margin: 0;
    }

    &.theme-dark {
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);

        h5,p {
            color: white;
        }

        p {
            color: rgba(255, 255, 255, 0.65);
        }
    }

    &.total-3,&.total-6 {
        &:nth-child(n+1):nth-child(-n+3) {
            border-bottom: none;
        }
    }

    &.total-4{
        &:nth-child(n+1):nth-child(-n+2) {
            border-bottom: none;
        }
        h5,p{
            @media (min-width: ${mediaQueriesSizes.md}px) {
                font-size: 22px;
            }
            //color: white;
        }
    }



    @media (min-width: ${mediaQueriesSizes.xl}px) {
        padding: 28px 0;

    }


`;
