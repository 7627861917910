export const fontSizes = {
  s: '14px', //14
  m: '16px', //16
  ml: '18px', //18
  'm+': '20px', //20
  mxl: '24px', //24
  l: '32px', //32
  'l+': '40px', //40
  xl: '56px', //56
  xxl: '80px', //80

  RD: {
    xs: '11px', //11
    s: '15px', //15
    l: '30px', //30
    'lm+': '35px', //40
    'l+': '52px', //52
    xl: '66px' //66
  }
};
