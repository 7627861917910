import React from 'react';

export const OperatingSystem = (
  <svg width="32" height="32" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="256" height="256" rx="16" fill="url(#paint0_linear_771_209)" />
    <path
      d="M38.242 128C38.242 156.14 60.922 178.68 89.062 178.68C117.202 178.68 139.882 156.14 139.882 128C139.882 99.72 117.202 77.18 89.062 77.18C60.922 77.18 38.242 99.72 38.242 128ZM55.462 128C55.462 108.82 70.162 93.42 89.062 93.42C107.962 93.42 122.662 108.82 122.662 128C122.662 147.04 107.962 162.44 89.062 162.44C70.162 162.44 55.462 147.04 55.462 128ZM151.025 154.18C156.485 169.72 169.785 178.68 186.165 178.68C204.085 178.68 219.205 167.48 219.205 148.86C219.205 135.84 211.645 127.16 196.385 121.28L184.345 116.66C176.365 113.58 172.165 109.94 172.165 104.06C172.165 97.34 177.905 92.86 185.605 92.86C193.865 92.86 199.605 98.74 202.545 106.16L216.965 98.88C211.505 85.3 199.605 77.32 185.465 77.18C168.385 77.18 155.085 88.1 155.085 105.04C155.085 118.48 163.625 127.02 178.045 132.2L189.665 136.4C198.065 139.48 201.985 143.68 201.985 149.98C201.985 158.24 194.845 162.86 186.445 162.86C176.645 162.86 169.365 156.56 165.865 147.18L151.025 154.18Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_771_209"
        x1="15"
        y1="256"
        x2="223"
        y2="4.65289e-06"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3115D" />
        <stop offset="1" stopColor="#D04B7E" />
      </linearGradient>
    </defs>
  </svg>
);
