import styled from 'styled-components';
import { RDAccentValue, RDAccentValueExtra, RDH2, RDPBold } from '../../../styles/typography';
import { colorsRD, media, spacing } from '../../foundations';

export const BackgroundContainer = styled.div<{ inverted?: boolean }>`
  ${({ inverted }) =>
    inverted &&
    `
    background-image: ${colorsRD.gradients.secondary};
    padding: ${spacing.xxxxxl}px; 
    * { color: ${colorsRD.white} !important }
    
    @media screen and (max-width: ${media['xxl+++']}px) {
      padding: ${spacing.xxxl}px;
    } 
  `};
`;

export const Container = styled.article<{ inverted?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: ${spacing.xxxl}px ${spacing.xxxxxl}px;

  @media screen and (max-width: ${media['xxl+++']}px) {
    flex-direction: column;
  }

  @media screen and (max-width: ${media['xxl+']}px) {
    padding: ${p => (p.inverted ? `${spacing.xxxxxl}px ${spacing.xxxl}px;` : `${spacing.xxxl}px ${spacing.xxxl}px;`)};
  }
`;

export const Title = styled.h1`
  ${RDH2};
  color: ${colorsRD.primary};

  @media screen and (max-width: ${media['xxl+++']}px) {
    text-align: center;
    padding-bottom: ${spacing.xl}px;
  }
`;
export const ContentContainer = styled.section`
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  gap: ${spacing.xxl}px;

  @media screen and (max-width: ${media.xxl}px) {
    flex-direction: column;
    gap: ${spacing.xl}px;
    justify-content: center;

    text-align: center;

    width: fit-content;
  }
`;

export const StatContainer = styled.article<{ hasExtra?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, min-content);
  grid-template-areas: 'value extra' 'label label';

  align-items: center;

  max-width: 235px;

  @media screen and (max-width: ${media.xxl}px) {
    grid-template-areas: ${({ hasExtra }) =>
      hasExtra ? `'value extra' 'label label'` : `'value value' 'label label'`};
  }
`;

export const StatLabel = styled.div<{ inverted?: boolean }>`
  grid-area: label;
  ${RDPBold};
  color: ${colorsRD.primary};

  ${({ inverted }) => (inverted ? `opacity: 0.8;` : '')};
`;
export const StatValue = styled.div`
  grid-area: value;
  ${RDAccentValue};
  color: ${colorsRD.accent.sunnyWinterSky};
`;
export const StatExtra = styled.div`
  width: min-content;
  grid-area: extra;
  ${RDAccentValueExtra};
  color: ${colorsRD.accent.sunnyWinterSky};
`;
