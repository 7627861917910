import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients} from "../../../GlobalStyles";

export const ContactFormHeroStyles = styled.div`
  padding: 40px 0;
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 100px 0;
  }
`;

export const TitleWrap = styled.div`
  max-width: 825px;
  
  h1 {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.primary};
    margin-bottom: 24px;
  }

  p {
    color: ${colors.primary};
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0.01em;
    text-align: left;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    
    h1{
      font-size: 48px;
      margin-bottom: 40px;

    }
    p{
      font-size: 24px;

    }
    
  }
`;
export const FormColumns = styled.div`
  margin: 64px 0;
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: flex;
    justify-content: space-between;
  }
`;
export const Sidebar = styled.div`
  padding-right: 32px;
  h3 {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.2;
    text-align: left;
    color: ${colors.primary};
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    min-width: 400px;
  }

`;
export const ListWrap = styled.ul`
  margin: 16px 0;

  li {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.2;
    list-style-image: url('/assets/icons/red-check.png');
    margin-left: 24px;
    padding-left: 12px;
    color: ${colors.light};
  }
`;
export const IframeContainer = styled.iframe`
  max-width: 350px;
  height: 1150px;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    max-width: 850px;
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    height: 850px;
  }
`;
