import React from 'react';

import MedicalTemplate from './../templates/MedicalTemplate';

const Medical = () => {
  const Lang = 'en';

  return <MedicalTemplate Lang={Lang} />;
};

export default Medical;
