import React from 'react';

import { BlogDataStyles } from './styles.jsx';

interface Props {
  Category?: string;
  Author?: object;
  Date?: string;
  ReadTime?: string;
}

export const BlogData = ({ Category = '', Author, Date, ReadTime, ...props }: Props) => {
  const convertDate = timestamp => {
    /* This converts times from this format: 2023-06-08 to 06/08/2023 */
    const parts = timestamp.split('-'); // Split the timestamp into parts

    // Extract the year, month, and day
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    // Format the date
    const formattedDate = `${month}/${day}/${year}`;
    // Create the time
    const time = '12:00am';

    // Combine the formatted date and time
    const formattedTimestamp = `${formattedDate} ${time}`;

    return formattedTimestamp;
  };
  const FormatDate = inputDate => {
    if (inputDate.includes('-')) {
      inputDate = convertDate(inputDate);
    }

    const [datePart, timePart] = inputDate.split(' ');
    const [month, day, year] = datePart.split('/');
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const monthName = monthNames[Number(month) - 1];
    const formattedDate = `${monthName} ${Number(day)}, ${year}`;
    return formattedDate;
  };

  return (
    <BlogDataStyles className={'Component-BlogData'} {...props}>
      {Category && <span className={'category'}>{Category}</span>}
      {Author && (
        <>
          <span className={'author_name'}>{Author}</span>
        </>
      )}
      {Date && <span className={'date'}>{FormatDate(Date)}</span>}
      {ReadTime && <span className={'readTime'}>{ReadTime}</span>}
    </BlogDataStyles>
  );
};
