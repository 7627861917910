import React from 'react';

import { Icon } from '../Icon';
import { Link } from '../Link';
import { spacing } from '../../foundations';
import { ButtonIcon, ButtonText, Button } from './styles';

export const StyledLink = ({ linkText, color, url }: { linkText?: React.ReactNode; color?: string; url?: string }) => {
  const content = (
    <Button color={color}>
      <ButtonText>{linkText ?? 'Learn More'}</ButtonText>
      <ButtonIcon ml={`${spacing.xs}px`} mt={`${spacing.xxxs}px`}>
        <Icon name="Arrow" width={20} height={20} marginTop="-1px" />
      </ButtonIcon>
    </Button>
  );

  if (url) return <Link link={url}>{content}</Link>;
  return content;
};
