import React, { useEffect, useState } from 'react';
import AllArticleData from '../../../../../data/new-blog-data.json';

import { Styles } from './styles.jsx';
import BlogCategories from '../../../../../data/blog-categories.json';

import Caret from './../../../../../static/assets/icons/caret.png';
import { createMarkUp } from '../../helpers';

interface Props {
  Categories?: object;
}

export const SidebarTopics = ({ Categories = [], ...props }: Props) => {
  const [articles, setArticles] = useState([]);
  const [isTagWrapVisible, setIsTagWrapVisible] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      setTimeout(() => {
        setArticles(AllArticleData.data.allDibPosts.edges);
      }, 100);
    };
    fetchData();
  }, []);

  let uniqueCategories = [];

  if (articles && articles.length > 0) {
    uniqueCategories = Array.from(
      new Set(articles.flatMap(article => article.node.categories.map(category => category.slug)))
    );
    uniqueCategories.sort();
  }

  const handleToggleTagWrap = () => {
    setIsTagWrapVisible(!isTagWrapVisible);
  };

  const TopicLink = (index, category) => {
    if (category.name) {
      category.title = category.name;
    }

    return (
      <li key={index}>
        <a href={'/news/?topics=' + category.slug + '&page=1&search='} key={index}>
          <span dangerouslySetInnerHTML={createMarkUp(category.title)}></span>
        </a>
      </li>
    );
  };

  const ProductLink = (index, category) => {
    if (category.name) {
      category.title = category.name;
    }

    return (
      <li key={index}>
        <a href={'/news/?products=' + category.slug + '&page=1&search='} key={index}>
          <span dangerouslySetInnerHTML={createMarkUp(category.title)}></span>
        </a>
      </li>
    );
  };

  return (
    <Styles>
      <h3 className={isTagWrapVisible ? 'active' : ''} onClick={handleToggleTagWrap}>
        Topics <img src={Caret} />
      </h3>
      {isTagWrapVisible && (
        <div className={'tag-wrap'}>
          <ul>
            {uniqueCategories.map((slug, index) => {
              const category = articles
                .flatMap(article => article.node.categories)
                .find(category => category.slug === slug);
              if (BlogCategories.products.includes(category.slug)) {
                return <React.Fragment key={index}>{ProductLink(index, category)}</React.Fragment>;
              }
              return null;
            })}
          </ul>

          <hr />

          <ul>
            {uniqueCategories.map((slug, index) => {
              const category = articles
                .flatMap(article => article.node.categories)
                .find(category => category.slug === slug);
              if (
                category.slug != '' &&
                !BlogCategories.products.includes(category.slug) &&
                !BlogCategories.categories.includes(category.slug)
              ) {
                return <React.Fragment key={index}>{TopicLink(index, category)}</React.Fragment>;
              }
              return null;
            })}
          </ul>
        </div>
      )}
    </Styles>
  );
};
