export const newLetterSpacing = {
  xs: '0.2px',
  s: '0.5px',
  m: '0.65px',
  l: '2px',
  xl: '0.01em',
  xxl: '0.02em',

  RD: {
    m: '1px',
    l: '0.11em',
    xl: '6px'
  }
};
