import React from 'react';

import AutomotiveTemplate from './../../templates/AutomotiveTemplate';

const Automotive = () => {
  const Lang = 'fr';

  return <AutomotiveTemplate Lang={Lang} />;
};

export default Automotive;
