import { colors } from '../components/foundations';

export const circle = `
    content: "";
    
    display: inline-block;
    height: 6px;
    width: 6px;
    border: 1px solid ${colors.secondary};
    border-radius: 50%;
`;

export const line = `
    content: "";
    
    display: inline-block;
    width: 53px;
    height: 2px;
    vertical-align: middle;
`;
