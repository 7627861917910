import { Facebook } from './Facebook';
import { RDDownload } from './RDDownload';
import { Twitter } from './Twitter';
import { Instagram } from './Instagram';
import { LinkedIn } from './LinkedIn';
import { Youtube } from './Youtube';
import { Arrow } from './Arrow';
import { Caret } from './Caret';
import { Checkmark } from './Checkmark';
import { Cross } from './Cross';
import { Download } from './Download';
import { ArrowRight } from './ArrowRight';
import { Minus } from './Minus';
import { Plus } from './Plus';
import { RowCheckmark } from './RowCheckmark';
import { RowCheckmarkDisabled } from './RowCheckmarkDisabled';
import { ControlX } from './ControlX';
import { DesignX } from './DesignX';
import { Freeform } from './Freeform';
import { Sculpt } from './Sculpt';
import { Solidworks } from './Solidworks';
import { Wrap } from './Wrap';
import { ThreeDXpert } from './3dXpert';
import { Amphyon } from './Amphyon';
import { D2P } from './D2P';
import { OperatingSystem } from './OperatingSystem';
import { Up } from './Up';
import { Down } from './Down';
import { DesignXEssentials } from './DesignXEssentials';
import { ControlXEssentials } from './ControlXEssentials';

export const svgs = {
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  Youtube,
  Arrow,
  Caret,
  Checkmark,
  Cross,
  Download,
  ArrowRight,
  RDDownload,
  Minus,
  Plus,
  Up,
  Down,
  RowCheckmark,
  RowCheckmarkDisabled,
  ControlX,
  DesignX,
  Freeform,
  Sculpt,
  Solidworks,
  Wrap,
  ThreeDXpert,
  Amphyon,
  D2P,
  OperatingSystem,
  DesignXEssentials,
  ControlXEssentials
};
