import styled from 'styled-components';
import { spacing, colorsRD, media } from '../../foundations';
import { RDH2, RDButton, RDH3 } from '../../../styles/typography';
import { Icon } from '../../subComponents/Icon';
import { Position } from '../../subComponents/Card/types';
import { Box } from '../../containers/Box';

export const DemoContainer = styled.section`
  background-image: url('/assets/images/demo/pattern.png');
  background-size: cover;
  padding: ${spacing.xxxxxl}px;

  @media screen and (max-width: ${media.md}px) {
    padding: ${spacing.xxl}px ${spacing.m}px;
  }
`;

export const Button = styled.button`
  background: ${colorsRD.accent.passion};
  padding: ${spacing.s}px ${spacing['m+']}px;
  border: solid 2px ${colorsRD.secondary};
  border-radius: ${spacing.xxs}px;
  color: ${colorsRD.white};
  display: flex;
  align-items: center;
  margin: ${spacing.none}px auto;
  cursor: pointer;
`;

export const Title = styled.h1`
  ${RDH2};
  text-align: center;
  color: ${colorsRD.primary};

  @media screen and (max-width: ${media.md}px) {
    ${RDH3};
  }
`;

export const Description = styled.p`
  text-align: center;
  color: ${colorsRD.primary}CC;

  max-width: 700px;
  margin: 0 auto ${spacing.xxl}px;
`;

export const ArrowIcon = styled(Icon)`
  margin-left: ${spacing.xxxs}px;
  height: ${spacing.l}px;
  width: ${spacing.l}px;
`;

export const ButtonText = styled.p`
  ${RDButton};
  color: ${colorsRD.white};
`;

export const ButtonIcon = styled(Box)<{ _position?: Position }>`
  color: ${colorsRD.white};
`;
