import React, { useEffect, useState } from 'react';

import ThankYouPageTemplate from './../../../templates/ThankYouPageTemplate';

const ThankYou = () => {
  const [lang, setLang] = useState('tr');

  useEffect(() => {
    // Get the search string from window.location
    const searchParams = new URLSearchParams(window.location.search);

    // Get the values of the 'name' and 'lang' parameters
    const langParam = searchParams.get('lang');

    // Update the component state with the parameter values
    setLang(langParam || 'tr');
  }, []);

  return <ThankYouPageTemplate Product={'geomagic-wrap'} Lang={lang} />;
};

export default ThankYou;
