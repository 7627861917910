import React from 'react';
import { Link } from '../../subComponents/Link';
import { DownloadAttachmentText } from './DownloadAttachmentStyles';

export const DownloadAttachment = ({ attachment }: { attachment: string }) => {
  return (
    <DownloadAttachmentText>
      You can download it&nbsp;
      <Link link={attachment} target="_blank" isHref>
        here
      </Link>
    </DownloadAttachmentText>
  );
};
