import React, { useState } from 'react';

import { MediaContainer, MediaItem, ButtonContainer, ButtonItem } from './MediaStyles';

export const ImageContainer = ({
  noMargin,
  noBackground,
  content
}: {
  noMargin?: boolean;
  noBackground?: boolean;
  content?: any;
}) => {
  const [index, setIndex] = useState(1);

  return (
    <MediaContainer noMargin={noMargin} noBackground={noBackground}>
      {index === 1 && (
        <>
          <MediaItem index={1}>
            <img src="../../../assets/images/locations/SF/SF1.jpg" alt={content.image1} />
            <p>{content.image1}</p>
          </MediaItem>
          <MediaItem index={2}>
            <img src="../../../assets/images/locations/SF/SF2.jpg" alt={content.image2} />
            <p>{content.image2}</p>
          </MediaItem>
          <MediaItem index={3}>
            <img src="../../../assets/images/locations/SF/SF3.jpg" alt={content.image3} />
            <p>{content.image3}</p>
          </MediaItem>
        </>
      )}
      {index === 2 && (
        <>
          <MediaItem index={1}>
            <img src="../../../assets/images/locations/GH/GH1.jpg" alt={content.image4} />
            <p>{content.image4}</p>
          </MediaItem>
          <MediaItem index={2}>
            <img src="../../../assets/images/locations/GH/GH2.jpg" alt={content.image5} />
            <p>{content.image5}</p>
          </MediaItem>
          <MediaItem index={3}>
            <img src="../../../assets/images/locations/GH/GH3.jpg" alt={content.image6} />
            <p>{content.image6}</p>
          </MediaItem>
        </>
      )}
      {index === 3 && (
        <>
          <MediaItem index={1}>
            <img src="../../../assets/images/locations/SH/SH1.jpg" alt={content.image7} />
            <p>{content.image7}</p>
          </MediaItem>
          <MediaItem index={2}>
            <img src="../../../assets/images/locations/SH/SH2.jpg" alt={content.image8} />
            <p>{content.image8}</p>
          </MediaItem>
          <MediaItem index={3}>
            <img src="../../../assets/images/locations/SH/SH3.jpg" alt={content.image9} />
            <p>{content.image9}</p>
          </MediaItem>
        </>
      )}
      <ButtonContainer>
        <ButtonItem onClick={() => setIndex(1)} selected={index === 1} />
        <ButtonItem onClick={() => setIndex(2)} selected={index === 2} />
        <ButtonItem onClick={() => setIndex(3)} selected={index === 3} />
      </ButtonContainer>
    </MediaContainer>
  );
};
