import React from 'react';
import { colors, colorsRD } from '../../foundations';
import { StyledLink } from '../StyledLink';
import { Container, Content, Title, Description, Image, Overlay, StyledLink as StyledLinkWGridArea } from './styles';
import { Position } from './types';

export interface ContentProps {
  width?: string;
  height?: string;
}

export interface CardProps {
  title?: string;
  description?: React.ReactNode;
  descriptionProps?: { maxHeight?: number; maxWidth?: number };
  useSmallDescription?: boolean;
  linkText?: string;
  image?: {
    src: string;
    alt: string;
    position: Position;
    keepOriginalSize?: boolean;
    padding?: string;
    margin?: string;
    resizeWidth?: boolean;
    height?: string;
  };
  gridArea?: string;
  hideLink?: boolean;

  content?: React.ReactNode;
  contentProps?: ContentProps;
  url?: string;
}

export const Card = ({
  title,
  description,
  descriptionProps,
  useSmallDescription,
  linkText,
  image,
  gridArea,
  hideLink,
  content,
  contentProps,
  url
}: CardProps) => {
  //TODO: Hmm don't like that we have to give position to all components to change the color.
  return (
    <StyledLinkWGridArea link={url} gridArea={gridArea}>
      <Container position={image?.position}>
        <Content position={image?.position} contentProps={contentProps}>
          {content ?? (
            <React.Fragment>
              {title && <Title position={image?.position}>{title}</Title>}
              {description && (
                <Description position={image?.position} size={descriptionProps} useSmallText={useSmallDescription}>
                  {description}
                </Description>
              )}
            </React.Fragment>
          )}
          {!hideLink && (
            <StyledLink
              linkText={linkText}
              color={image?.position !== 'full' ? colorsRD.primary : colors.primary}
              url={url}
            />
          )}
        </Content>
        {image && <Image {...image} />}
        {image?.position === 'full' && <Overlay />}
      </Container>
    </StyledLinkWGridArea>
  );
};
