import React from 'react';
import styled from 'styled-components';

import { BoxProps, Box } from '../containers/Box';
import { svgs } from '../icons';
import { Color, ColorRD, colors, colorsRD } from '../foundations';

type IconWrapProps = {
  isRedesign?: boolean;
  disabled?: boolean;
  cursor?: string;
  color?: Color;
  width?: number;
  height?: number;
  size?: number;
};

const IconWrap = styled(Box)`
  display: inline-block;
  vertical-align: middle;
  color: ${({ color, isRedesign }: IconWrapProps) => color && ((isRedesign ? colorsRD : colors) as any)[color]};
  svg {
    color: currentColor;
  }

  ${({ cursor }: IconWrapProps) => cursor && `cursor: ${cursor};`}

  &,
    svg {
    ${({ width, height, size }: IconWrapProps) =>
      (size || (width && height)) && `height: ${size || height}px; width: ${size || height}px;`}
  }
`;

export type IconName = keyof typeof svgs;

export interface IconProps extends BoxProps {
  cursor?: string;
  color?: Color | ColorRD;
  isRedesign?: boolean;
  name: IconName;
  children?: React.ReactNode;
  width?: number;
  height?: number;
  size?: number;
}

export const Icon = ({ cursor, color, name, size, width, height, isRedesign, children, ...rest }: IconProps) => (
  <IconWrap color={color} width={width} height={height} size={size} cursor={cursor} isRedesign={isRedesign} {...rest}>
    {children || svgs[name]}
  </IconWrap>
);
