import React from 'react';
import EnergyTemplate from './../templates/EnergyTemplate';

const Freeform = () => {
  const Lang = 'en';
  const wordpressData = require(`../../data/wordpress-data/2623.json`);

  return <EnergyTemplate Lang={Lang} wordpressData={wordpressData} />;
};

export default Freeform;
