import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const TextColumnsStyles = styled.div`

  padding: 36px 0;

  .grid-wrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 32px;
  }

  .TitleWrap {
    h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.2;
      text-align: left;
      margin-bottom: 18px;
    }
  }

  .logo-wrap{
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    div{
      margin:0 16px;
      &:first-of-type{
        margin-left: 0;
      }
      &:last-of-type{
        margin-right: 0;
      }
    }
  }
  
  .CopyWrap {
    h4 {
      color: ${colors.primary};
      font-size: 18px;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: 0.02em;
      text-align: left;
      margin-bottom: 8px;
    }

    p,li {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.33;
      letter-spacing: 0.02em;
      text-align: left;
      margin-bottom: 32px;
    }
    
    ul{
      padding-left: 24px;
      li{
        list-style: disc;
        margin-bottom: 24px;
        ul{
          margin-top: 12px;
          li{
            list-style: circle;
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    .grid-wrap {
      &.column-counter-1 {
      }
      &.column-counter-2 {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .TitleWrap {
      h2 {
        font-size: 28px;
        margin-bottom: 40px;
      }
    }
    .CopyWrap {
      h4 {
        font-size: 20px;
        margin-bottom: 24px;
      }
    }
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    .grid-wrap.Split-30-70{
      grid-column-gap: 32px;
      &.column-counter-2 {
        grid-template-columns: repeat(3, 1fr);
      }
      .CopyWrap {
        grid-column: span 2
      }
    }

  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 72px 0;

    .grid-wrap.Split-50-50 {
      grid-column-gap: 128px;
    }

    .TitleWrap {
      h2 {
        font-size: 48px;
        margin-bottom: 40px;
      }
    }
    .CopyWrap {
      h4 {
        font-size: 24px;
        margin-bottom: 24px;
      }
      p,li {
        font-size: 24px;
      }g
    }
  }


  &.additional-padding-top{
    padding-top: 100px;
    @media (min-width: ${mediaQueriesSizes.lg}px){
      padding-top: 60px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px){
      padding-top: 140px;
    }
  }
  
  &.annoucement-top{
    padding-top: 100px;
    @media (min-width: ${mediaQueriesSizes.lg}px){
      padding-top: 60px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px){
      padding-top: 140px;
      margin-top: -70px;
    }
  }
  
  &.Theme-dark{
    background: ${bgGradients.primary};
    
    div.title-underline{
      background: ${colors.teal};
    }
    
    .TitleWrap{
      h2{
        color: white;
      }
    }
    .CopyWrap{
      h4,p,li{
        color: white;
      }
    }
  }
  
  
  
`;