import React from 'react';
import { createMarkUp } from '../../helpers';
import { ComponentStyles, ItemContainer } from './styles.jsx';

interface Props {
  content?: object;
}

export const BakerHughesBenefits = ({ content, ...props }: Props) => {
  console.log(content);

  return (
    <ComponentStyles className={`Component-BakerHughesBenefits`} {...props}>
      <div className={'container'}>
        <div className={'title-bar'}>
          <h2 dangerouslySetInnerHTML={createMarkUp(content.title)}></h2>
          <img src={'/assets/logos/baker-hughes-white.png'} />
        </div>
        <p dangerouslySetInnerHTML={createMarkUp(content.copy)}></p>

        <div className={'grid'}>
          {content.items.map((item, index) => (
            <div className={'item'} key={index}>
              <div className={'text-wrap'}>
                <h4 dangerouslySetInnerHTML={createMarkUp(item.title)}></h4>
                <p className={'copy'} dangerouslySetInnerHTML={createMarkUp(item.copy)}></p>
                <div className={'statistic'}>
                  <p className={'number'}>{item.stat_number}</p>
                  <p className={'data'}>{item.stat_copy}</p>
                </div>
              </div>
              <img src={item.img} />
            </div>
          ))}
        </div>
      </div>
    </ComponentStyles>
  );
};
