import React from 'react';

import { Link } from '../../subComponents/Link';
import { Icon } from '../../subComponents/Icon';

import { Bold } from '../../../styles/typography';
import { ContentContainer, Title, Text, Links, ImageContainer } from './404Styles';

export const Container_404 = () => {
  return (
    <ContentContainer>
      <Title>
        Looks like
        <br /> <Bold>you are lost</Bold>
      </Title>
      <Text>
        The page you are looking for isn't accessible
        <br /> or doesn't exist. Here are some helpful links instead
      </Text>
      <ImageContainer>
        <img src="/assets/images/404.png" alt="404" />
      </ImageContainer>
      <Links>
        <li>
          <Link link="/">
            Homepage <Icon name="Arrow" ml={16} />
          </Link>
        </li>
        <li>
          <Link link="/company/">
            Company <Icon name="Arrow" ml={16} />
          </Link>
        </li>
      </Links>
    </ContentContainer>
  );
};
