import React from 'react';

export const ControlXEssentials = (
  <svg id="a" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
    <defs>
      <linearGradient
        id="ControlXEssentials"
        x1=".21"
        y1="254.16"
        x2="259.21"
        y2="-5.84"
        gradientTransform="translate(0 2.11)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#095d30" />
        <stop offset="1" stop-color="#108343" />
      </linearGradient>
    </defs>
    <path
      d="M16,0H240c8.8,0,16,7.2,16,16V240c0,8.8-7.2,16-16,16H16c-8.8,0-16-7.2-16-16V16C0,7.2,7.1,0,16,0Z"
      fill="url(#ControlXEssentials)"
    />
    <path
      d="M29.9,128c0,24.6,19,43.4,43.2,43.4,7.7,0,15.6-2,23.3-6.6v-15.5c-7.4,5.3-14.9,8-23,8-16.2,0-28.6-13.1-28.6-29.4s12.4-29.5,28.6-29.5c8.3,0,15.5,2.8,22.8,8.3v-15.4c-7.1-4.4-14.9-6.8-22.9-6.8-24.3,0-43.4,19.1-43.4,43.5Zm118.4,42h16.1l-21.8-30.2,21-28.6h-15.7l-13.3,18.8-13.2-18.8h-16l20.9,28.9-21.9,29.9h15.5l14.3-20.2,14.1,20.2Zm65.6-19.2c-3.4,5.2-7.3,8.9-15,8.9-8.8,0-16.3-6.4-16.9-14.9h45.2c0-.4,.1-1.9,.1-4.2,0-17.5-12.1-30.8-29.3-30.8s-29.9,13.2-29.9,30.8,12.7,30.8,30.6,30.8c12.4,0,21.6-6,26.5-15.2l-11.3-5.4Zm-31.8-16c.8-6.7,7.6-13.6,15.7-13.6s14.9,5.8,15.7,13.6h-31.4Z"
      fill="#fff"
    />
  </svg>
);
