import React from 'react';

import AdditiveTemplate from './../../templates/AdditivePageTemplate';

const Eklemeli = () => {
  const Lang = 'tr';

  return <AdditiveTemplate Lang={Lang} />;
};

export default Eklemeli;
