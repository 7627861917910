import React, { useEffect, useState } from 'react';

interface Props {
  touchedFields: object;
  formData: object;
  copy: object;
  handleTouch: () => void;
  handleChange: () => void;
  fieldName: '';
  required: boolean;
}

export const TextArea = ({
  touchedFields,
  formData,
  copy,
  handleTouch,
  handleChange,
  fieldName,
  required,
  ...props
}: Props) => {
  const [error, setError] = useState(false);

  const showError = () => {
    if (required && formData[fieldName].length < 1) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <label className={`${error ? 'full-width errored' : 'full-width'}`}>
      {copy.fields[fieldName].label}
      {required && (
        <>
          <span className={'required'}>*</span>
          <span
            className={`error required ${
              touchedFields[fieldName] === true && formData[fieldName].length < 1 ? '' : 'hidden'
            }`}
          >
            {copy.fields[fieldName].error.required}
          </span>
        </>
      )}
      {fieldName === 'email' && (
        <>
          <span
            className={`error invalid ${
              touchedFields[fieldName] === true && formData[fieldName].length > 0 && emailValid ? '' : 'hidden'
            }`}
          >
            {copy.fields[fieldName].error.invalid}
          </span>
        </>
      )}

      <textarea
        required={'required'}
        name={fieldName}
        value={formData[fieldName]}
        onChange={handleChange}
        maxLength={500}
        onBlur={e => {
          showError();
          handleTouch(e);
        }}
      />
    </label>
  );
};
