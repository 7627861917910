import styled from "styled-components";
import {colors, mediaQueriesSizes} from "../../../../GlobalStyles";

export const ArticleLayout3 = styled.a`

  text-decoration: none;
  display: block;
  
  .bgImg {
    border-radius: 4px;
    width: 100%;
    aspect-ratio: 1.91 / 1;
    background-position: center center;
    background-size: cover;
  }

  .text-wrap {
    padding: 24px 0 16px;

    h4 {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: 0.01em;
      color: ${colors.primary};
    }

    .category-wrap {
      display: inline-block;
      margin-top: 24px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    &.theme-light {
      &.key-1 {
        grid-column: 2 / 4;

        .text-wrap {
          h4 {
            font-size: 28px;
            line-height: 1.1;
              letter-spacing: 0;
          }
        }
      }
    }
  }
`
export const Category = styled.span`
  font-size: 12px;
  line-height: 1.3;
  color: ${colors.primary};
  span{
    display: inline-block;
    padding: 8px 12px 8px 0;
  }
  span.category {
    border-radius: 4px;
    padding: 8px;
    background: rgba(35, 64, 87, 0.05);
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 12px;
  }
`;
