import React from 'react';

import { createMarkUp } from '../helpers';
import GlobalStyle from '../../../GlobalStyles';
import { LinkedInListStyles, Profile, GridWrapper } from './styles.jsx';

interface Props {
  Title?: string;
  Copy?: string;
  Profiles?: object;
}

export const LinkedInList = ({ Title, Copy, Profiles, ...props }: Props) => {
  return (
    <LinkedInListStyles>
      <div className={'container'}>
        {Title && <h3>{Title}</h3>}
        {Copy && <p>{Copy}</p>}

        <GridWrapper>
          {Profiles &&
            Profiles.map((item, index) => {
              return (
                <Profile key={index} href={item.linkedin} target={'_blank'}>
                  <img src={item.image} alt={item.name} />
                  <img className={'linkedin'} src={'/assets/icons/linkedin-v2.svg'} alt={''} />
                  <p>
                    {item.name}
                    <span>{item['job_title']}</span>
                  </p>
                </Profile>
              );
            })}
        </GridWrapper>
      </div>
    </LinkedInListStyles>
  );
};
