import styled from 'styled-components';
import { media } from '../../foundations';

export const Content = styled.div`
  width: 1130px;
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (max-width: ${media.xl}px) {
    width: auto;
    padding: 0 60px;
  }

  @media screen and (max-width: ${media.md}px) {
    padding: 0 30px;
  }
`;
