import React from 'react';

import { Flex } from '../../containers/Flex';
import { Icon } from '../../subComponents/Icon';

import { createMarkUp } from '../../V2/helpers';
import {
  ArticleContainer,
  ArticleContent,
  ArticleImage,
  ArticleTitle,
  ArticleText,
  Subtitle,
  Button,
  StyledLink,
  ArticleImageCenterBottom
} from './styles';
import { ArticleProps } from './MediaComponent';

export const Article = ({
  title,
  content,
  image,
  mediaComponent,
  subTitle,
  button,
  buttonLink,
  Lang,
  width
}: ArticleProps) => {
  const position = mediaComponent?.pos ?? (image ? image?.imagePos ?? 'left' : undefined) ?? undefined;

  return (
    <ArticleContainer imagePos={position}>
      <ArticleContent width={width}>
        {subTitle && <Subtitle>{subTitle}</Subtitle>}
        {title && <ArticleTitle dangerouslySetInnerHTML={createMarkUp(title)}></ArticleTitle>}
        {content && <ArticleText>{content}</ArticleText>}
        {button && (
          <StyledLink link={buttonLink}>
            <Button>
              {button} <Icon ml="12px" name="Arrow" />
            </Button>
          </StyledLink>
        )}
      </ArticleContent>
      {(image || mediaComponent) && (
        <Flex justifyContent="center" alignItems="center" flex="auto">
          {mediaComponent ? mediaComponent.component : <ArticleImage {...image} />}
        </Flex>
      )}
    </ArticleContainer>
  );
};
export const ArticleWithCenterImage = ({
  title,
  content,
  image,
  mediaComponent,
  subTitle,
  button,
  buttonLink,
  Lang,
  width
}: ArticleProps) => {
  const position = mediaComponent?.pos ?? (image ? image?.imagePos ?? 'left' : undefined) ?? undefined;

  return (
    <>
      <ArticleContainer imagePos={position}>
        <ArticleContent width={width}>
          {subTitle && <Subtitle>{subTitle}</Subtitle>}
          {title && <ArticleTitle dangerouslySetInnerHTML={createMarkUp(title)}></ArticleTitle>}
          {content && <ArticleText>{content}</ArticleText>}
          {button && (
            <StyledLink link={buttonLink}>
              <Button>
                {button} <Icon ml="12px" name="Arrow" />
              </Button>
            </StyledLink>
          )}
        </ArticleContent>
      </ArticleContainer>
      <React.Fragment>
        {(image || mediaComponent) && (
          <Flex justifyContent="center" alignItems="center" flex="auto">
            {mediaComponent ? mediaComponent.component : <ArticleImageCenterBottom {...image} />}
          </Flex>
        )}
      </React.Fragment>
    </>
  );
};
