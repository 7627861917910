import React from 'react';
import FreeformTemplate from './../../../templates/FreeformIndustrial';

const FreeformHealthcare = () => {
  const Lang = 'it';
  const wordpressData = require(`../../../../data/wordpress-data/1369.json`);

  return <FreeformTemplate Lang={Lang} wordpressData={wordpressData} />;
};

export default FreeformHealthcare;
