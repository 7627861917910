import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { ContactFormHero } from '../components/V2/ContactFormHero/ContactFormHero';
import { ContactNumbers } from '../components/V2/ContactNumbers/ContactNumbers';
import { BackgroundGrey, BackgroundGreyMeshBottom } from '../GlobalStyles';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { WorldMap } from '../components/V2/WorldMap/WorldMap';

interface Props {
  lang?: string;
  form?: string;
}

const ContactTemplate = ({ lang = 'en', form, ...props }: Props) => {
  const Lang = lang;
  const data = require(`../../data/content/Pages/contact/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);

  return (
    <>
      <HelmetContainer lang={Lang} title={data.helmet.title} />

      <PageContainer>
        <BackgroundGreyMeshBottom>
          <Header lang={lang} />
          <ContactFormHero
            lang={lang}
            form={form}
            title={data.v2Content.header.copy}
            sidebar={data.v2Content.header.sidebar}
          />
        </BackgroundGreyMeshBottom>
        <ContactNumbers
          copyTop={data.v2Content.contactNumbers.copy}
          copyBase={data.v2Content.contactNumbers.lowerCopy}
          numbers={globalCopy.phoneNumbers}
        />
        <BackgroundGrey>
          <WorldMap
            Title={data.v2Content.map.title}
            OfficeLabel={data.v2Content.map.officeLabels}
            HomeOfficeLabels={data.v2Content.map.homeLabels}
            Copy={data.v2Content.map.copy}
            Countries={[
              ['Los Angeles', 'Cary', 'Riom', 'Ghent', 'Bremen', 'Copenhagen'],
              ['Tel Aviv', 'Goa', 'Shanghai', 'Seoul', 'Tokyo']
            ]}
          />
        </BackgroundGrey>

        <ArticleBlock
          titleClass={'text-centered'}
          Title={data.v2Content.articles.title}
          Layout={'layout-3'}
          MostRecentBlogs={true}
        />

        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default ContactTemplate;
