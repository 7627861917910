import React, { useEffect, useRef } from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';

import { TimelineStyles, TimelineRow, TimelineFooter } from './styles.jsx';
import { createMarkUp } from '../helpers';
import { ButtonComponent } from '../Button/Button';

interface Props {
  BooleanProp?: boolean;
  data?: object;
  title?: string;
  lang?: string;
  OptionsProp?: 'small' | 'medium' | 'large';
  functionalProp?: () => void;
}

export const Timeline = ({ title = 'Timeline', data = [], lang = 'en', ...props }: Props) => {
  const listRef = useRef(null);
  const itemRefs = useRef([]);
  const fullTimelineRef = useRef(null);

  function getThreshold() {
    console.log(window.innerHeight);
    // Set different thresholds based on screen width
    if (window.innerHeight > 1500) {
      // Example for small screens like mobile devices
      return 0.95;
    } else if (window.innerHeight > 1024) {
      // Example for medium screens like tablets
      return 0.7;
    } else if (window.innerHeight > 992) {
      // Example for medium screens like tablets
      return 0.5;
    } else if (window.innerHeight > 600) {
      // Example for medium screens like tablets
      return 0.4;
    } else {
      return 0.2; // Default threshold for larger screens
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const index = Number(entry.target.getAttribute('data-index'));

          // Check if the entry is intersecting (coming into view).
          if (entry.isIntersecting) {
            // Remove grayscale filter as it comes into view.
            gsap.to(entry.target, {
              opacity: '1',
              filter: 'grayscale(0%)',
              duration: 0.25,
              ease: 'power2.out'
            });
          } else {
            if (index != 0) {
              // Check if the entry is below the viewport.
              const boundingClientRect = entry.boundingClientRect;
              const rootBounds = entry.rootBounds;

              if (boundingClientRect.top > rootBounds?.top) {
                // Apply grayscale filter as it leaves the viewport from the bottom.
                gsap.to(entry.target, {
                  opacity: '0.7',
                  filter: 'grayscale(100%)',
                  duration: 0.5,
                  ease: 'power2.out'
                });
              }
            }
          }
        });
      },
      { threshold: getThreshold() }
    );

    itemRefs.current.forEach((ref, index) => {
      observer.observe(ref);
      ref.setAttribute('data-index', index.toString());
      gsap.set(ref, {
        filter: 'grayscale(100%)',
        opacity: '0.7'
      });
    });

    // Register the ScrollTrigger plugin
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: '#full-timeline',
      start: 'top top+=200',
      end: 'bottom bottom',
      onUpdate: self => {
        const progress = self.progress;
        const viewportHeight = window.innerHeight;
        const fullTimelineHeight = fullTimelineRef.current.getBoundingClientRect();
        let dynamicHeight = window.scrollY + viewportHeight / 2 - 400;

        gsap.set('#timeline-line', { height: dynamicHeight });
      }
    });
  }, []);

  const setRef = (ref: any, index: number) => {
    itemRefs.current[index] = ref;
  };

  return (
    <TimelineStyles id={'full-timeline'} ref={fullTimelineRef} {...props}>
      <div className={'container'}>
        <h1>{title}</h1>
        <hr className={'divider teal center'} />

        <h3 className={'start-year'}>{data[0].year}</h3>

        <div className={'timeline-wrap'}>
          <div id={'timeline-line'}></div>
          {data &&
            data.map((block, index) => {
              return (
                <TimelineRow ref={ref => setRef(ref, index)} key={index} className={`key-${index}`}>
                  <div className={'spacing mobile'}></div>
                  <div className={'image-wrap'}>
                    <div
                      className={'image'}
                      style={{
                        backgroundImage: `url("${block.image}")`
                      }}
                    ></div>
                  </div>
                  <div className={'spacing md-up'}></div>
                  <div className={'text-wrap'}>
                    {index > 0 && <h4>{block.year}</h4>}

                    <img src={block.image} />

                    <h3>{block.title}</h3>
                    <div dangerouslySetInnerHTML={createMarkUp(block.copy)}></div>
                  </div>
                </TimelineRow>
              );
            })}
        </div>
      </div>
      {lang === 'en' && (
        <TimelineFooter>
          <div className={'center-line'}></div>
          <div className={'container'}>
            <h3 className={'end'}>TODAY</h3>
            <h4>Now you know our history.</h4>
            <h2>
              Do you want to be part of our <b>future</b>?
            </h2>
            <ButtonComponent Label={'Contact Us'} Link={'/contact-us/'} Theme={'white-and-primary'} />
          </div>
        </TimelineFooter>
      )}
      {lang === 'fr' && (
        <TimelineFooter>
          <div className={'center-line'}></div>
          <div className={'container'}>
            <h3 className={'end'}>AUJOURD'HUI</h3>
            <h4>Maintenant, vous connaissez notre histoire.</h4>
            <h2>
              Voulez-vous faire partie de notre <b>avenir</b> ?
            </h2>
            <ButtonComponent Label={'Contactez-nous'} Link={'/fr/contactez-nous/'} Theme={'white-and-primary'} />
          </div>
        </TimelineFooter>
      )}
      {lang === 'de' && (
        <TimelineFooter>
          <div className={'center-line'}></div>
          <div className={'container'}>
            <h3 className={'end'}>HEUTE</h3>
            <h4>Jetzt kennen Sie unsere Geschichte.</h4>
            <h2>
              Möchten Sie Teil unserer <b>Zukunft</b> sein?
            </h2>
            <ButtonComponent Label={'Kontaktieren Sie uns'} Link={'/de/kontaktiereuns/'} Theme={'white-and-primary'} />
          </div>
        </TimelineFooter>
      )}
      {lang === 'it' && (
        <TimelineFooter>
          <div className={'center-line'}></div>
          <div className={'container'}>
            <h3 className={'end'}>OGGI</h3>
            <h4>Ora conosci la nostra storia.</h4>
            <h2>
              Vuoi far parte del nostro <b>futuro</b>?
            </h2>
            <ButtonComponent Label={'Contattaci'} Link={'/it/contattaci/'} Theme={'white-and-primary'} />
          </div>
        </TimelineFooter>
      )}
      {lang === 'ja' && (
        <TimelineFooter>
          <div className={'center-line'}></div>
          <div className={'container'}>
            <h3 className={'end'}>今日</h3>
            <h4>これで私たちの歴史を知っています。</h4>
            <h2>
              私たちの<b>未来</b>の一部になりたいですか？
            </h2>
            <ButtonComponent Label={'お問い合わせ'} Link={'/ja/contact-us/'} Theme={'white-and-primary'} />
          </div>
        </TimelineFooter>
      )}
      {lang === 'ko' && (
        <TimelineFooter>
          <div className={'center-line'}></div>
          <div className={'container'}>
            <h3 className={'end'}>오늘</h3>
            <h4>이제 우리의 역사를 알게 되었습니다.</h4>
            <h2>
              우리의 <b>미래</b>의 일부가 되고 싶으십니까?
            </h2>
            <ButtonComponent Label={'문의하기'} Link={'/ko/contact-us/'} Theme={'white-and-primary'} />
          </div>
        </TimelineFooter>
      )}
    </TimelineStyles>
  );
};
