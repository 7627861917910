import React, { useState } from 'react';
import { Flex } from '../../containers/Flex';
import { MaxContentContainer } from '../../subComponents/ContentContainer';

import { VideoPlayerWrapper } from '../Videos';
import { Icon } from '../../subComponents/Icon';
import { ContactModal } from '../ContactModal/ContactModal';
import { Vertical } from '../../../utils/types';
import {
  Container,
  Title,
  Subtitle,
  Description,
  Video,
  TrustedByContainer,
  TrustedByTitle,
  TrustedByList,
  TrustedByItem,
  ContentContainer,
  VideoContainer,
  InfoContainer,
  HeroImage
} from './styles';
interface HeroProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  description?: React.ReactNode;
  companies?: { src: string; alt: string; width: number }[];
  onWatchVideoClick?: React.ReactNode;
  buttonText?: string;
  buttonLink?: string;
  image?: { src: string; alt: string };
  vertical?: Vertical;
}

export const HeroButtonLess = ({
  title,
  subtitle,
  description,
  companies,
  onWatchVideoClick,
  image,
  vertical = 'default'
}: HeroProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [bookDemo, setBookDemo] = useState(false);

  return (
    <MaxContentContainer>
      <Container>
        <ContentContainer hasTrustedBySection={!!companies} hasImage={!!image}>
          <InfoContainer hasVideo={!!onWatchVideoClick}>
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            {description && <Description>{description}</Description>}
            {onWatchVideoClick && (
              <VideoContainer onClick={() => setOpenModal(true)}>
                <Video>Watch video</Video>
                <Icon ml="12px" name="ArrowRight" />
              </VideoContainer>
            )}
          </InfoContainer>
          {image && (
            <Flex justifyContent="center" alignItems="center">
              <HeroImage src={image.src} alt={image.alt} />
            </Flex>
          )}
        </ContentContainer>

        {companies && (
          <TrustedByContainer>
            <TrustedByTitle>Trusted by</TrustedByTitle>
            <TrustedByList>
              {companies?.map(company => {
                return (
                  <TrustedByItem>
                    <img {...company} alt={company.alt} />
                  </TrustedByItem>
                );
              })}
            </TrustedByList>
          </TrustedByContainer>
        )}
        {openModal && (
          <VideoPlayerWrapper
            shown={openModal}
            handleClose={() => {
              setOpenModal(false);
            }}
            url="https://vimeo.com/599539668"
            title="watch video"
            vimeo
          />
        )}
        {bookDemo && <ContactModal handleClose={() => setBookDemo(false)} vertical={vertical} />}
      </Container>
    </MaxContentContainer>
  );
};
