import React from 'react';

import { DownloadAttachment } from '../DownloadAttachment';
import { ThankYouContainer, ThankYouTitle, ThankYouSubContainer } from './ThankYouStyles';

export const ThankYou = ({
  customText = "Thank you for contacting our team, we'll respond to your request as soon as possible",
  attachment
}: {
  customText?: React.ReactNode;
  attachment?: string;
}) => {
  return (
    <ThankYouContainer>
      <ThankYouSubContainer>
        <ThankYouTitle>{customText}</ThankYouTitle>
        {attachment && <DownloadAttachment attachment={attachment} />}
      </ThankYouSubContainer>
    </ThankYouContainer>
  );
};
