import React, { useEffect, useState } from 'react';
import AllArticleData from '../../../../../data/new-blog-data.json';

import { NewsSubNavigationStyles } from './styles';
import BlogCategories from '../../../../../data/blog-categories.json';

interface Props {}

export const NewsSubNavigation = ({ ...props }: Props) => {
  const [articles, setArticles] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      setTimeout(() => {
        setArticles(AllArticleData.data.allDibPosts.edges);
      }, 100);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (articles && articles.length > 0) {
      let tempUniqueCategories = Array.from(
        new Set(articles.flatMap(article => article.node.categories.map(category => category.slug)))
      );
      tempUniqueCategories.sort();
      tempUniqueCategories = Array.from(
        new Set(articles.flatMap(article => article.node.categories.map(category => category.slug)))
      );
      tempUniqueCategories = tempUniqueCategories.sort();
      setUniqueCategories(tempUniqueCategories);
    }
  }, [articles]);

  return (
    <NewsSubNavigationStyles>
      <div className={'container'}>
        <ul>
          <li>
            <a href={'/news/?category=all&page=1&search='}>All Articles</a>
          </li>

          {uniqueCategories.map((slug, index) => {
            const category = articles
              .flatMap(article => article.node.categories)
              .find(category => category.slug === slug);
            if (BlogCategories.categories.includes(category.slug)) {
              return (
                <li key={index}>
                  <a href={'/news/?category=' + category.slug + '&page=1&search='}>
                    {category.title && <span>{category.title}</span>}
                    {category.name && <span>{category.name}</span>}
                  </a>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
    </NewsSubNavigationStyles>
  );
};
