// EventContext.js
import React, { createContext, useContext } from 'react';

const EventContext = createContext({});

export const useEventContext = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error('useEventContext must be used within an EventContextProvider');
  }
  return context;
};

export const EventContextProvider = ({ children, eventData }) => {
  return <EventContext.Provider value={eventData}>{children}</EventContext.Provider>;
};
