import React from 'react';

export const D2P = (
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="2" fill="url(#paint0_linear_10628_3825)" />
    <path
      d="M13.1295 15.4997C13.1295 12.3884 10.6625 10 7.59837 10H4V20.9994H7.59837C10.6625 20.9994 13.1295 18.611 13.1295 15.4997ZM11.1967 15.4997C11.1967 17.5896 9.64111 19.2395 7.55123 19.2395H5.88561V11.7599H7.55123C9.64111 11.7599 11.1967 13.4098 11.1967 15.4997Z"
      fill="white"
    />
    <path
      d="M24.9929 10H21.4259V20.9994H23.3115V16.7096H24.9929C27.2399 16.7096 28.7955 15.484 28.7955 13.3627C28.7955 11.2256 27.2399 10 24.9929 10ZM23.3115 11.697H25.0243C26.1242 11.697 26.9099 12.3099 26.9099 13.3627C26.9099 14.4155 26.1242 15.0283 25.0243 15.0283H23.3115V11.697Z"
      fill="white"
    />
    <path
      d="M16.9188 12.2261C15.1589 12.2261 13.9899 13.4831 13.9899 15.3185H15.4481C15.4606 14.3631 16.0263 13.6214 16.9188 13.6214C17.7234 13.6214 18.3519 14.2248 18.3519 15.0293C18.3519 15.595 18.0502 16.123 17.3211 16.7892L13.9521 19.8188V21.013H19.9358V19.6679H16.0766L18.1382 17.8578C19.345 16.7892 19.8478 15.9847 19.8478 14.9414C19.8478 13.3574 18.6033 12.2261 16.9188 12.2261Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10628_3825"
        x1="-3.43658e-07"
        y1="32"
        x2="23.0625"
        y2="8.9375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#839299" />
        <stop offset="1" stop-color="#A4B9C0" />
      </linearGradient>
    </defs>
  </svg>
);
