import styled from 'styled-components';
import { colors, media, spacing, typography } from '../../foundations';
import { circle } from '../../../styles/shapes';

export const SkillCardContainer = styled.article<{ index: number }>`
  grid-area: ${({ index }) => `skillCard${index}`};

  display: grid;

  grid-template-rows: repeat(3, min-content);
  grid-template-areas: 'index' 'title' 'text';

  gap: ${spacing.m}px 0;

  @media (max-width: ${media.lg}px) {
    padding-bottom: ${spacing.xxl}px;
  }

  p {
    font-size: 20px;
  }
`;

export const SkillCardTitle = styled.h3`
  grid-area: title;

  width: 260px;

  color: ${colors.secondary};
  font-size: ${typography.fontSizes.xxl}px;
  font-weight: ${typography.fontWeights.medium};
`;

export const SkillCardText = styled.p`
  grid-area: text;

  width: 260px;

  color: ${colors.tertiary};

  @media (max-width: ${media.xl}px) {
    width: auto;
  }
`;

export const SkillCardIndex = styled.sub`
  grid-area: index;

  color: ${colors.secondaryAccent};

  font-weight: ${typography.fontWeights.medium};
  letter-spacing: 0;

  margin-left: -54px;

  &:before {
    margin-right: ${spacing.xxl}px;
    margin-bottom: ${spacing.xxxs}px;
    ${circle}
  }
`;
