import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { EventCTAStyles, Wrap } from './styles.jsx';
import AllEventData from '../../../../../data/events/events.json';
import { BackgroundHalfGrey } from '../../../../GlobalStyles';
import moment from 'moment';
import { ButtonComponent } from '../../Button/Button';
import { LogoWrap } from '../SideColumn/styles';
import { createSlug } from '../../../../helpers/helper';

interface Props {
  Lang: string;
}

export const EventCTA = (Lang = 'en', { ...props }: Props) => {
  const upcomingEvent = null;

  if (!upcomingEvent) {
    return null; // Or render something else if there are no upcoming events
  } else {
    const MeetingButton = upcomingEvent.links.find(link => link.type === 'meeting');
    const startformattedDate = moment(upcomingEvent.date.start).format('D');
    const endformattedDate = moment(upcomingEvent.date.end).format('D MMMM');

    return (
      <BackgroundHalfGrey>
        <div className={'container'}>
          <EventCTAStyles {...props}>
            <div>
              <a href={'/events/' + createSlug(upcomingEvent.title) + '/'}>
                <h2>
                  Visit us at {upcomingEvent.title}, {startformattedDate}-{endformattedDate}
                </h2>
                <p>{upcomingEvent.subtitle}</p>
              </a>

              {MeetingButton && (
                <ButtonComponent
                  ModelTitle={MeetingButton.copy}
                  OpenDemoModel={true}
                  IframeURL={MeetingButton.form}
                  Theme={'accent-teal'}
                  Label={MeetingButton.copy}
                  Icon={'arrow-right'}
                />
              )}
            </div>
            {upcomingEvent.event_logo && (
              <div className={'img-wrap'}>
                <a href={'/events/' + createSlug(upcomingEvent.title) + '/'}>
                  <img src={upcomingEvent.event_logo} alt={upcomingEvent.title} />
                </a>
              </div>
            )}
          </EventCTAStyles>
        </div>
      </BackgroundHalfGrey>
    );
  }
};
