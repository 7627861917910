import styled from 'styled-components';
import {colors} from "../../../../GlobalStyles";

export const SalesTelStyles = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
  color: ${colors.light};
  margin: 0;
  a {
    color: ${colors.primary};
  }
    
  &.theme-geomagic-designx{
      a{
          color: ${colors.solidGeomagicDesignx};
      }
  }  
`;

