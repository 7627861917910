export const newLineHeight = {
  xs: '14px',
  s: '16px',
  's+': '22px',
  m: '24px',
  'm+': '29.8px',
  ml: '32px',
  l: '42px',
  'l+': '48px',
  xl: '64px',
  xxl: '96px',

  RD: {
    xs: '11px',
    s: '18px',
    m: '28.8px',
    l: '39px',
    'l+': '57.2px',
    xl: '72px'
  }
};
