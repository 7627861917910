import React from 'react';
import styled from 'styled-components';
import { mediaQueriesSizes } from './../../../GlobalStyles';

export const Block = styled.div`
  pointer-events: none;
  text-align: center;
  background: red;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  opacity: 0.3;
  display: none;
  font-weight: bold;
  padding: 3px 0;
  &.XS {
    color: white;
    display: block;
    @media (min-width: ${mediaQueriesSizes.sm}px) {
      display: none;
    }
  }

  &.SM {
    background: orange;
    @media (min-width: ${mediaQueriesSizes.sm}px) and (max-width: ${mediaQueriesSizes.md}px) {
      display: block;
    }
  }

  &.MD {
    background: yellow;
    @media (min-width: ${mediaQueriesSizes.md}px) and (max-width: ${mediaQueriesSizes.lg}px) {
      display: block;
    }
  }

  &.LG {
    color: white;
    background: green;
    @media (min-width: ${mediaQueriesSizes.lg}px) and (max-width: ${mediaQueriesSizes.xl}px) {
      display: block;
    }
  }

  &.XL {
    color: white;
    background: blue;
    @media (min-width: ${mediaQueriesSizes.xl}px) and (max-width: ${mediaQueriesSizes.xxl}px) {
      display: block;
    }
  }

  &.XXL {
    color: white;
    background: indigo;
    @media (min-width: ${mediaQueriesSizes.xxl}px) and (max-width: ${mediaQueriesSizes.xxxl}px) {
      display: block;
    }
  }

  &.XXXL {
    background: violet;
    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      display: block;
    }
  }
`;

const DevelopmentMediaQueryDisplay = () => {
  if (process.env.NODE_ENV !== 'development') {
    return null; // Don't render the component in production
  }

  return (
    <>
      <Block className={'XS'}>Screen size XS</Block>
      <Block className={'SM'}>Screen size SM</Block>
      <Block className={'MD'}>Screen size MD</Block>
      <Block className={'LG'}>Screen size LG</Block>
      <Block className={'XL'}>Screen size XL</Block>
      <Block className={'XXL'}>Screen size XXL</Block>
      <Block className={'XXXL'}>Screen size XXXL</Block>
    </>
  );
};

export default DevelopmentMediaQueryDisplay;
