import styled from 'styled-components';
import {colors,mediaQueriesSizes} from "../../../GlobalStyles";

export const CopyWrapper = styled.div`
  h1 {
    font-size: 32px;
    font-weight: 300;

    b {
      font-weight: 700;
    }

    line-height: 1.1;
    color: ${colors.primary};
  }

  h2 {
    color: ${colors.primary};
    font-family: centrano2-book;
    letter-spacing: 0.01em;
    font-size: 20px;
    margin: 6px 0 0;
    b{
      font-family: centrano2-medium;
      font-weight: normal;
    }
  }

  h3{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.25;
    color: ${colors.primary};
    a{
      text-decoration: none;
      color: ${colors.teal};
    }
  }
  
  h4{
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.45;
    color: ${colors.tertiary};
  }
  
  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.1;
    margin: 16px 0 32px;
  }

  hr{
    width: 64px;
    border-color: ${colors.teal};
    border-width: 2px;
    margin: 32px 0;
  }
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    h1 {
      font-size: 46px;
    }

    h2 {
      font-size: 36px;
    }

    h3{
      font-size: 25px;
    }
    p {
      font-size: 18px;
      line-height: 1.3;
    }
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    h1 {
      font-size: 38px;
    }

    h2 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    h1 {
      font-size: 46px;
    }
    
    h2{
      font-size: 46px;
    }

    p {
      font-size: 16px;
      &.large{
        font-size: 24px;
      }
    }
  }

  &.theme-manufacturing-os{
    hr{
      border-color: ${colors.solidMOS};
    }
  }
  &.theme-3dxpert{
    hr{
      border-color: ${colors.solid3DXpert};
    }
  }
  &.theme-amphyon{
    hr{
      border-color: ${colors.solidAmphyon};
    }
  }
  &.theme-geomagic-designx{
    hr{
      border-color: ${colors.solidGeomagicDesignx};
    }
  }
  &.theme-geomagic-controlx{
    hr{
      border-color: ${colors.solidGeomagicControlx};
    }
  }
  &.theme-freeform{
    hr{
      border-color: ${colors.solidFreeform};
    }
  }
  &.theme-geomagic-wrap{
    hr{
      border-color: ${colors.solidWrap};
    }
  }
  &.theme-geomagic-for-solidworks{
    hr{
      border-color: ${colors.solidGeomagicForSolidWorks};
    }
  }
  &.theme-d2p{
    hr{
      border-color: ${colors.solidD2P};
    }
  }
  
`;

export const LargeHeroStyles = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 88px 0 32px;
  min-height: 85vh;
  margin-top: -95px;

  &.background-style-center-right {
    display: block;
  }

  &.background-colour-off-white {
    background: ${colors.offWhite};
  }

  &.background-style-image {
    .container {
      display: flex;
      flex-direction: column;

      div.image {
        img {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 200px;
          margin: 25px auto;
          display: block;
        }
      }
    }
  }

  .container {
    position: relative;
  }

  .background-style-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    opacity: 0.3;

    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 250px;
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 56%);
    }

  }

  .background.background-style-bottom-right {
    position: absolute;
    bottom: -20%;
    right: 0;
    width: 80%;
    height: 100%;
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    opacity: 0.2;
    z-index: -1;
  }

  .background.background-style-center-right {
    width: calc(100% - 32px);
    aspect-ratio: 4 / 3;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
  }

  .secondaryLogo {
    width: auto;
    height: auto;
    max-width: 150px;
    max-height: 35px;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    &.background-style-image {
      .container {
        div.image {
          img {
            max-height: none;
          }
        }
      }
    }
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    &.background-style-image {
      .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        div.image {
          width: 45%;

          img {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 80vh;
            margin: 0 0 0 auto;
          }
        }
      }
    }

  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {

    &.background-style-center-right {
      display: flex;
    }

    &.background-style-image {
      .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        div.image {
          width: 53%;

          img {
            width: auto;
            max-width: 100%;
            height: auto;
          }
        }
      }
    }

    .background.background-style-center-right {
      position: absolute;
      bottom: 0;
      right: 0;
      height: calc(100% - 95px);
      width: 50%;
      background-size: contain;
      background-position: center right;
      background-repeat: no-repeat;
    }
  }

  .copy-wrap {
    width: 100%;

    .prod-logo {
      margin-bottom: 32px;
      height: 60px;
    }


  }

  .Component-ProductLogo {
    margin-bottom: 16px;

    img {
      height: 24px;
    }

  }

  .button-wrap {
    margin: 16px 0;
    display: flex;

    a, button {
      margin-bottom: 16px;
      margin-right: 16px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.sm}px) {
    .button-wrap {
      align-items: center;

      a, button {
        margin-bottom: 0;
      }

    }

  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 159px 0 64px 0;
    .Component-ProductLogo {
      margin-bottom: 32px;
      img {
        height: 40px;
      }
    }
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {

    .copy-wrap {
      width: 70%;

      .prod-logo {
        height: 100px;
      }
    }

    .background-style-bottom-right {
      bottom: -20%;
      width: 60%;
      opacity: 1;
    }

    .background-style-cover {
      opacity: 1;
    }

    .prod-logo {
      &.right-aligned {
        position: absolute;
        right: 0;
        height: 60px;
        top: calc(50% - 30px);
      }
    }

    .copy-wrap {
      div {
        z-index: 1;
      }
    }


    .copy-wrap {
      width: 50%;
      max-width: 600px;

    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {

    .prod-logo {
      &.right-aligned {
        position: absolute;
        right: 80px;
        top: calc(50% - 60px);
        height: 120px;
      }
    }
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    min-height: 85vh;

    .prod-logo {
      &.right-aligned {
        position: absolute;
        right: 120px;

        top: calc(50% - 100px);
        height: 200px;


      }
    }
  }

  &.eventHero {

    align-items: end;
    flex-direction: column;
    display: block;

    .background {
      position: relative;
      background-size: contain;
      background-position: center 30%;
      background-repeat: no-repeat;
      opacity: 0.5;
      aspect-ratio: 1 / 0.4;
      height: auto;

      &:after {
        opacity: 0;
      }
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      display: flex;
      flex-direction: row;
      align-items: center;

      .background {
        opacity: 1;
        position: absolute;
      }
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      margin-top: 75px;
      min-height: 65vh;
      .background {
        width: 100%;
        height: 100%;
        background-position: center right;
      }
    }


  }

  &.homepageHero {
    .background {
      background-position: center right;
      opacity: 1;

      &:after {
        display: none;
      }
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      .copy-wrap {
        width: 70%;

        h1 {
          margin-bottom: 16px;
        }


      }

      .button-wrap {
        margin-top: 62px;
      }
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      .background {
        background-attachment: fixed;
      }
    }
  }

  &.thankyou-gdx {
    background-image: url(/assets/images/download/gdx-hero-bg.png);
    background-position: 100% 30%;
    background-size: 80%;
    background-repeat: no-repeat;

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      background-position: 80% 30%;
      background-size: 65%;
    }

    hr {
      border-color: ${colors.red};
    }

    h4 {
      a {
        color: ${colors.red};
      }
    }
  }
  
  &.thankyou-g4s {
    background-image: url(/assets/images/download/gdx-hero-bg.png);
    background-position: 100% 30%;
    background-size: 80%;
    background-repeat: no-repeat;

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      background-position: 80% 30%;
      background-size: 65%;
    }

    hr {
      border-color: ${colors.darkRed};
    }

    h4 {
      a {
        color: ${colors.darkRed};
      }
    }
    
    .secondary-logo{
      display: none !important;
    }
    
  }
  
  &.thankyou-gcx {
    background-image: url(/assets/images/download/gcx-hero-bg.png);
    background-position: 100% 30%;
    background-size: 80%;
    background-repeat: no-repeat;

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      background-position: 80% 30%;
      background-size: 65%;
    }

    hr {
      border-color: ${colors.green};
    }

    h4 {
      a {
        color: ${colors.green};
      }
    }
  }

`;

