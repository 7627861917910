import React from 'react';

export const Solidworks = (
  <svg width="33" height="32" viewBox="0 0 33 32" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="32" height="32" rx="2" fill="url(#paint0_linear_10571_49789)" />
    <path
      d="M17.88 12.58C16.76 10.4 14.46 9 11.74 9C7.76 9 4.5 12.26 4.5 16.26C4.5 20.26 7.7 23.5 11.72 23.5C15.7 23.5 18.5 20.48 18.5 16.58V15.68H11.38V17.78H15.94C15.62 19.8 13.96 21.24 11.74 21.24C9.04 21.24 6.94 19 6.94 16.26C6.94 13.5 9.06 11.28 11.72 11.28C13.5 11.28 14.96 12.14 15.74 13.66L17.88 12.58Z"
      fill="white"
    />
    <path
      d="M20.1212 21C20.6812 22.52 22.1012 23.5 23.9212 23.5C25.8412 23.5 27.5212 22.34 27.5212 20.34C27.5212 19.04 26.7812 18.08 24.9412 17.48L23.7812 17.1C23.0612 16.86 22.6812 16.52 22.6812 16.04C22.6812 15.48 23.2012 15.14 23.8612 15.14C24.6012 15.14 25.2012 15.66 25.5612 16.44L27.4212 15.44C26.8013 14.08 25.4612 13.22 23.8612 13.22C22.0012 13.22 20.4812 14.38 20.4812 16.22C20.4812 17.58 21.2812 18.52 22.9812 19.06L24.1612 19.44C24.9212 19.68 25.2812 19.98 25.2812 20.5C25.2812 21.18 24.6812 21.52 23.9612 21.52C23.0812 21.52 22.3412 20.92 22.0012 20.02L20.1212 21Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10571_49789"
        x1="2.375"
        y1="32"
        x2="28.375"
        y2="5.81611e-07"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E1311" />
        <stop offset="1" stop-color="#941917" />
      </linearGradient>
    </defs>
  </svg>
);
