import React, { useState } from 'react';
import { ContactModal } from '../../../layouts/ContactModal/ContactModal';
import { Icon } from '../../../subComponents/Icon';
import { ArticleContent, ArticleText, Button, ButtonContainer, WhiteButton } from '../styles';
import { ListContainer } from '../../Styles/style';

import { createMarkUp } from '../../../V2/helpers';

export const GeomagicEssentialsControlXContent = props => {
  const [openModal, setOpenModal] = useState(false);

  if (props.content) {
    return (
      <React.Fragment>
        <ArticleContent>
          <ArticleText>
            <p>{props.content.p1}</p>
            <br />
            <ListContainer dangerouslySetInnerHTML={createMarkUp(props.content.list)}></ListContainer>
          </ArticleText>
          <ButtonContainer>
            <Button onClick={() => setOpenModal(true)}>
              {props.content.button} <Icon ml="12px" name="Arrow" />
            </Button>
            <a
              href="/assets/files/brochures/geomagicessentials/geomagic-controlxessentials-general-22v1-en-digital.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <WhiteButton>
                {props.content.button2} <Icon ml="12px" name="Arrow" />
              </WhiteButton>
            </a>
          </ButtonContainer>
        </ArticleContent>
        {openModal && (
          <ContactModal
            handleClose={() => setOpenModal(false)}
            vertical="GeomagicControlXEssentials"
            title="Request a free trial"
          />
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <ArticleContent>
          <ArticleText>
            <p>
              Geomagic Control X Essentials is a professional metrology software that lets you process the data captured
              by a broad range 3D scanners and other devices, lowering the barrier-to-entry for precise 3D metrology
              tools. Regardless of your budget, project frequency, or inspection background, Control X enables more
              people in your organization to:
            </p>
            <br />
            <ListContainer>
              <li>Measure faster</li>
              <li>Measure more often</li>
              <li>Measure more completely</li>
              <li>Measure anywhere</li>
            </ListContainer>
          </ArticleText>
          <ButtonContainer>
            <Button onClick={() => setOpenModal(true)}>
              Request free trial <Icon ml="12px" name="Arrow" />
            </Button>
            <a
              href="/assets/files/brochures/geomagicessentials/geomagic-controlxessentials-general-22v1-en-digital.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <WhiteButton>
                Download Comparison Flyer <Icon ml="12px" name="Arrow" />
              </WhiteButton>
            </a>
          </ButtonContainer>
        </ArticleContent>
        {openModal && (
          <ContactModal
            handleClose={() => setOpenModal(false)}
            vertical="GeomagicControlXEssentials"
            title="Request a free trial"
          />
        )}
      </React.Fragment>
    );
  }
};
