import React from 'react';

import DownloadTemplate from '../../../templates/DownloadTemplateDX';

const DownloadGeomagicDX = () => {
  const Lang = 'de';

  return <DownloadTemplate Lang={Lang} />;
};

export default DownloadGeomagicDX;
