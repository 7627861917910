import React from 'react';

import { spacing } from '../../foundations';
import { MaxContentContainer } from '../../subComponents/ContentContainer';
import { Icon } from '../../subComponents/Icon';
import { Card } from '../../subComponents/Card';

import { CardContent, ContentProps } from './CardContent';
import {
  Container,
  Title,
  CardContainer,
  CardContainer1,
  DownloadButton,
  ButtonLink,
  BackgroundContainer
} from './FeaturesStyles';

interface FeaturesProps {
  title: React.ReactNode;
  image?: string;
  featureCards: ContentProps[];
  button?: { text: React.ReactNode; src: string };
  columns: string;
}

export const Features = ({ title, featureCards, button, columns, image }: FeaturesProps) => {
  if (columns === '1') {
    return (
      <BackgroundContainer>
        <MaxContentContainer>
          <Container>
            <Title>{title}</Title>
            <CardContainer1>
              {featureCards.map(feature => (
                <Card {...feature} content={<CardContent {...feature} />} hideLink />
              ))}
            </CardContainer1>
            {button && (
              <ButtonLink href={button.src} target="_blank" rel="noreferrer">
                <DownloadButton>
                  <span>Download {button.text}</span>
                  <Icon name="RDDownload" ml={spacing.s} isRedesign />
                </DownloadButton>
              </ButtonLink>
            )}
          </Container>
        </MaxContentContainer>
      </BackgroundContainer>
    );
  } else {
    return (
      <BackgroundContainer>
        <MaxContentContainer>
          <Container>
            <Title>{title}</Title>
            <CardContainer>
              {featureCards.map(feature => (
                <Card {...feature} content={<CardContent {...feature} />} hideLink />
              ))}
            </CardContainer>
            {button && (
              <ButtonLink href={button.src} target="_blank" rel="noreferrer">
                <DownloadButton>
                  <span>Download {button.text}</span>
                  <Icon name="RDDownload" ml={spacing.s} isRedesign />
                </DownloadButton>
              </ButtonLink>
            )}
          </Container>
        </MaxContentContainer>
      </BackgroundContainer>
    );
  }
};
