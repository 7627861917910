import styled from 'styled-components';
import { colorsRD, spacing } from '../../foundations';
import { SmallText } from '../../../styles/typography';

export const ListContainer = styled.ul`
  list-style: initial;
  padding: 0 ${spacing['m+']}px;

  &.inverted {
    color: white;
  }
`;
export const ListContainerIndented = styled.ul`
  list-style: initial;
  padding: 0 0 0 ${spacing['xl+']}px;
`;
export const Note = styled.p`
  ${SmallText};
  padding: ${spacing.RD.m}px;
`;

export const RandomButtonContainer = styled.p`
  div a button {
    background: ${colorsRD.white};
    color: ${colorsRD.primary};
    border: 1px solid ${colorsRD.primary};
  }
`;
