import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { FooterStyles } from './styles.jsx';

interface Props {
  Lang?: string;
  AdditionalClasses?: string;
}

export const FooterComponent = ({ Lang, AdditionalClasses, ...props }: Props) => {
  if (!Lang) {
    Lang = 'en';
  }

  const globalData = require(`../../../../data/content/Global/${Lang}.json`);

  return (
    <>
      <FooterStyles className={AdditionalClasses} {...props}>
        <div className={'container top-section'}>
          <div className={'col products'}>
            <h3>{globalData.Navigation.Products}</h3>
            <hr />
            <div className={'wrap'}>
              <ul>
                <li>
                  <a href={globalData.Navigation.ProductURLs.manufacturingOs}>Manufacturing OS</a>
                </li>
                <li>
                  <a href={globalData.Navigation.ProductURLs.Threedxpert}>3DXpert</a>
                </li>
                <li>
                  <a href={globalData.Navigation.ProductURLs.geoFreeeform}>Freeform</a>
                </li>
                <li>
                  <a href={globalData.Navigation.ProductURLs.d2p}>D2P</a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href={globalData.Navigation.ProductURLs.geoDesignX}>Geomagic Design X</a>
                </li>
                <li>
                  <a href={globalData.Navigation.ProductURLs.geoControlX}>Geomagic Control X</a>
                </li>
                <li>
                  <a href={globalData.Navigation.ProductURLs.geoWrap}>Geomagic Wrap</a>
                </li>
                <li>
                  <a href={globalData.Navigation.ProductURLs.GeomagicForSolidworks}>Geomagic For SOLIDWORKS</a>
                </li>
              </ul>
            </div>
          </div>
          <div className={'col'}>
            <h3>{globalData.Navigation.Solutions}</h3>
            <hr />
            <div className={'wrap'}>
              <ul>
                {globalData.Navigation.SolutionsSubMenu.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.fixedUrl}>{item.title}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className={'col'}>
            <h3>{globalData.Navigation.CompanyTitle}</h3>
            <hr />
            <div className={'wrap'}>
              <ul>
                <li>
                  <a href={globalData.Navigation.FooterURLs.company}>{globalData.Navigation.Company}</a>
                </li>
                <li>
                  <a href={globalData.Navigation.FooterURLs.history}>{globalData.Navigation.History}</a>
                </li>
                <li>
                  <a href={globalData.Navigation.FooterURLs.careers}>{globalData.Navigation.Careers}</a>
                </li>
                <li>
                  <a href={'/news/'}>{globalData.Navigation.News}</a>
                </li>
                <li>
                  <a href={globalData.Navigation.FooterURLs.ai}>{globalData.Navigation.AI}</a>
                </li>
                <li>
                  <a href={globalData.Navigation.FooterURLs.contact}>{globalData.Navigation.Contact}</a>
                </li>
                <li>
                  <a href={'https://softwaresupport.oqton.com/s/'}>{globalData.Navigation.Support}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        {Lang === 'zh-cn' && (
          <div className={'container zh-cn-footer'}>
            <p>
              <a href={'https://beian.miit.gov.cn/#/Integrated/index'}>沪ICP备18014130号-1</a>
              <a href={'https://beian.mps.gov.cn/#/query/webSearch'}>沪公网安备 31011502007913号</a>
            </p>
          </div>
        )}
        <div className={'container lower-section'}>
          <img className={'logo'} src={`/assets/images/OqtonLogo.svg`} alt="Oqton.com" />
          <ul>
            <li>
              <a href="/terms-of-use/">{globalData.Navigation.Terms}</a>
            </li>
            <li>
              {Lang === 'zh-cn' ? (
                <a href="/terms-of-service/">{globalData.Navigation.TermsAndConditions}</a>
              ) : (
                <a href="/terms-and-conditions/">{globalData.Navigation.TermsAndConditions}</a>
              )}
            </li>
            <li>
              <a href="/privacy-policy/">{globalData.Navigation.Privacy}</a>
            </li>
            <li>
              <a href="/cookie-policy/">{globalData.Navigation.Cookie}</a>
            </li>
            <li>© Oqton 2024. All Rights Reserved</li>
          </ul>
        </div>
      </FooterStyles>
    </>
  );
};
