import styled from 'styled-components';
import { colors, media, spacing, typography } from '../../foundations';

export const TermsAndConditionsContentContainer = styled.section`
  margin: 120px auto 0;
  padding-bottom: 160px;

  color: ${colors.tertiary};
  ul {
    list-style: disc;
    display: block;
    margin: 0 1em 0 5em;
  }
  li {
    padding: 0.5em 0 0.5em 1em;
  }
  li li {
    padding: 0.5em;
  }
  ul ul {
    margin: 1em 1em 1em 2em;
  }
  table {
    margin: 0 auto;
    padding: 2em;
    background-color: ${colors.tertiaryBackground};
    width: 90%;
  }
  th,
  td {
    border: 1px solid black;
    padding: 0.25em;
  }
  h2 {
    margin-top: 1em;
  }
  p,
  h3 {
    display: block;
    margin: 0 1em;
    margin-top: ${spacing.l}px;
  }

  h3 {
    color: ${colors.secondary};
    font-weight: ${typography.fontWeights.medium};
  }

  strong {
    color: ${colors.secondary};
    font-weight: ${typography.fontWeights.medium};
  }

  @media screen and (max-width: ${media.lg}px) {
    width: auto;
    p,
    h3 {
      width: auto;
    }
  }
`;

export const Title = styled.h1`
  color: ${colors.secondary};

  font-size: ${typography.fontSizes.xxxxl}px;
  font-weight: ${typography.fontWeights.medium};

  margin-bottom: ${spacing.l}px;
`;

export const Sub = styled.sub`
  color: ${colors.tertiary};

  font-size: ${typography.fontSizes.xxxl}px;
  font-weight: normal;

  &:after {
    display: block;

    content: '';

    width: 360px;
    height: 2px;

    margin-top: ${spacing.xxl}px;
    margin-bottom: 120px;

    background-color: ${colors.quaternaryAccent};
  }
`;
