import React from 'react';

import { PageContainer } from '../../components/containers/PageContainer';
import { Footer } from '../../components/layouts/Footer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { Header } from '../../components/layouts/HeaderComponent';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { FeaturedNavigation } from '../../components/V2/FeaturedNavigation/FeaturedNavigation';
import { CapterraScore } from '../../components/V2/CapterraScore/CapterraScore';
import { BackgroundGrey, BackgroundHalfGrey } from '../../GlobalStyles';
import { Accordion } from '../../components/V2/Accordion/Accordion';
import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { FullScreenImage } from '../../components/V2/FullScreenImage/FullScreenImage';
import { SolutionBlock } from '../../components/V2/SolutionBlock/SolutionBlock';
import { HighlightBlock } from '../../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { ArticleBlock } from '../../components/V2/ArticleBlock/ArticleBlock';
import { IconGrid } from '../../components/V2/IconGrid/IconGrid';
import { Grid } from '../../components/V2/Grid/Grid';
import { ResourceDownloads } from '../../components/V2/ResourceDownloads/ResourceDownloads';

const GeomagicForSolidworks = () => {
  const Lang = 'pt-br';
  const data = require(`../../../data/content/Pages/products/geomagic-for-solidworks/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);

  return (
    <PageContainer lang={'pt-br'}>
      <HelmetContainer
        currentPage={'geomagic-for-solidworks'}
        image="/assets/images/pages/geomagic-for-solidworks/computer_with_3d_parts.png"
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.description}
      />
      <Header lang={Lang} demoVertical={'GeomagicForSolidworks'} />

      <LargeHero
        Country={Lang}
        AddSalesTel={true}
        BackgroundStyle={'image'}
        BackgroundColour={'off-white'}
        Logo={'geomagic-for-solid'}
        Copy={data.content.hero.copy}
        Background={'/assets/images/pages/geomagic-for-solidworks/computer_with_3d_parts.png'}
        Button={{
          ModelTitle: globalCopy.Buttons.RequestFreeTrial,
          ModelVertical: 'GeomagicForSolidworks',
          theme: 'geo-for-solid',
          copy: globalCopy.Buttons.RequestFreeTrial,
          OpenDemoModel: true
        }}
      />

      <BackgroundGrey>
        <FeaturedNavigation
          AdditionalClass={'additional-margin-bottom'}
          Theme={'geomagicSolidWorks'}
          Title={data.content.FeaturedNavigation.Title}
          NavItems={[
            {
              Title: data.content.FeaturedNavigation.Nav[0],
              Link: '#overview'
            },
            {
              Title: data.content.FeaturedNavigation.Nav[1],
              Link: '#applications'
            },
            {
              Title: data.content.FeaturedNavigation.Nav[2],
              Link: '#highlights'
            },
            {
              Title: data.content.FeaturedNavigation.Nav[3],
              Link: '#resources'
            }
          ]}
          Copy={data.content.FeaturedNavigation.Copy}
          Image={{
            link: '/assets/images/pages/geomagic-for-solidworks/exploded_3d_part.png',
            alt: data.content.FeaturedNavigation.ImageAlt
          }}
        />

        <SplitColumn
          id={'overview'}
          TextColor={'Grey'}
          ImagePosition={'Left'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SpltiCol1.Copy}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/geomagic-for-solidworks/straightforward_data_handling_image.png',
            alt: data.content.SpltiCol1.ImgAlt
          }}
        />
        <SplitColumn
          TextColor={'Grey'}
          ImagePosition={'Right'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SpltiCol2.Copy}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/geomagic-for-solidworks/scanning_a_3d_object.png',
            alt: data.content.SpltiCol2.ImgAlt
          }}
        />
        <SplitColumn
          TextColor={'Grey'}
          ImagePosition={'Left'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SpltiCol3.Copy}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/geomagic-for-solidworks/illustration_3d_part_being_digitzed.png',
            alt: data.content.SpltiCol3.ImgAlt
          }}
        />
      </BackgroundGrey>

      <FullScreenImage
        Title={data.content.FullScreenImage.Title}
        Subtitle={data.content.FullScreenImage.Subtitle}
        TextColour={'Red'}
        Background={'/assets/images/components/background-circles.webp'}
        Image={{
          alt: data.content.FullScreenImage.ImgAlt,
          url: '/assets/images/pages/geomagic-for-solidworks/computer_showing_software.png'
        }}
      />

      <IconGrid
        id={'applications'}
        Theme={'light'}
        Title={data.content.IconGrid.Title}
        Subtitle={data.content.IconGrid.Subtitle}
        Items={data.content.IconGrid.Items}
      />

      <Grid
        ID={'highlights'}
        Title={data.content.Grid.Title}
        Subtitle={data.content.Grid.Subtitle}
        Items={data.content.Grid.Items}
      />

      <ResourceDownloads
        id={'resources'}
        Title={data.content.ResourceDownloads.Title}
        Items={data.content.ResourceDownloads.Items}
      />

      <HighlightBlock
        Theme={'geomagicSolidWorks'}
        Title={data.content.HighlightBlock.Title}
        Button={{
          ModelTitle: globalCopy.Buttons.RequestFreeTrial,
          ModelVertical: 'GeomagicForSolidworks',
          copy: globalCopy.Buttons.RequestFreeTrial,
          OpenDemoModel: true
        }}
        Image={{
          title: '',
          alt: '',
          url: '/assets/images/pages/geomagic-for-solidworks/3d_parts.png'
        }}
      />

      <Accordion id="faqs" Title={data.content.Accordion.title} Items={data.content.Accordion.Items} />

      <SolutionBlock
        Title={data.content.SolutionBlock.Title}
        Theme={'dark'}
        Items={[
          {
            logo: 'geomagic-controlx',
            title: data.content.SolutionBlock.Items[0],
            link: globalCopy.Navigation.ProductURLs.geoControlX
          },
          {
            logo: 'geomagic-wrap',
            title: data.content.SolutionBlock.Items[1],
            link: globalCopy.Navigation.ProductURLs.geoWrap
          },
          {
            logo: 'geomagic-designx',
            title: data.content.SolutionBlock.Items[2],
            link: globalCopy.Navigation.ProductURLs.geoDesignX
          }
        ]}
      />

      <Footer lang={Lang} />
    </PageContainer>
  );
};

export default GeomagicForSolidworks;
