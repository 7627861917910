import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const GDXVideoStyles = styled.div`
    background: #d81c1d;
    
    &.top-announcement-bar{
        padding-top: 120px;
    }
    
    @media (min-width: ${mediaQueriesSizes.md}px) {
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent;
            opacity: 1;
            width: 100%;
            height: 100%;
            background-image: url('/assets/2024/geomagic-designx/mesh-wireframe-bg.png');
            background-position: right bottom;
            background-size: auto 100%;
            background-repeat: no-repeat;
            z-index: 10;
        }
    }
    position: relative;

    .container {
        display: flex;
        align-items: center;
        color: white;

        video {
            max-width: 100%;
            margin: 16px 0;
            -webkit-box-shadow: inset 0px 0px 65px 0px rgba(216, 28, 29, 1);
            -moz-box-shadow: inset 0px 0px 65px 0px rgba(216, 28, 29, 1);
            box-shadow: inset 0px 0px 65px 0px rgba(216, 28, 29, 1);

            @media (min-width: ${mediaQueriesSizes.md}px) {
                width: 50%;
                margin: 42px 0;
                max-width: 600px;
                position: relative;
                z-index: 5;
            }
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                margin: 42px 0;
                max-width: 600px;
            }
            max-height: 600px;
        }

    }

    .copy-wrap {
        @media (min-width: ${mediaQueriesSizes.md}px) {
            position: absolute;
            display: flex;
            align-items: center;
            width: 50vw;
            top: 0;
            right: 0;
            height: 100%;
            z-index: 15;
        }

        .inner {

            .fake-container {
                width: calc(100% - 32px);
                @media (min-width: ${mediaQueriesSizes.sm}px) {
                    width: calc(${containerWidths.sm}px - 32px);
                    margin: 0 auto;
                }
                @media (min-width: ${mediaQueriesSizes.md}px) {
                    width: calc((${containerWidths.md}px / 2) - 36px);
                    margin: 0 auto 0 0;
                }
                @media (min-width: ${mediaQueriesSizes.xl}px) {
                    width: auto;
                }
            }

            padding: 0 16px 16px;

            @media (min-width: ${mediaQueriesSizes.md}px) {
                width: 50vw;
                padding: 24px;
                backdrop-filter: blur(5px);
                background: rgba(244 116 118 / 26%);
                -webkit-border-top-left-radius: 6px;
                -webkit-border-bottom-left-radius: 6px;
                -moz-border-radius-topleft: 6px;
                -moz-border-radius-bottomleft: 6px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                padding: 56px 80px;
            }
        }

        h2 {
            font-style: normal;
            font-weight: 700;
            line-height: 1.3;
            letter-spacing: 0.02em;
            color: ${colors.UILightBackground};
            font-size: 24px;
            margin-bottom: 16px;
            @media (min-width: ${mediaQueriesSizes.md}px) {
                font-size: 28px;
            }
            @media (min-width: ${mediaQueriesSizes.lg}px) {
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                font-size: 50px;
                margin-bottom: 24px;
                width: auto;
            }
        }

        p {
            font-family: centrano2-book;
            font-weight: 400;
            font-size: 18px;
            @media (min-width: ${mediaQueriesSizes.md}px) {
                font-size: 22px;
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                font-size: 26px;
            }
            line-height: 1.16;
            color: #FFFFFF;
        }
    }


    @media (min-width: ${mediaQueriesSizes.md}px) {
    }
`;