import styled from 'styled-components';
import {
  space,
  SpaceProps,
  maxWidth,
  MaxWidthProps,
  minWidth,
  MinWidthProps,
  maxHeight,
  MaxHeightProps,
  minHeight,
  MinHeightProps,
  width,
  WidthProps,
  height,
  HeightProps,
  flex,
  FlexProps,
  alignSelf,
  AlignSelfProps,
  textAlign,
  TextAlignProps,
  overflow,
  OverflowProps,
  position,
  PositionProps
} from 'styled-system';
import { HTMLAttributes, ComponentType } from 'react';

export interface BoxProps
  extends SpaceProps,
    MaxWidthProps,
    MinWidthProps,
    MaxHeightProps,
    MinHeightProps,
    WidthProps,
    HeightProps,
    FlexProps,
    AlignSelfProps,
    TextAlignProps,
    OverflowProps,
    PositionProps,
    HTMLAttributes<HTMLDivElement> {
  as?: any;
  ref?: any;
}

export const Box: ComponentType<BoxProps> = styled.div.attrs((props: BoxProps) => {
  // because width and height are often changed programmatically with
  // re-size observers they need to be injected in to the element styles
  // and not part of the styled-components css class, otherwise it constantly generates new classes
  const style = {
    ...height(props),
    ...width(props),
    ...maxHeight(props),
    ...minHeight(props),
    ...maxWidth(props),
    ...minWidth({ minWidth: props.minWidth || 0 })
  };
  return Object.keys(style).length === 0 ? {} : { style };
})`
  ${space}
  ${flex}
  ${alignSelf}
  ${textAlign}
  ${overflow}
  ${position}
`;
