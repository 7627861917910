import styled from 'styled-components';

import {colors,mediaQueriesSizes,bgGradients} from "../../../GlobalStyles";

export const FullScreenImageStyles = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 32px 0;
  h2,h4 {
    &.text-Red {
      background: ${bgGradients.designx}
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
    &.text-Green {
      background: ${bgGradients.controlx}
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
    &.text-White {
      color: white;
    }
    &.text-Primary {
      color: ${colors.primary};
    }
    &.text-Teal {
      color: ${colors.teal};
    }
  }
  
  img{
    width: auto;
    max-width: 100%;
    max-height: 65vh;
    display: block;
    margin: 36px auto 0;
  }

  h2 {
    font-size: 32px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 16px;
  }

  h4{
    text-align: center;
    font-weight: 300;
    font-size: 18px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    h2{
      font-size: 48px;
      line-height: 1.5;
    }
    
    h4{
      font-size: 20px;
    }
    
    img{
      margin: 64px auto 0;
    }
  }
`;
