import styled from 'styled-components';

import {colors,mediaQueriesSizes} from "../../../GlobalStyles";

export const SimpleTitleStyles = styled.div`

  .container {
    padding: 32px 16px;
  }

  h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
      text-wrap: balance;
  }

  hr.divider {
    margin: 16px auto;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    .container {
      padding: 40px 16px;
    }

    h2 {
      font-size: 28px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    .container {
      padding: 48px 16px;
    }
    h2 {
      font-size: 36px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    .container {
      padding: 64px 16px;
    }

    h2 {
      font-size: 48px;
    }

    hr.divider {
      margin: 32px auto;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    .container {
      padding: 96px 16px;
    }
  }


`;

