import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const ProductSpotlightStyles = styled.div`

  padding: 54px 0;

  h2 {
    margin-bottom: 54px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 0.01em;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    max-width: 800px;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    .ItemWrap {
      display: flex;
      flex-wrap: wrap;
      flex: 1 0 0%;
      gap: 16px;
      justify-content: space-between;
    }

  }
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    h2{
      font-size: 40px;
    }

    .ItemWrap {
      display: flex;
      flex-wrap: wrap;
      flex: 1 0 0%;
      gap: 16px;
      justify-content: space-between;
    }

  }
  
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    h2{
      font-size: 32px;
    }

    .ItemWrap {
      display: flex;
      flex-wrap: nowrap;
      flex: 1 0 0%;
      gap: 16px;
      justify-content: space-between;
    }

  }
`;

export const Item = styled.div`
  background: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  width: 100%;
  padding: 20px 16px;
  margin-bottom: 16px;
  
  h4 {
    font-family: centrano2-book;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.01em;
    text-align: left;
    color: ${colors.primary};
    margin-bottom: 32px;
  }

  p {
    font-family: centrano2-book;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.02em;
    text-align: left;
    color: ${colors.UILightSecondary};
    margin-bottom: 32px;
  }

  .btn-wrap {
    text-align: center;
  }

  .img-wrap {
    aspect-ratio: 4 / 3;
    display: flex;
    align-items: center;

    //height: 150px;
    margin: 0 auto;
    text-align: center;

    img {
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }

  a, button {
    display: inline-block;
    margin: 0 auto;
    margin-top: 32px;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    width: calc(50% - 16px);
    padding: 20px 16px 136px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .btn-wrap {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      padding-bottom: 26px;
    }
  }
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    width: calc(50% - 32px);
    padding: 20px 16px 136px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .btn-wrap {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      padding-bottom: 26px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    width: calc(50% - 32px);
    padding: 20px 20px 96px;
    margin-bottom: 32px;

    h4 {
      font-size: 24px;
    }

    p {
      font-size: 22px;

    }

  }

  &.Item-Length-4,
  &.Item-Length-3,
  &.Item-Length-2 {
    .Component-ProductLogo {
      height: 24px !important;
      margin-bottom: 16px;
      img {
        height: 100%;
      }
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    &.Item-Length-2,
    &.Item-Length-3 {
      .Component-ProductLogo {
        height: 32px !important;
      }
    }
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    &.Item-Length-2 {
      padding: 36px 36px 96px;
    }

    &.Item-Length-2 {
      .Component-ProductLogo {
        height: 42px !important;
      }
    }
    &.Item-Length-3 {
      .Component-ProductLogo {
        height: 28px !important;
      }
    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    &.Item-Length-2 {
      padding: 50px 50px 96px;
    }
    &.Item-Length-3 {
      .Component-ProductLogo {
        height: 32px !important;
      }
    }
    &.Item-Length-4 {
      .Component-ProductLogo {
        height: 30px !important;
      }
    }
  }
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    &.Item-Length-3 {
      .Component-ProductLogo {
        height: 42px !important;
      }
    }
    &.Item-Length-4 {
      .Component-ProductLogo {
        height: 30px !important;
      }
    }
  }





`