import React from 'react';

import { Main } from '../styles/background';

import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { ThankYou } from '../components/layouts/ThankYou';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

const DemoThankYou = () => {
  return (
    <>
      <HelmetContainer title="Thank you" />
      <PageContainer>
        <Main noPadding>
          <Header />
          <Content>
            <ThankYou />
          </Content>
        </Main>
        <Footer />
      </PageContainer>
    </>
  );
};

export default DemoThankYou;
