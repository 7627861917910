import { useEffect, useState } from 'react';

export const useGetInnerWidth = () => {
  const isBrowser = () => typeof window !== 'undefined';
  const [innerWidth, setInnerWidth] = useState(isBrowser() ? window.innerWidth : 0);

  const getInnerWidth = (e: any) => {
    setInnerWidth(e.currentTarget?.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', getInnerWidth);

    return () => window.removeEventListener('resize', getInnerWidth);
  }, []);

  return { innerWidth };
};
