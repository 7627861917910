import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const BoxImageGridStyles = styled.div`

    
    
  .container {
    padding: 32px 16px;
  }

  h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    color: ${colors.primary};
    margin-bottom: 8px;
  }
  
  h4{
    color: ${colors.primary};
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0.02em;
    text-align: center;
  }

  hr.divider {
    margin: 16px auto;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    .container {
      padding: 40px 16px;
    }

    h2 {
      font-size: 28px;
    }
    h4{
      font-size: 22px;
    }

    hr.divider {
      margin: 32px auto 64px;
    }

  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    .container {
      padding: 48px 16px;
    }
    h2 {
      font-size: 36px;
    }
    h4{
      font-size: 32px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    .container {
      padding: 64px 16px;
    }

    h2 {
      font-size: 48px;
    }
    h4{
      font-size: 32px;
    }

    hr.divider {
      margin: 32px auto;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    .container {
      padding: 96px 16px;
    }
  }

`;

export const GridWrapper = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-template-columns: repeat(2, 1fr);
    
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    grid-template-columns: repeat(3, 1fr);
    
  }

`;
export const Item = styled.div`
  background: white;
  border: 1px solid #EBE8E5;
  text-align: center;
  padding: 24px;
  border-radius: 4px;

  &.Theme-light{
    box-shadow: 0px 4px 16px rgba(233, 230, 226, 0.3);
  }  
  
    hr.divider{
        background: ${colors.teal};
        width: 90%;
        margin: 16px auto;
    }
  img{
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 150px;
    margin: 0 auto 16px;
  }
  
  h5 {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.01em;
    color: ${colors.primary};
  }

  p{
    font-size: 18px;
    font-family: centrano2-book;
    line-height: 1.25;
    letter-spacing: 0.01em;
    color: #59747A;
  }
  
`;
