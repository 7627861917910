import React from 'react';

import DownloadTemplateGWrap from '../../templates/DownloadTemplateGWrap';

const DownloadTemplateGeomagicWrap = () => {
  const Lang = 'en';

  return <DownloadTemplateGWrap Lang={Lang} />;
};

export default DownloadTemplateGeomagicWrap;
