import styled from 'styled-components';

import {colors,mediaQueriesSizes} from "../../../GlobalStyles";

export const CapterraScoreStyles = styled.div`

  margin-top: -25px;
  padding-bottom: 25px;
  position: relative;
  z-index: 1;

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    margin-top: -125px;
    padding-bottom: 125px;
  }


`;

export const Inner = styled.div`
  background: white;
  border-radius: 6px;
  padding: 32px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);

  img{
    width: 100%;
    max-width: 250px;
    height: auto;
    margin-bottom: 32px;
  }
  
  
  h3{
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: left;
    color: ${colors.primary};
    margin-bottom: 12px;
  }
  
  h6{
    color: ${colors.primary};
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: left;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    div.text-wrap{
      width: calc(100% - 334px);
    }

    img{
      width: 250px;
      margin-bottom: 0;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 64px 96px;
  }

`;

