import React, { useState } from 'react';
import { Flex } from '../../containers/Flex';
import { MaxContentContainer } from '../../subComponents/ContentContainer';

import { VideoPlayerWrapper } from '../Videos';
import { Icon } from '../../subComponents/Icon';
import { ContactModal } from '../ContactModal/ContactModal';
import { Vertical } from '../../../utils/types';

import { createMarkUp } from '../../V2/helpers';
import { getLocalisedPhoneNumber } from '../../helpers';
import { Buttons, ButtonsProps } from './components/Buttons';

import {
  Container,
  Title,
  Subtitle,
  Subtitle2,
  Description,
  Video,
  TrustedByContainer,
  TrustedByList,
  ContentContainer,
  VideoContainer,
  InfoContainer,
  HeroImage,
  Phone,
  TelephoneBox
} from './styles';
interface HeroProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  subtitle2?: React.ReactNode;
  announcement?: React.ReactNode;
  announcementUrl?: string;
  phone?: Boolean;
  phoneCopy?: string;
  lang?: string;
  description?: string;
  companies?: { src: string; alt: string; width: number }[];
  onWatchVideoClick?: React.ReactNode;
  buttonText?: string;
  buttonLink?: string;
  image?: { src: string; alt: string };
  vertical?: Vertical;
  buttons?: ButtonsProps;
}

export const Hero = ({
  title,
  subtitle,
  subtitle2,
  announcement,
  announcementUrl,
  lang,
  phone,
  description,
  companies,
  onWatchVideoClick,
  buttonText = 'Book a demo',
  buttonLink,
  buttons,
  image,
  vertical = 'default',
  phoneCopy = 'Speak to sales'
}: HeroProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [bookDemo, setBookDemo] = useState(false);

  if (!lang) {
    lang = 'en';
  }

  const globalData = require(`../../../../data/content/Global/${lang}.json`);

  return (
    <>
      <MaxContentContainer>
        <Container>
          <ContentContainer hasTrustedBySection={!!companies} hasImage={!!image}>
            <InfoContainer hasVideo={!!onWatchVideoClick}>
              <Title dangerouslySetInnerHTML={createMarkUp(title)}></Title>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
              {subtitle2 && <Subtitle2>{subtitle2}</Subtitle2>}
              {description && (
                <Description>
                  <span dangerouslySetInnerHTML={{ __html: description }} />
                </Description>
              )}

              <Buttons
                buttons={buttons}
                buttonLink={buttonLink}
                buttonText={globalData.Buttons.Demo}
                setBookDemo={setBookDemo}
              />
              {onWatchVideoClick && (
                <VideoContainer onClick={() => setOpenModal(true)}>
                  <Video>{globalData.Buttons.Watch}</Video>
                  <Icon ml="12px" name="ArrowRight" />
                </VideoContainer>
              )}
              {phone && (
                <TelephoneBox>
                  <Phone>
                    {globalData.Buttons.Sales}
                    <br /> <a href={'tel:' + getLocalisedPhoneNumber(lang)}>{getLocalisedPhoneNumber(lang)}</a> <br />
                  </Phone>
                </TelephoneBox>
              )}
            </InfoContainer>
            {image && (
              <Flex justifyContent="center" alignItems="center">
                <HeroImage src={image.src} alt={image.alt} />
              </Flex>
            )}
          </ContentContainer>
          {companies && (
            <TrustedByContainer>
              <TrustedByList>
                <li dangerouslySetInnerHTML={createMarkUp(announcement)}></li>
              </TrustedByList>
            </TrustedByContainer>
          )}
          {openModal && (
            <VideoPlayerWrapper
              shown={openModal}
              handleClose={() => {
                setOpenModal(false);
              }}
              url="https://vimeo.com/599539668"
              title="watch video"
              vimeo
            />
          )}
          {bookDemo && <ContactModal handleClose={() => setBookDemo(false)} vertical={vertical} />}
        </Container>
      </MaxContentContainer>
    </>
  );
};
