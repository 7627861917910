import styled from 'styled-components';

import {colors,mediaQueriesSizes} from "../../../GlobalStyles";

export const ComponentSolutionBlock = styled.div`

  background: #F7F6F5;
  padding: 64px 0;

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 120px 0;
  }

  h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.5px;
    text-align: left;
  }
  
  p{
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
    margin: 18px 0;
  }

  &.theme-dark {
    background: ${colors.primary};

    h2 {
      color: ${colors.white};
    }
  }

  .row {
    align-items: center;
    .col {
      min-width: 25%;
      flex: 1 0 0%;
      padding: 0 16px;
      align-items: center;
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    .row{
      margin: 0 -16px;
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      .col{
        min-width: 50%;
        margin-bottom: 16px;
      }
    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    .row{
      .col{
        min-width: 25%;
      }
    }
  }


`;


export const LinkBlock = styled.a`
  text-decoration: none;
  transition: 0.25s;

  .inner {
    height: 350px;
    display: flex;
    background: white;
    border-radius: 4px;
    color: ${colors.primary};
    text-decoration: none;
    transition: 0.3s;
    box-shadow: 0px 91px 36px rgba(35, 64, 87, 0.01), 0px 51px 31px rgba(35, 64, 87, 0.04), 0px 23px 23px rgba(35, 64, 87, 0.06), 0px 6px 12px rgba(35, 64, 87, 0.07), 0px 0px 0px rgba(35, 64, 87, 0.07);
    display: flex;
    align-items: end;

    p.category {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.11em;
      text-align: left;
      padding: 4px 6px;
      border: solid 1px ${colors.primary};
      border-radius: 4px;
      display: inline-block;
      margin: 0 16px 8px;
    }

    p.title {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.5px;
      margin: 0 16px 8px;
    }

    p.btn {
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.3px;
      text-align: left;
      padding: 4px 6px;
      border: solid 1px ${colors.fossilGrey};
      border-radius: 4px;
      margin: 16px;
      display: inline-block;
    }

    span {
      width: 100%;
      height: 1px;
      background: rgba(35, 64, 87, 0.3);
      display: block;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      height: 250px;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      height: 300px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      height: 275px;
    }

  }

  &:hover {
    transform: scale(1.02);
  }

  p {
    color: ${colors.primary};
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
  }

  &.theme-dark {
    background: linear-gradient(52.57deg, #142533 -90.51%, rgba(255, 255, 255, 0) 146.87%),
    linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));

    p {
      color: ${colors.white};
    }

  }

`;

