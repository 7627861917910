import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const Stat = styled.div`
  text-align: center;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    text-align: left;
  }
    p {
    font-weight: 700;
    font-size: 17px;
    line-height: 141%;
    letter-spacing: 0.2px;
    color: ${colors.primary};
    max-width: 180px;

    span {
      color: ${colors.teal};
      display: block;
      font-size: 66px;
      line-height: 110%;
    }
  }
`;

export const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  margin: 45px auto 0;
  align-items: center;
  gap: 32px;
  margin-bottom: 32px;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const TextStyles = styled.div`
  position: relative;

  img.arrow {
    height: 60px;
    margin: 30px auto 30px;
    display: block;
  }

  .line-vertical-divider {
    width: 2px;
    display: block;
    background: ${colors.teal};
    height: 60px;
    margin: 0 auto 30px;
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 3em;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${colors.teal};
  }

  h2 {
    font-family: centrano2-medium;
    font-weight: 500;
    font-size: 40px;
    line-height: 1.2;
    text-align: center;
    color: ${colors.primary};
    max-width: 840px;
    margin: 0 auto;
  }

  p {
    font-style: normal;
    font-weight: 200;
    font-size: 32px;
    line-height: 1.5;
    text-align: center;
    color: ${colors.primary};
    max-width: 840px;
    margin: 25px auto 0;
  }


`;

export const FormWrapper = styled.div`
  scroll-margin-top: 120px;
  
    iframe{
      width: 100%;
      height: 700px;
    }
`;

export const StatisticsHomepageBlockStyles = styled.div`
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 200%;
    background-image: url('/assets/images/homepage-v2/background_circles-2.svg');
    position: absolute;
    top: -50%;
    pointer-events: none;
    z-index: 0;
    background-size: 100% auto;
    opacity: 0.5;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .container {
    display: flex;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      padding: 30px 0;
    }
    align-items: center;
    position: relative;
    z-index: 1;

    .inner {
      width: 100%;
    }

    h2 {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      text-align: center;

      font-weight: 700;
      font-size: 40px;
      line-height: 1.25;
      color: ${colors.primary};

      span {
        margin-top: 16px;
        display: block;
        font-weight: 300;
      }
    }
  }

`;