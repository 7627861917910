export const ThankYouPageRouteBuilder = (lang, product, userName) => {
  let url;
  let productURL = '';

  switch (lang) {
    case 'en':
      url = '/thank-you';
      break;
    case 'de':
      url = '/de/danke';
      break;
    case 'fr':
      url = '/fr/merci';
      break;
    case 'it':
      url = '/it/grazie';
      break;
    case 'ja':
      url = '/ja/thank-you';
      break;
    case 'ko':
      url = '/ko/thank-you';
      break;
    default:
      url = '/thank-you';
  }

  if (product.includes('Oqton') || product.includes('Additive')) {
    url += `/manufacturing-os`;
  } else {
    switch (product) {
      case '3DXpert':
        url += `/3dxpert`;
        break;
      case 'Geomagic Wrap':
        url += `/geomagic-wrap`;
        break;
      case 'Geomagic Design X':
        url += `/geomagic-designx`;
        break;
      case 'Geomagic Control X':
        url += `/geomagic-controlx`;
        break;
      case 'Geomagic For SOLIDWORKS':
      case 'Geomagic For Solidworks':
      case 'Geomagic for Solidworks':
        url += `/geomagic-for-solidworks`;
        break;
      case 'Manufacturing OS':
        url += `/manufacturing-os`;
        break;
      case 'Freeform':
        url += `/freeform`;
        break;
      case 'D2P':
        url += `/d2p`;
        break;
      default:
        url += ``;
    }
  }

  url += `/?name=${userName}&lang=${lang}`;

  return url;
};
