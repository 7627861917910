import styled from 'styled-components';
import { colors, fontSizes, media, spacing } from '../../foundations';

import { circle } from '../../../styles/shapes';
import { SecondaryHeading, Paragraph } from '../../../styles/typography';

export const PageContentContainer = styled.article<{ align: 'left' | 'right' }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  ${({ align }) =>
    align === 'left' ? `grid-template-areas: 'content image';` : `grid-template-areas: 'image content';`}
  width: 1130px;
  margin: 65px 0;
  @media screen and (max-width: ${media.xl}px) {
    width: auto;

    ${({ align }) => (align === 'left' ? `grid-template-columns: 558px 1fr;` : `grid-template-columns: 1fr 558px;`)}
  }

  @media screen and (max-width: ${media.lg}px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 'image' 'content';

    height: auto;
  }
`;

export const ImageContainer = styled.div<{ align: 'left' | 'right' }>`
  grid-area: image;
  img {
    width: 100%;
  }
  ${({ align }) =>
    align === 'left'
      ? ``
      : `
      display: flex;
      justify-content: flex-end;
  `}

  video {
    width: 100%;
    margin-left: 100px;
  }

  @media screen and (max-width: ${media.xl}px) {
    margin: 0 auto;

    video {
      margin: unset;
    }
  }

  @media screen and (max-width: ${media.md}px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const ContentContainer = styled.section<{ align: 'left' | 'right' }>`
  grid-area: content;
  width: 558px;
  display: grid;
  grid-template-rows: min-content min-content min-content;
  ${({ align }) => (align === 'left' ? `margin-right: 95px;` : `margin-left: 95px;`)};

  @media screen and (max-width: ${media.lg}px) {
    width: calc(100vw - 48px);
    max-width: 558px;
    margin: 16px 0 0 0;
  }
`;

export const Index = styled.p`
  grid-area: index;

  color: ${colors.secondaryAccent};

  height: 28px;
  margin-left: -54px;
  margin-bottom: 24px;

  &:before {
    margin-right: ${spacing.xxl}px;
    margin-bottom: ${spacing.xxxs}px;
    ${circle}
  }
`;

export const PHTitle = styled(SecondaryHeading)`
  margin-bottom: 25px;
  color: ${colors.secondary};
`;

export const PHParagraph = styled(Paragraph)`
  color: ${colors.tertiary};
`;
export const Disclaimer = styled(Paragraph)`
  margin: 0 auto 0 auto;
  font-size: ${fontSizes.s};
  text-align: center;
`;
