import styled from 'styled-components';
import { colorsRD } from '../../foundations';
import { RDHeaderSubtitle, RDSubtitle } from '../../../styles/typography';

export const SubtitleText = styled.p`
  ${RDSubtitle};
  color: ${colorsRD.accent.passion};
`;

export const SubtitleWithIcon = styled.div`
  ${RDHeaderSubtitle};
  color: ${colorsRD.primary};
`;
