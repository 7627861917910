import React from 'react';

export const ControlX = (
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="2" fill="url(#paint0_linear_10571_49819)" />
    <path
      d="M5 16.26C5 20.36 8.16 23.5 12.2 23.5C13.48 23.5 14.8 23.16 16.08 22.4V19.82C14.84 20.7 13.6 21.16 12.24 21.16C9.54 21.16 7.48 18.98 7.48 16.26C7.48 13.5 9.54 11.34 12.24 11.34C13.62 11.34 14.82 11.8 16.04 12.72V10.14C14.86 9.4 13.56 9 12.22 9C8.18 9 5 12.18 5 16.26Z"
      fill="white"
    />
    <path
      d="M24.7316 23.26H27.4116L23.7716 18.22L27.2716 13.46H24.6516L22.4316 16.6L20.2316 13.46H17.5716L21.0516 18.28L17.4116 23.26H19.9916L22.3716 19.9L24.7316 23.26Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10571_49819"
        x1="-3.63216e-07"
        y1="32"
        x2="32.375"
        y2="-0.499999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#085C2F" />
        <stop offset="1" stop-color="#0C8243" />
      </linearGradient>
    </defs>
  </svg>
);
