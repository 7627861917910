import React from 'react';
import { StatProps } from './Statistics';
import { StatContainer, StatExtra, StatLabel, StatValue } from './styles';

export const Stat = ({ label, value, extra, inverted }: StatProps) => {
  return (
    <StatContainer hasExtra={!!extra}>
      <StatLabel inverted={inverted}>{label}</StatLabel>
      <StatValue>{value}</StatValue>
      {extra && <StatExtra>{extra}</StatExtra>}
    </StatContainer>
  );
};
