import React from 'react';

import { Bold } from '../../../styles/typography';
import { Icon } from '../../subComponents/Icon';
import { createMarkUp } from '../../V2/helpers';
import { ContactFormContainer, FormTitle, Text, LocationsLink, FormContainer } from './ContactStyles';

export const ContactForm = props => {
  if (props.content) {
    return (
      <ContactFormContainer>
        <section>
          <FormTitle dangerouslySetInnerHTML={createMarkUp(props.content.title)}></FormTitle>
          <Text dangerouslySetInnerHTML={createMarkUp(props.content.copy)}></Text>
          <LocationsLink href="#locations">
            {props.content.locations}
            <Icon name="Caret" color="quaternaryAccent" ml={14} />
          </LocationsLink>
        </section>
        <FormContainer
          title="Contact form"
          src="https://go.oqton.com/l/976933/2022-04-28/223w"
          width="100%"
          height="100%"
          frameBorder="0"
          allowTransparency
        />
      </ContactFormContainer>
    );
  } else {
    return (
      <ContactFormContainer>
        <section>
          <FormTitle>
            <Bold>Get in touch</Bold>
            <br />
            with us?
          </FormTitle>
          <Text>
            Have a question?
            <br /> Send us a note and we'll get back to you.
          </Text>
          <LocationsLink href="#locations">
            Our locations
            <Icon name="Caret" color="quaternaryAccent" ml={14} />
          </LocationsLink>
        </section>
        <FormContainer
          title="Contact form"
          src="https://go.oqton.com/l/976933/2022-04-28/223w"
          width="100%"
          height="100%"
          frameBorder="0"
          allowTransparency
        />
      </ContactFormContainer>
    );
  }
};
