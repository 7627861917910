import React from 'react';
import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { CXFreeTrial } from '../../components/V2/GeomagicCX-FreeTrial/FreeTrial';
import { BackgroundGDX, BackgroundGrey } from '../../GlobalStyles';

const geomagicDXFreeTrial = () => {
  const vertical = 'Geomagic-Controlx';
  const pageDescription = '';

  return (
    <PageContainer>
      <HelmetContainer
        showChatlio={false}
        title="Geomagic Control X Free Trial"
        pageDescription={pageDescription}
        description={pageDescription}
      />

      <CXFreeTrial />

      <Footer />
    </PageContainer>
  );
};

export default geomagicDXFreeTrial;
