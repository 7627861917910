import React from 'react';
import FreeformTemplate from './../templates/FreeformTemplate';

const Freeform = () => {
  const Lang = 'en';
  const wordpressData = require(`../../data/wordpress-data/688.json`);

  return <FreeformTemplate Lang={Lang} wordpressData={wordpressData} />;
};

export default Freeform;
