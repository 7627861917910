import React from 'react';

import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';

import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundWhite } from '../../GlobalStyles';
import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { BasicTextGrid } from '../../components/V2/BasicTextGrid/BasicTextGrid';
import { FormComponent } from '../../components/V2/Form/Form';
import { Gallery } from '../../components/V2/Gallery/Gallery';
import { ProductHighlight } from '../../components/V2/ProductHighlightBlock/ProductHighlightBlock';
import { ArticleBlock } from '../../components/V2/ArticleBlock/ArticleBlock';

interface Props {
  Lang?: string;
}

const Polymers = ({ Lang = 'en', ...props }: Props) => {
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);
  const data = require(`../../../data/wordpress-data/4384.json`);
  const pageVertical = 'NewForm-3dxpert';

  return (
    <>
      <PageContainer>
        <BackgroundGrey>
          <HelmetContainer
            lang={Lang}
            title={data.fields.seo.page_title}
            description={data.fields.seo.page_meta_description}
          />
          <Header demoVertical={pageVertical} lang={Lang} demoTitle={globalCopy.Buttons.RequestDemo} />

          <LargeHero
            BackgroundStyle={'center-right'}
            Background={data.fields.hero_.image.url}
            Copy={`<h1><b>${data.fields.hero_.title}</b></h1>${data.fields.hero_.copy}`}
            Country={Lang}
            AddSalesTel={true}
            Button={{
              ModelVertical: pageVertical,
              ModelTitle: globalCopy.Buttons.RequestDemo,
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true,
              theme: 'primary'
            }}
          />
        </BackgroundGrey>

        <SplitColumn
          AdditionalClass={'polymers-video'}
          Copy={`<h2>Watch how to <b>3D nest over 450 polymer parts</b> for additive manufacturing</h2>`}
          MediaType={'Video'}
          Video={'https://play.vidyard.com/X2UyZM3RLBBgbQaS47WwGY?'}
          Background={'3DXpert-Circles'}
          ImagePosition={'Left'}
        />

        <BackgroundWhite>
          <BasicTextGrid
            Items={data.fields.main_section.blocks}
            Title={data.fields.main_section.title}
            Copy={data.fields.main_section.copy}
          />
        </BackgroundWhite>

        <Gallery
          Title={'Polymer parts created with additive manufacturing'}
          Images={[
            {
              src: '/assets/2024/polymers/3d-nesting.png',
              caption: '<span>Object 1.</span> Drone Structure'
            },
            {
              src: '/assets/2024/polymers/fixture.png',
              caption: '<span>Object 2.</span> Fixture'
            },
            {
              src: '/assets/2024/polymers/drone-structure.png',
              caption: '<span>Object 3.</span> 3D Nesting '
            }
          ]}
        />

        <div className={'polymer-form-container'}>
          <div className={'container '}>
            <h3>{data.fields.form.form_subtitle}</h3>
            <h2>{data.fields.form.form_title}</h2>
            <hr className={'divider teal'} />
            <FormComponent
              Lang={Lang}
              HiddenFields={['message', 'product_interest']}
              PrefilledFields={[{ field: 'product_interest', value: '3DXpert' }]}
            />
          </div>
        </div>

        <ProductHighlight
          Title={'Professional Additive Manufacturing Software'}
          Copy={'3DXpert offers an integrated solution for 3D printing high-quality metal and plastic additive parts.'}
          Product={'3dxpert'}
        />

        <ArticleBlock
          Theme={'basic'}
          Title={'Additive Manufacturing'}
          Layout={'layout-1'}
          MostRecentBlogs={true}
          Topic={'additive-manufacturing'}
          Link={{
            copy: 'All Insights',
            link: '/news/'
          }}
        />

        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default Polymers;
