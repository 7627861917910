import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { ChildPageStyles } from './styles.jsx';
import { GridWrap } from '../Grid/styles';
import { ButtonComponent } from '../Button/Button';
import it from '../../../pages/it';
import globalStyles from '../../../GlobalStyles.jsx';

interface Props {
  Title?: string;
  Lang?: string;
  Items: object;
}

export const ChildPageLinks = ({ Title = '', Lang = 'en', Items = [], ...props }: Props) => {
  const globalCopy = require(`../../../../data/content/Global/${Lang}.json`);

  return (
    <ChildPageStyles>
      <div className={'container'}>
        <h2>{Title}</h2>
        <hr className={'divider'} />

        <div className={'item-wrapper'}>
          {Items.map((item, index) => {
            return (
              <a key={index} className={'item'} href={item.link}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <img src={item.image.url} alt={''} />
                <div className={'button-wrap'}>
                  <ButtonComponent Link={item.link} Theme={'accent-teal'} Label={globalCopy.Buttons.Learn} />
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </ChildPageStyles>
  );
};
