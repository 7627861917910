import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const TabbedContentStyles = styled.div`
  margin: 136px 0;
  color: ${colors.primary};
  text-align: center;
  
  h2{
    font-size: 24px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0.02em;
    text-align: center;
    margin-bottom: 32px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      margin-bottom: 56px;
      font-size: 40px;
    }
  }
  
  
  
  
`;

export const Navigation = styled.ul`
  margin: 0 auto 72px;
  display: inline-block;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    border-bottom: solid 1px ${colors.border};
  }
  li {
    cursor: pointer;
    display: block;
    margin: 0 8px;
    position: relative;
    top: 1px;
    border-bottom: solid 1px transparent;
    transition: 0.35s;
    padding-bottom: 8px;
    font-size: 12px;
    color: #59747A;
    
    &:hover {
      border-bottom: solid 1px ${colors.primary};
    }

    &.active {
      color: ${colors.primary};
      border-bottom: solid 1px ${colors.primary};
    }


    @media (min-width: ${mediaQueriesSizes.md}px) {
      display: inline-block;
      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      margin: 0 16px;
      padding-bottom: 20px;
      font-size: 16px;
      padding-bottom: 20px;
    }
  }
`;

export const Content = styled.div`

`;

export const ContentItem = styled.div`
  text-align: left;
  justify-content: space-evenly;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .imageContainer {
    max-width: 600px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 50%;
    }

    img {
      width: 100%;
    }
  }

  .textContainer {
    max-width: 500px;

    h3 {
      color: ${colors.primary};
      font-size: 24px;
      font-weight: 700;
      line-height: 1.4;
      letter-spacing: 0.02em;
      text-align: left;
      margin-bottom: 24px;
    }

    p {
      font-family: centrano2-book;
      font-size: 14px;
      line-height: 1.4;
      letter-spacing: 0.02em;
      text-align: left;
      color: ${colors.UILightSecondary};
    }

    ul {
      margin: 24px 0 0 24px;

      li {
        font-size: 14px;
        line-height: 1.4;
        list-style: initial;
        color: ${colors.UILightSecondary};
        font-family: centrano2-book;
        margin-bottom: 12px;
        padding-left: 4px;

        &::marker {
          color: ${colors.teal}
        }
      }

      &.list-style-checkmark {
        li {
          list-style-image: url('/assets/icons/checkmark-teal.svg');
        }
      }
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 50%;
      margin-left: 32px;

      h3 {
        font-size: 28px;
        margin-bottom: 16px;
      }

      p {
        font-size: 12px;
      }
      
      ul {
        li {
          font-size: 12px;
        }
      }


    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      margin-left: 64px;

      h3 {
        font-size: 32px;
        margin-bottom: 40px;
      }

      p {
        font-size: 18px;
      }
      
      ul {
        margin: 48px 0 0 48px;

        li {
          font-size: 18px;
          margin-bottom: 20px;
        }
      }


    }

  }
`;
