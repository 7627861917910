import React from 'react';

export const Wrap = (
  <svg width="33" height="32" viewBox="0 0 33 32" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="32" height="32" rx="2" fill="url(#paint0_linear_10571_49827)" />
    <path
      d="M13.185 23L16.505 13L19.845 23H21.645L26.425 9H23.985L20.745 18.98L17.425 9H15.665L12.325 18.98L9.105 9H6.625L11.385 23H13.185Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10571_49827"
        x1="0.5"
        y1="32"
        x2="32.875"
        y2="-0.499999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C9562E" />
        <stop offset="1" stop-color="#EA5C2D" />
      </linearGradient>
    </defs>
  </svg>
);
