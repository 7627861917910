import React, { useContext, useState } from 'react';
import GlobalStyle, { BackgroundGCX, BackgroundWhite } from '../../../GlobalStyles.jsx';
import { ProductLogo } from '../ProductLogo/ProductLogo';
import { ButtonComponent } from '../Button/Button';
import { DiscoverBlock, Hero, Panel, WhatToExpect, MainContent } from './styles.jsx';
import { Accordion } from '../Accordion/Accordion';
import { Header } from '../../layouts/HeaderComponent';
import { HighlightBlock } from '../HighlightBlocks/HighlightBlock/HighlightBlock';

interface Props {}

export const CXFreeTrial = ({ ...props }: Props) => {
  const [activeItem, setActiveItem] = useState(null);
  const vertical = 'NewForm-3dxpert';

  return (
    <>
      <BackgroundGCX>
        <Header demoVertical={vertical} whiteStripped={true} />

        <Hero>
          <div className={'container'}>
            <div className={'text-container'}>
              <ProductLogo Logo={'geomagic-controlx'} Theme={'outline'} Size={'xl'} />
              <h1>Trial for free now</h1>
              <p>
                See how our industry-leading 3D metrology software makes it easier than ever to capture and interpret
                scan data, and output professional inspection reports.
              </p>
              <ButtonComponent
                Link={'/geomagic-controlx/download/'}
                Label={'Download a free trial'}
                ID={'hero-trial-button'}
              />
            </div>
            <img className={'hero-image'} src={'/assets/2024/geomagic-controlx/hero-keyvisual.png'} />
            <img
              src={'https://assets.capterra.com/badge/7efb3e2ab8d3bf7cfcdf2617994471f8.svg?v=2225314&p=276905'}
              alt={'capterra'}
              className={'capterra'}
            />
          </div>
        </Hero>

        <Panel>
          <div className={'container'}>
            <div className={'inner'}>
              <div className={'text-wrap'}>
                <h2>Geomagic Control X. 3D inspection software trusted by global manufacturers.</h2>
                <p>
                  “The biggest time-saver is automated reporting. We do all our reporting with Control X because it’s
                  easy and fast. You just set up the report for the first sample, and you can apply it to all future
                  samples.”
                </p>
                <div className={'review-wrap'}>
                  <img src={'/assets/2024/geomagic-controlx/air-up.png'} />
                  <p>
                    <b>Hazim Abueideh</b>
                    <br />
                    Quality Control Analyst
                    <br />
                    <b>air up</b>
                  </p>
                </div>
              </div>
              <div className={'logo-wrap'}>
                <img src={'/assets/2024/geomagic-controlx/company-cummins.png'} />
                <img src={'/assets/2024/geomagic-controlx/company-pennstate.png'} />
                <img src={'/assets/2024/geomagic-controlx/company-asano.png'} />
                <img src={'/assets/2024/geomagic-controlx/company-lzmfg.png'} />
                <img src={'/assets/2024/geomagic-controlx/company-kindig.png'} />
                <img src={'/assets/2024/geomagic-controlx/company-airup.png'} />
                <img src={'/assets/2024/geomagic-controlx/company-aisin.png'} />
                <img src={'/assets/2024/geomagic-controlx/company-gkforge.png'} />
              </div>
            </div>
          </div>
        </Panel>
      </BackgroundGCX>
      <MainContent>
        <WhatToExpect>
          <div className={'container'}>
            <div className={'key-visual'}>
              <img src={'/assets/2024/geomagic-controlx/gcx-keyvisual.png'} />
            </div>
            <div className={'text-primary'}>
              <div>
                <h2>
                  <b>Start inspecting your parts faster,</b> with the Geomagic Control X free trial
                </h2>
                <hr className={'divider green'} />
                <p>
                  Get access to the complete 3D scanning and metrology capabilities of Geomagic Control X with a 14-day
                  free trial.
                </p>
                <ul>
                  <li>
                    <b>Easy to learn.</b> Get your results with little or no training.
                  </li>
                  <li>
                    <b>Handle huge data sets</b> quickly and easily with scan-native algorithms built on a CAD kernel.
                  </li>
                  <li>
                    Get all the tools you need for <b>professional-level inspection.</b>
                  </li>
                  <li>
                    <b>Compatible with any 3D scanner</b> or portable measuring arm.
                  </li>
                  <li>
                    Scan your parts to <b>capture more information and leverage rich data</b> to understand why parts
                    are in or out of tolerance.
                  </li>
                  <li>
                    <b>Optimize your manufacturing processes</b> to recognize and address issues faster.
                  </li>
                </ul>
                <ButtonComponent
                  Link={'/geomagic-controlx/download/'}
                  Label={'Download a free trial'}
                  Theme={'geomagic-controlx'}
                  ID={'main-content-trial-button'}
                />
              </div>
            </div>
          </div>
        </WhatToExpect>
      </MainContent>

      <HighlightBlock
        AdditionalClass={'scaled-right-image'}
        BlockWidth={'full-width'}
        Theme={'accent-green-clear'}
        Button={{
          link: '/geomagic-controlx/download/',
          copy: 'Download a free trial',
          theme: 'clear'
        }}
        Title={'<b>What are you waiting for?<br/>Try for yourself now</b>'}
        ImageType={'Split'}
        Image={{
          url: '/assets/2024/geomagic-controlx/secondary-visual.png'
        }}
      />

      <Accordion
        Title={'FAQ'}
        Items={[
          {
            title: 'How do I download and install my Geomagic Control X free trial?',
            content: [
              {
                contentType: 'content',
                copy: 'Download a free trial and fill in your contact details. A member of our team will then contact you with the installer and install information. The Geomagic Control X step-by-step installation guide can also be found on our support site. Your trial will last 14 days from the day of activation.. '
              }
            ]
          },
          {
            title: 'How long does my free trial of Geomagic Control X last?',
            content: [
              {
                contentType: 'content',
                copy: 'Once you have received your installer download link, your free trial will last 14 days from the day of activation.'
              }
            ]
          },
          {
            title: 'What are the minimum system requirements to run Geomagic Control X?',
            content: [
              {
                contentType: 'content',
                copy: "<ul><li>Windows 8.1 (64-bit)</li><li>Intel or AMDS processor with a minimum 2.0 GHz</li><li>8GB RAM</li><li>30GB of available hard-disc space (additional space may be required for cache)</li><li>32-bit true colour display, screen resolution 1280x960</li><li>GPU - OpenGL 3.0 or above enabled graphics card with 4GB of RAM or more</li><li>See <a href='https://softwaresupport.oqton.com/s/article/Geomagic-Control-X?language=en_US' target='_blank'>full system requirements here.</a></li></ul>"
              }
            ]
          },
          {
            title: 'Where can I get technical support during my trial of Geomagic Control X?',
            content: [
              {
                contentType: 'content',
                copy: 'A comprehensive list of FAQs can be found on our Geomagic Control X Knowledge Base. If you can’t find what you are looking for, you can submit a case to our support team.'
              }
            ]
          },
          {
            title: 'Where can I learn the basics to help me with my Geomagic Control X free trial?',
            content: [
              {
                contentType: 'content',
                copy:
                  'We have put together a series of Geomagic Control X tutorial videos to get you up and running during\n' +
                  'your free trial.'
              }
            ]
          },
          {
            title: 'What languages are supported in the free trial of Geomagic Control X?',
            content: [
              {
                contentType: 'content',
                copy: 'The Geomagic Control X free trial supports the following languages:<br/><br/>English, Chinese, Japanese, Korean, German, Italian, Russian, Portuguese, Spanish, Polish,Turkish, French and Czech.'
              }
            ]
          },
          {
            title: 'How do I cancel my Geomagic Control X free trial?',
            content: [
              {
                contentType: 'content',
                copy: 'Your Geomagic Control X free trial will automatically end after the 14 day period.'
              }
            ]
          }
        ]}
      />
    </>
  );
};
