import React from 'react';

import { ReferencesStyles } from './styles.jsx';

interface Props {
  copy?: string;
}

export const References = ({ copy, ...props }: Props) => {
  return (
    <ReferencesStyles>
      <div className={'container'}>
        <p>{copy}</p>
      </div>
    </ReferencesStyles>
  );
};
