import React, { useState } from 'react';
import { ButtonComponent } from '../../Button/Button';
import styled from 'styled-components';
import { colors, mediaQueriesSizes } from '../../../../GlobalStyles';
import It from '../../../../pages/it';
import { Modal } from './GridItemHelper';

interface Props {
  Item?: object;
}

const GridItemStyle = styled.div`
  position: relative; /* Ensure the div has a relative position */
  padding: 0;
  border-radius: 4px;

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    display: flex; /* Enable flexbox layout */
    align-items: flex-end; /* Align the image to the bottom */
    flex-direction: row;
    justify-content: space-between;
  }

  h3,
  p,
  a,
  button {
    text-align: center;
    position: relative;
    z-index: 1;
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      text-align: left !important;
    }
  }

  h3 {
    font-family: centrano2-medium;
    font-size: 32px;
    line-height: 40px;
  }

  p {
    font-family: centrano2-book;
    font-size: 18px !important;
    line-height: 1.9 !important;
    letter-spacing: 0.01em;
    text-align: center !important;
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      text-align: left !important;
    }
  }

  &.double {
    align-items: start !important;
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      flex-basis: calc(66% - 20px) !important;
    }
  }

  p {
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 100%;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 100%;
    }
  }

  div.content {
    width: 100%;
    padding: 32px 16px;
    text-align: center;
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 45%;
      text-align: left !important;
      padding: 32px 0 16px 32px;
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      width: 55%;
      padding: 52px 32px 16px 48px;
    }
  }

  div.image-wrap {
    width: 100%;
    position: relative;

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      height: 100%;
      width: 55%;
    }

    img {
      width: 100%;
      display: block;
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 100%;
        height: 100%;
        width: auto;
      }
    }
  }
`;

export const GridItem10 = ({ Item, ...props }: Props) => {
  const handleClick = () => {
    setModalActive(true);
  };

  const [modalActive, setModalActive] = useState(null);

  return (
    <>
      {modalActive ? (
        <Modal
          close={() => {
            setModalActive(false);
          }}
          video={Item.Copy.Video}
        />
      ) : null}
      <GridItemStyle className={'item double'} {...props}>
        <div className={'content'}>
          <h3>{Item.Copy.Title}</h3>
          <p>{Item.Copy.Copy}</p>
          {Item.Copy.Button && Item.Copy.Button.Link && (
            <ButtonComponent Theme={'stripped'} Link={Item.Copy.Button.Link} Label={Item.Copy.Button.Label} />
          )}
        </div>
        <div className={'image-wrap'}>
          <img alt="" src={Item.Copy.BackgroundImage} />
        </div>
      </GridItemStyle>
    </>
  );
};
