import React from 'react';

import AerospaceTemplate from './../../templates/AerospaceTemplate';

const Aero = () => {
  const Lang = 'fr';

  return <AerospaceTemplate Lang={Lang} />;
};

export default Aero;
