import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../../GlobalStyles";

export const Wrap = styled.section`
    
`;

export const EventCTAStyles = styled.section`

  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 1;
  background-image: url('/assets/images/events/event-bg-small.png');
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px;
  a{
    text-decoration: none;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.3px;
    color: ${colors.primary};
  }

  p {
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 145%;
    color: ${colors.primary};
  }

  a {
    margin-top: 32px;
  }

  .img-wrap {
    img {
      max-width: 150px;
      max-height: 150px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    background-size: contain;
    background-position: center right;

    h2{
      font-size: 32px;
    }
    p{
      font-size: 24px;
    }

    .img-wrap {
      img {
        max-width: 250px;
      }
    }


    align-items: center;
    padding: 32px;
    flex-direction: row;

  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    min-height: 290px;
    padding: 0 72px;
  }

`;
