import React from 'react';
import FreeformTemplate from './../../../templates/FreeformHealthcare';

const FreeformHealthcare = () => {
  const Lang = 'tr';
  const wordpressData = require(`../../../../data/wordpress-data/4621.json`);

  return <FreeformTemplate Lang={Lang} wordpressData={wordpressData} />;
};

export default FreeformHealthcare;
