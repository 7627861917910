import React from 'react';
import { createMarkUp } from '../../helpers';
import { ComponentStyles, ReviewContainer } from './styles.jsx';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Props {
  title?: string;
  reviews?: object;
  theme?: string;
}

const NextArrow = props => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={'/assets/icons/next-white-small.png'} />
    </div>
  );
};

const PrevArrow = props => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={'/assets/icons/prev-white-small.png'} />
    </div>
  );
};

export const Reviews = ({ title, reviews, theme, ...props }: Props) => {
  const settings = {
    dots: false,
    infinite: true,
    adaptiveHeight: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />, // Custom next arrow
    prevArrow: <PrevArrow /> // Custom previous arrow
  };

  return (
    <ComponentStyles className={'Component-Reviews'} {...props}>
      <div className={'container title'}>
        <h2 dangerouslySetInnerHTML={createMarkUp(title)}></h2>
        <hr className={'divider'} />
      </div>
      <div className={'container grid'}>
        <div className="main-review">
          <Slider {...settings}>
            {reviews.map((review, index) => (
              <div className={'review'} key={index}>
                <img src={`/assets/logos/${review.logo}.svg`} />
                <p dangerouslySetInnerHTML={createMarkUp(review.copy)}></p>
                <h6 dangerouslySetInnerHTML={createMarkUp(review.author)}></h6>
              </div>
            ))}
          </Slider>
        </div>
        <div className="side-reviews">
          <div className="side-review capterra">
            <a href={'https://www.capterra.com/p/276905/Geomagic-Design-X/'} target={'_blank'}>
              <img
                src="https://assets.capterra.com/badge/7efb3e2ab8d3bf7cfcdf2617994471f8.svg?v=2225314&p=276905"
                alt="Capterra Rating"
              />
            </a>
          </div>
          <div className="side-review g2">
            <a href={'https://www.g2.com/products/geomagic-design-x/reviews'} target={'_blank'}>
              <img src="https://oqtonadmin.com/wp-content/uploads/2024/11/g2-reviews-1.png" alt="G2 Rating" />
            </a>
          </div>
        </div>
      </div>
    </ComponentStyles>
  );
};
