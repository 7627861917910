import React from 'react';

export const RowCheckmark = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.4833" cy="11.9833" r="11.9833" fill="#020221" fillOpacity="0.05" />
    <path
      d="M16.0718 7.47279L9.91628 13.6283L7.6694 11.358C7.55238 11.2644 7.36514 11.2644 7.27152 11.358L6.59277 12.0368C6.49915 12.1304 6.49915 12.3176 6.59277 12.4346L9.72904 15.5475C9.84606 15.6645 10.0099 15.6645 10.1269 15.5475L17.1484 8.52601C17.242 8.43239 17.242 8.24515 17.1484 8.12812L16.4697 7.47279C16.376 7.35576 16.1888 7.35576 16.0718 7.47279Z"
      fill="#3BB3DB"
    />
  </svg>
);
