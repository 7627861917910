import React from 'react';
import EnergyTemplate from './../../templates/EnergyTemplate';

const Energy = () => {
  const Lang = 'ko';
  const wordpressData = require(`../../../data/wordpress-data/2632.json`);

  return <EnergyTemplate Lang={Lang} wordpressData={wordpressData} />;
};

export default Energy;
