import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { IntroStyles } from './styles.jsx';
import { createMarkUp } from '../helpers';

interface Props {
  Title: string;
  Copy: string;
}

export const IntroBlock = ({ Title, Copy, ...props }: Props) => {
  return (
    <IntroStyles {...props}>
      <div className={'container'}>
        <div className={'shadow'}></div>
      </div>
      <div className={'container wrap'}>
        <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
        <p dangerouslySetInnerHTML={createMarkUp(Copy)}></p>
      </div>
    </IntroStyles>
  );
};
