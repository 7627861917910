import React from 'react';

import { AnnoucementBarStyles, Inner } from './styles.jsx';
import { createMarkUp } from '../helpers';
import { ButtonComponent } from '../Button/Button';

interface Props {
  Image: object;
  Content?: string;
  AdditionalClass?: string;
  Link?: object;
}

export const AnnoucementBar = ({ Image, AdditionalClass, Content, Link, ...props }: Props) => {
  if (Link) {
    return (
      <AnnoucementBarStyles className={`Component-AnnoucementBar ${AdditionalClass}`} {...props}>
        <div className={'container'}>
          <Inner className={`${AdditionalClass}`} target={'_blank'} href={Link.link}>
            {Image && <img alt={''} className={`${AdditionalClass}`} src={Image} />}
            <div className={'text-wrap'} dangerouslySetInnerHTML={createMarkUp(Content)}></div>
            {Link && (
              <div className={'button-wrap'}>
                <ButtonComponent Theme={'stripped'} Label={Link.label} Link={Link.link} Target={'_blank'} />
              </div>
            )}
          </Inner>
        </div>
      </AnnoucementBarStyles>
    );
  } else {
    return (
      <AnnoucementBarStyles className={`Component-AnnoucementBar ${AdditionalClass}`} {...props}>
        <div className={'container'}>
          <Inner className={`${AdditionalClass}`}>
            {Image && <img alt={''} className={`${AdditionalClass}`} src={Image} />}
            <div className={'text-wrap'} dangerouslySetInnerHTML={createMarkUp(Content)}></div>
          </Inner>
        </div>
      </AnnoucementBarStyles>
    );
  }
};
