import styled from 'styled-components';
import { colors, media, spacing, typography } from '../../foundations';
import { subStyle } from '../../../styles/typography';

export const Title = styled.h1`
  color: ${colors.secondary};

  font-size: ${typography.fontSizes.xxxxl}px;
  font-weight: ${typography.fontWeights.medium};

  margin-bottom: ${spacing.l}px;
  margin-top: 150px;
`;

export const Sub = styled.sub`
  display: block;
  margin-top: ${spacing.xl}px;

  color: ${colors.tertiary};
  max-width: 600px;
`;

export const CookiesContainer = styled.section`
  margin-top: 100px;
  padding-bottom: 100px;

  display: flex;
  flex-direction: row;
  gap: 0 30px;

  @media screen and (max-width: ${media.xl}px) {
    display: grid;
    grid-template-rows: repeat(2, 360px);
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 30px;
  }

  @media screen and (max-width: ${media.md}px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 360px);
  }
`;

export const CookieCard = styled.article`
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: repeat(2, min-content) 1fr;
  grid-template-areas: 'CardTitle CardSub' 'CardText CardText' 'CardToggle .';
  background-color: ${colors.primary};

  width: 360px;
  height: 360px;
  box-sizing: border-box;

  padding: 52px 55px 55px;

  border-radius: ${spacing.xs}px;
  box-shadow: 0 3px 15px rgb(0 0 0 / 7%);

  @media screen and (max-width: ${media.xl}px) {
    width: auto;
  }
`;

export const CookieTitle = styled.h4`
  grid-area: CardTitle;
  margin-bottom: ${spacing.l}px;

  color: ${colors.secondary};

  font-size: ${typography.fontSizes.xxl}px;
  font-weight: ${typography.fontWeights.normal};
`;

export const CookieSub = styled.sub`
  grid-area: CardSub;

  margin-top: 13px;
  margin-left: ${spacing.m}px;

  color: ${colors.tertiary};

  font-size: ${typography.fontSizes.m}px;
`;

export const CookieText = styled.p`
  grid-area: CardText;

  color: ${colors.tertiary};

  font-size: ${typography.fontSizes.s}px;
`;

export const CookieToggle = styled.div`
  grid-area: CardToggle;

  align-self: flex-end;
`;

export const DetailCard = styled.article`
  display: grid;
  grid-template-rows: repeat(2, min-content) 1fr;
  grid-template-areas: 'detailSub' 'detailTitle' 'detailLink';
  background: linear-gradient(45deg, #052e40, #10526b);

  color: ${colors.primary};

  width: 360px;
  height: 360px;
  box-sizing: border-box;

  padding: 52px 55px 55px;

  border-radius: ${spacing.xs}px;
  box-shadow: 0 3px 15px rgb(0 0 0 / 7%);

  @media screen and (max-width: ${media.xl}px) {
    grid-column: auto / span 2;
    width: auto;
  }

  @media screen and (max-width: ${media.md}px) {
    grid-column: auto;
  }
`;

export const DetailTitle = styled.h4`
  grid-area: detailTitle;

  font-size: ${typography.fontSizes.xxl}px;
  font-weight: ${typography.fontWeights.normal};
  line-height: 1.25;
`;

export const DetailSub = styled.sub`
  grid-area: detailSub;
  margin-bottom: ${spacing.l}px;

  color: ${colors.accent};

  ${subStyle};
`;

export const DetailLink = styled.p`
  grid-area: detailLink;
  align-self: flex-end;

  padding-top: ${spacing.l}px;

  font-size: ${typography.fontSizes.l}px;
  font-weight: ${typography.fontWeights.medium};

  border-top: 2px solid ${colors.accent};
`;
