import styled from 'styled-components';

import {colors, bgGradients, mediaQueriesSizes} from "../../../GlobalStyles";

export const Item = styled.div`

  position: relative;
  margin-bottom: 36px;
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    margin-bottom: 0;
  }

  a.inner {
    display: block;
    text-align: left;
    background: ${colors.teal};
    backdrop-filter: blur(6px);
    background: rgba(59, 179, 219, 0.7);
    border-radius: 16px;
    z-index: 10;
    position: relative;
    padding: 24px;
    text-decoration: none;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      padding: 32px;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      padding: 16px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      padding: 32px;
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      padding: 40px;
    }
  }


  h3 {
    font-family: centrano2-medium;
    line-height: 120%;
    color: #FFFFFF;
    margin-bottom: 8px;
    font-size: 22px;
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin-bottom: 12px;
      font-size: 26px;
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      font-size: 32px;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    font-family: centrano2-book;

    span {
      font-family: centrano2-bookitalic;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      font-size: 18px;
    }
  }

  div.fakeBtn {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-top: 16px;
    background-image: url('/assets/icons/arrow_circle-icon.png');
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      margin-top: 32px;
    }
  }

  img {
    pointer-events: none;
    position: absolute;
    display: none;
    @media (min-width: ${mediaQueriesSizes.sm}px) {
      display: block;
    }
  }

  &.energy {
    img {
      z-index: 15;
      width: 130px;
      top: -30px;
      left: -120px;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        z-index: 15;
        width: 130px;
        top: -40px;
        left: -100px;
      }

      @media (min-width: ${mediaQueriesSizes.lg}px) {
        z-index: 15;
        width: 150px;
        top: -60px;
        left: -130px;
      }
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        z-index: 15;
        width: 180px;
        top: -60px;
        left: -150px;
      }
      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        z-index: 15;
        width: 220px;
        top: -80px;
        left: -190px;
      }
    }
  }

  &.healthcare {
    .inner {
      background: ${colors.primary};
        @media (min-width: ${mediaQueriesSizes.lg}px) {
          background: rgba(59, 179, 219, 0.7);
        }
    }

    img {
      z-index: 22;
      width: 150px;
      top: -90px;
      right: -110px;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        z-index: 22;
        width: 170px;
        top: -100px;
        right: -110px;
      }
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        z-index: 0;
        width: 160px;
        top: -80px;
        left: -120px;
      }
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        z-index: 0;
        width: 230px;
        top: -150px;
        left: -150px;
      }
    }
  }

  &.automotive {
    
    .inner{
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        background: ${colors.primary};
      }
    }
    
    img {
      z-index: 15;
      width: 270px;
      top: calc(100% - 62px);
      left: -48%;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        z-index: 15;
        width: 310px;
        top: calc(100% - 70px);
        left: -37%;
      }
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        z-index: 15;
        width: 400px;
        top: calc(100% - 70px);
        left: -100%;
      }
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        width: 450px;
        left: -90%;
        top: calc(100% - 80px);
      }
      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        width: 550px;
        left: -80%;
        top: calc(100% - 80px);
      }
      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        width: 530px;
        left: -80%;
      }
    }
  }

  &.aerospace {

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin-top: 250px;
    }

    .inner {
      background: ${colors.primary};
    }

    img {
      z-index: 15;
      width: 340px;
      top: -22%;
      right: -60%;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        z-index: 15;
        width: 300px;
        top: -20%;
        right: -30%;
      }
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        z-index: 15;
        width: 310px;
        top: -20%;
        right: -85%;
      }
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        z-index: 15;
        width: 530px;
        top: -30%;
        right: -135%;
      }
      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        right: -90%;
      }
      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        width: 600px;
        top: -40%;
        right: -80%;
      }
    }
  }

  &.industrial {
    img {
      z-index: 5;
      width: 250px;
      bottom: -15%;
      right: -40%;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        z-index: 5;
        width: 220px;
        bottom: -15%;
        right: -25%;
      }
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        z-index: 5;
        width: 220px;
        bottom: -15%;
        right: -50%;
      }
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        z-index: 5;
        width: 290px;
        bottom: -15%;
        right: -50%;
      }
      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        right: -35%;
      }
      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        width: 350px;
        bottom: -35%;
        right: -40%;
      }
    }
  }


`;
export const Inner = styled.div`
  display: block;
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 0 20px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 150px;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    margin-top: 0;
    padding: 0 60px;
    gap: 38px;
  }

  .column {
    width: 100%;
    margin-bottom: 36px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      padding: 0 60px;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      padding: 0;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      gap: 38px;
    }
  }

`;

export const InnovationIllustrationStyles = styled.div`
  overflow-x: hidden;
  padding: 42px 0;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 60px 0 200px;
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 120px 0;
  }

  h2 {
    color: #F8F7F6;
    text-align: center;
    font-family: centrano2-medium;
    font-size: 32px;
    line-height: 1;
    margin-bottom: 26px;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      font-size: 48px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 56px;
    }

  }

  p.copy {
    font-size: 18px;
    line-height: 1.33;
    text-align: center;
    letter-spacing: 0.01em;
    color: #F8F7F6;
    opacity: 0.6;
    font-family: centrano2-book;
    max-width: 750px;
    padding: 0 25px;
    margin: 0 auto 26px;
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      font-size: 24px;
    }
  }

  .wrapper {
    width: 100%;
    position: relative;
    background-size: contain;
    @media (min-width: ${mediaQueriesSizes.sm}px) {
      padding: 0 60px;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      background-image: url('/assets/images/homepage-v2/background_circles.svg');
      display: flex;
      aspect-ratio: 1 / 1;
    }
  }

`;
