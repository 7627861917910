import styled from 'styled-components';
import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const Ribbon = styled.div`
    background: ${colors.primary};
    position: absolute;
    color: white;
    padding: 5px 45px;
    transform: rotate(45deg);
    box-shadow: 0px 1.38327px 14.5726px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    z-index: 5;
    font-size: 0.6em;
    border: 3px solid white;
    right: -46px;
    top: 18px;


    @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 0.6em;
        border: 3px solid white;
        right: -46px;
        top: 18px;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        font-size: 1em;
        border: 7px solid white;
        top: 32px;
        right: -46px;
    }
`;


export const ComponentStyles = styled.div`
    padding: 0 0 32px;
    margin-top: -42px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 0;
        margin-top: 0;
    }

    .container {
        display: grid;
        column-gap: 18px;
        row-gap: 18px;
        grid-template-columns: repeat(1, 1fr);
        @media (min-width: ${mediaQueriesSizes.md}px) {
            column-gap: 8px;
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            column-gap: 18px;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &.Component_GDXHighlightCardBottom {
        padding-bottom: 64px;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            padding-bottom: 0;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            padding-bottom: 32px;
        }
    }
`;


export const CardBottomStyles = styled.div`
    background: white;
    padding: 32px;
    
    box-shadow: rgba(35, 64, 87, 0.1) 0px 10px 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 16px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
        margin-bottom: 32px;
        padding: 32px 24px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        padding: 40px 30px;
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        padding: 50px 40px;
    }

    p {
        font-weight: 400;
        font-size: 1em;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: 1.125em;
        }
        line-height: 1.47;
        letter-spacing: 0.02em;
        font-family: centrano2-book;
        color: ${colors.UILightSecondary};
    }

    ul {
        padding-left: 16px;
        margin: 16px 0 0;
        li {
            position: relative;
            font-size: 1em;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                font-size: 1.125em;
            }
            font-weight: 400;
            letter-spacing: 0.02em;
            line-height: 1.47;
            font-family: centrano2-book;
            color: ${colors.UILightSecondary};
            margin: 0 0 8px;
            padding: 0;
            list-style: none;
            &:before{
                content: "";
                display: block;
                position: absolute;
                width: 5px;     
                height: 5px;
                background: ${colors.UILightSecondary};
                border-radius: 50%;
                top: 11px;
                left: -12px;
            }
        }
    }


`;

export const CardStyles = styled.div`
    position: relative;
    z-index: 1;
    background: ${colors.UILightQuaternary};
    padding: 32px 32px 0;
    box-shadow: rgba(35, 64, 87, 0.1) 0px -20px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 24px 24px 0;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        padding: 40px 30px 0;
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        padding: 50px 40px 0;
    }

    .ribbon-wrap{
        position: absolute;
        top:0;
        right: 0;
        width: 150px;
        height: 150px;
        overflow: hidden;
        pointer-events: none;
    }
    
    .Component-ProductLogo{
        position: relative;
        z-index: 5;
    }
    
    h4 {
        font-weight: 500;
        font-size: 1.1em;
        line-height: 1.2;
        letter-spacing: 0.02em;
        color: ${colors.primary};
        text-wrap: balance;
        z-index: 5;
        position: relative;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            font-size: 1.1em;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            font-size: 1.2em;
            margin: 20px 0;
        }
        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            font-size: 1.375em;
        }


    }

    .custom-button {
        position: relative;
        top: 18px;
        z-index: 5;
    }

`;
