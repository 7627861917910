import React, { useState } from 'react';

import { spacing } from '../../foundations';
import { Icon } from '../../subComponents/Icon';
import { ContactModal } from '../ContactModal/ContactModal';

import { createMarkUp } from '../../V2/helpers';
import { DemoContainer, Button, Description, Title, ButtonText, ButtonIcon } from './DemoStyles';

interface DemoProps {
  title: React.ReactNode;
  description: React.ReactNode;
  buttonText: React.ReactNode;
}
export const Demo = ({ title, description, buttonText }: DemoProps) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <DemoContainer>
      <Title dangerouslySetInnerHTML={createMarkUp(title)}></Title>
      <Description>{description}</Description>
      <Button onClick={() => setOpenModal(true)}>
        <ButtonText>{buttonText}</ButtonText>
        <ButtonIcon ml={`${spacing.s}px`}>
          <Icon name="Arrow" />
        </ButtonIcon>
      </Button>
      {openModal && <ContactModal handleClose={() => setOpenModal(false)} />}
    </DemoContainer>
  );
};
