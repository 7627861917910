import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import moment from 'moment';

import { EventTitleStyles, DataBlock } from './styles.jsx';
import { createMarkUp } from '../../helpers';

interface Props {
  EventData: object;
}

export const EventTitleBlock = ({ EventData, ...props }: Props) => {
  console.log(EventData);

  const startformattedDate = moment(EventData.fields.date.start).format('MMMM D');
  const endformattedDate = moment(EventData.fields.date.end).format('D, YYYY');

  const simplifiedStartDate = moment(EventData.fields.date.start).format('MMMM D YYYY');
  const simplifiedEndDate = moment(EventData.fields.date.end).format('MMMM D YYYY');

  return (
    <EventTitleStyles className={`theme-${EventData.fields['type'].toLowerCase()}`} {...props}>
      <h1>{EventData.title}</h1>
      <h3>{EventData['fields'].subtitle}</h3>

      <h4 className={`category ${EventData['fields'].type.replace(' ', '-').toLowerCase()}`}>
        <span>{EventData['fields'].type}</span>
      </h4>

      <DataBlock>
        <p>
          {EventData['type'] === 'Partner Event' && (
            <img loading="lazy" alt={''} src={'/assets/icons/calendar-gray.svg'} />
          )}
          {EventData['type'] === 'Tradeshow' && <img loading="lazy" alt={''} src={'/assets/icons/calendar-red.svg'} />}
          {EventData['type'] === 'Conference' && (
            <img loading="lazy" alt={''} src={'/assets/icons/calendar-blue.svg'} />
          )}

          {simplifiedStartDate === simplifiedEndDate ? (
            <>{simplifiedStartDate}</>
          ) : (
            <>
              {startformattedDate} - {endformattedDate}
            </>
          )}
        </p>
        <p>
          {EventData['type'] === 'Partner Event' && (
            <img loading="lazy" alt={''} src={'/assets/icons/location-gray.svg'} />
          )}
          {EventData['type'] === 'Tradeshow' && <img loading="lazy" alt={''} src={'/assets/icons/location-red.svg'} />}
          {EventData['type'] === 'Conference' && (
            <img loading="lazy" alt={''} src={'/assets/icons/location-blue.svg'} />
          )}
          {EventData['fields']['location']['copy']}
        </p>
        {EventData['fields']['booth_location'] && EventData['fields']['booth_location']['link'] && (
          <a href={EventData['fields']['booth_location']['link']} target={'_blank'}>
            {EventData['type'] === 'Partner Event' && (
              <img loading="lazy" alt={''} src={'/assets/icons/map-gray.svg'} />
            )}
            {EventData['type'] === 'Tradeshow' && <img loading="lazy" alt={''} src={'/assets/icons/map-red.svg'} />}
            {EventData['type'] === 'Conference' && <img loading="lazy" alt={''} src={'/assets/icons/map-blue.svg'} />}
            Hall {EventData['fields']['booth_location']['hall']} - Booth{' '}
            {EventData['fields']['booth_location']['booth']}
          </a>
        )}
        <a href={EventData['fields']['event_website']} target={'_blank'}>
          {EventData['type'] === 'Partner Event' && <img alt={''} src={'/assets/icons/website-gray.svg'} />}
          {EventData['type'] === 'Tradeshow' && <img alt={''} src={'/assets/icons/website-red.svg'} />}
          {EventData['type'] === 'Conference' && <img alt={''} src={'/assets/icons/website-blue.svg'} />}
          Website
        </a>
      </DataBlock>

      <p dangerouslySetInnerHTML={createMarkUp(EventData['fields'].description)}></p>

      {EventData['fields'].preview_booth && <img alt={''} src={EventData['fields'].preview_booth} />}
    </EventTitleStyles>
  );
};
