import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { IconGridComponentStyles, TableWrap, ItemStyle } from './styles.jsx';
import { createMarkUp } from '../helpers';
import { ButtonComponent } from '../Button/Button';

interface Props {
  id?: string;
  Title?: string;
  Copy?: string;
  Items?: object;
  Theme?: 'light' | 'dark';
}

export const ImageGrid = ({ id = '', Title = '', Copy = '', Items = [], Theme = 'light', ...props }: Props) => {
  return (
    <IconGridComponentStyles className={'theme-' + Theme} id={id}>
      <GlobalStyle />
      <div className={'container'}>
        <h2>{Title}</h2>
        <p>{Copy}</p>
        <TableWrap>
          {Items.map((item, index) => (
            <ItemStyle className={'theme-' + Theme} key={index}>
              <div className={'bgImg'} style={{ backgroundImage: 'url(' + item.Image + ')' }}></div>
              <h5>{item.Title}</h5>
              <p dangerouslySetInnerHTML={createMarkUp(item.Copy)}></p>
            </ItemStyle>
          ))}
        </TableWrap>
      </div>
    </IconGridComponentStyles>
  );
};
