import React from 'react';

export const RowCheckmarkDisabled = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.8154" cy="11.9833" r="11.9833" fill="#020221" fillOpacity="0.05" />
    <path
      opacity="0.2"
      d="M16.4038 7.47279L10.2483 13.6283L8.00143 11.358C7.88441 11.2644 7.69717 11.2644 7.60355 11.358L6.9248 12.0368C6.83118 12.1304 6.83118 12.3176 6.9248 12.4346L10.0611 15.5475C10.1781 15.6645 10.3419 15.6645 10.459 15.5475L17.4804 8.52601C17.5741 8.43239 17.5741 8.24515 17.4804 8.12812L16.8017 7.47279C16.7081 7.35576 16.5208 7.35576 16.4038 7.47279Z"
      fill="#234057"
    />
  </svg>
);
