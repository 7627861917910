export const createMarkUp = content => {
  return { __html: content };
};

export const getLocalisedPhoneNumber = (lang: string) => {
  let globalData = require(`../../data/content/Global/${lang}.json`);
  for (let i = 0; i < globalData.phoneNumbers.length; i++) {
    if (globalData.phoneNumbers[i].code.toLowerCase() === lang.toLowerCase()) {
      return globalData.phoneNumbers[i].phone;
    }
  }
};
