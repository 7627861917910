import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients} from "../../../GlobalStyles";

export const CustomerStorySpotlightStyles = styled.div`
  padding: 72px 0;

  h2 {
    font-family: centrano2-medium;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 16px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 148px 0;
    h2 {
      font-size: 40px;
      margin-bottom: 32px;
    }

  }
`;
export const TitleContainer = styled.div`

  a {
    margin-top: 32px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      margin-top: 0;
      position: relative;
      top: -13px;
    }

  }


`;

export const CardContainer = styled.div`
  margin-top: 32px;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    margin-top: 75px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .img-wrap {
    width: 100%;
    margin: 0 auto 32px;

    img.card-img {
      border-radius: 4px;
      width: 100%;
      -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);

    }
  }

  .button-wrap {
    min-width: 140px;
    display: flex;
    align-items: end;
    justify-content: end;
    margin-top: 16px;
  }

  .text-wrap {
    p {
      font-family: centrano2-bookitalic;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: 0.02em;
      text-align: left;
      margin-bottom: 32px;
      color: ${colors.UILightSecondary};
      position: relative;

      &:before {
        color: #234057;
        opacity: 0.07;
        content: "“";
        display: block;
        position: absolute;
        top: 15px;
        left: -16px;
        font-size: 150px;
        line-height: 0.5;
      }

    }

    h5 {
      font-family: centrano2-book;
      font-size: 20px;
      line-height: 1.2;
      letter-spacing: 0.02em;
      text-align: left;
      color: ${colors.primary};
    }

    h6 {
      font-family: centrano2-book;
      color: ${colors.UILightSecondary};
      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: 0.02em;
      text-align: left;
    }
    
  }


  @media (min-width: ${mediaQueriesSizes.lg}px) {
    margin-bottom: 146px;
    flex-direction: row;
    .img-wrap {
      width: auto;
      height: 150px;
      margin-bottom: 0;

      img.card-img {
        width: auto;
        height: 100%;
      }
    }

    .text-wrap {
      padding-left: 60px;

      p {
        padding-top: 6px;
        font-size: 22px;
        &:before {
          top: 15px;
          left: -36px;
        }

        &:after {
          bottom: -36px;
          right: -20px;
          font-size: 72px;
        }
      }

      h5 {
        font-size: 20px;
      }

      h6 {
        font-size: 20px;
      }
    }

    .button-wrap {
      text-align: right;
      margin-top: 0;
    }

  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    .img-wrap {
      width: auto;
      height: 250px;

      img.card-img {
        width: auto;
        height: 100%;
      }
    }
  }
`;
