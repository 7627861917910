import React from 'react';

import { PageContainer } from '../../components/containers/PageContainer';
import { Footer } from '../../components/layouts/Footer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { Header } from '../../components/layouts/HeaderComponent';
import { Timeline } from '../../components/V2/Timeline/Timeline';

const OurHistory = () => {
  const Lang = 'ko';
  const data = require(`../../../data/wordpress-data/1664.json`);

  return (
    <PageContainer>
      <HelmetContainer currentPage={'history'} title={data.title} pageDescription={''} description={''} lang={Lang} />

      <Header lang={Lang} />

      <Timeline title={data.title} lang={Lang} data={data.fields.timeline} />

      <Footer lang={Lang} />
    </PageContainer>
  );
};

export default OurHistory;
