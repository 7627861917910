import React, { useContext, useEffect, useState } from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { BackgroundGrey, BackgroundHalfGreyReverse } from '../GlobalStyles';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { HighlightBlockNoImage } from '../components/V2/HighlightBlocks/HighlightBlockNoImage/HighlightBlockNoImage';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { ProductSpotlight } from '../components/V2/ProductSpotlight/ProductSpotlight';
import { TextColumns } from '../components/V2/TextColumns/TextColumns';
import { ClientSpotlight } from '../components/V2/ClientSpotlight/ClientSpotlight';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';
import { ModalContext } from '../context/ModalContext';
import { FeaturedPartner } from '../components/V2/News/FeaturedPartner/FeaturedPartner';
import { BlogData } from '../components/V2/News/BlogData/BlogData';
import { SingleNewsContent } from '../styles/newsStyles';
import { createMarkUp } from '../components/V2/helpers';
import BlogCategories from '../../data/blog-categories.json';

interface Props {
  Lang?: string;
  post?: object;
}

const PostInner = ({ Lang = 'en', post, ...props }: Props) => {
  const [updatedContent, setUpdatedContent] = useState(false);

  const { setIsModalOpen } = useContext(ModalContext);
  const { setModalVertical } = useContext(ModalContext);
  const { setModalTitle } = useContext(ModalContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://oqtonadmin.com/wp-json/custom/v1/post/${post.id}`);

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const resultData = await response.json();
        if (post.modifiedAt !== resultData.modifiedAt) {
          setUpdatedContent(resultData.content);
        }
      } catch (error) {
        console.error('Error fetching post data:', error);
      }
    };

    fetchData();
  }, [post]);

  useEffect(() => {
    // Check the initial hash in case the page loads with the #openRequestDemo in the URL
    if (window.location.hash === '#openRequestDemo') {
      handleOpenRequestDemo();
    }

    // Set up a listener for hash changes
    const onHashChange = () => {
      if (window.location.hash.startsWith('#openRequestDemo')) {
        handleOpenRequestDemo();
      }
    };

    // Listen for hash changes
    window.addEventListener('hashchange', onHashChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('hashchange', onHashChange);
    };
  }, []);

  const handleOpenRequestDemo = () => {
    // This is where your function would run when #openRequestDemo is present

    console.log(window.location.hash);
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split('?')[1]);
    const product = params.get('product');
    const title = params.get('title');
    console.log('Open Request Demo Popup');

    let vertical;
    switch (product) {
      case '3dxpert':
        vertical = 'NewForm-3dxpert';
        break;
      case 'wrap':
        vertical = 'NewForm-Wrap';
        break;
      case 'mos':
        vertical = 'NewForm-MOS';
        break;
      case 'freeform':
        vertical = 'NewForm-Freeform';
        break;
      default:
        vertical = '';
    }

    setIsModalOpen(true);
    setModalTitle(title);
    setModalVertical(vertical);
  };

  const getPrimaryCateogory = postCategories => {
    const PrimaryCategories = BlogCategories.categories;
    const matchingSlugs = postCategories.filter(item => PrimaryCategories.includes(item.slug)).map(item => item.slug);

    if (matchingSlugs.length > 0) {
      return matchingSlugs[0];
    } else {
      return undefined;
    }
  };

  return (
    <>
      <h1>{post.title}</h1>

      <FeaturedPartner post={post} />

      <hr />

      <BlogData
        Category={getPrimaryCateogory(post.categories)}
        Author={post.author}
        Date={post.publishedAt}
        ReadTime={post.fields.read_time}
      />

      <SingleNewsContent
        dangerouslySetInnerHTML={createMarkUp(updatedContent !== false ? updatedContent : post.content)}
      ></SingleNewsContent>
    </>
  );
};

export default PostInner;
