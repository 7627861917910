import styled from 'styled-components';
import { RDButton } from '../../../styles/typography';
import { Box } from '../../containers/Box';
import { colorsRD, spacing } from '../../foundations';

export const Button = styled.button<{ color?: string }>`
  background: none;
  padding: unset;
  border: none;

  display: flex;
  align-items: flex-start;

  color: ${({ color }) => (color ? color : colorsRD.primary)};
  cursor: pointer;
`;

export const ButtonText = styled.p`
  ${RDButton};

  padding-bottom: ${spacing.xs}px;
  border-bottom: 1px solid ${colorsRD.accent.passion};
`;

export const ButtonIcon = styled(Box)``;
