import React from 'react';

import { createMarkUp } from '../helpers';
import GlobalStyle from '../../../GlobalStyles';
import { ListWrap, IframeContainer, FormColumns, TitleWrap, ContactFormHeroStyles, Sidebar } from './styles.jsx';
import { DefaultItem } from '../TextGrid/styles';
import { FormComponent } from '../Form/Form';

interface Props {
  title?: string;
  sidebar?: string;
  form?: string;
  lang?: string;
}

export const ContactFormHero = ({ lang, title, sidebar, form, ...props }: Props) => {
  return (
    <ContactFormHeroStyles>
      <GlobalStyle />
      <div className={'container'}>
        <TitleWrap dangerouslySetInnerHTML={createMarkUp(title)}></TitleWrap>
        <FormColumns>
          <Sidebar>
            <h3>{sidebar.title}</h3>
            <ListWrap>
              {sidebar.listItems.map((listItem, index) => (
                <li key={index}>{listItem}</li>
              ))}
            </ListWrap>
          </Sidebar>

          <FormComponent Lang={lang} />
        </FormColumns>
      </div>
    </ContactFormHeroStyles>
  );
};
