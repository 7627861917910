import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients} from "../../../GlobalStyles";

export const ChildPageStyles = styled.div`
  padding: 48px 0;

  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 0.01em;
    color: ${colors.primary}
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 68px 0;

    h2 {
      font-size: 40px;
    }

  }

  .divider {
    margin: 20px 0;
  }

  .item-wrapper {
    margin: 24px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 100%;
      text-decoration: none;
      background: white;
      border-radius: 4px;
      text-align: center;
      margin-bottom: 32px;
      padding: 32px 16px;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 32px;
        width: 48%;
      }
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 54px;
      }

      h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 8px;
        color: ${colors.primary};
        @media (min-width: ${mediaQueriesSizes.md}px) {
          font-size: 32px;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
          font-size: 40px;
        }
      }

      p {
        font-family: centrano2-book;
        font-style: normal;
        font-size: 16px;
        line-height: 1.2;
        color: ${colors.primary};
        margin-bottom: 16px;
        @media (min-width: ${mediaQueriesSizes.md}px) {
          font-size: 20px;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
          font-size: 24px;
        }
      }

      img {
        width: auto;
        height: auto;
        max-width: 80%;
        max-height: 250px;
        margin: 16px 0;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
          max-height: 350px;
        }
      }

      .button-wrap {
        width: 100%;

      }

    }

    @media (min-width: ${mediaQueriesSizes.sm}px) {
      margin: 90px 0 0;
    }

  }

`;
