import React from 'react';

import { Icon } from '../../subComponents/Icon';

import { createMarkUp } from '../helpers';
import {
  ArticleContainer,
  ArticleContent,
  ArticleImage,
  ArticleTitle,
  ArticleText,
  Subtitle,
  Button,
  StyledLink,
  ImageContainer
} from './styles';
import { ArticleProps } from './MediaComponent';

export const Article = ({ title, content, image, mediaComponent, subTitle, button, buttonLink }: ArticleProps) => {
  const position = mediaComponent?.pos ?? (image ? image?.imagePos ?? 'left' : undefined) ?? undefined;

  return (
    <>
      <ArticleContainer imagePos={position}>
        <ArticleContent>
          {subTitle && <Subtitle>{subTitle}</Subtitle>}
          {title && <ArticleTitle dangerouslySetInnerHTML={createMarkUp(title)}></ArticleTitle>}
          {content && <ArticleText>{content}</ArticleText>}
          {button && (
            <StyledLink link={buttonLink}>
              <Button>
                {button} <Icon ml="12px" name="Arrow" />
              </Button>
            </StyledLink>
          )}
        </ArticleContent>
        {(image || mediaComponent) && (
          <ImageContainer>{mediaComponent ? mediaComponent.component : <ArticleImage {...image} />}</ImageContainer>
        )}
      </ArticleContainer>
    </>
  );
};
