import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients} from "../../../GlobalStyles";

export const BenefitsIconsStyles = styled.div`
  padding: 70px 0;

  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 0.02em;
    color: ${colors.highlight};
    margin-bottom: 8px;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.02em;
    color: ${colors.primary};
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 40px;
    }
  }

`;

export const IconWrap = styled.div`

  display: flex;
  flex-wrap: wrap;
  padding: 18px 0;
  column-gap: 24px;
  row-gap: 16px;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 32px 0;
    column-gap: 60px;
    row-gap: 40px;
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    padding: 46px 0 0;
    column-gap: 75px;
    row-gap: 55px;
  }

  .benefit {
    flex: 0 1 auto;
    min-width: 0;
    display: inline-flex;
    align-items: center;

    h5 {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.1;
      color: ${colors.primary};
      font-family: centrano2-book;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 24px;
      }
    }

    img {
      display: block;
      width: 32px;
      height: 32px;
      margin-right: 8px;
      @media (min-width: ${mediaQueriesSizes.md}px) {
        width: 50px;
        height: 50px;

      }
    }
  }

`;

