import React from 'react';
import HomepageTemplate from './../../templates/HomepageTemplate';
import { graphql, useStaticQuery } from 'gatsby';

const HomeRD = ({ pageContext }) => {
  let defaultLang = 'tr';

  const data = useStaticQuery(graphql`
    query {
      trFile: allFile(filter: { name: { eq: "index-tr" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
    }
  `);

  let content = data.trFile.edges[0].node.childRawJson.content;

  const json = JSON.parse(content);
  console.log(json);

  return <HomepageTemplate Context={pageContext} Content={json} Lang={defaultLang} />;
};

export default HomeRD;
