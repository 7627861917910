import React, { useEffect, useState } from 'react';

interface Props {
  touchedFields: object;
  formData: object;
  copy: object;
  options: object;
  restrictOptions: object;
  handleTouch: () => void;
  handleChange: () => void;
  fieldName: '';
  required: boolean;
  disabled: boolean;
}

export const SelectField = ({
  touchedFields,
  formData,
  copy,
  options,
  handleTouch,
  restrictOptions = [],
  handleChange,
  fieldName,
  required,
  disabled = false,
  ...props
}: Props) => {
  const [error, setError] = useState(false);

  const showError = () => {
    if (required && formData[fieldName].length < 1) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <label className={`${error ? 'errored' : ''} ${disabled ? 'disabled' : ''}`}>
      {copy.fields[fieldName].label}
      {required && (
        <>
          <span className={'required'}>*</span>
          <span
            className={`error required ${
              touchedFields[fieldName] === true && formData[fieldName].length < 1 ? '' : 'hidden'
            }`}
          >
            {copy.fields[fieldName].error.required}
          </span>
        </>
      )}

      <select
        disabled={disabled}
        name={fieldName}
        value={formData[fieldName]}
        onChange={handleChange}
        onBlur={e => {
          showError();
          handleTouch(e);
        }}
      >
        <option value={''}></option>
        {options.map((choice, index) => {
          if (fieldName === 'country') {
            return (
              <option key={index} value={choice.Country}>
                {choice.Country}
              </option>
            );
          } else if (fieldName === 'state') {
            return (
              <option key={index} value={choice.state}>
                {choice.state}
              </option>
            );
          } else {
            if (restrictOptions.length > 0) {
              if (restrictOptions.includes(choice.value)) {
                return (
                  <option key={index} value={choice.value}>
                    {choice.label}
                  </option>
                );
              } else {
                return null;
              }
            } else {
              return (
                <option key={index} value={choice.value}>
                  {choice.label}
                </option>
              );
            }
          }
        })}
      </select>
    </label>
  );
};
