import React from 'react';

import AerospaceTemplate from './../../templates/AerospaceTemplate';

const Aerospace = () => {
  const Lang = 'it';

  return <AerospaceTemplate Lang={Lang} />;
};

export default Aerospace;
