// WebinarContext.js
import React, { createContext, useContext } from 'react';

const WebinarContext = createContext({});

export const useWebinarContext = () => {
  const context = useContext(WebinarContext);
  if (!context) {
    throw new Error('useWebinarContext must be used within an WebinarContextProvider');
  }
  return context;
};

export const WebinarContextProvider = ({ children, WebinarData }) => {
  return <WebinarContext.Provider value={WebinarData}>{children}</WebinarContext.Provider>;
};
