import React, { useContext, useState } from 'react';
import GlobalStyle, { BackgroundWhite } from '../../../GlobalStyles.jsx';
import { ProductLogo } from '../ProductLogo/ProductLogo';
import { ButtonComponent } from '../Button/Button';
import { BackgroundGDX } from '../../../GlobalStyles.jsx';
import { DiscoverBlock, Hero, Panel, WhatToExpect, MainContent } from './styles.jsx';
import { Accordion } from '../Accordion/Accordion';
import { Header } from '../../layouts/HeaderComponent';
import { HighlightBlock } from '../HighlightBlocks/HighlightBlock/HighlightBlock';

interface Props {}

export const DXFreeTrial = ({ ...props }: Props) => {
  const [activeItem, setActiveItem] = useState(null);
  const vertical = 'NewForm-3dxpert';

  return (
    <>
      <BackgroundGDX>
        <Header demoVertical={vertical} whiteStripped={true} />

        <Hero>
          <div className={'container'}>
            <div className={'text-container'}>
              <ProductLogo Logo={'geomagic-designx'} Theme={'outline'} Size={'xl'} />
              <h1>Trial for free now</h1>
              <p>
                The industry standard for reverse engineering software. See how you can quickly go from scan to CAD with
                a free trial of Geomagic Design X.
              </p>
              <ButtonComponent
                Link={'/geomagic-designx/download/'}
                Label={'Download a free trial'}
                ID={'hero-trial-button'}
              />
            </div>
            <img alt={''} className={'hero-image'} src={'/assets/2024/geomagic-designx/hero-keyvisual.png'} />
            <img
              src={'https://assets.capterra.com/badge/7efb3e2ab8d3bf7cfcdf2617994471f8.svg?v=2225314&p=276905'}
              alt={'capterra'}
              className={'capterra'}
            />
          </div>
        </Hero>

        <Panel>
          <div className={'container'}>
            <div className={'inner'}>
              <div className={'text-wrap'}>
                <h2>Geomagic Design X. Reverse engineering software trusted by global manufacturers.</h2>
                <p>
                  “Everything that I've scanned using the FARO ScanArm and reverse engineered in Geomagic Design X, I've
                  printed it and it has fit the car perfectly every time.”
                </p>
                <div className={'review-wrap'}>
                  <img alt={''} src={'/assets/2024/geomagic-designx/Kindig.png'} />
                  <p>
                    <b>Greg Hebard</b>
                    <br />
                    Metal Fabricator
                    <br />
                    <b>Kindig-It Design</b>
                  </p>
                </div>
              </div>
              <div className={'logo-wrap'}>
                <img alt={''} src={'/assets/2024/geomagic-designx/company-cummins.png'} />
                <img alt={''} src={'/assets/2024/geomagic-designx/company-uconn.png'} />
                <img alt={''} src={'/assets/2024/geomagic-designx/company-kindig.png'} />
                <img alt={''} src={'/assets/2024/geomagic-designx/company-lzmfg.png'} />
                <img alt={''} src={'/assets/2024/geomagic-designx/company-inter.png'} />
                <img alt={''} src={'/assets/2024/geomagic-designx/company-elring.png'} />
                <img alt={''} src={'/assets/2024/geomagic-designx/company-asano.png'} />
                <img alt={''} src={'/assets/2024/geomagic-designx/company-pennstate.png'} />
                <img alt={''} src={'/assets/2024/geomagic-designx/company-ks.png'} />
              </div>
            </div>
          </div>
        </Panel>
      </BackgroundGDX>
      <MainContent>
        <WhatToExpect>
          <div className={'container'}>
            <div className={'key-visual'}>
              <img alt={''} src={'/assets/2024/geomagic-designx/gdx-keyvisual.png'} />
            </div>
            <div className={'text-primary'}>
              <div>
                <h2>
                  <b>Discover what you can achieve</b> with the Geomagic Design X free trial
                </h2>
                <hr className={'divider red'} />
                <p>
                  Get access to the complete reverse engineering capabilities of Geomagic Design X with a 14-day free
                  trial and discover how you can:
                </p>
                <ul>
                  <li>
                    Convert 3D scan data into high-quality, <b>feature-based CAD</b> models.
                  </li>
                  <li>
                    <b>Rebuild</b> CAD data for broken tools and molds.
                  </li>
                  <li>
                    <b>Recreate</b> lost CAD data for parts and molds.
                  </li>
                  <li>
                    <b>Design</b> custom products.
                  </li>
                  <li>
                    Convert physical parts into CAD for <b>new product designs.</b>
                  </li>
                  <li>Make existing parts fit with new parts.</li>
                  <li>
                    Utilize <b>exact surface fitting</b> to organic 3D scans, as well as mesh editing and point-cloud
                    processing
                  </li>
                </ul>
                <ButtonComponent
                  Link={'/geomagic-designx/download/'}
                  Label={'Download a free trial'}
                  Theme={'geomagic-designx'}
                  ID={'main-content-trial-button'}
                />
              </div>
            </div>
          </div>
        </WhatToExpect>
      </MainContent>

      <HighlightBlock
        AdditionalClass={'scaled-right-image'}
        BlockWidth={'full-width'}
        Theme={'accent-red-clear'}
        Button={{
          link: '/geomagic-designx/download/',
          copy: 'Download a free trial',
          theme: 'clear'
        }}
        Title={'<b>What are you waiting for?<br/>Try for yourself now</b>'}
        ImageType={'Split'}
        Image={{
          url: '/assets/2024/geomagic-designx/secondary-visual.png'
        }}
      />

      <Accordion
        Title={'FAQ'}
        Items={[
          {
            title: 'How do I download and install my Geomagic Design X free trial?',
            content: [
              {
                contentType: 'content',
                copy: "Request a <a href='/geomagic-designx/download/'>free trial</a> and fill in your contact details. A member of our team will then contact you with the installer and install information. The Geomagic Design X <a href='https://softwaresupport.oqton.com/s/article/Getting-Started-DXCX-Installation?language=en_US&r=403&ui-knowledge-components-aura-actions.KnowledgeArticleVersionCreateDraftFromOnlineAction.createDraftFromOnlineArticle=1'>step-by-step installation guide</a> can also be found on our support site. Your trial will last 14 days from the day of activation. "
              }
            ]
          },
          {
            title: 'How long does my free trial of Geomagic Design X last?',
            content: [
              {
                contentType: 'content',
                copy: 'Once you have received your installer download link, your free trial will last 14 days from the day of activation.'
              }
            ]
          },
          {
            title: 'What are the minimum system requirements to run Geomagic Design X?',
            content: [
              {
                contentType: 'content',
                copy: "<ul><li>Windows 8.1 (64-bit)</li><li>Intel or AMDS processor with a minimum 2.0 GHz</li><li>8GB RAM</li><li>30GB of available hard-disc space (additional space may be required for cache)</li><li>32-bit true colour display, screen resolution 1280x960</li><li>GPU - OpenGL 3.0 or above enabled graphics card with 4GB of RAM or more</li><li>See <a href='https://softwaresupport.oqton.com/s/article/GeomagicDesignX?language=en_US' target='_blank'>full system requirements here.</a></li></ul>"
              }
            ]
          },
          {
            title: 'Where can I get technical support during my trial of Geomagic Design X?',
            content: [
              {
                contentType: 'content',
                copy: "A comprehensive list of FAQs can be found on our <a href='https://softwaresupport.oqton.com/s/article/Design-X-Knowledge-Base?language=en_US' target='_blank'>Geomagic Design X Knowledge Base</a>. If you can’t find what you are looking for, you can submit a case to our support team."
              }
            ]
          },
          {
            title: 'Where can I learn the basics to help me with my Geomagic Design X free trial?',
            content: [
              {
                contentType: 'content',
                copy: "We have put together a series of <a href='https://softwaresupport.oqton.com/s/article/Design-X-Introductory-Tutorial-Start-Page?language=en_US' target='_blank'>Geomagic Design X tutorial videos</a> to get you up and running during your free trial."
              }
            ]
          },
          {
            title: 'What languages are supported in the free trial of Geomagic Design X?',
            content: [
              {
                contentType: 'content',
                copy: 'The Geomagic Design X free trial supports the following languages:<br/><br/>English, Chinese, Japanese, Korean, German, Italian, Russian, Portuguese, Spanish, Polish,Turkish, French and Czech.'
              }
            ]
          },
          {
            title: 'How do I cancel my Geomagic Design X free trial?',
            content: [
              {
                contentType: 'content',
                copy: 'Your Geomagic Design X free trial will automatically end after the 14 day period.'
              }
            ]
          }
        ]}
      />
    </>
  );
};
