import React from 'react';
import { Footer } from '../../components/layouts/Footer';
import { Hero } from '../../components/layouts/Hero';
import { MediaComponentV2 } from '../../components/V2/MediaComponent';
import { Statistics } from '../../components/layouts/Statistics';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { ListContainer } from '../../components/pageComponents/Styles/style';

import { HeaderSubtitle } from '../../components/subComponents/HeaderSubtitle';
import { HeaderBackground } from '../../components/pageComponents/header';
import { Header } from '../../components/layouts/HeaderComponent';
import { ButtonContainer, WhiteButton } from '../../components/pageComponents/mediaComponentContent/styles';
import { Icon } from '../../components/subComponents/Icon';
import { PageContainer } from '../../components/containers/PageContainer';

const GeomagicControlX = () => {
  return (
    <>
      <HelmetContainer
        currentPage={'geomagic-controlx-automated-inspection'}
        image="/assets/images/pages/geomagic-control-x/automation/controlx-robotarm-1.png"
        title="Automated Inspection - Geomagic Controlx 3D Inspection Software"
        pageDescription="Unlock the power of automated inspection | Developed by Oqton"
        description="Unlock the power of automated inspection | Developed by Oqton"
      />

      <PageContainer>
        <HeaderBackground>
          <Header />
          <Hero
            title="Unlock the power of <b>automated inspection</b>"
            subtitle={<HeaderSubtitle icon="ControlX" boldText="Geomagic" text="Control X" />}
            image={{
              src: '/assets/images/pages/geomagic-control-x/automation/controlx-robotarm-1.png',
              alt: 'Geomagic Control X Automobile Part'
            }}
            description="Bring industry-leading automated inspection to more people in more places with a feature rich, highly versatile 3D metrology software that enables automated quality control and better throughput."
            buttons={[
              { text: 'Request a free trial', vertical: 'controlXAutomation' }
              // {
              //   text: 'Contact us',
              //   vertical: 'controlXAutomation',
              //   color: colorsRD.primary
              // }
            ]}
            vertical="controlX"
            phone
          />
        </HeaderBackground>
        <Statistics
          title="Ensure Quality Everywhere"
          stats={[
            { label: 'solution for everywhere', value: '1' },
            { label: 'faster than CMM', value: '7X' },
            { label: 'lower inspection costs', value: '80%' }
          ]}
          inverted
        />
        <MediaComponentV2
          decreasedPadding
          articles={[
            {
              title: '<b>Seamlessly automate</b> your quality control workflows',
              content: (
                <>
                  <ListContainer>
                    <li>Automate time intensive tasks</li>
                    <li>Address the growing manufacturing skill gap by preserving learned expertise</li>
                    <li>Improve part quality in less time</li>
                    <li>Be more agile in your responses to new customer requests</li>
                    <li>Accelerate process sustainability, scrap less parts</li>
                  </ListContainer>
                  {/*<BottomText>*/}
                  {/*  Check out the videos below to learn how automated quality control workflows in Geomagic Control X are*/}
                  {/*  perfect for overcoming many common production bottlenecks.*/}
                  {/*</BottomText>*/}
                </>
              ),
              image: {
                src: '/assets/images/pages/geomagic-control-x/automation/geomagic_controlx_on_screens.png',
                alt: 'Geomagic ControlX on computer screens',
                imagePos: 'left'
              }
            }
          ]}
        />
        <MediaComponentV2
          articles={[
            {
              title: '<b>Integrate automated inspection</b> into your high-volume production',
              subTitle: 'automation server',
              content: (
                <>
                  <p>Maximize PC utilization to optimize production throughput with the Automation Server.</p>
                  <br />
                  <ListContainer>
                    <li>
                      Increase the efficiency of inspection operations with simultaneous and parallel job processing
                      using server and client PCs.
                    </li>
                    <li>Use inspection results to inform and optimize downstream processes and devices. </li>
                    <li>
                      Combine with Visual Scripting to maximize production throughput. Use the automation server to call
                      a script, execute a workflow and retrieve the data for further processing.{' '}
                    </li>
                    <li>
                      Send Pass/Fail results to external production control systems to inform other processes in your
                      production environment.
                    </li>
                  </ListContainer>
                  <ButtonContainer>
                    <a href="/assets/files/brochures/controlX/cx-automation-volumeextract_samplefiles.zip">
                      <WhiteButton>
                        Download Sample Files
                        <Icon ml="12px" name="Arrow" />
                      </WhiteButton>
                    </a>
                  </ButtonContainer>
                </>
              ),
              mediaComponent: {
                component: (
                  <iframe
                    title="Integrate automated inspection into your high-volume production"
                    className="vidyard_iframe"
                    src="//play.vidyard.com/xGTcvUFXnpgJLMM82L5z4h.html"
                    width="640"
                    height="360"
                    scrolling="no"
                    frameBorder="0"
                    allowTransparency
                    allowFullScreen
                  />
                ),
                pos: 'right'
              },
              image: {
                src: '/assets/images/pages/geomagic-control-x/media_2.png',
                alt: 'Placeholder',
                imagePos: 'right'
              }
            }
          ]}
        />
        <MediaComponentV2
          articles={[
            {
              title: '<b>Complex or custom inspection? </b> Script it. Automate it.',
              subTitle: 'visual scripting',
              content: (
                <>
                  <p>
                    Control X Visual Scripting brings complex inspection automation workflows to more people, with no
                    programming knowledge required.
                  </p>
                  <br />
                  <ListContainer>
                    <li>Solve complex inspection challenges using simple visual interface.</li>
                    <li>
                      Build a complete automated workflow by dragging operations into the visual scripting environment
                      and stringing them together.
                    </li>
                    <li>
                      Improve collaboration and operational efficiency by create logical workflow accessible for less
                      experienced users.
                    </li>
                    <li>
                      Eliminate human error and increase throughput by removing human interaction from important quality
                      assurance checks.
                    </li>
                  </ListContainer>
                  <ButtonContainer>
                    <a href="/assets/files/brochures/controlX/cx-automation-replace-ref-data.scrpt.zip">
                      <WhiteButton>
                        Download Sample Files
                        <Icon ml="12px" name="Arrow" />
                      </WhiteButton>
                    </a>
                  </ButtonContainer>
                  {/*<BottomText>*/}
                  {/*  Click the video to find out how to automate a set inspection routine, even when the reference geometry*/}
                  {/*  changes from part to part*/}
                  {/*</BottomText>*/}
                </>
              ),
              mediaComponent: {
                component: (
                  <iframe
                    title="Complex or custom inspection? Script it. Automate it."
                    className="vidyard_iframe"
                    src="//play.vidyard.com/u46vaWpdR32pfCDwwpio2s.html"
                    width="640"
                    height="360"
                    scrolling="no"
                    frameBorder="0"
                    allowTransparency
                    allowFullScreen
                  />
                ),
                pos: 'left'
              },
              image: {
                src: '/assets/images/pages/geomagic-control-x/media_4.png',
                alt: 'Placeholder',
                imagePos: 'left'
              }
            }
          ]}
        />
        <MediaComponentV2
          articles={[
            {
              title: '<b>Define once. Run forever. </b>',
              subTitle: 'batch processing',
              content: (
                <>
                  <p>
                    Execute a series of inspection jobs without manual intervention. Define your inspection routine once
                    and run as long as the part is in production.{' '}
                  </p>
                  <br />
                  <ListContainer>
                    <li>Simply and quickly compare many measured data files to reference geometry.</li>
                    <li>Maximize throughput, optimize computer resource utilization when less busy.</li>
                    <li>Avoid idling computer resource with minute-by-minute intervention and supervision.</li>
                    <li>Optimize metrology processes for production runs, free up expertise for value-add tasks.</li>
                    <li>Automatically produce a detailed inspection report for each job</li>
                  </ListContainer>
                </>
              ),
              mediaComponent: {
                component: (
                  <iframe
                    title="Define once. Run forever"
                    className="vidyard_iframe"
                    src="//play.vidyard.com/sQWqpdBcPeoptakqZ8ne7R.html"
                    width="640"
                    height="360"
                    scrolling="no"
                    frameBorder="0"
                    allowTransparency
                    allowFullScreen
                  />
                ),
                pos: 'right'
              },
              image: {
                src: '/assets/images/pages/geomagic-control-x/media_3.png',
                alt: 'Placeholder',
                imagePos: 'right'
              }
            }
          ]}
        />
        <MediaComponentV2
          articles={[
            {
              title: '<b>Process scan data quickly </b> with no manual intervention',
              subTitle: 'Scan Process',
              content: (
                <>
                  <p>
                    Automatically execute a series of commands to post process scan data ready for interrogation and
                    reporting.
                  </p>
                  <br />
                  <ListContainer>
                    <li>Process a series of scan data optimization commands for multiple scan files.</li>
                    <li>Share presets with others in your organization to minimize inspection setup time. </li>
                    <li>
                      Use the intuitive Scan Process Designer to create straightforward processing workflows that can be
                      used every time you capture data.
                    </li>
                    <li>Eliminate human error and increase throughput by automating common scan processing steps.</li>
                  </ListContainer>
                </>
              ),
              image: {
                src: '/assets/images/pages/geomagic-control-x/automation/product_visual_with_image.png',
                alt: 'Product Visual with Image',
                imagePos: 'left'
              }
            }
          ]}
        />

        <Footer />
      </PageContainer>
    </>
  );
};

export default GeomagicControlX;
