import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { ProductHighlightStyles } from './styles.jsx';
import { createMarkUp } from '../helpers';
import { ProductLogo } from '../ProductLogo/ProductLogo';
import { ButtonComponent } from '../Button/Button';

interface Props {
  Product?:
    | 'manufacturing-os'
    | 'geomagic-for-solid'
    | 'geomagic-for-solidworks'
    | 'geomagic-designx'
    | 'geomagic-design-x'
    | 'geomagic-controlx'
    | 'geomagic-control-x'
    | 'geomagic-freeform'
    | 'freeform'
    | 'geomagic-wrap'
    | '3dxpert'
    | 'amphyon'
    | 'd2p';
  Title?: string;
  Copy?: string;
}

export const ProductHighlight = ({ Title = '', Copy = '', Product = '', ...props }: Props) => {
  return (
    <ProductHighlightStyles className={`Component-ProductHighlight Theme-${Product}`} {...props}>
      <div className={'container'}>
        <ProductLogo Size={'xl'} Logo={Product} Theme={'outline'} />
        <h1>{Title}</h1>
        <p>{Copy}</p>

        <ButtonComponent Theme={'3dxpert-reverse'} Label={'Learn More'} Link={'/3dxpert/'} />
      </div>
    </ProductHighlightStyles>
  );
};
