import styled from 'styled-components';
import { RDButton, RDH1, RDPL, RDPM, RDPS } from '../../../styles/typography';
import {
  boxShadows,
  colorsRD,
  fontSizes,
  media,
  spacing,
  typography,
  newFontWeights as fontWeights,
  newLineHeight as lineHeight
} from '../../foundations';
import { Link } from '../../subComponents/Link';
export const Container = styled.article`
  padding: ${spacing.xxxxxl}px ${spacing.xxxxxl}px 0;

  @media screen and (max-width: ${media.xxl}px) {
    padding: ${spacing.xxxxxl}px ${spacing.xxl}px 0;
  }
`;

export const ContentContainer = styled.div<{ hasTrustedBySection: boolean; hasImage: boolean }>`
  display: flex;
  justify-content: ${p => (p.hasImage ? 'space-evenly' : 'flex-start')};
  align-items: center;
  padding-bottom: ${p => (p.hasTrustedBySection ? '' : '110px')};
`;

export const InfoContainer = styled.div<{ hasVideo?: boolean }>`
  width: 590px;
  display: grid;
  grid-template-rows: repeat(4, min-content);
  grid-auto-columns: minmax(0, 1fr);
  grid-template-areas: ${p =>
    p.hasVideo
      ? `'sub sub sub .' 'sub2 sub2 sub2 .''title title title title' 'description description description description' 'button button video video' 'telephonebox telephonebox telephonebox .'`
      : `'sub sub sub .' 'sub2 sub2 sub2 .''title title title title' 'description description description description' 'button button telephonebox telephonebox'`};

  gap: ${spacing.l}px;
  margin-top: ${spacing.xl}px;

  @media screen and (max-width: ${media.md}px) {
    width: auto;
    grid-template-rows: repeat(7, auto);
    grid-template-areas: 'sub sub sub' 'sub2 sub2 sub2' 'title title title' 'description description description' 'video video .' 'button button button' 'telephonebox telephonebox telephonebox telephonebox';
  }
`;

export const HeroImage = styled.img`
  width: 570px;
  @media screen and (max-width: ${media['lg']}px) {
    z-index: -1;
    opacity: 0.2;
  }
`;

export const Title = styled.h1`
  grid-area: title;
  ${RDH1};
  color: ${colorsRD.primary};
  @media screen and (max-width: ${media['lg']}px) {
    font-size: ${fontSizes.RD['lm+']};
    font-weight: ${fontWeights.medium};
    line-height: ${lineHeight.RD['l']};
  }
`;

export const Subtitle = styled.sub`
  grid-area: sub;
`;

export const Subtitle2 = styled.sub`
  grid-area: sub2;
`;
export const Announcement = styled.p`
  background-color: ${colorsRD.accent.fossilGrey50};
  border-radius: 4px;
  padding: 1em 1em 1em 1em;
  text-align: center;
`;
export const AnnouncementContainer = styled.p`
  margin-top: ${spacing['xxxs']}px;
`;

export const Description = styled.p`
  grid-area: description;

  ${RDPL};
  color: ${colorsRD.primary};
`;

export const StyledLink = styled(Link)`
  grid-area: button;
  a {
    ${RDButton};
  }
`;

export const Button = styled.button<{ color?: string; noArea?: boolean; maxWidth?: string }>`
  ${p => (p.noArea ? '' : 'grid-area: button')};
  color: ${p => (p.color === '#FFFFFF' ? colorsRD.primary : colorsRD.white)};
  ${p => (p.color === '#FFFFFF' ? `border: 1px solid ` + colorsRD.primary + ';' : 'border: none;')};
  ${p => (p.maxWidth ? `max-width: ${p.maxWidth};` : '')}
  ${RDButton};
  background: linear-gradient(43.73deg, #99181d 8.58%, ${p => (p.color ? p.color : colorsRD.accent.passion)} 83.32%);
  padding: ${spacing.s}px ${spacing['m+']}px;
  border-radius: ${spacing.xxs}px;
  cursor: pointer;
  padding-right: 27px;
`;

export const VideoContainer = styled.div`
  grid-area: video;
  color: ${colorsRD.primary};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const Video = styled.p`
  ${RDButton};
  width: fit-content;
  height: min-content;
  padding-bottom: ${spacing.xs}px;
  border-bottom: solid 1px ${colorsRD.accent.passion};
`;
export const TelephoneBox = styled.div`
  grid-area: telephonebox;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'phone';
`;
export const Phone = styled.p`
  grid-area: phone;
  font-weight: ${typography.fontWeights.normal};
  font-size: ${fontSizes.ml};
  color: ${colorsRD.primaryText};
  a {
    color: ${colorsRD.description};
  }
`;
export const OtherPhone = styled.p`
  grid-area: otherphone;
  font-weight: ${typography.fontWeights.medium};
  font-size: ${fontSizes.ml};
  color: ${colorsRD.primary};
  text-align: center;
`;
export const TrustedByContainer = styled.section`
  padding: ${spacing.xl}px ${spacing.l}px;
  background-color: ${colorsRD.white};

  display: flex;
  box-shadow: ${boxShadows.tertiary};

  border-radius: ${spacing.xs}px;

  margin-top: 223px;
  width: fit-content;

  position: relative;
  top: ${spacing.xxl}px;

  @media screen and (max-width: ${media['xxl+']}px) {
    flex-direction: column;
    justify-content: center;

    margin: 223px auto 0;
  }

  @media screen and (max-width: ${media['sm+']}px) {
    margin: ${spacing.xl}px auto 0;
  }
`;
export const AnnouncementQuoteContainer = styled.section`
  display: grid;
  grid-template-columns: 75% 150px;
  grid-template-rows: 2fr;
  grid-column-gap: ${spacing.xxxl}px;
  grid-row-gap: ${spacing.l}px;
  grid-template-areas: 'quote stars' 'person stars';
  align-items: center;
  padding: ${spacing.xxl}px ${spacing.xxxl}px;
  background-color: ${colorsRD.white};
  box-shadow: ${boxShadows.tertiary};
  border-radius: ${spacing.xs}px;
  margin-top: 80px;
  position: relative;
  top: ${spacing.xxl}px;

  @media screen and (max-width: ${media['xxl+']}px) {
    margin: 223px auto 0;
    grid-template-areas: 'quote' 'person' 'stars';
    grid-template-columns: 100%;
    justify-items: center;
  }

  @media screen and (max-width: ${media['sm+']}px) {
    margin: ${spacing.xl}px auto 0;
  }
`;
export const AnnouncementQuote = styled.h3`
  ${RDPS};
  color: ${colorsRD.primary};
  @media screen and (max-width: ${media['xxl+']}px) {
    ${RDPM};
    padding-bottom: ${spacing.m}px;
    padding-left: 0;

    align-self: center;
  }
  font-size: 1.5em;
  font-weight: bold;
  grid-area: quote;
  padding-left: ${spacing.xxxxxxxl}px;
`;
export const AnnouncementPerson = styled.p`
  ${RDPS};
  color: ${colorsRD.primary};
  @media screen and (max-width: ${media['xxl+']}px) {
    ${RDPM};
    width: 100%;
    padding-bottom: ${spacing.m}px;
    padding-left: 0;
  }
  width: 50%;
  grid-area: person;
  padding-left: ${spacing.xxxxxxxl}px;
`;
export const AnnouncementStars = styled.h3`
  ${RDPS};
  color: ${colorsRD.accent.starYellow};
  font-size: ${spacing.xl}px !important;
  align: right;
  white-space: nowrap;
  grid-area: stars;
  @media screen and (max-width: ${media['xxl+']}px) {
    ${RDPM};
  }
`;
export const TrustedByTitle = styled.h3`
  ${RDPS};
  color: ${colorsRD.primary};
  align-self: center;
  white-space: nowrap;

  @media screen and (max-width: ${media['xxl+']}px) {
    ${RDPM};
  }
`;

export const TrustedByList = styled.ul`
  padding: 0 ${spacing['l+']}px;
  display: flex;
  gap: ${spacing.m}px;

  @media screen and (max-width: ${media['xxl+']}px) {
    flex-wrap: wrap;
    row-gap: ${spacing.xl}px;
    justify-content: center;
  }
`;

export const TrustedByItem = styled.li`
  min-width: ${spacing.xxxl}px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
