import React from 'react';
import { createMarkUp } from '../../helpers';
import { ProductLogo } from '../../V2/ProductLogo/ProductLogo';

import { ComponentStyles, CardBottomStyles } from './styles.jsx';
import { ButtonComponent } from '../../V2/Button/Button';

interface Props {
  Items?: string;
}

export const CardBottom = ({ ...props }) => {
  return (
    <CardBottomStyles>
      <div className={'content'} dangerouslySetInnerHTML={createMarkUp(props.copy)} />
    </CardBottomStyles>
  );
};

export const GDXHighlightCardBottom = ({ Items, ...props }: Props) => {
  return (
    <ComponentStyles className={`Component_GDXHighlightCardBottom`} {...props}>
      <div className={'container'}>
        {Items.map((item, index) => (
          <CardBottom key={index} {...item} />
        ))}
      </div>
    </ComponentStyles>
  );
};
