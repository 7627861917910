import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { FullScreenImageStyles } from './styles.jsx';

interface Props {
  Title?: string;
  Subtitle?: string;
  Background?: '';
  Image?: object;
  TextColour?: 'Red' | 'White' | 'Primary' | 'Teal' | 'Green';
  MinHeight?: '100vh';
}

export const FullScreenImage = ({
  Title = '',
  Subtitle,
  Background,
  Image,
  TextColour,
  MinHeight,
  ...props
}: Props) => {
  return (
    <FullScreenImageStyles
      className={'Component-FullScreenImage'}
      {...props}
      style={{ backgroundImage: 'url(' + Background + ')', minHeight: MinHeight }}
    >
      <GlobalStyle />
      <div className={'container'}>
        <h2 className={'text-' + TextColour}>{Title}</h2>
        <h4 className={'text-' + TextColour}>{Subtitle}</h4>
        <img src={Image.url} alt={Image.alt} />
      </div>
    </FullScreenImageStyles>
  );
};
