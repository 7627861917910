import styled from 'styled-components';
import { RDButton, RDH2, DescriptionStyles, RDH3 } from '../../../styles/typography';
import { Box } from '../../containers/Box';
import { colorsRD, spacing, media } from '../../foundations';
import { Position } from '../../subComponents/Card/types';

export const Container = styled.section`
  padding: ${spacing.xxxxxl}px;

  @media screen and (max-width: ${media['xxl++']}px) {
    padding: ${spacing.xxxxxl}px ${spacing.xxxl}px;
  }

  @media screen and (max-width: ${media.md}px) {
    padding: ${spacing.xxl}px ${spacing.m}px;
  }
`;

export const Title = styled.h1`
  ${RDH2};
  text-align: center;
  color: ${colorsRD.primary};
  margin-bottom: ${spacing.l}px;

  @media screen and (max-width: ${media.md}px) {
    ${RDH3};
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
  width: min-content;
`;

export const Button = styled.button`
  background: ${colorsRD.white};
  padding: ${spacing.s}px ${spacing['m+']}px;
  border: solid 2px ${colorsRD.secondary};
  border-radius: ${spacing.xxs}px;
  cursor: pointer;

  display: flex;
  align-items: center;
  margin: 0 auto ${spacing.xxxl}px auto;
`;

export const ButtonText = styled.p`
  ${RDButton};
  color: ${colorsRD.primary};

  text-decoration: none;
`;

export const ButtonIcon = styled(Box)<{ _position?: Position }>`
  color: ${colorsRD.primary};
`;

export const CardContainer = styled.section`
  display: grid;
  grid-gap: ${spacing['xl+']}px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;

  @media screen and (max-width: 1600px) {
    width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: ${media.lg}px) {
    width: auto;
    margin: 0 ${spacing.m}px;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: ${media.sm}px) {
    margin: 0 ${spacing.xs}px;
    display: flex;
    flex-direction: column;
  }
`;

export const ListContainer = styled.ul`
  list-style: initial;
  li {
    margin-left: ${spacing['l+']}px;
  }
`;

export const List = styled.li`
  margin-left: ${spacing['l+']}px;
`;

export const Description = styled.div`
  ${DescriptionStyles}
`;
