import React from 'react';

import { SkillCardContainer, SkillCardIndex, SkillCardText, SkillCardTitle } from './SkillCardStyles';

export interface SkillCardProps {
  title: string;
  index: number;
  text: string;
}

export const SkillCard = ({ title, index, text }: SkillCardProps) => {
  return (
    <SkillCardContainer index={index}>
      <SkillCardTitle>{title}</SkillCardTitle>
      <SkillCardIndex>0{index}.</SkillCardIndex>
      <SkillCardText>{text}</SkillCardText>
    </SkillCardContainer>
  );
};
