import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { ProductLogo } from '../ProductLogo/ProductLogo';

import { LargeHeroStyles, CopyWrapper } from './styles.jsx';
import { DownloadEmailForm } from '../Form/DownloadEmailForm';

interface Props {
  AdditionalClasses?: string;
  Content?: object;
  Button?: {};
  BackgroundColour?: 'white' | 'off-white';
  Country?: 'en' | 'fr' | 'uk' | 'de' | 'it' | 'ko';
  BackgroundStyle?: 'bottom-right' | 'center-right' | 'cover' | 'image';
  AddSalesTel?: boolean;
  Logo?:
    | null
    | 'manufacturing-os'
    | 'geomagic-designx'
    | 'geomagic-controlx'
    | 'geomagic-freeform'
    | 'geomagic-wrap'
    | '3dxpert'
    | 'amphyon'
    | 'd2p'
    | 'geomagic-for-solid';
  Background?: string;
  SecondaryLogo?: string;
}

export const MobileDownloadHero = ({
  AddSalesTel = false,
  AdditionalClasses,
  SecondaryLogo,
  Button,
  Logo,
  Country,
  Background,
  BackgroundColour = 'white',
  BackgroundStyle = 'bottom-right',
  Content = [],
  ...props
}: Props) => {
  let FormProductCopy;
  if (Logo === 'geomagic-for-solid') {
    FormProductCopy = 'Geomagic for SOLIDWORKS';
  }
  if (Logo === 'geomagic-designx') {
    FormProductCopy = 'Geomagic Design X';
  }
  if (Logo === 'geomagic-wrap') {
    FormProductCopy = 'Geomagic Wrap';
  }
  if (Logo === 'geomagic-controlx') {
    FormProductCopy = 'Geomagic Control X';
  }

  return (
    <LargeHeroStyles
      className={`Component_LargeHero background-colour-${BackgroundColour} background-style-${BackgroundStyle} ${AdditionalClasses}`}
      {...props}
    >
      {Background && BackgroundStyle !== 'image' && (
        <div
          className={'background background-style-' + BackgroundStyle}
          style={{ backgroundImage: 'url(' + Background + ')' }}
        ></div>
      )}
      <GlobalStyle />
      <div className={'container'}>
        <div className={'copy-wrap'}>
          {Logo && <ProductLogo Logo={Logo} />}
          <CopyWrapper className={AdditionalClasses}>
            <DownloadEmailForm
              Product={FormProductCopy}
              Lang={Country}
              StartText={Content.mobile.preform}
              CompletedText={Content.mobile.completed}
            />
          </CopyWrapper>
        </div>

        {Background && BackgroundStyle === 'image' && (
          <div className={'image'}>
            <img src={Background} alt={''} />
          </div>
        )}
      </div>
    </LargeHeroStyles>
  );
};
