import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const WorldMapStyles = styled.div`
  padding: 64px 0;

  h2 {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 24px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 40px;
    }
    span{
      color: ${colors.highlight};
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 0.01em;
    width: 100%;
    max-width: 770px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 20px;
    }
  }

  .img-wrap {
    position: relative;

    img.map {
      width: 100%;
    }
  }

  .desktop-list {
    display: none;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    .desktop-list {
      display: block;
    }

    .mobile-list {
      display: none;
    }

  }
`;

export const LocationWrap = styled.div`
  max-width: 580px;
  width: 100%;

  hr.divider.full {
    height: 1px;
    margin: 0 0 24px 0;
  }

  ul {
    margin: 0 0 8px;
    padding: 0;

    li {
      list-style: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.25;
      display: inline-block;
      color: ${colors.primary};
      font-family: centrano2-book;
      span {
        margin: 0 8px 3px;
        width: 5px;
        height: 5px;
        background: ${colors.red};
        display: inline-block;
        border-radius: 50%;
      }

      &:last-of-type {
        span {
          display: none;
        }
      }
    }
  }
`;

export const StatisticsWrap = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const Stat = styled.div`
  display: flex;
  align-items: end;
  margin-right: 16px;

  h4 {
    font-size: 46px;
    line-height: 1;
    letter-spacing: 0.01em;
    color: ${colors.primary};
    margin-right: 8px;
    font-family: centrano2-medium;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    line-height: 1.3;
    color: ${colors.primary};
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    margin-right: 40px;
    h4 {
      font-size: 64px;
      margin-right: 16px;
    }

    p {
      line-height: 1.5;

    }
  }

`;