import React, { useEffect, useRef } from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Grid, LargeMapStyles, MapStyles, MapWrap } from './styles.jsx';
import customMarkerIcon from '../../../../static/assets/icons/map-pin.svg'; // Import your custom marker icon
import customPartnerIcon from '../../../../static/assets/icons/map-pin-partner.svg'; // Import your custom marker icon
import { snazzyMapStyles } from './MapHelper';
import { createSlug } from '../../../helpers/helper';

interface Props {
  events: object;
  lat: string;
  lng: string;
}

export const GoogleMapsMultiPointComponent: React.FC<Props> = ({ events, lng, lat, ...props }) => {
  const center = { lat: parseFloat(lat), lng: parseFloat(lng) };
  const zoom = 2;

  const generateGoogleMapsURL = address => {
    const baseURL = 'https://www.google.com/maps/search/?api=1&query=';
    const encodedAddress = encodeURIComponent(address).replace(/%20/g, '+');
    return baseURL + encodedAddress;
  };

  return (
    <MapWrap {...props}>
      <Wrapper apiKey={'AIzaSyAFe3RwU_KsL6I4X4FA-G0cg5U52hg81KI'}>
        <Map pins={events} center={center} zoom={zoom} />
      </Wrapper>
      <hr />
    </MapWrap>
  );
};

interface MapProps {
  pins: object;
  center: google.maps.LatLngLiteral;
  zoom: number;
  isPartnerEvent: boolean;
}

export const Map: React.FC<MapProps> = ({ pins, center, zoom, isPartnerEvent }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const mapInstance = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        styles: snazzyMapStyles
      });

      pins.map((pin, index) => {
        const center = {
          lat: parseFloat(pin.location['google-maps']['lat']),
          lng: parseFloat(pin.location['google-maps']['lng'])
        };

        let icon = customMarkerIcon;
        if (pin.type === 'Partner Event') {
          icon = customPartnerIcon;
        }

        const marker = new window.google.maps.Marker({
          position: center,
          map: mapInstance,
          icon: icon
        });

        const url = createSlug(pin.title);

        const contentString = `
  <a href="/events/${url}/">
    <h1 style="text-align: center">${pin.title}</h1>
    <img src="${pin.event_logo}" style="width: auto;
    height: auto;
    max-width: 150px;
    max-height: 90px;
    margin: 16px auto;
    display: block;" />
  </a>`;

        const infoWindow = new window.google.maps.InfoWindow({
          content: contentString
        });

        marker.addListener('click', () => {
          infoWindow.open(mapInstance, marker);
        });
      });
    }
  }, [center, zoom]);

  return <LargeMapStyles ref={ref} id="map" />;
};
