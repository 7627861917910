import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { FormStyles } from './styles.jsx';
import { InputField } from './InputField';
import { createMarkUp } from '../helpers';

interface Props {
  Lang?: string;
  Product: string;
  StartText: string;
  CompletedText: string;
}

export const DownloadEmailForm = ({
  Lang = 'en',
  Product = 'Geomagic DesignX',
  StartText = '',
  CompletedText = '',
  ...props
}: Props) => {
  const apiEndpoint = 'https://middleware-oqton.netlify.app/';
  let apiLang = '';
  if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
    apiLang = 'cn-';
  }

  const copy = require(`../../../../data/forms/${Lang}.json`);

  const [formInProgress, setFormInProgress] = useState(false);
  const [status, setStatus] = useState('');

  const [formData, setFormData] = useState({
    utm_term: '',
    utm_content: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    product_interest: '',
    preferred_lang: Lang,
    campaign_id: '',
    email: ''
  });

  const [touchedFields, setTouchedFields] = useState({
    email: false
  });

  const [errors, setErrors] = useState([]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const isValidEmail = email => {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Test the email against the pattern
    return emailPattern.test(email);
  };

  const handleTouch = e => {
    const { name, value } = e.target;

    setTouchedFields({
      ...touchedFields,
      [name]: true
    });

    // If the field is a required field
    if (name === 'email') {
      //If the field is not filled in
      if (formData[name].length < 1) {
        setErrors(prevErrors => {
          // Create a Set to store unique errors
          const uniqueErrors = new Set(prevErrors);

          // Add the new error to the Set
          uniqueErrors.add(copy['fields'][name]['error']['required']);

          // Convert the Set back to an array
          return [...uniqueErrors];
        });
      } else {
        if (name === 'email') {
          setErrors(prevErrors => {
            // Create a Set to store unique errors
            const uniqueErrors = new Set(prevErrors);

            // Remove the specific string from the Set
            const stringToRemove = copy['fields'][name]['error']['required'];
            uniqueErrors.delete(stringToRemove);

            // Convert the Set back to an array
            return [...uniqueErrors];
          });

          if (!isValidEmail(formData[name])) {
            setErrors(prevErrors => {
              // Create a Set to store unique errors
              const uniqueErrors = new Set(prevErrors);

              // Add the new error to the Set
              uniqueErrors.add(copy['fields'][name]['error']['invalid']);

              // Convert the Set back to an array
              return [...uniqueErrors];
            });
          } else {
            setErrors(prevErrors => {
              // Create a Set to store unique errors
              const uniqueErrors = new Set(prevErrors);

              // Remove the specific string from the Set
              const stringToRemove = copy['fields'][name]['error']['invalid'];
              uniqueErrors.delete(stringToRemove);

              // Convert the Set back to an array
              return [...uniqueErrors];
            });
          }
        } else {
          setErrors(prevErrors => {
            // Create a Set to store unique errors
            const uniqueErrors = new Set(prevErrors);

            // Remove the specific string from the Set
            const stringToRemove = copy['fields'][name]['error']['required'];
            uniqueErrors.delete(stringToRemove);

            // Convert the Set back to an array
            return [...uniqueErrors];
          });
        }
      }
    }
  };

  const handleSubmit = async e => {
    setFormInProgress(true);

    e.preventDefault();

    // Check if any required fields are empty
    const requiredFields = ['email'];

    const emptyFields = requiredFields.filter(field => !formData[field]);

    if (emptyFields.length > 0) {
      const newErrors = [];
      const newTouched = { ...touchedFields };
      emptyFields.map((field, key) => {
        newErrors.push(copy['fields'][field]['error']['required']);
        newTouched[field] = true;
      });
      setErrors(newErrors);
      setTouchedFields(newTouched);
      setFormInProgress(false);
      return;
    }

    try {
      const response = await axios.post(`${apiEndpoint}.netlify/functions/${apiLang}download-product`, formData);
      setStatus('completed');
      setFormInProgress(false);
    } catch (error) {
      alert(copy.errors.unknown);
      console.error(copy.errors.unknown, error);
      setFormInProgress(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const cookieValue = Cookies.get('utmdata');

      if (cookieValue != null && cookieValue !== undefined) {
        const parsedData = cookieValue.split('|').reduce((acc, section) => {
          const match = section.match(/(utm\w+)=\(?([^\)]+)\)?/);
          if (match) {
            const [, key, value] = match;
            acc[key] = value;
          }
          return acc;
        }, {});

        setFormData(prevFormData => ({
          ...prevFormData,
          utm_source: parsedData['utm_source'] || prevFormData['utm_source'],
          utm_campaign: parsedData['utm_campaign'] || prevFormData['utm_campaign'],
          utm_medium: parsedData['utm_medium'] || prevFormData['utm_medium'],
          utm_term: parsedData['utm_term'] || prevFormData['utm_term'],
          utm_content: parsedData['utm_content'] || prevFormData['utm_content']
        }));
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const newFormData = formData;
    newFormData['product_interest'] = Product;
    setFormData(newFormData);
  }, []);

  return (
    <>
      {status !== 'completed' && (
        <>
          <div dangerouslySetInnerHTML={createMarkUp(StartText)}></div>

          <FormStyles
            onSubmit={handleSubmit}
            className={`Component-Form Component-DownloadForm in-progress-${formInProgress}`}
            {...props}
          >
            <InputField
              required={true}
              touchedFields={touchedFields}
              formData={formData}
              copy={copy}
              handleTouch={handleTouch}
              handleChange={handleChange}
              fieldName={'email'}
              fieldType={'email'}
            />

            <p className={`${errors.length > 0 ? 'errors' : 'errors hidden'}`}>
              <span className={'validation'}>
                {copy.errors.validation}
                <ul>
                  {errors &&
                    errors.map((error, index) => {
                      return <li key={index}>{error}</li>;
                    })}
                </ul>
              </span>
            </p>

            <label className={'full-width'}>
              <input
                className={`theme-${Product.toLowerCase().replace(/ /g, '-')}`}
                disabled={errors.length !== 0}
                type="submit"
                value={copy.fields.submit.label}
              />
            </label>
          </FormStyles>
        </>
      )}

      {status === 'completed' && <h4>{CompletedText}</h4>}
    </>
  );
};
