import styled from 'styled-components';

import { colors, mediaQueriesSizes, bgGradients } from '../../../GlobalStyles';

export const LogoGridStyles = styled.div`
  padding: 24px 0;
  position: relative;
  text-align: center;

  .custom-button {
    margin: 60px 0 0;
  }

  h2 {
    text-transform: uppercase;
    font-family: centrano2-medium;
    color: ${colors.UILightSecondary};
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    margin-bottom: 16px;
    position: relative;

    & > span {
      font-size: 2em;
      position: relative;
      top: 0.1em;

      span {
        color: ${colors.teal};
      }
    }

    &.title-size-l {
      color: ${colors.primary};
      font-size: 26px;
      line-height: 26px;
    }
  }

  p{
    font-size: 18px;
    text-align: center;
    margin-bottom: 16px;
    font-family: centrano2-book;
  }

  hr {
    width: calc(95% - 32px);
    margin: 52px auto 36px;
    max-width: 1300px;
  }

  &.reduced-lower-margin {
    margin-bottom: -50px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 62px 0;

    h2 {
      font-size: 20px;
      & > span {
        font-size: 2em;
        position: relative;
        top: 0.1em;

        span {
          color: ${colors.teal};
        }
      }

      &.title-size-l {
        color: ${colors.primary};
        font-size: 42px;
        line-height: 42px;
      }
    }
  }

  &.theme-dark {
    h2 {
      color: ${colors.UILightInverseSecondary};
    }
  }

  &.left-headers {
    h2 {
      color: ${colors.primary};
      text-transform: none;
      text-align: left;
    }
    p{
      text-align: left;
      padding: 0.5em 0 1em 0;
    }
    h3 {
      color: ${colors.red};
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 16px;
      &.subtitle-size-l {
        font-family: centrano2-medium;
        font-size: 18px;
        line-height: 1.5;
        letter-spacing: 0.02em;
      }
    }
  }

  &.homepageLogos {
    background: ${colors.primary};
    position: relative;
    z-index: 10;
    .grid-item {
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        flex-basis: calc(100% / 6);
        padding: 16px;
      }
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //grid-template-columns: repeat(2, 1fr);
  //grid-row-gap: 32px;
  //grid-column-gap: 32px;
  width: calc(95% - 32px);
  margin: 0 auto;

  //@media (min-width: ${mediaQueriesSizes.md}px) {
  //  grid-row-gap: 32px;
  //  grid-column-gap: 32px;
  //  grid-template-columns: repeat(3, 1fr);
  //}
  //@media (min-width: ${mediaQueriesSizes.lg}px) {
  //  grid-row-gap: 32px;
  //  grid-column-gap: 32px;
  //  grid-template-columns: repeat(5, 1fr);
  //}
  //
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    //  grid-template-columns: repeat(5, 1fr);
    //  grid-row-gap: 24px;
    //  grid-column-gap: 72px;
    max-width: 1300px;
    margin: 0 auto;
  }
  //
`;

export const Item = styled.div`
  flex-basis: calc(100% / 2);
  display: flex;
  align-items: center;
  text-align: center;
  padding: 16px 8px;

  img {
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
  }

  @media (min-width: ${mediaQueriesSizes.sm}px) {
    flex-basis: calc(100% / 3);
  }
  @media (min-width: ${mediaQueriesSizes.md}px) {
    flex-basis: calc(100% / 4);
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 16px 32px;
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    flex-basis: calc(100% / 5);
  }
`;
