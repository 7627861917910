import styled from 'styled-components';
import { colors, spacing, typography } from '../../foundations';

export const DownloadAttachmentText = styled.p`
  color: ${colors.secondary};

  font-size: ${typography.fontSizes.xxl}px;
  font-weight: ${typography.fontWeights.thin};
  line-height: 1.06;

  margin-top: ${spacing.s}px;

  a {
    display: inline-block;
    color: ${colors.accent};
    text-align: center;

    font-size: ${typography.fontSizes.xxl}px;
    font-weight: ${typography.fontWeights.thin};
    line-height: 1;

    &:hover {
      color: ${colors.tertiaryAccent};
      text-decoration: underline solid ${colors.tertiaryAccent};
    }
  }
`;
