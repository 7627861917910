import styled from 'styled-components';
import {colors,mediaQueriesSizes} from "../../../GlobalStyles";

export const MicroHeroStyles = styled.div`
    padding: 0 0 32px;
    position: relative;
    z-index: 1;
    @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 0;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        padding: 32px 0;
    }
    
    
`;

export const CopyWrapper = styled.div`
    h1 {
        font-size: 24px;
        font-weight: 300;
        line-height: 1.33;
        letter-spacing: 0.01em;
        color: ${colors.white};
        text-wrap: balance;

        b {
            font-weight: 700;
        }

        span {
            display: inline-block;
        }

    }

    p {
        font-size: 16px;
        margin: 16px 0 32px;
        text-wrap: balance;
        font-family: centrano2-book;
        font-weight: 400;
        line-height: 1.44;
        letter-spacing: 0.02em;
        max-width: 800px;

        span {
            display: inline-block;
        }

        color: ${colors.white};
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
        h1 {
            font-size: 32px;
        }

        p {
            font-size: 18px;
            line-height: 1.3;
        }
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        h1 {
            font-size: 38px;
        }
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        h1 {
            font-size: 48px;
        }
    }
`;
