import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { BrowserRouter } from 'react-router-dom';

import { ModalContext } from '../../context/ModalContext';
import { ContactModal } from '../layouts/ContactModal/ContactModal';

export const PageContainer = ({ children, lang = 'en' }: { children: React.ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVertical, setModalVertical] = useState('default');
  const [modalTitle, setModalTitle] = useState('');
  const [modalIframeUrl, setModalIframeURL] = useState(undefined);

  useEffect(() => {
    if (window) {
      // Function to set UTM cookies
      const setUTMCookies = () => {
        let existingCookie = Cookies.get('utmdata');
        let updatedCookieString = existingCookie ? existingCookie + '|' : '';

        // Get the current URL
        const url = new URL(window.location.href);

        // Define UTM parameters
        const utmParameters = [
          'utm_source',
          'utm_medium',
          'utm_campaign',
          'utm_adgroup',
          'utm_content',
          'utm_term',
          'gclid'
        ];

        // Iterate over each UTM parameter
        utmParameters.forEach(param => {
          // Check if the UTM parameter exists in the URL
          const value = url.searchParams.get(param);
          if (value) {
            // Check if the UTM parameter already exists in the cookie
            if (existingCookie && existingCookie.includes(param)) {
              // Update the value of the existing parameter
              updatedCookieString = updatedCookieString.replace(new RegExp(param + '=([^|]*)'), param + '=' + value);
            } else {
              // Append the new UTM parameter to the cookie string
              updatedCookieString += param + '=' + value + '|';
            }
          }
        });

        // Remove trailing '|' characters
        updatedCookieString = updatedCookieString.replace(/\|+$/, '');

        if (updatedCookieString != '') {
          // Set the updated cookie string
          Cookies.set('utmdata', updatedCookieString, { expires: 365 });
        }
      };

      setUTMCookies();
    }
  }, []);

  useEffect(() => {
    const cookieValue = Cookies.get('utmdata');
    if (cookieValue != null && cookieValue != undefined) {
      const parsedData = cookieValue.split('|').reduce((acc, section) => {
        const match = section.match(/(utm\w+)=\(?([^\)]+)\)?/);
        if (match) {
          const [, key, value] = match;
          acc[key] = value;
        }
        return acc;
      }, {});
    }
  }, []);

  if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
    lang = 'zh-cn';
  }

  return (
    <>
      {typeof window !== 'undefined' ? (
        <BrowserRouter>
          <ModalContext.Provider
            value={{
              isModalOpen,
              setIsModalOpen,
              modalVertical,
              setModalVertical,
              modalTitle,
              setModalTitle,
              modalIframeUrl,
              setModalIframeURL
            }}
          >
            {isModalOpen && (
              <ContactModal
                iframeURL={modalIframeUrl}
                vertical={modalVertical}
                title={modalTitle}
                lang={lang}
                handleClose={() => setIsModalOpen(false)}
              />
            )}
            {children}
          </ModalContext.Provider>
        </BrowserRouter>
      ) : (
        <></>
      )}
    </>
  );
};
