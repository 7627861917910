import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const ProductSliderStyles = styled.div`

  h2 {
    font-family: centrano2-medium;
    font-size: 30px;
    line-height: 1.4;
    color: ${colors.primary};
    margin-bottom: 44px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 40px;
    }
  }
  
  h3{
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.8;
    color: ${colors.primary};
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 30px;
    }
  }

  p {
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    line-height: 1.35;
    color: ${colors.UILightSecondary};
    font-size: 18px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 26px;
    }

  }

  &.theme-light{
    h2,h3,p{
      color: white;
    }
  }
  
`;

export const SliderWrap = styled.div`
  margin-left: -12px;
  margin-right: -12px;
  padding: 16px 0;
  width: 100%;
  margin-bottom: 24px;
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 32px 0 64px;
    margin-bottom: 32px;

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;

      & > div:first-child {
        height: 100%;
      }

      .tile {
        height: 100%;

      }
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-dots{
    bottom: -45px;
  }
  
  .slick-dots li button:before{
    font-size: 12px;
    color: ${colors.primary};
  }

`;

export const ProductTile = styled.div`
  padding: 0 12px;
  text-decoration: none !important;
  height: 100%;
  & > .inner {
    border-radius: 4px;
    background: white;
    padding: 32px 24px;
    box-shadow: 0px 91px 36px rgba(35, 64, 87, 0.01), 0px 51px 31px rgba(35, 64, 87, 0.04), 0px 23px 23px rgba(35, 64, 87, 0.06), 0px 6px 12px rgba(35, 64, 87, 0.07), 0px 0px 0px rgba(35, 64, 87, 0.07);
    height: 100%;
    h3{
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: 0.5px;
      color: ${colors.primary};
    }
    
    h4{
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: 0.5px;
      color: ${colors.primary};
      margin-bottom: 16px;
    }
    
    p{
      font-size: 16px;
      margin: 16px 0 24px;
      color: ${colors.UILightSecondary};
    }
    
    .Component-ProductLogo{
      img{
        height: 30px;
      }
    }
    
  }





`;
