import React from 'react';
import HomepageTemplate from './../templates/HomepageTemplate';
import { graphql, useStaticQuery } from 'gatsby';

const HomeRD = () => {
  let defaultLang = 'en';
  if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
    defaultLang = 'zh-cn';
  }

  const data = useStaticQuery(graphql`
    query {
      zhFile: allFile(filter: { name: { eq: "index-zh-cn" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
      enFile: allFile(filter: { name: { eq: "index-en" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
      enGlobal: allFile(filter: { name: { eq: "global-en" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
      zhGlobal: allFile(filter: { name: { eq: "global-zh-cn" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
    }
  `);

  let content = data.enFile.edges[0].node.childRawJson.content;
  let globalContent = data.enGlobal.edges[0].node.childRawJson.content;

  if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
    content = data.zhFile.edges[0].node.childRawJson.content;
    globalContent = data.zhGlobal.edges[0].node.childRawJson.content;
  }

  const json = JSON.parse(content);
  const pageContext = JSON.parse(globalContent);

  return (
    <HomepageTemplate
      Context={{
        globalData: pageContext
      }}
      Content={json}
      Lang={defaultLang}
    />
  );
};

export default HomeRD;
