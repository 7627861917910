import React from 'react';
import { createMarkUp } from '../../helpers';
import { ProductLogo } from '../../V2/ProductLogo/ProductLogo';

import { ComponentStyles, CardStyles, Ribbon, CardBottomStyles } from './styles.jsx';
import { ButtonComponent } from '../../V2/Button/Button';

interface Props {
  Items?: string;
}

export const Card = ({ ...props }) => {
  return (
    <div>
      <CardStyles>
        {props.ribbon && (
          <div className={'ribbon-wrap'}>
            <Ribbon>{props.ribbon}</Ribbon>
          </div>
        )}
        <ProductLogo Logo={props.product} />
        <h4>{props.tag}</h4>
        <ButtonComponent Target={'_blank'} Link={props.cta.link} Theme={'geomagic-designx'} Label={props.cta.label} />
      </CardStyles>
      <CardBottomStyles>
        <div className={'content'} dangerouslySetInnerHTML={createMarkUp(props.copy)} />
      </CardBottomStyles>
    </div>
  );
};

export const GDXHighlightCard = ({ Items, ...props }: Props) => {
  return (
    <ComponentStyles className={`Component_GDXHighlightCard`} {...props}>
      <div className={'container'}>
        {Items.map((item, index) => (
          <Card key={index} {...item} />
        ))}
      </div>
    </ComponentStyles>
  );
};
