import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ProductSliderStyles, ProductTile, SliderWrap } from './styles.jsx';
import { createMarkUp } from '../helpers';

import { ProductLogo } from '../ProductLogo/ProductLogo';
import { ButtonComponent } from '../Button/Button';

interface Props {
  Title?: string;
  Lang?: string;
  Copy?: string;
  Products?: object;
}

export const SelectedProductSlider = ({ Lang = 'en', Title, Products = [], ...props }: Props) => {
  const Content = require(`../../../../data/content/Components/ProductSlider/${Lang}.json`);
  const globalCopy = require(`../../../../data/content/Global/${Lang}.json`);

  let slideNumbers = 3;
  if (Products && Products.length < 3) {
    slideNumbers = Products.length;
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: slideNumbers,
    slidesToScroll: slideNumbers,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1169,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <ProductSliderStyles {...props}>
      <GlobalStyle />
      <div className={'container'}>
        {Title && <h3 dangerouslySetInnerHTML={createMarkUp(Title)}></h3>}

        <SliderWrap>
          <Slider {...settings}>
            {Products &&
              Products.map((slide, index) => {
                let slug = slide.product_selector.toLowerCase().replace(/ /g, '-');

                if (slug === 'dicom-to-print') {
                  slug = 'd2p';
                }
                if (slug === 'geomagic-design-x') {
                  slug = 'geomagic-designx';
                }
                if (slug === 'geomagic-control-x') {
                  slug = 'geomagic-controlx';
                }

                let slideContent = {};

                if (slide.product_selector === '3DXpert') {
                  slideContent = Content.threeDXpert;
                }
                if (slide.product_selector === 'Geomagic Design X') {
                  slideContent = Content.designx;
                }
                if (slide.product_selector === 'Geomagic Control X') {
                  slideContent = Content.controlx;
                }
                if (slide.product_selector === 'Manufacturing OS') {
                  slideContent = Content.mos;
                }
                if (slide.product_selector === 'D2P' || slide.product_selector === 'DICOM to Print') {
                  slideContent = Content.d2p;
                }
                if (slide.product_selector === 'Amphyon') {
                  slideContent = Content.amphyon;
                }
                if (slide.product_selector === 'Freeform') {
                  slideContent = Content.freeform;
                }
                if (slide.product_selector === 'Geomagic Wrap') {
                  slideContent = Content.wrap;
                }

                return (
                  <ProductTile className={'tile'}>
                    <div className={'inner'}>
                      <ProductLogo Logo={slug} />
                      <hr />
                      <h4>{slideContent.subTitle}</h4>
                      <ButtonComponent
                        Link={slideContent.fixedURL}
                        Label={globalCopy.Buttons.Learn}
                        Theme={'transparent-with-grey-outline'}
                      />
                    </div>
                  </ProductTile>
                );
              })}
          </Slider>
        </SliderWrap>
      </div>
    </ProductSliderStyles>
  );
};
