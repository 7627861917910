import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { SocialFollowStyles } from './styles.jsx';
import { createMarkUp } from '../helpers';
import { FacebookF, LinkedInBlock } from '../SocialIcons/SocialIcons';

interface Props {
  Title?: string;
  Theme?: string;
}

export const SocialFollow = ({ Theme = 'default', Title = '', ...props }: Props) => {
  return (
    <SocialFollowStyles className={`Component-SocialFollow theme-${Theme}`} {...props}>
      <GlobalStyle />
      <div className={'container'}>
        <div className={'title'} dangerouslySetInnerHTML={createMarkUp(Title)}></div>

        <div className={'btn-wrap'}>
          <a href={'https://www.facebook.com/oqtonai/'} target={'_blank'}>
            <FacebookF />
            Facebook
          </a>
          <a href={'https://www.linkedin.com/company/oqton/'} target={'_blank'}>
            <LinkedInBlock />
            Linkedin
          </a>
        </div>
      </div>
    </SocialFollowStyles>
  );
};
