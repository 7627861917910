export const spacing = {
  none: 0, // 0
  xxxs: 2, // 2
  xxs: 4, // 4
  'xxs+': 6, //6
  xs: 8, // 8
  'xs+': 10, // 10
  s: 12, // 12
  m: 16, // 16
  'm+': 18, // 20
  l: 20, // 24
  'l+': 25, // 30
  xl: 30, // 32
  'xl+': 35, // 40
  xxl: 40, // 48
  'xxl+': 45, // 58
  xxxl: 50, // 64
  'xxxl+': 60, // 77
  xxxxl: 70, // 96
  'xxxxl+': 65, // 132
  xxxxxl: 70, //128
  xxxxxxl: 80,
  xxxxxxxl: 90,
  xxxxxxxxxxl: 160,
  RD: {
    m: 17, //22
    xxxl: 58 // 72
  }
} as const;

export type Spacing = keyof typeof spacing;
export const getSpacing = (val: Spacing | undefined) => {
  if (val) {
    return spacing[val] + 'px';
  }
  return spacing.xs + 'px';
};
