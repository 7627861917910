import React from 'react';

export const DesignXEssentials = (
  <svg id="a" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
    <defs>
      <linearGradient
        id="DesignXEssentials"
        x1="24.36"
        y1="19.59"
        x2="211.36"
        y2="215.09"
        gradientTransform="translate(0 255.94) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#99181d" />
        <stop offset="1" stop-color="#db2229" />
      </linearGradient>
    </defs>
    <rect width="256" height="256" rx="16" ry="16" fill="url(#DesignXEssentials)" />
    <path
      d="M100.89,128c0-23.76-18.84-42-42.24-42H31.17v84h27.48c23.4,0,42.24-18.24,42.24-42Zm-14.76,0c0,15.96-11.88,28.56-27.84,28.56h-12.72v-57.12h12.72c15.96,0,27.84,12.6,27.84,28.56Zm65.32,42h16.08l-21.84-30.24,21-28.56h-15.72l-13.32,18.84-13.2-18.84h-15.96l20.88,28.92-21.84,29.88h15.48l14.28-20.16,14.16,20.16Zm65.53-19.2c-3.36,5.16-7.32,8.88-15,8.88-8.76,0-16.32-6.36-16.92-14.88h45.24c0-.36,.12-1.92,.12-4.2,0-17.52-12.12-30.84-29.28-30.84s-29.88,13.2-29.88,30.84,12.72,30.84,30.6,30.84c12.36,0,21.6-6,26.52-15.24l-11.4-5.4Zm-31.8-16.08c.84-6.72,7.56-13.56,15.72-13.56s14.88,5.76,15.72,13.56h-31.44Z"
      fill="#fff"
    />
  </svg>
);
