import React, { useEffect, useState } from 'react';

import { createMarkUp } from '../helpers';
import GlobalStyle from '../../../GlobalStyles';
import { LogoGridStyles, Grid, Item } from './styles.jsx';
import { ButtonComponent } from '../Button/Button';

interface Props {
  Title?: string;
  TitleSize?: 's' | 'm' | 'l';
  SubTitle?: string;
  Copy?: string;
  SubTitleSize?: 's' | 'm' | 'l';
  AdditionalClasses?: string;
  Logos?: object;
  LogosGroup2?: object;
  ExpandButtonText?: string;
}

const DisplayLogo = (Logo, key) => {
  if (Logo.src) {
    return (
      <Item className="grid-item standard-grid-item" key={key}>
        <img alt={''} src={Logo.src} loading="lazy" />
      </Item>
    );
  } else {
    //IF IS A WORDPRESS LOGO INPUT
    return (
      <Item className="grid-item" key={key}>
        <img alt={Logo.logo.alt} src={Logo.logo.url} loading="lazy" />
      </Item>
    );
  }
};

export const LogoGridV2 = ({
  TitleSize = 's',
  Title,
  Subtitle,
  Copy,
  SubTitleSize = 's',
  Logos,
  LogosGroup2,
  ExpandButtonText,
  AdditionalClasses,
  ...props
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (ExpandButtonText !== undefined) {
      setIsOpen(false);
    }
  }, []);
  return (
    <LogoGridStyles className={AdditionalClasses} {...props}>
      <div className={'container'}>
        {Subtitle && (
          <h3 className={`subtitle-size-${SubTitleSize}`} dangerouslySetInnerHTML={createMarkUp(Subtitle)}></h3>
        )}
        <h2 className={`title-size-${TitleSize}`} dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
        {Copy && <p dangerouslySetInnerHTML={createMarkUp(Copy)}></p>}
      </div>
      <Grid>{Logos && Logos.map((item, index) => DisplayLogo(item, index))}</Grid>

      {ExpandButtonText && LogosGroup2 && isOpen && (
        <>
          {!ExpandButtonText && <hr />}
          <Grid>{LogosGroup2.map((item, index) => DisplayLogo(item, index))}</Grid>
        </>
      )}

      {!ExpandButtonText && LogosGroup2 && (
        <>
          {!ExpandButtonText && <hr />}
          <Grid>{LogosGroup2.map((item, index) => DisplayLogo(item, index))}</Grid>
        </>
      )}

      {ExpandButtonText && (
        <ButtonComponent
          ClickFunction={() => {
            setIsOpen(!isOpen);
          }}
          Label={ExpandButtonText}
          Theme={'stripped'}
          Icon={isOpen ? 'chevron-up' : 'chevron-down'}
        />
      )}
    </LogoGridStyles>
  );
};
