import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const MapStyles = styled.div`
  height: 250px;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    height: 450px;
  }
`;

export const LargeMapStyles = styled.div`
  height: 250px;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    height: 450px;
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    height: 650px;
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    height: 750px;
  }
`;

export const Grid = styled.div`
  h4 {
    font-family: centrano2-book;
    font-weight: 400;
    font-size: 22px;
    line-height: 1.2;
    color: ${colors.primary};
  }

    
  div.location-booth-data {
    span {
      padding: 15px 25px;
      border: solid 1px ${colors.UILightBordersSecondary};
      display: inline-block;
      font-family: centrano2-book;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 2;
      color: #59747A;
    }
  }

  .partner-divider{
    column-span: 2;
    height: 1px;
    background: rgba(35, 64, 87, 0.2);
    grid-column: 1 / 3;
  }
  
  .location {
    display: flex;
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.6;
    color: ${colors.primary};
    a{
      display: flex;
      align-items: center;
    }
    img {
      margin-right: 8px;
    }
  }

  .speakers{
    margin-bottom: 72px;

    .speaker{
      margin-bottom: 32px;
      display: block;
      text-decoration: none;
      transition: 0.4s;

      font-family: centrano2-book;
      font-weight: 400;
      font-size: 22px;
      line-height: 145%;
      color: ${colors.light};

      span.name{
        color: ${colors.UILightTertiary};
      }
      
      span.time{
        display: flex;
        align-items: center;
        margin-top: 12px;
        img{
          margin-right: 6px;
        }
      }
    }
      a.speaker{
          &:hover{
              color: ${colors.highlight};
          }
      }
  }

  display: grid;
  grid-row-gap: 20px;
  margin-bottom: 46px;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-template-columns: 300px 1fr;
    grid-row-gap: 50px;
  }
`;

export const MapWrap = styled.div`
  
  h2{
    font-family: centrano2-book;
    font-weight: 400;
    font-size: 30px;
    line-height: 0.9;
    color: ${colors.primary};
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    span{
      width: 380px;
    }
    &:after{
      content: "";
      display: block;
      height: 1px;
      background: rgba(35,64,87,0.2);
      width: 100%;
    }
  }
  
  p{
    font-family: centrano2-book;
    font-size: 18px;
    line-height: 150%;
    text-align: justify;
    letter-spacing: 0.01em;
    color: ${colors.UILightSecondary};
    margin-bottom: 56px;
  }
  
  .partner-description{
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: justify;
    letter-spacing: 0.01em;
    color: ${colors.UILightSecondary};

    h4{
      font-family: centrano2-book;
      font-weight: bold;
    }
    p{
      margin-bottom: 0;
    }
    .btn-wrap{
      margin-top: 16px;
    }

  }
  
  .partner-logo-wrap{
    display: flex;
    align-items: center;
    img.partner-logo{
      max-width: 225px;
      max-height: 100%;
      width: 90%;
      height: auto;
      display: block;
    }

  }
  
  hr{
    margin: 100px 0 0;
  }
  
  
`;
