import React from 'react';

export const Sculpt = (
  <svg width="33" height="32" viewBox="0 0 33 32" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="32" height="32" rx="2" fill="url(#paint0_linear_10571_49797)" />
    <path
      d="M6.5 20C7.28 22.22 9.18 23.5 11.52 23.5C14.08 23.5 16.24 21.9 16.24 19.24C16.24 17.38 15.16 16.14 12.98 15.3L11.26 14.64C10.12 14.2 9.52 13.68 9.52 12.84C9.52 11.88 10.34 11.24 11.44 11.24C12.62 11.24 13.44 12.08 13.86 13.14L15.92 12.1C15.14 10.16 13.44 9.02 11.42 9C8.98 9 7.08 10.56 7.08 12.98C7.08 14.9 8.3 16.12 10.36 16.86L12.02 17.46C13.22 17.9 13.78 18.5 13.78 19.4C13.78 20.58 12.76 21.24 11.56 21.24C10.16 21.24 9.12 20.34 8.62 19L6.5 20Z"
      fill="white"
    />
    <path
      d="M18.1877 18.36C18.1877 21.34 20.3077 23.5 23.3477 23.5C24.2877 23.5 25.2077 23.26 26.0277 22.8V20.5C25.3277 20.98 24.4877 21.38 23.5677 21.38C21.8077 21.38 20.5277 20.06 20.5277 18.36C20.5277 16.64 21.8277 15.34 23.5677 15.34C24.4277 15.34 25.2277 15.64 25.9877 16.22V13.92C25.2277 13.46 24.3077 13.22 23.3677 13.22C20.3277 13.22 18.1877 15.44 18.1877 18.36Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10571_49797"
        x1="0.5"
        y1="32"
        x2="32.875"
        y2="-0.499999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3A615A" />
        <stop offset="1" stop-color="#50857A" />
      </linearGradient>
    </defs>
  </svg>
);
