import styled from 'styled-components';

import {
  Color,
  colors,
  fontSizes,
  newLineHeight as lineHeight,
  newLetterSpacing as letterSpacing,
  newFontWeights as fontWeights
} from '../components/foundations';
import { Link } from '../components/subComponents/Link';

/* Heading styles based on:
  https://www.figma.com/file/LGolsPiNmsnZWKnrVLvt0C/Oqton.com?node-id=915%3A14989
   */

export const PrimaryHeading = styled.h1`
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.xl};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeight.xl};
  letter-spacing: ${letterSpacing.s};
  color: ${colors.secondary};
`;

export const SecondaryHeading = styled.span`
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.l};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeight.l};
  letter-spacing: ${letterSpacing.s};
  color: ${colors.secondary};
`;

export const TertiaryHeading = styled.span`
  ${SecondaryHeading};
  font-weight: ${fontWeights.light};
`;

/* Heading styles based on:
  https://www.figma.com/file/LGolsPiNmsnZWKnrVLvt0C/Oqton.com?node-id=1259%3A23844
   */

export const HeadingXL = `
    font-family: 'centrano2', sans-serif;
    font-size: ${fontSizes.xxl};
    font-weight: ${fontWeights.medium};
    line-height: ${lineHeight.xxl};
    letter-spacing: ${letterSpacing.xl};
    color: ${colors.secondary};
  `;

export const HeadingL = `
    font-family: 'centrano2', sans-serif;
    font-size: ${fontSizes.xl};
    font-weight: ${fontWeights.medium};
    line-height: ${lineHeight.xl};
    letter-spacing: ${letterSpacing.xl};
    color: ${colors.secondary};
  `;

export const HeadingLLight = `
    ${HeadingL};
    font-weight: ${fontWeights.light};
  `;

export const HeadingM = `
    font-family: 'centrano2', sans-serif;
    font-size: ${fontSizes.l};
    font-weight: ${fontWeights.medium};
    line-height: ${lineHeight.l};
    letter-spacing: ${letterSpacing.xl};
    color: ${colors.secondary};
  `;

export const HeadingMLight = `
    ${HeadingM};
    font-weight: ${fontWeights.light};
  `;

export const HeadingS = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.mxl};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeight.ml};
  letter-spacing: ${letterSpacing.xl};
  color: ${colors.secondary};
  `;

export const HeadingSLight = `
  ${HeadingS};
  font-weight: ${fontWeights.light};
  `;

export const HeadingXS = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.ml};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeight.m};
  letter-spacing: ${letterSpacing.xl};
  color: ${colors.secondary};
  `;

export const HeadingXSLight = `
  ${HeadingXS};
  font-weight: ${fontWeights.light};
  `;

export const Quote = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.mxl};
  font-weight: ${fontWeights.medium};
  font-style: italic;
  line-height: ${lineHeight.ml};
  letter-spacing: ${letterSpacing.xl};
  color: ${colors.secondary};
  `;

export const SubText = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.s};
  font-weight: ${fontWeights.book};
  line-height: ${lineHeight.m};
  letter-spacing: ${letterSpacing.xxl};
  color: ${colors.secondary};
  `;

export const Paragraph = styled.p`
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.ml};
  font-weight: ${fontWeights.book};
  line-height: ${lineHeight.ml};
  letter-spacing: ${letterSpacing.xxl};
  color: ${colors.secondary};
`;

export const ParagraphBold = styled(Paragraph)`
  font-weight: ${fontWeights.medium};
`;

export const ParagraphStyle = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.ml};
  font-weight: ${fontWeights.book};
  line-height: ${lineHeight.ml};
  letter-spacing: ${letterSpacing.xxl};
  color: ${colors.secondary};
`;

export const ParagraphBoldStyle = `
  ${ParagraphStyle};
  font-weight: ${fontWeights.medium};
`;

export const Anchor = `
  font-family: "centrano2",sans-serif;
  font-size: ${fontSizes.m};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeight.s};
  letter-spacing: ${letterSpacing.m};
`;

export const Tag = `
  font-family: "centrano2",sans-serif;
  font-size: ${fontSizes.s};
  font-weight: ${fontWeights.bold};
  line-height: ${lineHeight.xs};
  letter-spacing: ${letterSpacing.l}; 
  text-transform: uppercase;
`;

export const Bold = styled.span<{ color?: Color }>`
  font-weight: ${fontWeights.medium};
  ${({ color }) => color && `color: ${colors[color]}`};
`;

export const subStyle = `
    font-size: ${fontSizes.s}px;
    font-weight: ${fontWeights.bold};
    letter-spacing: ${letterSpacing.l}px;
    line-height: 2;

    text-transform: uppercase;
`;

export const LinkContainer = styled(Link)`
  a {
    color: ${colors.quaternaryAccent};

    font-size: 15px;
    font-weight: ${fontWeights.medium};

    text-decoration: none;

    border-bottom: solid 2px ${colors.quaternaryAccent};
  }
`;

//Redesign H1
export const RDH1 = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.RD['l+']};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeight.RD['l+']};
  letter-spacing: ${letterSpacing.s};
`;

//Redesign H2
export const RDH2 = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes['l+']};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeight['l+']};
  letter-spacing: ${letterSpacing.s};
`;

//Redesign H2 - Bold
export const RDH2Bold = `
  ${RDH2};
  font-weight: ${fontWeights.bold};
`;

//Redesign H3
export const RDH3 = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.RD.l};
  font-weight: ${fontWeights.bold};
  line-height: ${lineHeight.RD.l};
  letter-spacing: ${letterSpacing.s};
`;

export const RDH4 = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.mxl};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeight.RD.m};
  letter-spacing: ${letterSpacing.s};
`;

export const RDSubtitle = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.m};
  font-weight: ${fontWeights.bold};
  line-height: ${lineHeight.m};
  letter-spacing: ${letterSpacing.RD.l};
  text-transform: uppercase;
`;

//Redesign P - Large
export const RDPL = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes['m+']};
  line-height: ${lineHeight['m+']};
  letter-spacing: ${letterSpacing.xs};
  font-weight: ${fontWeights.book};

`;

//Redesign P - Medium
export const RDPM = `
font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.ml};
  line-height: ${lineHeight['s+']};
  letter-spacing: ${letterSpacing.xs};
`;

//Redesign P - Small
export const RDPS = `
font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.m};
  line-height: ${lineHeight['s+']};
  letter-spacing: ${letterSpacing.s};
  font-weight: ${fontWeights.book};
`;

//Redesign P - Bold
export const RDPBold = `
  font-family: 'centrano2', sans-serif;
  font-size: ${fontSizes.m};
  line-height: ${lineHeight['s+']};
  letter-spacing: ${letterSpacing.s};
  font-weight: ${fontWeights.bold};
`;

//Redesign Button
export const RDButton = `
  font-family: "centrano2",sans-serif;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.ml};
  line-height: 20.4px;
  letter-spacing: 0.3px;
`;

//Redesign Button Small
export const RDButtonSmall = `
  ${RDButton};
  font-size: ${fontSizes.RD.s};
  line-height: ${lineHeight.RD.s};
`;

//Redesign Accent Value
export const RDAccentValue = `
  font-family: "centrano2",sans-serif;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.RD.xl};
  line-height: ${lineHeight.RD.xl};
  letter-spacing: ${letterSpacing.RD.m};
`;

//Redesign Accent Value Extra
export const RDAccentValueExtra = `
  font-family: "centrano2",sans-serif;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.mxl};
  line-height: ${lineHeight.m};
  letter-spacing: ${letterSpacing.RD.m};
`;

//Redesign Label
export const RDLabel = `
  font-family: "centrano2",sans-serif;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.m};
  line-height: ${lineHeight.m};
  letter-spacing: ${letterSpacing.RD.m};
  text-transform: uppercase;
`;

export const RDSmallCaps = `
  font-family: "centrano2",sans-serif;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.RD.xs};
  line-height: ${lineHeight.RD.xs};
  letter-spacing: ${letterSpacing.RD.l};
  text-transform: uppercase;
`;

export const RDCardTitle = `
  ${RDPL};
  font-weight: ${fontWeights.book};
`;

export const RDHeaderSubtitle = `
  ${RDPL};
  font-weight: ${fontWeights.medium};
`;

export const RDDescription = `
${RDPL};
font-size: ${fontSizes.m};
line-height: ${lineHeight['s+']};
`;

//Redesign Bold
export const RDBold = styled.span<{ color?: Color }>`
  font-weight: ${fontWeights.bold};
  ${({ color }) => color && `color: ${colors[color]}`};
`;

export const DescriptionStyles = `
  ${ParagraphStyle}
  line-height: ${lineHeight['s+']};
`;

export const RDMenuTitle = `
font-family: 'centrano2', sans-serif;
 font-size: ${fontSizes.l};
 line-height: ${lineHeight.s};
 font-weight: ${fontWeights.bold};
`;

export const SmallText = `
  font-family: "centrano2",sans-serif;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.RD.xs};
  line-height: ${lineHeight.xs};
`;
