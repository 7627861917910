import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import YouTubePlayer from 'react-player/youtube';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { createMarkUp, generateRandomStrong } from '../helpers';

import { ProductLogo } from '../ProductLogo/ProductLogo';
import { ButtonComponent } from '../Button/Button';
import { SplitColumnStyles, ImageWrap } from './styles.jsx';

interface Props {
  ID?: string;
  AdditionalClass?: string;
  Copy?: string;
  Image?: object;
  Button?: object;
  TextSize?: 'S' | 'M';
  TextColor?: 'Blue' | 'Grey';
  Background?: 'White' | 'Red' | 'Off-White' | 'Primary' | 'Teal' | 'Green' | 'Transparent' | '3DXpert-Circles';
  ImagePosition?: 'Left' | 'Right';
  MediaType?: 'Image' | 'Video';
  Video?: string;
  Logo?:
    | 'manufacturing-os'
    | 'geomagic-for-solid'
    | 'geomagic-designx'
    | 'geomagic-controlx'
    | 'geomagic-freeform'
    | 'geomagic-wrap'
    | '3dxpert'
    | 'amphyon'
    | 'd2p';
  ImageBackground?: boolean;
  ImageOverlap?: boolean;
  BoxShadow?: boolean;
}

export const SplitColumn = ({
  ID,
  Copy,
  Image,
  Button,
  AdditionalClass,
  TextSize = 'M',
  TextColor = 'Blue',
  BoxShadow = false,
  ImageOverlap = true,
  ImageBackground = true,
  Background = 'Off-White',
  ImagePosition,
  MediaType = 'Image',
  Video = 'https://vimeo.com/599548225',
  Logo,
  ...props
}: Props) => {
  const ShowVideo = (videoEmbedString: string) => {
    if (videoEmbedString.includes('youtube')) {
      return (
        <YouTubePlayer
          width={'100%'}
          height={'auto'}
          style={{ aspectRatio: '16/9' }}
          url={videoEmbedString}
          controls
          className={'YoutubePlayer'}
        />
      );
    }
    if (videoEmbedString.includes('vimeo')) {
      return (
        <ReactPlayer
          style={{}}
          width="100%"
          url={videoEmbedString}
          controls
          config={{
            playerOptions: {
              autoplay: false,
              muted: true
            }
          }}
        />
      );
    } else {
      return (
        <iframe
          title={generateRandomStrong(5)}
          width={'100%'}
          height={'auto'}
          style={{ aspectRatio: '16/9' }}
          className="vidyard_iframe"
          src={videoEmbedString}
          allowTransparency
          allowFullScreen
        />
      );
    }
  };

  const ShowButtons = (Buttons: any) => {
    if (Array.isArray(Buttons)) {
      return (
        <>
          {Buttons.map((btn, index) => (
            <ButtonComponent
              Target={btn.target}
              Theme={btn.theme}
              Label={btn.copy}
              Link={btn.link}
              ModelTitle={btn.ModelTitle}
              ModelVertical={btn.ModelVertical}
              OpenDemoModel={btn.OpenDemoModel}
              Icon={btn.icon}
              IconPosition={btn.iconPosition}
            />
          ))}
        </>
      );
    } else {
      return (
        <ButtonComponent
          Target={Buttons.target}
          Theme={Buttons.theme}
          Label={Buttons.copy}
          Link={Buttons.link}
          ModelTitle={Buttons.ModelTitle}
          ModelVertical={Buttons.ModelVertical}
          OpenDemoModel={Buttons.OpenDemoModel}
          Icon={Buttons.icon}
          IconPosition={Buttons.iconPosition}
        />
      );
    }
  };

  return (
    <>
      <SplitColumnStyles
        id={ID}
        className={`Component-SplitColumn ImagePosition-${ImagePosition} Background-${Background} ${AdditionalClass}`}
        {...props}
      >
        <GlobalStyle />
        <div className={'container'}>
          <div className={'row'}>
            <div className={'col copy-wrap TextSize-' + TextSize + ' TextColor-' + TextColor}>
              {Logo && <ProductLogo Logo={Logo} />}
              <div dangerouslySetInnerHTML={createMarkUp(Copy)}></div>
              {Button && <div className={'ButtonWrap'}>{ShowButtons(Button)}</div>}
            </div>
            <div className={'col divider'}></div>
            {MediaType === 'Image' && (
              <ImageWrap
                className={`col img-wrap ${AdditionalClass} ${ImageBackground ? '' : 'no-background'}  ${
                  BoxShadow ? 'add-shadow' : ''
                } ${ImageOverlap ? '' : 'no-overlap'}`}
              >
                <div className={'inner'}>
                  <img src={Image.src} alt={Image.alt} />
                </div>
              </ImageWrap>
            )}
            {MediaType === 'Video' && (
              <ImageWrap className={`col img-wrap video-wrapper ${AdditionalClass}`}>{ShowVideo(Video)}</ImageWrap>
            )}
          </div>
        </div>
      </SplitColumnStyles>
    </>
  );
};
