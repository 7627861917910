import React from 'react';
import FreeformTemplate from './../../templates/FreeformIndustrial';

const FreeformHealthcare = () => {
  const Lang = 'en';
  const wordpressData = require(`../../../data/wordpress-data/889.json`);

  return <FreeformTemplate Lang={Lang} wordpressData={wordpressData} />;
};

export default FreeformHealthcare;
