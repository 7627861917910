import styled from 'styled-components';
import { colors, media, spacing } from '../components/foundations';

export const BackgroundGradient = styled.div`
  background-color: ${colors.background};
  background-image: linear-gradient(210deg, #1080a3, rgba(16, 128, 163, 0) 1950px, rgba(16, 128, 163, 0));
  z-index: -1;
  height: 100%;

  @media (max-width: ${media.lg}px) {
    background-image: none;
  }
`;

export const Main = styled.div<{ extraPadding?: boolean; noPadding?: boolean }>`
  background-color: ${colors.secondaryBackground};

  ${({ noPadding }) => !noPadding && `padding-top: 100px;`};
  ${({ extraPadding }) => extraPadding && 'padding-bottom: 140px;'};
`;

export const HomeBackground = styled.div`
  background-color: ${colors.background};
  background-image: linear-gradient(210deg, #1080a3, rgba(16, 128, 163, 0) 1950px, rgba(16, 128, 163, 0));
  z-index: -1;

  @media (max-width: ${media.lg}px) {
    background-image: none;
  }
`;

export const ContactCalloutBackground = styled.div`
  background-image: linear-gradient(to bottom, ${colors.secondaryBackground} 0% 50%, ${colors.background} 50% 100%);
  height: auto;
`;

export const ContactCalloutBackgroundTertiary = styled.div`
  background-image: linear-gradient(to bottom, ${colors.tertiaryBackground} 0% 50%, ${colors.background} 50% 100%);
  height: auto;
`;

export const ContactBlockBackground = styled.div`
  background: url('/assets/images/home/twigs.svg') #e7e7e7;
  background-position: bottom;
  background-size: cover;
  height: auto;
  padding-bottom: ${spacing.xs}px;
`;

export const PrimaryBackground = styled.div`
  background-color: ${colors.background};
  height: auto;
`;
