import React, { useContext } from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { ModalContext } from '../../../context/ModalContext';

export const CustomCopy = ({ customCopyID = '', ...props }: Props) => {
  const { setIsModalOpen } = useContext(ModalContext);
  const { setModalVertical } = useContext(ModalContext);
  const { setModalTitle } = useContext(ModalContext);

  const handleClick = (vertical, title) => {
    setIsModalOpen(true), setModalVertical(vertical), setModalTitle(title);
  };

  if (customCopyID === 'threeDXpert-FAQ-1') {
    return (
      <div className={'copy'}>
        <p>
          To organize a training course, please fill in our{' '}
          <span
            className="clickable"
            onClick={() => {
              handleClick('default', 'Contact Us');
            }}
          >
            Contact Us form
          </span>
          .{' '}
        </p>
      </div>
    );
  } else if (customCopyID === 'threeDXpert-FAQ-2') {
    return (
      <div className={'copy'}>
        <p>
          3DXpert supports polymer-based processes, as well as metal additive manufacturing. Oqton partners with all
          major powder bed machine vendors and more (3D Systems, EOS, SLM Solutions, Renishaw, Farsoon, HP, Xact Metal,
          Aconity3D, Freemelt, etc.) to ensure smooth integration.{' '}
          <span
            className="clickable"
            onClick={() => {
              handleClick('default', 'Contact Us');
            }}
          >
            Contact Us
          </span>{' '}
          to find out how deeply your preferred systems are integrated..{' '}
        </p>
      </div>
    );
  } else {
    return <></>;
  }
};
