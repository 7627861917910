import React from 'react';
import { MaxContentContainer } from '../../subComponents/ContentContainer';
import { Article, ArticleWithCenterImage } from './Article';
import { BackgroundContainer, Container } from './styles';

export interface ArticleProps {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  content: React.ReactNode;
  button?: React.ReactNode;
  buttonLink?: string;
  image?: { src: string; alt: string; imagePos?: 'left' | 'right'; width?: number; height?: number };
  mediaComponent?: { component: React.ReactNode; pos: 'left' | 'right' };
  width?: string;
}

interface MediaComponentProps {
  articles: ArticleProps[];
  inverted?: boolean;
  decreasedPadding?: boolean;
  id?: string;
  Lang?: string;
  width?: string;
}

export const MediaComponent = ({ articles, inverted, decreasedPadding, id, Lang, width }: MediaComponentProps) => {
  return (
    <BackgroundContainer inverted={inverted} id={id}>
      <MaxContentContainer>
        <Container decreasedPadding={decreasedPadding}>
          {articles.map((article, index) => {
            return <Article width={width} lang={Lang} {...article} key={`article-${index}`} />;
          })}
        </Container>
      </MaxContentContainer>
    </BackgroundContainer>
  );
};
export const MediaComponentWithCenterImage = ({
  articles,
  inverted,
  decreasedPadding,
  id,
  Lang,
  width
}: MediaComponentProps) => {
  return (
    <BackgroundContainer inverted={inverted} id={id}>
      <MaxContentContainer>
        <Container decreasedPadding={decreasedPadding}>
          {articles.map((article, index) => {
            return <ArticleWithCenterImage width={width} lang={Lang} {...article} key={`article-${index}`} />;
          })}
        </Container>
      </MaxContentContainer>
    </BackgroundContainer>
  );
};
