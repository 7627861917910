import styled from 'styled-components';

import {colors, bgGradients, mediaQueriesSizes} from '../../GlobalStyles';

export const Wrap = styled.div`
  background: ${colors.primary};
  @media (min-width: ${mediaQueriesSizes.md}px) {
  }
`;

export const ThankYouSliderWrap = styled.div`
  background: #f8f7f6;

  &.theme-3dxpert {
    background: ${bgGradients.threeDXpert};

    h2, p.intro {
      color: white;
    }
  }

  &.theme-freeform {
    background: ${bgGradients.freeform};

    h2, p.intro {
      color: white;
    }
  }

  &.theme-manufacturing-os {
    background: ${bgGradients.mos};

    h2, p.intro {
      color: white;
    }

  }

  &.theme-amphyon {
    background: ${bgGradients.amphyon};

    h2, p.intro {
      color: white;
    }

  }

  &.theme-geomagic-designx {
    background: ${bgGradients.designx};

    h2, p.intro {
      color: white;
    }

  }

  &.theme-geomagic-controlx {
    background: ${bgGradients.controlx};

    h2, p.intro {
      color: white;
    }

  }

  &.theme-geomagic-wrap {
    background: ${bgGradients.wrap};

    h2, p.intro {
      color: white;
    }

  }

  &.theme-geomagic-for-solidworks {
    background: ${bgGradients.geomagicSolidWorks};

    h2, p.intro {
      color: white;
    }

  }

  &.theme-d2p {
    background: ${bgGradients.d2p};

    h2, p.intro {
      color: white;
    }

  }


`;
