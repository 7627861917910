import React from 'react';

import MedicalTemplate from './../../templates/MedicalTemplate';

const Medical = () => {
  const Lang = 'ko';

  return <MedicalTemplate Lang={Lang} />;
};

export default Medical;
