import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { ProductSpotlightStyles, Item } from './styles.jsx';
import { ProductLogo } from '../ProductLogo/ProductLogo';
import { ButtonComponent } from '../Button/Button';
import { getProductURL } from '../../../helpers/helper';

interface Props {
  Title?: string;
  Language?: string;
  Items: object;
  WordpressData?: boolean;
}

export const ProductSpotlight = ({ Title, Items, Language = 'en', WordpressData = false, ...props }: Props) => {
  let globalCopy = require(`../../../../data/content/Global/${Language}.json`);

  return (
    <ProductSpotlightStyles {...props}>
      <div className={'container'}>
        {Title && <h2>{Title}</h2>}

        <div className={`ItemWrap Item-Length-${Items.length}`}>
          {Items.map((item, index) => {
            return (
              <Item key={index} className={`Item-Length-${Items.length}`}>
                <div>
                  <ProductLogo Size={'xl'} Logo={item.product} />
                  {item.title && <h4>{item.title}</h4>}
                  {item.copy && <p>{item.copy}</p>}
                </div>
                {WordpressData ? (
                  <>
                    <div className={'img-wrap'}>
                      <img src={item.image} alt={item.title} />
                    </div>
                    <div className={'btn-wrap'}>
                      <ButtonComponent
                        Label={globalCopy.Buttons.Learn}
                        Link={getProductURL(item.product, Language)}
                        Theme={item.buttonStyle ? item.buttonStyle : item.product}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={'img-wrap'}>
                      <img src={item.img} alt={item.title} />
                    </div>
                    <div className={'btn-wrap'}>
                      <ButtonComponent
                        Label={item.link_text}
                        Link={item.link}
                        Theme={item.buttonStyle ? item.buttonStyle : item.product}
                      />
                    </div>
                  </>
                )}
              </Item>
            );
          })}
        </div>
      </div>
    </ProductSpotlightStyles>
  );
};
