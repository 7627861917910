import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { IconGridComponentStyles, TableWrap, ItemStyle } from './styles.jsx';
import { createMarkUp } from '../helpers';

interface Props {
  id?: string;
  Title?: string;
  Copy?: string;
  Subtitle?: string;
  Items?: object;
  Theme?: 'light' | 'dark';
}

export const IconGrid = ({
  id = '',
  Title = '',
  Copy = '',
  Subtitle = '',
  Items = [],
  Theme = 'light',
  ...props
}: Props) => {
  return (
    <IconGridComponentStyles className={'theme-' + Theme} id={id}>
      <GlobalStyle />
      <div className={'container'}>
        {Subtitle && <h4>{Subtitle}</h4>}
        <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
        <hr className={'divider teal'} />
        {Copy && <p>{Copy}</p>}
        <TableWrap className={`counter-${Items.length}`}>
          {Items.map((item, index) => (
            <ItemStyle className={`total-${Items.length} theme-${Theme} `} key={index}>
              {item.Icon && <img alt={''} src={item.Icon} />}
              {item.image && <img alt={''} src={item.image} />}

              {item.Title && <h5>{item.Title}</h5>}
              {item.title && <h5>{item.title}</h5>}

              {item.Copy && <p>{item.Copy}</p>}
              {item.copy && <p>{item.copy}</p>}
            </ItemStyle>
          ))}
        </TableWrap>
      </div>
    </IconGridComponentStyles>
  );
};
