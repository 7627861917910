import styled from 'styled-components';
import { RDButton, RDH2, RDPL, RDPM, RDPS } from '../../../styles/typography';
import { Box } from '../../containers/Box';
import { colorsRD, media, spacing } from '../../foundations';
import { Position } from '../../subComponents/Card/types';

export const Container = styled.article`
  background-color: ${colorsRD.background.primary};
  padding: ${spacing.xxxxxl}px 0 ${spacing.xxxxxl}px;

  @media screen and (max-width: ${media['xxl++']}px) {
    padding: ${spacing.xxxxl}px 0 ${spacing.xxl}px;
  }

  @media screen and (max-width: ${media['lg+']}px) {
    padding: ${spacing.xxxxxl}px ${spacing.xl}px ${spacing.xxxxxl}px;
  }
`;

export const Title = styled.h1`
  ${RDH2};
  text-align: center;
  color: ${colorsRD.primary};
  margin-bottom: ${spacing.l}px;
`;

export const Description = styled.p`
  ${RDPL};
  text-align: center;
  color: ${colorsRD.primary};

  max-width: 700px;
  margin: 0 auto ${spacing.xxl}px;
`;

export const Button = styled.button`
  background: ${colorsRD.white};
  padding: ${spacing.s}px ${spacing['m+']}px;
  border: solid 2px ${colorsRD.secondary};
  border-radius: ${spacing.xxs}px;

  display: flex;
  align-items: center;
  margin: 0 auto ${spacing.xxxl}px auto;
  cursor: pointer;
`;

export const ButtonText = styled.p`
  ${RDButton};
  color: ${colorsRD.primary};
`;

export const ButtonIcon = styled(Box)<{ _position?: Position }>`
  color: ${colorsRD.primary};
`;

export const CardContainer = styled.section`
  display: flex;
  gap: ${spacing['xl+']}px;
  justify-content: center;
  flex-wrap: wrap;

  & > * {
    width: 453px;
    height: min-content;
  }
  @media screen and (max-width: ${media['xs']}px) {
    width: auto;
    margin: 0;
    padding: 0;
    section,
    img,
    p,
    > div {
      width: 350px;
      margin: 0;
      padding: 0;
    }
    > div > a > div > a > article > section > p {
      width: 320px;
      padding: 1em;
    }
    > div a > div > a > article > section > div > img {
      width: auto;
    }
  }

  @media screen and (max-width: ${media['lg+']}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Label = styled.div`
  padding: ${spacing.xxs}px ${spacing.xs}px;
  border: 1px solid ${colorsRD.secondary};
  border-radius: ${spacing.xxs}px;
  width: min-content;
  height: 43px;
  margin-bottom: ${spacing.m}px;
  @media screen and (max-width: ${media['xs']}px) {
    margin: 1em;
  }
`;

export const SmallText = styled.p`
  ${RDPM};
  color: ${colorsRD.primary};

  a {
    ${RDPM};
    color: ${colorsRD.accent.passion};
  }
`;

export const CustomerName = styled.p`
  ${RDPS};
  color: ${colorsRD.primary};
  margin-top: ${spacing.xs}px;
`;

export const ButtonLink = styled.a`
  text-decoration: none;
  display: flex;
`;
