import styled, { StyledComponent, DefaultTheme } from 'styled-components';
import {
  flexWrap,
  FlexWrapProps,
  flexDirection,
  FlexDirectionProps,
  alignItems,
  AlignItemsProps,
  justifyContent,
  JustifyContentProps
} from 'styled-system';
import { ComponentType } from 'react';
import { Box, BoxProps } from './Box';

export interface FlexContainerProps
  extends BoxProps,
    FlexWrapProps,
    FlexDirectionProps,
    AlignItemsProps,
    JustifyContentProps {
  space?: boolean;
}

export const Flex: StyledComponent<ComponentType<BoxProps>, DefaultTheme, FlexContainerProps> = styled(
  Box
)<FlexContainerProps>`
  display: flex;
  ${flexWrap}
  ${flexDirection}
  ${alignItems}
  ${justifyContent}
`;
