import styled from 'styled-components';

import {colors,mediaQueriesSizes,bgGradients} from "../../../GlobalStyles";

export const ProductHighlightStyles = styled.div`
    background: ${colors.solid3DXpert};
    color: white;
    padding: 64px 0;
    text-align: center;

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.3;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            font-size: 46px;
        }
    }

    p {
        font-family: centrano2-book;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        max-width: 850px;
        margin: 16px auto 24px;
        text-wrap: balance;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            font-size: 30px;
        }
    }


`;
