import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const Ribbon = styled.div`
    background: ${colors.primary};

    position: absolute;
    top: 32px;
    right: -40px;
    color: white;
    padding: 5px 45px;
    transform: rotate(45deg);
    font-size: 0.75em;
    border: solid 2px white;
    box-shadow: 0px 1.38327px 14.5726px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
`;


export const Table = styled.div`

    margin: 64px 0;
    padding-right: 16px;
    display: grid;
    column-gap: 16px;
    min-width: 700px;
    grid-template-columns: 1fr 150px 150px 150px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
        padding-right: 0;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        grid-template-columns: 1fr 150px 150px 150px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        grid-template-columns: 1fr 250px 250px 250px;
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        grid-template-columns: 1fr 300px 300px 300px;
    }

    .ribbon {
        transform: rotate(34deg);
        top: 18px;
        right: -37px;
    }

    .end {
        background: ${colors.UILightBackground};
        height: 36px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .product-title {
        background: ${colors.UILightBackground};
        border-radius: 6px;
        padding: 56px 12px 12px;
        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            padding: 42px 36px 36px;
        }
        position: relative;
        overflow: hidden;
        text-align: center;

        .custom-button{
            background-color: transparent;
            margin-top: 16px;
            color: ${colors.primary};
        }
        
        img {
            margin: 0 auto 8px;
            display: block;
            max-height: 28px;
            @media (min-width: ${mediaQueriesSizes.xxl}px) {
                max-height: none;
            }
        }

        p {
            font-style: italic;
            font-weight: 300;
            line-height: 1.3;
            font-size: 0.875em;
            letter-spacing: 0.02em;
            color: ${colors.UILightSecondary};
            text-wrap: balance;
            @media (min-width: ${mediaQueriesSizes.xl}px) {
                font-size: 1.375em;

            }
        }
    }

    .description {
        font-family: centrano2-book;
        font-weight: 400;
        font-size: 0.75em;
        line-height: 1.25;
        color: ${colors.primary};
        text-wrap: balance;
        padding: 8px 0;
        span{
            display: block;
            font-size: 0.8em;
            color: ${colors.tertiary};
        }
        @media (min-width: ${mediaQueriesSizes.md}px) {
            padding: 14px 0;
            font-size: 1em;
        }
    }

    .header {
        font-weight: 700;
        font-size: 0.75em;
        line-height: 1.35;
        color: ${colors.primary};
        text-transform: uppercase;
        display: flex;
        align-items: center;
        align-content: center;
        padding: 8px 0;
        cursor: pointer;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            padding: 14px 0;
            font-size: 1em;
        }

        &.active {
            img {
                transform: rotate(180deg);
            }
        }

        img {
            margin-left: 16px;
            transition: 0.25s;
        }
    }


    .divider {
        grid-column: span 4;
        border-bottom: solid 1px ${colors.UILightBordersSecondary};
    }

    .item-status {
        background: ${colors.UILightBackground};
        align-content: center;
        text-align: center;

        &.row-0, &.row-2, &.row-4, &.row-6, &.row-8, &.row-10, &.row-12, &.row-14 {
            background: #ECECEC;
        }

    }

    .empty {
        background: ${colors.UILightBackground};
    }

    .button-wrapper {
        margin-top: 32px;
        text-align: center;
    }


`
export const ComponentStyles = styled.div`
    overflow-x: auto;

    padding: 32px 16px 32px 0;
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 72px 0;
    }

    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 1.125em;
        line-height: 1.33;
        letter-spacing: 0.02em;
        color: ${colors.highlight};
        margin-bottom: 8px;
    }

    h2 {
        font-size: 1.625em;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: 2.5em;

        }
    }

`;

export const StyledIframe = styled.div`
    width: 800px;
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: auto;
    background: rgba(248, 247, 246, 0.9);
    border: 1px solid rgba(35, 64, 87, 0.2);
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    border-radius: 6px;
    padding: 50px;
    position: relative;
    
    h3{
        font-size: 1.125em;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: 0.01em;
        text-align: left;
        color: ${colors.primary};
        margin: 0 0 16px;
        text-wrap: balance;

        @media (min-width: ${mediaQueriesSizes.md}px) {
            font-size: 1.5em;
        }

    }
    
    p{
        font-family: centrano2-book;
        font-weight: 400;
        font-size: 1em;
        line-height: 1.25;
        color: ${colors.primary};
    }
    
    iframe{
        margin: 16px auto;
    }
    
    div.YoutubePlayer{
        margin: 16px 0;
        iframe{
            margin: 0;
        }
    }

    button{
        position: absolute;
        width: 32px;
        height: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        top:24px;
        right: 24px;
        background: transparent;
        border-radius: 6px;
        cursor: pointer;
        img{
            margin: 0;
            margin-right: 0;
            position: relative;
        }
        &:hover{
            /* UI-Light/Stack Accent */
            background: ${colors.UILightStackSecondary}
        }
    }



`;
