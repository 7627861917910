import React from 'react';
import { createMarkUp } from '../../helpers';
import { ProductLogo } from '../../V2/ProductLogo/ProductLogo';

import { ComponentStyles, CardStyles, Ribbon } from './styles.jsx';
import { ButtonComponent } from '../../V2/Button/Button';

interface Props {
  Items?: string;
}

export const CardTop = ({ ...props }) => {
  return (
    <CardStyles>
      {props.ribbon && (
        <div className={'ribbon-wrap'}>
          <Ribbon className={'ribbon'}>{props.ribbon}</Ribbon>
        </div>
      )}
      <ProductLogo Logo={props.product} />
      <h4>{props.tag}</h4>
      <ButtonComponent Target={'_blank'} Link={props.cta.link} Theme={'geomagic-designx'} Label={props.cta.label} />
    </CardStyles>
  );
};

export const GDXHighlightCardTop = ({ Items, ...props }: Props) => {
  return (
    <ComponentStyles className={`Component_GDXHighlightCardTop`} {...props}>
      <div className={'container'}>
        {Items.map((item, index) => (
          <CardTop key={index} {...item} />
        ))}
      </div>
    </ComponentStyles>
  );
};
