import React from 'react';
import { Footer } from '../../../components/layouts/Footer';
import { Hero } from '../../../components/layouts/Hero';
import { MediaComponentV2 } from '../../../components/V2/MediaComponent';
import { Statistics } from '../../../components/layouts/Statistics';
import { HelmetContainer } from '../../../components/pageComponents/helmet';
import { ListContainer } from '../../../components/pageComponents/Styles/style';

import { HeaderSubtitle } from '../../../components/subComponents/HeaderSubtitle';
import { HeaderBackground } from '../../../components/pageComponents/header';
import { Header } from '../../../components/layouts/HeaderComponent';
import { ButtonContainer, WhiteButton } from '../../../components/pageComponents/mediaComponentContent/styles';
import { Icon } from '../../../components/subComponents/Icon';

import { createMarkUp } from '../../../components/V2/helpers';
import { PageContainer } from '../../../components/containers/PageContainer';

const GeomagicControlX = () => {
  const Lang = 'es';
  const data = require(`../../../../data/content/Pages/products/geomagic-controlx/automated-inspection/${Lang}.json`);
  const globalCopy = require(`../../../../data/content/Global/${Lang}.json`);

  return (
    <PageContainer>
      <HelmetContainer
        currentPage={'geomagic-controlx-automated-inspection'}
        lang={Lang}
        image="/assets/images/pages/geomagic-control-x/automation/controlx-robotarm-1.png"
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.description}
      />
      <HeaderBackground>
        <Header lang={Lang} />
        <Hero
          title={data.content.hero.title}
          description={data.content.hero.description}
          subtitle={<HeaderSubtitle icon="ControlX" boldText="Geomagic" text="Control X" />}
          image={{
            src: '/assets/images/pages/geomagic-control-x/automation/controlx-robotarm-1.png',
            alt: 'Geomagic Control X Automobile Part'
          }}
          buttons={[{ text: globalCopy.Buttons.RequestFreeTrial, vertical: 'controlXAutomation' }]}
          vertical="controlX"
          phone
        />
      </HeaderBackground>
      <Statistics title={data.content.Statistics.title} stats={data.content.Statistics.stats} inverted />
      <MediaComponentV2
        decreasedPadding
        articles={[
          {
            title: data.content.Media1.title,
            content: (
              <>
                <ListContainer dangerouslySetInnerHTML={createMarkUp(data.content.Media1.list)}></ListContainer>
              </>
            ),
            image: {
              src: '/assets/images/pages/geomagic-control-x/automation/geomagic_controlx_on_screens.png',
              alt: 'Geomagic ControlX on computer screens',
              imagePos: 'left'
            }
          }
        ]}
      />
      <MediaComponentV2
        articles={[
          {
            title: data.content.Media2.title,
            subTitle: data.content.Media2.subtitle,
            content: (
              <>
                <p>{data.content.Media2.p1}</p>
                <br />
                <ListContainer dangerouslySetInnerHTML={createMarkUp(data.content.Media2.list)}></ListContainer>
                <ButtonContainer>
                  <a href="/assets/files/brochures/controlX/cx-automation-volumeextract_samplefiles.zip">
                    <WhiteButton>
                      {data.content.Media2.button}
                      <Icon ml="12px" name="Arrow" />
                    </WhiteButton>
                  </a>
                </ButtonContainer>
              </>
            ),
            mediaComponent: {
              component: (
                <iframe
                  title="What is 3dXpert"
                  className="vidyard_iframe"
                  src="//play.vidyard.com/xGTcvUFXnpgJLMM82L5z4h.html"
                  width="640"
                  height="360"
                  scrolling="no"
                  frameBorder="0"
                  allowTransparency
                  allowFullScreen
                />
              ),
              pos: 'right'
            },
            image: { src: '/assets/images/pages/geomagic-control-x/media_2.png', alt: 'Placeholder', imagePos: 'right' }
          }
        ]}
      />
      <MediaComponentV2
        articles={[
          {
            title: data.content.Media3.title,
            subTitle: data.content.Media3.subtitle,
            content: (
              <>
                <p>{data.content.Media3.p1}</p>
                <br />
                <ListContainer dangerouslySetInnerHTML={createMarkUp(data.content.Media3.list)}></ListContainer>
                <ButtonContainer>
                  <a href="/assets/files/brochures/controlX/cx-automation-replace-ref-data.scrpt.zip">
                    <WhiteButton>
                      {data.content.Media3.button}
                      <Icon ml="12px" name="Arrow" />
                    </WhiteButton>
                  </a>
                </ButtonContainer>
              </>
            ),
            mediaComponent: {
              component: (
                <iframe
                  title="What is 3dXpert"
                  className="vidyard_iframe"
                  src="//play.vidyard.com/u46vaWpdR32pfCDwwpio2s.html"
                  width="640"
                  height="360"
                  scrolling="no"
                  frameBorder="0"
                  allowTransparency
                  allowFullScreen
                />
              ),
              pos: 'left'
            },
            image: { src: '/assets/images/pages/geomagic-control-x/media_4.png', alt: 'Placeholder', imagePos: 'left' }
          }
        ]}
      />
      <MediaComponentV2
        articles={[
          {
            title: data.content.Media4.title,
            subTitle: data.content.Media4.subtitle,
            content: (
              <>
                <p>{data.content.Media4.p1}</p>
                <br />
                <ListContainer dangerouslySetInnerHTML={createMarkUp(data.content.Media4.list)}></ListContainer>
              </>
            ),
            mediaComponent: {
              component: (
                <iframe
                  title="What is 3dXpert"
                  className="vidyard_iframe"
                  src="//play.vidyard.com/sQWqpdBcPeoptakqZ8ne7R.html"
                  width="640"
                  height="360"
                  scrolling="no"
                  frameBorder="0"
                  allowTransparency
                  allowFullScreen
                />
              ),
              pos: 'right'
            },
            image: {
              src: '/assets/images/pages/geomagic-control-x/media_3.png',
              alt: 'Placeholder',
              imagePos: 'right'
            }
          }
        ]}
      />
      <MediaComponentV2
        articles={[
          {
            title: data.content.Media5.title,
            subTitle: data.content.Media5.subtitle,
            content: (
              <>
                <p>{data.content.Media5.p1}</p>
                <br />
                <ListContainer dangerouslySetInnerHTML={createMarkUp(data.content.Media5.list)}></ListContainer>
              </>
            ),
            image: {
              src: '/assets/images/pages/geomagic-control-x/automation/product_visual_with_image.png',
              alt: 'Product Visual with Image',
              imagePos: 'left'
            }
          }
        ]}
      />

      <Footer lang={Lang} />
    </PageContainer>
  );
};

export default GeomagicControlX;
