const fontSizes = {
  xxs: 10,
  xs: 11,
  s: 12,
  m: 14,
  l: 16,
  xl: 21,
  xxl: 24,
  xxxl: 32,
  'xxxl+': 42,
  xxxxl: 48,
  'xxxxl+': 60,
  xxxxxl: 72
};

export type FontSize = keyof typeof fontSizes;
export const getFontSize = (size: FontSize | undefined) => {
  if (size) {
    return fontSizes[size] + 'px';
  }
  return fontSizes.m + 'px';
};

const fontWeights = {
  thin: 300,
  book: 400,
  normal: 'normal',
  medium: 500,
  heavy: 600,
  bold: 'bold'
};

export type FontWeight = keyof typeof fontWeights;
export const getFontWeight = (val: FontWeight | undefined) => {
  if (val) {
    return fontWeights[val];
  }
  return fontWeights.normal;
};
const letterSpacing = {
  none: 0,
  s: 0.02,
  m: 0.04,
  l: 0.08,
  xl: 0.1,
  xxl: 0.15,
  xxxl: 4.8
};

export type LetterSpacing = keyof typeof letterSpacing;
export const getLetterSpacing = (val: LetterSpacing | undefined) => {
  if (val) {
    return letterSpacing[val];
  }
  return letterSpacing.m;
};

export const transition = {
  formGroup: '0.1s linear'
};

export const lineHeight = {
  m: 32
};

export const typography = {
  fontSizes,
  fontWeights,
  transition,
  letterSpacing,
  lineHeight
} as const;
