import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import CivilTemplate from '../../templates/CivilTemplate';

const Civil = ({ pageContext }) => {
  const Lang = 'pt-br';

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "civil-pt-br" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
    }
  `);

  const content = data.allFile.edges[0].node.childRawJson.content;
  const json = JSON.parse(content); // Parse the raw JSON

  return <CivilTemplate Lang={Lang} {...pageContext} pageContent={json} />;
};

export default Civil;
