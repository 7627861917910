import React from 'react';

import { Main, PrimaryBackground } from '../../styles/background';
import { Content } from '../../components/layouts/PageContainer/PageContainer';
import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';

import { ImageContainer } from '../../components/layouts/ImageContainer';
import { CareersHeader, CareersOpen, CareersValues } from '../../components/pageComponents/careers';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';

const Careers = () => {
  const Lang = 'es';
  const data = require(`../../../data/content/Pages/careers/${Lang}.json`);

  return (
    <>
      {' '}
      <HelmetContainer currentPage={'careers'} title="Careers" lang={Lang} />
      <PageContainer>
        <Main noPadding>
          <Header lang={Lang} />
          <Content>
            <CareersHeader content={data.content.hero} />
            <CareersValues content={data.content.values_section} />
          </Content>
        </Main>
        <PrimaryBackground>
          <Content>
            <CareersOpen content={data.content.careers_footer} />
          </Content>
        </PrimaryBackground>
        <Footer lang={Lang} />
      </PageContainer>
    </>
  );
};

export default Careers;
