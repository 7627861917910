import React from 'react';

import { FooterComponent } from '../../V2/Footer/Footer';

export const Footer = props => {
  let Lang;
  let additionalClasses;

  if (!props.Lang) {
    Lang = 'en';
  } else {
    Lang = props.Lang;
  }

  if (!props.additionalClasses) {
    additionalClasses = '';
  } else {
    additionalClasses = props.additionalClasses;
  }

  const globalData = require(`../../../../data/content/Global/${Lang}.json`);

  return (
    <>
      <FooterComponent AdditionalClasses={additionalClasses} Lang={Lang}></FooterComponent>
    </>
  );
};
