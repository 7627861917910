import styled from 'styled-components';
import { bgGradients, colors, mediaQueriesSizes } from '../../../GlobalStyles';

export const ButtonStyles = styled.a`
    display: inline-block;
    border-radius: 4px;
    border-style: none;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    transition: 0.35s;
    text-decoration: none;
    padding: 12px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 16px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        padding: 16px 20px;
    }
    .inner {
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        svg {
            font-size: 12px;
            margin-left: 8px;
        }

        &.icon-pos-right {
            img,
            svg {
                margin-left: 8px;
            }
        }

        &.icon-pos-left {
            img,
            svg {
                margin-right: 8px;
            }
        }
    }

    &:hover {
        opacity: 0.7;
    }

    &.theme-primary {
        background: ${colors.primary};
        color: ${colors.white};
    }

    &.theme-clear {
        background: rgba(255, 255, 255, 0.4);
        color: ${colors.white};
    }

    &.theme-accent-red {
        background: ${colors.red};
        color: ${colors.white};
    }

    &.theme-manufacturing-os {
        background: ${bgGradients.mos};
        color: ${colors.white};
    }

    &.theme-manufacturing-os-reverse {
        background: white;
        .inner {
            background: ${bgGradients.mos}
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &.theme-d2p {
        background: ${bgGradients.d2p};
        color: ${colors.white};
    }

    &.theme-geomagic-freeform {
        background: ${bgGradients.freeform};
        color: ${colors.white};
    }

    &.theme-geomagic-designx {
        background: ${colors.solidGeomagicDesignx};
        color: ${colors.white};
    }

    &.theme-geomagic-designx-outline {
        background: ${colors.white};
        color: ${colors.solidGeomagicDesignx};
        border: ${colors.solidGeomagicDesignx} solid 2px;
    }

    &.theme-geomagic-controlx-outline {
        background: ${colors.white};
        color: ${colors.solidGeomagicControlx};
        border: ${colors.solidGeomagicControlx} solid 2px;
    }

    &.theme-geomagic-controlx {
        background: ${bgGradients.controlx};
        color: ${colors.white};
    }

    &.theme-3dxpert {
        background: linear-gradient(45deg, #568599 0%, #7bbeda 100%);
        color: ${colors.white};
    }

    &.theme-geomagic-freeform,
    &.theme-freeform {
        background: ${bgGradients.freeform};
        color: ${colors.white};
    }

    &.theme-3dxpert-reverse {
        background: white;
        .inner {
            background: linear-gradient(45deg, #568599 0%, #7bbeda 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &.theme-gradient-red {
        background: ${bgGradients.designx};
        color: ${colors.white};
        &:hover {
            opacity: 0.7;
        }
    }

    &.theme-geo-for-solid {
        background: ${bgGradients.geomagicSolidWorks};
        color: ${colors.white};

        &:hover {
            opacity: 0.7;
        }
    }

    &.theme-geo-for-solid-reverse {
        color: ${colors.darkRed};
        background: ${colors.white};

        &:hover {
            opacity: 0.7;
        }
    }

    &.theme-gradient-green {
        background: ${bgGradients.controlx};
        color: ${colors.white};

        &:hover {
            opacity: 0.7;
        }
    }

    &.theme-gradient-orange {
        background: ${bgGradients.wrap};
        color: ${colors.white};

        &:hover {
            opacity: 0.7;
        }
    }

    &.theme-accent-teal {
        color: ${colors.white};
        font-weight: bold;
        background: ${colors.teal};
    }

    &.theme-white-and-red {
        color: ${colors.red};
        background: ${colors.white};

        &:hover {
            background: ${colors.red};
            color: ${colors.white};
        }
    }

    &.theme-white-and-primary {
        color: ${colors.primary};
        background: ${colors.white};
        border: solid 1px ${colors.white};
    }

    &.theme-white-and-green {
        color: ${colors.green};
        background: ${colors.white};

        &:hover {
            background: ${colors.green};
            color: ${colors.white};
        }
    }

    &.theme-white-and-orange {
        color: ${colors.orange};
        background: ${colors.white};

        &:hover {
            background: ${colors.secondaryOrange};
            color: ${colors.white};
        }
    }

    &.theme-white-and-teal {
        color: ${colors.teal};
        background: ${colors.white};
    }

    &.theme-dark-teal {
        color: ${colors.darkTeal};
        background: ${colors.white};
    }

    &.theme-white-outline {
        color: ${colors.primary};
        background: ${colors.white};
        border: solid 1px ${colors.light2};

        &:hover {
            background: ${colors.primary};
            color: ${colors.white};
        }
    }

    &.theme-transparent-with-outline {
        color: ${colors.primary};
        background: transparent;
        border: solid 1px ${colors.teal};

        &:hover {
            background: ${colors.teal};
            color: ${colors.white};
        }
    }

    &.theme-transparent-with-white-outline {
        color: white;
        background: transparent;
        border: solid 1px white;

        &:hover {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    &.theme-transparent-with-grey-outline {
        color: ${colors.primary};
        background: transparent;
        border: solid 1px ${colors.UILightBordersSecondary};

        &:hover {
            background: ${colors.teal};
            color: ${colors.white};
        }
    }

    &.theme-tertiary {
        color: ${colors.tertiary};
        background: transparent;
        border: solid 1px ${colors.tertiary};
    }

    &.theme-stripped {
        color: ${colors.primary};
        border-radius: 0;
        padding: 0 0 8px;
        border-bottom: solid 1px ${colors.fossilGrey};
        font-weight: 700;
        font-family: centrano2-book;
    }

    &.theme-stripped-geomagic-designx {
        color: ${colors.white};
        border-radius: 0;
        padding: 0 0 8px;
        border-bottom: solid 1px ${colors.solidGeomagicDesignx};
        font-weight: 700;
        font-family: centrano2-book;
    }
    &.theme-stripped-geomagic-for-solid {
        color: ${colors.white};
        border-radius: 0;
        padding: 0 0 8px;
        border-bottom: solid 1px ${colors.solidGeomagicForSolidWorks};
        font-weight: 700;
        font-family: centrano2-book;
    }
    &.theme-stripped-geomagic-controlx {
        color: ${colors.white};
        border-radius: 0;
        padding: 0 0 8px;
        border-bottom: solid 1px ${colors.solidGeomagicControlx};
        font-weight: 700;
        font-family: centrano2-book;
    }
    &.theme-stripped-geomagic-wrap {
        color: ${colors.white};
        border-radius: 0;
        padding: 0 0 8px;
        border-bottom: solid 1px ${colors.solidWrap};
        font-weight: 700;
        font-family: centrano2-book;
    }

    &:disabled,
    &[disabled] {
        opacity: 0.4;
        pointer-events: none;
    }
`;
