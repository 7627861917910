import styled from 'styled-components';

import {colors,mediaQueriesSizes} from "../../../GlobalStyles";

export const SocialFollowStyles = styled.div`

  .container {
    padding: 128px 0;
  }

  .title p {
    max-width: 800px;
    margin: 0 auto;
    font-family: centrano2-book;
    font-size: 24px;
    line-height: 1.6;
    text-align: center;
    color: ${colors.tertiary};
    padding: 0 16px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 35px;
    }

    a {
      color: ${colors.highlight};
    }
  }

  .btn-wrap {
    display: flex;
    justify-content: center;
    margin: 25px 0 0;

    a {
      font-family: centrano2-book;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: ${colors.tertiary};
      display: flex;
      align-items: center;
      padding: 16px;

      &:hover {
        color: ${colors.teal};
      }

      img {
        display: inline-block;
        margin-right: 6px;

        &.facebook {
          height: 20px;
        }

        &.linkedin {
          height: 32px;
        }
      }

    }

    svg {
      width: 24px;
      height: 20px;
      display: block;
      margin-right: 6px;

      fill: ${colors.teal};
    }


  }


  &.theme-gdx, &.theme-geomagic-designx {
    .btn-wrap {
      svg {
        fill: ${colors.solidGeomagicDesignx};
      }
    }
  }

  &.theme-gcx, &.theme-geomagic-controlx {
    .btn-wrap {
      svg {
        fill: ${colors.solidGeomagicControlx};
      }
    }
  }

  &.theme-g4s, &.theme-geomagic-for-solidworks {
    .btn-wrap {
      svg {
        fill: ${colors.solidGeomagicForSolidWorks};
      }
    }
  }

  &.theme-manufacturing-os {
    .btn-wrap {
      svg {
        fill: ${colors.solidMOS};
      }
    }
  }

  &.theme-3dxpert {
    .btn-wrap {
      svg {
        fill: ${colors.solid3DXpert};
      }
    }
  }

  &.theme-geomagic-wrap {
    .btn-wrap {
      svg {
        fill: ${colors.solidWrap};
      }
    }
  }

  &.theme-amphyon {
    .btn-wrap {
      svg {
        fill: ${colors.solidAmphyon};
      }
    }
  }

  &.theme-freeform {
    .btn-wrap {
      svg {
        fill: ${colors.solidFreeform};
      }
    }
  }

  &.theme-d2p {
    .btn-wrap {
      svg {
        fill: ${colors.solidD2P};
      }
    }
  }

}



`;

