import React from 'react';

export const Arrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 13"
    id="arrow-right"
    width={16}
    height={9}
    fill="currentColor"
  >
    <path d="M16.7 5.3l-3.5-3.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L13.6 5H6c-.6 0-1 .4-1 1s.4 1 1 1h7.6l-1.8 1.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.5-3.5c.4-.4.4-1 0-1.4z"></path>
  </svg>
);
