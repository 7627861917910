import YouTubePlayer from 'react-player/youtube';
import ReactPlayer from 'react-player/vimeo';
import { generateRandomStrong } from '../../helpers';
import React from 'react';
import styled from 'styled-components';
import { mediaQueriesSizes } from '../../../../GlobalStyles';

const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;

  .modal {
    width: calc(100% - 32px);
    background: white;
    margin: 0 auto;
    padding: 32px 16px;
    position: relative;
    //padding: 64px;

    display: flex;
    align-items: center;

    span {
      cursor: pointer;
      position: absolute;
      top: 4px;
      right: 4px;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      padding: 6px;
    }

    iframe {
      aspect-ratio: 4 / 3;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      max-width: 800px;
      padding: 64px 36px;

      span {
        width: 32px;
        height: 32px;
        font-size: 30px;
        line-height: 32px;
        top: 10px;
        right: 10px;
      }
    }
  }
`;

export const ShowVideo = (videoEmbedString: string) => {
  if (videoEmbedString.includes('youtube')) {
    return (
      <YouTubePlayer
        width={'100%'}
        height={'auto'}
        style={{ aspectRatio: '16/9' }}
        url={videoEmbedString}
        controls
        className={'YoutubePlayer'}
      />
    );
  }
  if (videoEmbedString.includes('vimeo')) {
    return (
      <ReactPlayer
        style={{}}
        width="100%"
        url={videoEmbedString}
        controls
        config={{
          playerOptions: {
            autoplay: false,
            muted: true
          }
        }}
      />
    );
  } else {
    return (
      <iframe
        title={generateRandomStrong(5)}
        width={'100%'}
        height={'auto'}
        style={{ aspectRatio: '16/9' }}
        className="vidyard_iframe"
        src={videoEmbedString}
        allowTransparency
        allowFullScreen
      />
    );
  }
};

export const Modal = props => {
  return (
    <ModalWrap>
      <div className={'modal'}>
        <span onClick={props.close}>✕</span>
        {ShowVideo(props.video)}
      </div>
    </ModalWrap>
  );
};
