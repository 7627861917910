import React from 'react';

export const ThreeDXpert = (
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="2" fill="url(#paint0_linear_10628_28)" />
    <path
      d="M4.3 7L8.84 13.8L4 21H6.7L10.24 15.64L13.74 21H16.52L11.66 13.78L16.2 7H13.5L10.26 11.92L7.06 7H4.3Z"
      fill="white"
    />
    <path
      d="M18.312 11.2V25.16H20.612V19.66C21.332 20.66 22.572 21.24 23.932 21.24C26.632 21.24 28.712 18.98 28.712 16.1C28.712 13.18 26.632 10.96 23.932 10.96C22.572 10.96 21.332 11.6 20.612 12.6V11.2H18.312ZM26.392 16.1C26.392 17.8 25.112 19.14 23.452 19.14C21.792 19.14 20.512 17.8 20.512 16.1C20.512 14.4 21.792 13.06 23.452 13.06C25.112 13.06 26.392 14.4 26.392 16.1Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10628_28"
        x1="3.74857e-08"
        y1="32"
        x2="32"
        y2="-1.86986e-06"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#568599" />
        <stop offset="1" stop-color="#7BBEDA" />
      </linearGradient>
    </defs>
  </svg>
);
