import styled from 'styled-components';

import { colors, mediaQueriesSizes, bgGradients } from '../../../../GlobalStyles';

export const HighlightBlockStyles = styled.div`
  background: ${colors.primary};
  position: relative;
  z-index: 5;

  background-position: right center;
  background-repeat: no-repeat;

  &.increased-outer-padding {
    padding: 32px 0;
  }
    
    
    &.announcement-bar-on-top{
        padding-top: 150px;
        margin-top: -180px;
        position: relative;
        z-index: 0;
        
          @media (min-width: ${mediaQueriesSizes.lg}px) {
            padding-top: 30px;
    margin-top: -50px;
    position: relative;
    z-index: 0;
    }
    }
    
    
    
`;

export const Inner = styled.div`
  position: relative;
  border-radius: 10px;

  padding: 0 16px;
  &.decreased-padding {
    padding: 0;
  }
  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 0 32px;
    &.decreased-padding {
      padding: 0;
    }
    display: flex;
    justify-content: space-between;

    &.flex-center {
      align-items: center;
    }
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: flex;
    align-items: flex-end;
    &.flex-center {
      align-items: center;
    }
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 0 64px;
  }

  &.theme-accent-green {
    background: ${bgGradients.controlx};
  }

  &.theme-3dxpert {
    background: ${bgGradients.threeDXpert};
  }

  &.theme-geomagicSolidWorks {
    background: ${bgGradients.geomagicSolidWorks};
  }

  &.theme-Primary {
    background: ${bgGradients.dark};
  }

  &.theme-manufacturing-os {
    background: ${bgGradients.mos};
  }

  &.background-image {
    background-position: right center;
    background-repeat: no-repeat;
  }

  &.background-white {
    background-color: white;
  }
`;

export const BackgroundImage = styled.div``;

export const ImgWrapSplit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 400px;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    justify-content: flex-end;
  }
`;

export const CopyWrap = styled.div`
  width: 350px;
  max-width: 100%;
  padding: 32px 16px 32px 0;

  h5 {
    font-family: centrano2-medium;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.09em;
    color: #ffffff;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  h4 {
    color: #ffffff;
    font-family: centrano2-book;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 16px;
  }

  h3 {
    font-weight: 500;
    line-height: 1.2;
    color: #ffffff;
    font-size: 18px;
  }
  p {
    font-weight: 300;
    padding-top: 1em;
    line-height: 1.4;
    color: #ffffff;
    font-size: 20px;
    text-wrap: balance;
  }
  @media (min-width: ${mediaQueriesSizes.md}px) {
    min-width: 350px;

    h3 {
      font-size: 22px;
      padding-right: 1em;
    }
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 42px 64px 42px 0;
    min-width: 450px;
    h3 {
      font-size: 36px;
    }
    h4 {
      font-size: 24px;
    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    min-width: 550px;
    padding: 86px 86px 86px 0;
    h5 {
      font-size: 20px;
    }
    h3 {
      text-wrap: balance;
      font-size: 40px;
    }
  }
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    min-width: 750px;
    padding: 120px 86px 120px 0;
  }

  button,
  a {
    margin-top: 16px;
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin-top: 32px;
    }
  }

  &.size-extraText {
    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 400px;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      width: 610px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 820px;
      padding: 56px 80px 56px 0;
      h3 {
        font-size: 32px;
      }
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      width: 950px;
    }
  }

  &.theme-teal {
    h5 {
      color: ${colors.teal};
    }
  }

  &.text-primary {
    h3,
    h4,
    h5 {
      color: ${colors.primary};
    }
  }

  &.ImageType-Background {
    width: 100%;
  }

  &.text-limit-width {
    max-width: 835px;
  }
`;

export const ImgWrap = styled.div`
  aspect-ratio: 4 / 3;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    max-width: 300px;
    display: flex;
    align-items: center;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    max-width: 450px;
    justify-content: end;
    margin-right: 16px;
  }

  img {
    width: 100%;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 100%;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      transform: scale(1.15);
      width: auto;
      max-width: 100%;
      max-height: 100%;
      transform-origin: top right;

      //background: yellow;
    }
    // @media (min-width: ${mediaQueriesSizes.xl}px) {
    //   position: absolute;
    //   top: 16px;
    //   right: 16px;
    //   height: 120%;
    //   width: auto;
    //   margin-bottom: 0;
    // }
  }

  //width: auto;
  //height: 100%;
  //max-width: 50%;
  //bottom: 75px;
  //top: auto;
  //right: 0%;

  &.top-right {
    img {
      width: 90%;
      margin: 0 5% -20%;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      img {
        width: auto;
        top: -15%;
        right: 5%;
      }
    }
  }
`;
