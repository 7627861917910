import React from 'react';
import { FeaturedPartnerStyles } from './styles.jsx';

interface FeaturedPartnerFields {
  add_featured_partner: boolean;
  name: string;
}

interface PostFields {
  featured_partner: FeaturedPartnerFields;
}

interface Post {
  fields: PostFields;
}

interface Props {
  post: Post;
}

export const FeaturedPartner = ({ post, ...props }: Props) => {
  if (!post.fields.featured_partner) {
    return null;
  }

  if (post.fields.featured_partner.add_featured_partner === true) {
    return (
      <FeaturedPartnerStyles>
        {post.fields.featured_partner.website && post.fields.featured_partner.website !== '' ? (
          <a target={'_blank'} href={post.fields.featured_partner.website}>
            Featured Partner: {post.fields.featured_partner.name}
            <img src={post.fields.featured_partner.logo} alt="Partner Logo" />
          </a>
        ) : (
          <p>
            Featured Partner: {post.fields.featured_partner.name}
            <img src={post.fields.featured_partner.logo} alt="Partner Logo" />
          </p>
        )}
      </FeaturedPartnerStyles>
    );
  }

  return null;
};
