import styled from 'styled-components';
import { colors, typography } from '../../foundations';

export const ThankYouContainer = styled.section`
  height: calc(100vh - 368px);

  display: flex;
  align-items: center;
`;

export const ThankYouSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ThankYouTitle = styled.h1`
  color: ${colors.secondary};
  text-align: center;

  font-size: ${typography.fontSizes.xxxxl}px;
  font-weight: ${typography.fontWeights.medium};
  line-height: 1.2;

  a {
    display: inline-block;
    color: ${colors.accent};
    text-align: center;

    font-size: ${typography.fontSizes.xxxxl}px;
    font-weight: ${typography.fontWeights.medium};
    line-height: 1;

    &:hover {
      color: ${colors.tertiaryAccent};
      text-decoration: underline solid ${colors.tertiaryAccent};
    }
  }
`;
