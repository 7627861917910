import styled from 'styled-components';
import { HeadingS, ParagraphStyle, RDButton, RDPS } from '../../../styles/typography';
import { Box } from '../../containers/Box';
import { boxShadows, colors, colorsRD, spacing } from '../../foundations';
import { Link as GatsbyLink } from '../Link';
import { ContentProps } from './Card';
import { Position } from './types';

const getGrid = (position?: Position) => {
  switch (position) {
    case 'left':
      return `
        display: grid;
        grid-template-columns: min-content auto;
        grid-template-rows: auto;
        grid-template-areas: "image content";
      `;
    case 'right':
      return `
        display: grid;
        grid-template-columns: auto min-content;
        grid-template-rows: auto;
        grid-template-areas: "content image";
      `;
    case 'top':
      return `
        display: grid;  
        grid-template-columns: auto;
        grid-template-rows: min-content auto;
        grid-template-areas: "image" "content";
      `;
    case 'bottom':
      return `
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto min-content;
        grid-template-areas: "content" "image";
      `;
    case 'full':
      return `
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      `;
    default:
      return '';
  }
};

export const StyledLink = styled(GatsbyLink)<{ gridArea?: string }>`
  ${({ gridArea }) => (gridArea ? `grid-area: ${gridArea}` : '')};
`;

export const Container = styled.article<{ position?: Position; gridArea?: string }>`
  border-radius: ${spacing.xs}px;
  background-color: ${colors.primary};

  border: 1px solid #f3f5f6;
  box-shadow: ${boxShadows.primary};

  ${p => getGrid(p.position)};

  ${p => (p.position === 'full' ? `width: 453px;` : '')};
  height: 100%;
`;

export const Content = styled.section<{ position?: Position; contentProps?: ContentProps }>`
  ${p => (p.position === 'full' ? `z-index: 1; grid-area: 2 / 1 / -1 / -1;` : 'grid-area: content;')};
  padding: ${spacing['xl+']}px;

  display: flex;
  flex-direction: column;

  ${({ contentProps }) =>
    contentProps
      ? `
    ${contentProps.height ? `height: ${contentProps.height}` : ''};
    ${contentProps.width ? `width: ${contentProps.width}` : ''};
  `
      : ''}
`;

export const Title = styled.h3<{ position?: Position }>`
  ${HeadingS};
  color: ${p => (p.position !== 'full' ? colorsRD.primary : colors.primary)};
  margin-bottom: ${spacing.m}px;
`;

export const Description = styled.p<{
  position?: Position;
  size?: { maxHeight?: number; maxWidth?: number };
  useSmallText?: boolean;
}>`
  ${p => (p.useSmallText ? RDPS : ParagraphStyle)};
  color: ${p => (p.position !== 'full' ? colorsRD.primaryText : colors.primary)};
  margin-bottom: ${spacing.m}px;
  ${p =>
    p.size
      ? `
      max-height: ${p.size.maxHeight}px; 
      width: ${p.size.maxWidth}px; 
      text-overflow: ellipsis;  
      overflow: hidden;`
      : ''};
`;

export const Link = styled.button<{ position?: Position }>`
  background: none;
  padding: unset;
  border: none;

  display: flex;
  align-items: center;
`;

export const ButtonText = styled.p<{ position?: Position }>`
  ${RDButton};
  color: ${p => (p.position !== 'full' ? colorsRD.primary : colors.primary)};
  padding-bottom: ${spacing.xs}px;
  border-bottom: 1px solid ${colorsRD.accent.passion};
`;

export const ButtonIcon = styled(Box)<{ _position?: Position }>`
  color: ${p => (p._position !== 'full' ? colorsRD.primary : colors.primary)};
`;

const getCornerRadius = (position: Position) => {
  switch (position) {
    case 'top':
      return `${spacing.xs}px ${spacing.xs}px 0 0`;
    case 'bottom':
      return `0 0 ${spacing.xs}px ${spacing.xs}px`;
    case 'left':
      return `${spacing.xs}px 0 0 ${spacing.xs}px`;
    case 'right':
      return `0 ${spacing.xs}px ${spacing.xs}px 0`;
    case 'full':
      return `${spacing.xs}px`;
  }
};

export const Image = styled.img<{
  position: Position;
  keepOriginalSize?: boolean;
  padding?: string;
  margin?: string;
  resizeWidth?: boolean;
}>`
  ${p => (p.position === 'full' ? `z-index: 0; grid-area: 1 / 1 / -1 / -1;` : 'grid-area: image;')};

  ${({ margin }) => (margin ? `margin: ${margin};` : '')};
  ${({ padding }) => (padding ? `padding: ${padding};` : '')};

  height: ${({ keepOriginalSize }) => (keepOriginalSize ? 'auto' : '100%')};
  width: ${({ resizeWidth }) => (resizeWidth ? '100%' : 'auto')};
  border-radius: ${p => (p.keepOriginalSize ? '0' : getCornerRadius(p.position))};
`;

export const Overlay = styled.div`
  z-index: 0;
  grid-area: 1 / 1 / -1 / -1;
  border-radius: ${spacing.xs}px;
  background: linear-gradient(3.22deg, rgba(35, 64, 87, 0.7) 25.68%, rgba(35, 64, 87, 0) 60.33%);
`;
