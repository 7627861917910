import React from 'react';
import { MaxContentContainer } from '../../subComponents/ContentContainer';
import { createMarkUp } from '../../V2/helpers';
import { Stat } from './Stat';
import { BackgroundContainer, Container, ContentContainer, Title } from './styles';

interface StatisticProps {
  title: React.ReactNode;
  stats: StatProps[];
  inverted?: boolean;
}

export interface StatProps {
  label: React.ReactNode;
  value: React.ReactNode;
  extra?: string;
  inverted?: boolean;
}

export const Statistics = ({ title, stats, inverted }: StatisticProps) => {
  return (
    <BackgroundContainer inverted={inverted}>
      <MaxContentContainer>
        <Container inverted={inverted}>
          <Title dangerouslySetInnerHTML={createMarkUp(title)}></Title>
          <ContentContainer>
            {stats.map((stat, index) => (
              <Stat {...stat} inverted={inverted} key={`Statistic-${index}`} />
            ))}
          </ContentContainer>
        </Container>
      </MaxContentContainer>
    </BackgroundContainer>
  );
};
