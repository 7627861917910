import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const ComponentStyles = styled.div`
    
    padding: 64px 0;
    
    .container.title {
        h2 {
            min-width: 300px !important;
            font-weight: bold;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                padding-right: 16px;
            }
        }
    }

    hr.divider {
        margin: 32px 0;
    }

    .container.grid {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            flex-direction: row;
        }

        .main-review {
            position: relative;
            background: url('/assets/2024/backgrounds/review-background.png');
            background-size: cover;
            background-position: center center;
            padding: 40px;
            border-radius: 8px;
            flex-grow: 2;

            @media (min-width: ${mediaQueriesSizes.lg}px) {
                max-width: 60%;
                height: 400px;
            }

            display: flex;
            align-items: center;

            .slick-slider {
                width: 100%;
                @media (min-width: ${mediaQueriesSizes.lg}px) {
                    padding: 0 60px;
                }
            }

            .slick-arrow {

                width: 18px;
                height: 18px;

                @media (min-width: ${mediaQueriesSizes.lg}px) {
                    width: 36px;
                    height: 36px;

                }

                img {
                    width: 100%;
                }

                &:before {
                    display: none;
                }
            }

            .review {
                img {
                    margin: 0 auto 16px;
                    display: block;
                }

                p {
                    font-family: centrano2-book;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.875em;
                    @media (min-width: ${mediaQueriesSizes.lg}px) {
                        font-size: 1.25em;
                    }
                    line-height: 1.5;
                    text-align: center;
                    letter-spacing: 0.02em;
                    color: #FFFFFF;
                }

                h6 {
                    margin-top: 26px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1em;
                    line-height: 1.3;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.02em;
                    color: rgba(255, 255, 255, 0.9);
                }
            }

        }

        .side-reviews {
            //min-width: 550px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            flex-grow: 1;
        }

        .side-review {
            height: 50%;
            background-color: #1f2d3d;
            border-radius: 8px;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-position: center center;


            &.capterra {
                background-image: url("/assets/2024/backgrounds/background-capterra.png");
            }

            &.g2 {
                background-image: url("/assets/2024/backgrounds/background-g2.png");
            }


        }

        a {
            display: block;
            transition: 0.25s;

            &:hover {
                transform: scale(1.1);
            }
        }

        .side-review img {
            width: auto;
            height: auto;
            min-height: 75px;
            max-height: 75px;
        }


    }
`;