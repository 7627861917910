import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import { VideoWrapper, Popup, PopupContent } from './styles';

interface VideosPops {
  shown: boolean;
  handleClose: () => void;
  url: string;
  title: string;
  vidyard?: React.ReactNode;
  vimeo?: React.ReactNode;
}
export const VideoPlayerWrapper = ({ shown, handleClose, url, title, vidyard, vimeo }: VideosPops) => {
  return shown ? (
    <Popup
      onClick={() => {
        handleClose();
      }}
    >
      <PopupContent
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {vidyard && <VideoWrapper src={url} title={title} allowFullScreen />}
        {vimeo && (
          <ReactPlayer
            width="100%"
            height="100%"
            url={url}
            controls
            config={{
              playerOptions: {
                autoplay: true,
                muted: true
              }
            }}
          />
        )}
      </PopupContent>
    </Popup>
  ) : null;
};
