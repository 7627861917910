import React from 'react';

import { SalesTelStyles } from './styles.jsx';

interface Props {
  Country?: 'en' | 'fr' | 'uk' | 'de' | 'it' | 'ko' | 'ja' | 'es';
  Theme?:
    | 'primary'
    | 'clear'
    | 'accent-red'
    | 'gradient-red'
    | 'gradient-green'
    | 'gradient-orange'
    | 'accent-teal'
    | 'dark-teal'
    | 'white-and-red'
    | 'white-and-primary'
    | 'white-and-green'
    | 'white-outline'
    | '3dxpert'
    | 'freeform'
    | 'geomagic-freeform'
    | 'geomagic-controlx'
    | 'geomagic-designx'
    | '3dxpert-reverse'
    | 'manufacturing-os'
    | 'geo-for-solid'
    | 'transparent-with-outline'
    | 'transparent-with-white-outline'
    | 'transparent-with-grey-outline'
    | 'stripped';
}

export const SalesTel = ({ Theme, Country = 'en', ...props }: Props) => {
  const data = require(`../../../../../data/content/Global/${Country}.json`);

  return (
    <SalesTelStyles className={`Component-SalesTel theme-${Theme}`} {...props}>
      {data.Buttons.Sales}
      <br />
      <a href={'tel:' + data.phoneNumbers[0].phone}>{data.phoneNumbers[0].phone}</a>
    </SalesTelStyles>
  );
};
