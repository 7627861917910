import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const DefaultTextGridStyles = styled.div`
  padding: 64px 0;

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.16;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;
  }

  .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 64px;
    grid-row-gap: 64px;

  }

  .description {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 0.02em;
    text-align: left;
    color: #59747A;

    a {
      color: ${colors.teal};
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 120px 0;

    h2 {
      font-size: 48px;
      max-width: 75%;
      margin-bottom: 80px;
    }


    .grid-wrapper {
      grid-template-columns: repeat(2, 1fr);
    }

    .description {
      font-size: 24px;
    }
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    .grid-wrapper {
      grid-template-columns: repeat(3, 1fr);
    }
    h2{
      max-width: 50%;
    }
  }

`;
export const DefaultItem = styled.div`
  border-top: solid 1px rgba(35, 64, 87, 0.2);
  padding: 16px 0;
  min-height: 180px;

  h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.02em;
    color: ${colors.primary};
    margin-bottom: 22px;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 0.02em;
    text-align: left;
    color: #59747A;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 32px 0;
    min-height: 300px;
    h4 {
      font-size: 24px;
    }

    p {
      font-size: 24px;
    }
  }
`;

export const SecondaryGridStyles = styled.div`
  padding: 64px 0;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    .grid-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 32px;
      grid-row-gap: 32px;
    }

  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 120px 0;
    .grid-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

  }
  
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    .grid-wrapper {
      grid-column-gap: 64px;
      grid-row-gap: 64px;
    }

  }


`;


export const SecondaryLayout_PrimaryItem = styled.div`
  
  h2{
    font-size: 24px;
    font-weight: 700;
    line-height: 1.16;
  }
  
  hr.divider{
    margin: 16px 0 ;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-column: 1 / 3;
    h2{
      font-size: 32px;
      max-width: 480px;
    }
    hr{
      margin: 42px 0 0;
    }
  }
  
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    h2{
      font-size: 48px;
      max-width: 680px;
    }
  }
`;


export const SecondaryLayout_Item = styled(DefaultItem)`
  h6{
    color: ${colors.red};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 12px;
  }
  
  h4{
    font-size: 16px;
  }
  
  p{
    color: ${colors.light};
    font-weight: 300;
    font-size: 12px;
    line-height: 1.667;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    h6{
      font-size: 20px;
      margin-bottom: 24px;
    }
    h4{
      font-size: 18px;
    }
    p{
      font-size: 18px;
    }
  }

`;