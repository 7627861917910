import styled from 'styled-components';
import { colors, colorsRD, media, spacing } from '../../foundations';
import { RDH2Bold, RDButton, HeadingS, RDH3, RDButtonSmall } from '../../../styles/typography';
import { Box } from '../../containers/Box';

export const BackgroundContainer = styled.div`
  background-image: ${colorsRD.background.secondary};
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.xxxxxl}px;

  @media screen and (max-width: ${media['xxl++']}px) {
    padding: ${spacing.xxxxxl}px ${spacing.xxxl}px;
  }

  @media screen and (max-width: ${media.md}px) {
    padding: ${spacing.xxl}px ${spacing.m}px;
  }
`;

export const CardTitle = styled.h3`
  ${HeadingS};
  color: ${colors.secondary};
  margin-bottom: ${spacing.xs}px;

  @media screen and (max-width: ${media.md}px) {
    margin-bottom: ${spacing.xs}px;
  }
`;

export const Text = styled.p`
  color: ${colors.tertiary};
  margin: ${spacing.s}px ${spacing.none}px;
`;

export const Title = styled.h1`
  ${RDH2Bold};
  text-align: center;
  margin-bottom: ${spacing.xxl}px;
  color: ${colorsRD.white};

  @media screen and (max-width: ${media.md}px) {
    ${RDH3};
  }
`;

export const CardContainer = styled.section`
  grid-template-rows: 1fr 1fr;
  display: grid;
  grid-gap: ${spacing['xl+']}px;
  grid-template-columns: repeat(3, 1fr);
  img {
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
  }
  @media screen and (max-width: ${media.xxl}px) {
    width: 700px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    img {
      height: 200px;
    }
  }

  @media screen and (max-width: ${media.lg}px) {
    width: auto;
    margin: 0 ${spacing.m}px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (max-width: ${media.sm}px) {
    margin: 0 ${spacing.xs}px;
    display: flex;
    flex-direction: column;
  }
`;
export const CardContainer1 = styled.section`
  display: grid;
  grid-gap: ${spacing['xl+']}px;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (max-width: ${media.xxl}px) {
    width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: ${media.lg}px) {
    width: auto;
    margin: 0 ${spacing.m}px;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: ${media.sm}px) {
    margin: 0 ${spacing.xs}px;
    display: flex;
    flex-direction: column;
  }
`;
export const Link = styled.button<{ color?: string }>`
  background: none;
  padding: unset;
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;

  color: ${({ color }) => (color ? color : colorsRD.primary)};
`;

export const ButtonText = styled.p`
  ${RDButton};

  padding-bottom: ${spacing.xs}px;
  border-bottom: 1px solid ${colorsRD.accent.passion};
`;

export const ButtonIcon = styled(Box)``;

export const DownloadButton = styled.button`
  cursor: pointer;
  ${RDButtonSmall};
  color: ${colorsRD.white};

  background: none;
  border-radius: ${spacing.xxs}px;
  border: solid ${spacing.xxxs}px ${colorsRD.accent.fossilGrey50};

  padding: ${spacing.m}px ${spacing.l}px;
`;

export const ButtonLink = styled.a`
  width: fit-content;
  height: min-content;
  margin: ${spacing.xxxxl}px auto;
`;
