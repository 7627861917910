import React from 'react';

import { PageContainer } from '../../components/containers/PageContainer';
import { Footer } from '../../components/layouts/Footer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { Header } from '../../components/layouts/HeaderComponent';
import { SolutionBlock } from '../../components/V2/SolutionBlock/SolutionBlock';
import { Accordion } from '../../components/V2/Accordion/Accordion';
import { ArticleBlock } from '../../components/V2/ArticleBlock/ArticleBlock';
import { ResourceDownloads } from '../../components/V2/ResourceDownloads/ResourceDownloads';
import { HighlightBlock } from '../../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { IconGrid } from '../../components/V2/IconGrid/IconGrid';
import { SimpleTitle } from '../../components/V2/SimpleTitle/SimpleTitle';
import { BoxImageGrid } from '../../components/V2/BoxImageGrid/BoxImageGrid';
import { FeaturedNavigation } from '../../components/V2/FeaturedNavigation/FeaturedNavigation';

import { BackgroundGrey } from './../../GlobalStyles.jsx';
import { ImageGrid } from '../../components/V2/ImageGrid/ImageGrid';

const VideoSchema = {
  '@context': 'https://schema.org',
  '@type': 'VideoObject',
  name: '3DXpert Overview Video',
  description:
    'Prepare, optimize, and 3D print high-quality parts in record time with an all-in-one, integrated 3D additive manufacturing software that streamlines workflow, from design to printing.',
  thumbnailUrl: 'https://play.vidyard.com/zr6tjyFj3r6DN2BCtJdTYf.jpg',
  uploadDate: '2020-08-05',
  duration: 'PT2M35S',
  contentUrl: 'https://share.vidyard.com/watch/zr6tjyFj3r6DN2BCtJdTYf?',
  embedUrl: 'https://secure.vidyard.com/organizations/280568/embed_select/zr6tjyFj3r6DN2BCtJdTYf'
};

const JsonVideo = JSON.stringify(VideoSchema);

const ThreeDXpert = () => {
  const Lang = 'de';
  const data = require(`../../../data/content/Pages/products/threedxpert/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);
  const vertical = 'NewForm-3dxpert';

  return (
    <PageContainer lang={Lang}>
      <HelmetContainer
        image="/assets/images/pages/3DXpert/hero.png"
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.description}
        json={JsonVideo}
        lang={Lang}
        currentPage={'3dxpert'}
      />

      <Header lang={Lang} demoVertical={vertical} />

      <LargeHero
        Country={Lang}
        AddSalesTel={true}
        BackgroundStyle={'image'}
        BackgroundColour={'off-white'}
        Logo={'3dxpert'}
        Copy={data.content.hero.copy}
        Background={'/assets/images/pages/3DXpert/3dxpert-hero.webp'}
        Button={{
          ModelTitle: globalCopy.Buttons.RequestFreeTrial,
          ModelVertical: vertical,
          theme: '3dxpert',
          copy: globalCopy.Buttons.RequestFreeTrial,
          OpenDemoModel: true
        }}
      />
      <FeaturedNavigation
        Theme={'3dxpert'}
        Title={data.content.FeaturedNavigation.Title}
        NavItems={[
          {
            Title: data.content.FeaturedNavigation.Nav[0],
            Link: '#overview'
          },
          {
            Title: data.content.FeaturedNavigation.Nav[1],
            Link: '#industries'
          },
          {
            Title: data.content.FeaturedNavigation.Nav[2],
            Link: '#toolkit'
          },
          {
            Title: data.content.FeaturedNavigation.Nav[3],
            Link: '#resources'
          }
        ]}
        Copy={data.content.FeaturedNavigation.Copy}
        Image={{
          link: '/assets/images/pages/3DXpert/3dxpert-secondary.webp',
          alt: data.content.FeaturedNavigation.ImageAlt
        }}
      />

      <BackgroundGrey>
        <SimpleTitle id={'overview'} Title={data.content.SimpleTitle.Title} />

        <SplitColumn
          ImagePosition={'Right'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SplitCol1.Copy}
          TextSize={'S'}
          MediaType={'Video'}
          Background={'Transparent'}
          Video={'https://www.youtube.com/watch?v=Pqdqs2sPI14'}
        />
        <SplitColumn
          ImagePosition={'Left'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SplitCol2.Copy}
          TextSize={'S'}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/3DXpert/part-performance.webp',
            alt: ''
          }}
        />
        <SplitColumn
          ImagePosition={'Right'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SplitCol3.Copy}
          TextSize={'S'}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/3DXpert/minimzing-costs.webp',
            alt: ''
          }}
        />
        <SplitColumn
          ImagePosition={'Left'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SplitCol4.Copy}
          TextSize={'S'}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/3DXpert/thermal-simulation.webp',
            alt: ''
          }}
        />
      </BackgroundGrey>

      <IconGrid
        id={'industries'}
        Theme={'light'}
        Title={data.content.IconGrid.Title}
        Subtitle={data.content.IconGrid.Subtitle}
        Items={data.content.IconGrid.Items}
      />

      <BackgroundGrey>
        <BoxImageGrid
          id={'toolkit'}
          Title={data.content.BoxImageGrid.Title}
          Subtitle={data.content.BoxImageGrid.Subtitle}
          Items={data.content.BoxImageGrid.Items}
        />

        <ImageGrid
          Title={data.content.ImageGrid.Title}
          Copy={data.content.ImageGrid.Copy}
          Items={data.content.ImageGrid.Items}
        />

        <HighlightBlock
          Theme={'3dxpert'}
          Title={data.content.HighlightBlock.Title}
          Button={{
            ModelTitle: globalCopy.Buttons.RequestFreeTrial,
            ModelVertical: vertical,
            copy: globalCopy.Buttons.RequestFreeTrial,
            OpenDemoModel: true
          }}
          Image={{
            className: 'top-right',
            title: '',
            alt: '',
            url: '/assets/images/pages/3DXpert/highlight-block-visual.webp'
          }}
        />

        <ResourceDownloads
          id={'resources'}
          Title={data.content.ResourceDownloads.Title}
          Items={data.content.ResourceDownloads.Items}
        />
      </BackgroundGrey>

      <ArticleBlock
        Title={"Don't take our word for it! Have a look at how successful our customers are:"}
        Layout={'layout-3'}
        Link={{
          copy: 'All Customer Stories',
          link: '/news/?&category=Customer-Story'
        }}
        MostRecentBlogs={true}
        Topic={'3dxpert-customer-story'}
      />

      <BackgroundGrey>
        <Accordion Title={data.content.Accordion.title} Items={data.content.Accordion.Items} />
      </BackgroundGrey>

      <SolutionBlock
        Title={data.content.SolutionBlock.Title}
        Theme={'dark'}
        Items={[
          {
            logo: 'manufacturing-os',
            title: data.content.SolutionBlock.Items[0],
            link: globalCopy.Navigation.ProductURLs.manufacturingOs
          },
          {
            logo: 'geomagic-designx',
            title: data.content.SolutionBlock.Items[1],
            link: globalCopy.Navigation.ProductURLs.geoDesignX
          },
          {
            logo: 'geomagic-controlx',
            title: data.content.SolutionBlock.Items[2],
            link: globalCopy.Navigation.ProductURLs.geoControlX
          }
        ]}
      />

      <Footer lang={'de'} />
    </PageContainer>
  );
};

export default ThreeDXpert;
