import styled from 'styled-components';
import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const GridWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 48px 0 0;
    
`;
export const Item = styled.div`

    padding: 24px;
    border: 1.5px solid #EBE8E5;
    box-shadow: 0px 4px 16px rgba(233, 230, 226, 0.3);
    border-radius: 4px;

    width: 100%;
    margin-bottom: 16px;

    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 1.25;
        letter-spacing: 0.01em;
        color: ${colors.primary};
        margin-bottom: 16px;
    }

    div.copy {
        font-family: centrano2-book;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.6;
        letter-spacing: 0.01em;
        color: #59747A;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 32px;

        margin-bottom: 24px;
        width: calc(50% - 12px);

        h3 {
            font-size: 24px;
        }
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        padding: 56px;

        &.item-0,
        &.item-1 {
            margin-bottom: 24px;
            width: calc(50% - 12px);
        }

        &.item-2 {
            width: calc(36% - 12px);
        }

        &.item-3 {
            width: calc(64% - 12px);
        }

        h3 {
            font-size: 32px;
        }

        div.copy {
            font-size: 18px;
        }
    }


`;
export const GridStyles = styled.div`
    padding: 64px 0;

    h2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 1.2;
        letter-spacing: 0.01em;
        color: ${colors.primary};
    }

    hr.divider.teal {
        margin: 24px 0;
    }

    p {
        font-family: centrano2-book;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        letter-spacing: 0.02em;
        color: #59747A;
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
    
        h2{
            font-size: 42px;
        }
        p{
            font-size: 18px;
        }
        
    }
`;