import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients} from "../../../../GlobalStyles";

export const HighlightBlockStyles = styled.div`
  padding: 64px 0;
  
  &.width-Full{
    padding: 0;
    .container{
      margin: 0;
      padding: 0;
      max-width: none;
      width: 100%;
    }
  }
`;

export const Inner = styled.div`
  position: relative;
  min-height: 350px;
  border-radius: 4px;
  padding: 32px;
  display: flex;
  align-items: center;
  z-index: 1;

  &.with-description {
    display: block;

    .copy-wrap {
      text-align: left;

      h1, h2, h3, h4, h5, h6, p {
        text-align: left;
      }
    }

    .additional-description {
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: 0.02em;
      text-align: left;
      color: rgba(255, 255, 255, 0.85);
    }
  }

  &.theme-Primary {
    background: ${colors.primary};
  }

  &.theme-Dark-Primary {
    background: ${bgGradients.dark};
  }

  &.theme-Tertiary {
    background: ${colors.light};
  }

  &.theme-Light-Tertiary {
    background: ${colors.light2};
  }

  &.theme-Primary-Gradient {
    background: ${bgGradients.primary};
  }
  
  &.theme-3dxpert {
    background: ${bgGradients.threeDXpert};
  }

  &.theme-Accent-Red {
    background: ${colors.highlight};
  }

  &.theme-Accent-Teal {
    background: ${colors.teal};
  }

  &.theme-Accent-Red-Gradient {
    background: ${bgGradients.highlight};
  }

  &.theme-Light-Gradient {
    background: ${bgGradients.light};
  }

  &.theme-Light {
    background: #F8F7F6;
  }


  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: flex;
    align-items: center;
    padding: 64px 32px;

  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 64px;

    &.with-description {
      display: flex;

      .copy-wrap {
        min-width: 300px;
        margin-right: 50px;
      }
    }

  }
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    padding: 64px 72px;
  }



`;

export const CopyWrap = styled.div`

  padding: 32px 0;
  
  width: 100%;
  text-align: center;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 42px 0;
  }

  h3 {
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 300;
    color: white;
    text-wrap: balance;
    b {
      font-weight: 700;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 32px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 40px;
    }
  }

  p {
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    color: ${colors.light2};

    b {
      font-weight: 700;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 18px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 24px;
    }
  }


  button, a {
    margin-top: 16px;
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin-top: 32px;
    }
  }
  
  &.theme-Light{
    h3,p{
      color:${colors.primary};
    }
  }
  
`;
