import styled from 'styled-components';

import {colors,mediaQueriesSizes} from "../../../GlobalStyles";

export const IntroStyles = styled.div`

  .shadow {
    position: relative;

    &:before {
      width: 100%;
      content: "";
      display: block;

      transform: scale3d(0.68,1.1,1) rotateX(180deg);
      -webkit-box-shadow: 0px -50px 125px 290px rgba(0,222,255,0.15);
      -moz-box-shadow: 0px -50px 125px 290px rgba(0,222,255,0.15);
      box-shadow: 0px -50px 125px 290px rgba(0,222,255,0.15);

    }

    &:after {
      width: 100%;
      content: "";
      display: block;
      transform: scale3d(0.78, 1.1, 1);
      -webkit-box-shadow: 0px 20px 100px 150px rgba(0, 222, 255, 0.15);
      -moz-box-shadow: 0px 20px 100px 150px rgba(0, 222, 255, 0.15);
      box-shadow: 0px 20px 100px 150px rgba(0, 222, 255, 0.15);
    }
  }

  .wrap {
    &:before{
      content: "";
      display: block;
      width:calc(100% - 30px);
      left: 15px;
      height: 2px;
      border-radius: 2px;
      @media (min-width: ${mediaQueriesSizes.md}px) {
      height: 1px;
      left: 50px;
      width:calc(100% - 100px);
      }
      margin: 0 auto;
      background: white;
      position: absolute;
      top:0;
    }
    //border-top: solid 1px white;
    padding-top: 30px;
    padding-bottom: 40px;
    position: relative;
    z-index: 10;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      padding-top: 80px;
      padding-bottom: 100px;
    }

  }

  h2 {
    font-size: 30px;
    line-height: 1.14;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    max-width: 900px;
    margin: 0 auto 46px;

    span {
      color: ${colors.teal};
    }

    @media (min-width: ${mediaQueriesSizes.sm}px) {
      font-size: 38px;
    }
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 42px;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      font-size: 48px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 56px;
    }
  }

  p {
    max-width: 980px;
    margin: 0 auto;
    font-family: centrano2-book;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.85);

    @media (min-width: ${mediaQueriesSizes.sm}px) {
      font-size: 28px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 32px;
    }


  }

`;