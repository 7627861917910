import React from 'react';

import DownloadTemplate from '../../../templates/DownloadTemplateG4SW';

const DownloadTemplateG4SW = () => {
  const Lang = 'pt-br';

  return <></>;
};

export default DownloadTemplateG4SW;
