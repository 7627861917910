import React from 'react';
import { CardProps } from '../../subComponents/Card';
import { CustomerName, Label, SmallText } from './styles';
import { createMarkUp } from '../../V2/helpers';

export type CustomCardContentProps = CardProps & {
  label?: React.ReactNode;
  smallText: React.ReactNode;
  customerName?: React.ReactNode;
};

export const CardContent = ({ label, smallText, customerName }: CustomCardContentProps) => {
  return (
    <React.Fragment>
      {label && <Label>{label}</Label>}
      {smallText && <SmallText dangerouslySetInnerHTML={createMarkUp(smallText)}></SmallText>}
      {customerName && <CustomerName>{customerName}</CustomerName>}
    </React.Fragment>
  );
};
