import React from 'react';

import AerospaceTemplate from './../../templates/AerospaceTemplate';

const Havacilik = () => {
  const Lang = 'tr';

  return <AerospaceTemplate Lang={Lang} />;
};

export default Havacilik;
