import React from 'react';
import { spacing } from '../../foundations';
import { Card, CardProps } from '../../subComponents/Card';
import { Icon } from '../../subComponents/Icon';
import { Link } from '../../subComponents/Link';
import { createMarkUp } from '../../V2/helpers';
import { CardContent, CustomCardContentProps } from './CardContent';
import { Button, ButtonIcon, ButtonText, CardContainer, Container, Description, Title, ButtonLink } from './styles';

interface CustomerStorySpotlightProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  buttonText?: React.ReactNode;
  cards: Array<CardProps & CustomCardContentProps>;
}

export const CustomerStorySpotlight = ({ title, description, buttonText, cards }: CustomerStorySpotlightProps) => {
  return (
    <Container>
      <Title dangerouslySetInnerHTML={createMarkUp(title)}></Title>
      {description && <Description>{description}</Description>}
      {buttonText && (
        <Button>
          <ButtonLink href="/news/?&category=Customer-Story">
            <ButtonText>{buttonText}</ButtonText>
            <ButtonIcon ml={`${spacing.s}px`}>
              <Icon name="Arrow" />
            </ButtonIcon>
          </ButtonLink>
        </Button>
      )}
      <CardContainer>
        {cards.map((card, index) => {
          if (!card.url) return <Card key={index} {...card} content={<CardContent {...card} />} />;
          return (
            <Link link={card.url}>
              <Card key={index} {...card} content={<CardContent {...card} />} />
            </Link>
          );
        })}
      </CardContainer>
    </Container>
  );
};
