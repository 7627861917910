import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../../GlobalStyles";

export const ProductLink = styled.a`
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2) !important;
  background: white !important;
  padding: 12px !important;
  border-radius: 12px !important;
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 20px !important;
  }

  .Component-ProductLogo {
    display: flex;

    img {
      max-height: 24px;
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        max-height: 32px;
      }
    }
  }
`;

export const Styles = styled.div`
  padding: 24px 0;
  h3 {
    margin-bottom: 32px;
    font-family: centrano2-medium;
    font-weight: 400;
    font-size: 18px;
    color: ${colors.primary};
  }

  a,div.tag {
    display: inline-block;
    padding: 7px 15px;
    margin-bottom: 16px;
    font-size: 16px;
    background: ${colors.UILightStackSecondary};
    font-family: centrano2-book;
    border-radius: 4px;
    margin-right: 34px;
    text-underline-position: under;
    position: relative;
    bottom: 2px;
    color: ${colors.primary};
  }

  .product-links{
    margin-top: 64px;
  }
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 60px 0;

    h3 {
      margin-bottom: 56px;
      font-size: 22px;
    }

    a{
      padding: 15px;
      margin-right: 34px;
      font-size: 18px;
    }

  }

`;
