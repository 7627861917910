import React from 'react';

import { Main } from '../styles/background';

import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { ThankYou } from '../components/layouts/ThankYou';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

const AdditiveDownloadThankYou = () => {
  return (
    <PageContainer>
      <HelmetContainer title="Thank you" />
      <Main noPadding>
        <Header />
        <Content>
          <ThankYou
            customText="Thank you for requesting the brochure!"
            attachment="/assets/verticals/dental/oqton-additive-slm-brochure.pdf"
          />
        </Content>
      </Main>
      <Footer />
    </PageContainer>
  );
};

export default AdditiveDownloadThankYou;
