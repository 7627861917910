import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { GalleryStyles } from './styles.jsx';
import { createMarkUp } from '../helpers';

interface Props {
  Title?: string;
  Images: object;
}

export const Gallery = ({ Title = '', Images = [], ...props }: Props) => {
  return (
    <GalleryStyles className={'Component-Gallery'} {...props}>
      <GlobalStyle />
      <div className={'container'}>
        <h2>{Title}</h2>
        <hr className={'divider teal'} />
        <div className={'img-grid'}>
          {Images.map((img, key) => {
            return (
              <div className={'grid-item'} key={key}>
                <div className={'img-outer'}>
                  <img src={img.src} alt={''} />
                </div>
                <p dangerouslySetInnerHTML={createMarkUp(img.caption)}></p>
              </div>
            );
          })}
        </div>
      </div>
    </GalleryStyles>
  );
};
