import React from 'react';
import GeomagicDesignXPlansTemplate from '../../../templates/GDXPlansPage';

const GeomagicDesignXPlans = () => {
  const Lang = 'pt-br';

  return <GeomagicDesignXPlansTemplate Lang={Lang} />;
};

export default GeomagicDesignXPlans;
