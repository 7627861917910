export const media = {
  xxxs: 0,
  xxs: 320,
  xs: 420,
  sm: 480,
  'sm+': 600,
  md: 768,
  lg: 960,
  'lg+': 1024,
  xl: 1130,
  xxl: 1200,
  'xxl+': 1400,
  'xxl++': 1520,
  'xxl+++': 1800,
  xxxl: 2200
};
