import React from 'react';

import { Resource } from './Resource';
import { Container, ContentContainer, Description, ResourcesContainer, Title } from './styles';

export interface ResourceProps {
  title: string;
  label: string;
  withBackground?: boolean;
  link?: string;
}

interface ResourcesProps {
  title: string;
  description?: string;
  resources: ResourceProps[];
}

export const Resources = ({ title, description, resources }: ResourcesProps) => {
  return (
    <Container>
      <ContentContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ContentContainer>
      <ResourcesContainer>
        {resources.map((resource, index) => (
          <Resource {...resource} key={index} />
        ))}
      </ResourcesContainer>
    </Container>
  );
};
