import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import {
  Color,
  getColor,
  FontSize,
  FontWeight,
  getFontWeight,
  getFontSize,
  newLetterSpacing as letterSpacing,
  getColorRD,
  ColorRD
} from '../foundations';

export type LinkProps = {
  color?: Color | ColorRD;
  size?: FontSize;
  weight?: FontWeight;
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void;
  className?: string;
  target?: string;
  link?: string;
  isHref?: boolean;
  text?: string;
  children?: any;
  label?: string;
  id?: string;
  redesign?: boolean;
};

export const StyledLink = styled.div<Partial<LinkProps>>`
  display: inline-block;
  a {
    color: ${props => (props.redesign ? getColorRD(props.color as ColorRD) : getColor(props.color as Color))};
    font-size: ${props => getFontSize(props.size)};
    font-weight: ${props => getFontWeight(props.weight)};
    letter-spacing: ${letterSpacing.xxl};
    text-decoration: none;
  }
`;

const RegExpNormalLink = /^(https|http|#)/;
const isInternalPageLink = (link: string) => {
  if (RegExpNormalLink.test(link)) {
    return false;
  }
  return true;
};

export const Link = ({
  color,
  redesign,
  size,
  weight,
  link,
  isHref,
  children,
  onClick = () => {},
  className = '',
  target = '_self',
  text,
  id,
  label
}: LinkProps) => {
  if (!link) return text || children;

  let finalLink = (
    <a href={link} onClick={onClick} target={target} aria-label={label}>
      {text || children}
    </a>
  );

  if (isInternalPageLink(link) && !isHref) {
    finalLink = (
      <GatsbyLink to={link} onClick={onClick} target={target} aria-label={label}>
        {text || children}{' '}
      </GatsbyLink>
    );
  }
  return (
    <StyledLink
      id={id}
      color={color}
      size={size}
      weight={weight}
      className={className}
      link={link}
      aria-label={label}
      redesign={redesign}
    >
      {finalLink}
    </StyledLink>
  );
};
