import styled from 'styled-components';
import { colors, spacing, typography } from '../../foundations';

export const ContentContainer = styled.article`
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'img title' 'img text' 'img links';

  align-items: center;
  justify-content: center;

  margin-top: 180px;
  padding-bottom: 200px;
`;

export const Title = styled.h1`
  grid-area: title;

  color: ${colors.secondary};

  font-size: ${typography.fontSizes.xxxxl}px;
  font-weight: ${typography.fontWeights.normal};
  line-height: 1;
`;

export const Text = styled.p`
  grid-area: text;

  margin-top: ${spacing.xxl}px;

  color: ${colors.tertiary};
`;

export const Links = styled.ul`
  grid-area: links;

  display: flex;
  flex-direction: column;

  margin-top: ${spacing.xl}px;

  transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

  a {
    color: ${colors.secondary};

    font-size: ${typography.fontSizes.l}px;
    font-weight: ${typography.fontWeights.medium};

    svg {
      color: ${colors.quaternaryAccent};
    }

    &:hover {
      color: ${colors.quaternaryAccent};

      svg {
        color: ${colors.secondary};
      }
    }
  }
`;

export const ImageContainer = styled.div`
  grid-area: img;

  margin-right: 100px;
`;
