import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../../GlobalStyles";

export const Styles = styled.div`
  margin: 32px 0;
  border-top: solid 1px ${colors.UILightBordersSecondary};
  border-bottom: solid 1px ${colors.UILightBordersSecondary};

  @media (min-width: ${mediaQueriesSizes.md}px) {
    text-align: right;
  }

  hr {
    background: ${colors.UILightBordersSecondary};
    border: none;
    margin: 0;
  }

  h3 {
    cursor: pointer;
    font-family: centrano2-medium;
    font-weight: 400;
    font-size: 22px;
    color: ${colors.primary};
    margin: 16px 0;
    display: inline-flex;
    align-items: center;

    img {
      height: 10px;
      margin-left: 8px;
      transform: rotate(90deg);
    }

    &.active {
      margin-bottom: 0px;

      img {
        transform: rotate(0deg);
      }
    }
  }

  ul {
    padding: 16px 0;
  }

  a {
    font-family: centrano2-book;
    text-decoration: none;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 16px;
    display: inline-block;
    color: ${colors.UILightSecondary};
  }
`;
