import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { CapterraScoreStyles, Inner } from './styles.jsx';
import { createMarkUp } from '../helpers';

interface Props {
  Image: object;
  Review?: string;
  Reviewer?: string;
}

export const CapterraScore = ({ Image, Review, Reviewer, ...props }: Props) => {
  return (
    <CapterraScoreStyles className={'Component-CapterraScore'} {...props}>
      <div className={'container'}>
        <Inner>
          <img alt={''} src={Image} />
          <div className={'text-wrap'}>
            <h3 dangerouslySetInnerHTML={createMarkUp(Review)}></h3>
            <h6 dangerouslySetInnerHTML={createMarkUp(Reviewer)}></h6>
          </div>
        </Inner>
      </div>
    </CapterraScoreStyles>
  );
};
