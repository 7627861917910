import React, { useEffect, useState } from 'react';

import { ArticleLayout3, Category } from './styles';
import { createMarkUp } from '../../helpers';
import BlogCategories from '../../../../../data/blog-categories.json';

interface Props {
  Theme: string;
  Key?: number;
  ItemData: object;
}

export const SingleArticle = ({ Theme, Key, ItemData, ...props }: Props) => {
  const [mainCategory, setMainCategory] = useState(undefined);

  let target = '_self';

  const FormatDate = inputDate => {
    const [datePart, timePart] = inputDate.split(' ');
    const [month, day, year] = datePart.split('/');
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = monthNames[Number(month) - 1];
    const formattedDate = `${monthName} ${Number(day)}, ${year}`;
    return formattedDate;
  };

  if (ItemData.node) {
    ItemData['title'] = ItemData.node.title;
    ItemData['image'] = ItemData.node.featuredImage;
    ItemData['readTime'] = ItemData.node.readtime;
    ItemData['categories'] = ItemData.node.categories;
    ItemData['publishedAt'] = FormatDate(ItemData.node.publishedAt);

    if (ItemData.node.externalLink) {
      target = '_blank';
    }
  }

  if (ItemData['image'] === undefined) {
    ItemData['image'] = ItemData.featuredImage;
  }

  if (ItemData.slug) {
    ItemData['link'] = '/posts/' + ItemData.slug;
  } else if (ItemData.node && ItemData.node.link) {
    ItemData['link'] = ItemData.node.link;
  } else if (ItemData.node && ItemData.node.slug !== undefined) {
    ItemData['link'] = '/posts/' + ItemData.node.slug;
  }

  if (ItemData['image'] === undefined || ItemData['image'] === 'undefined') {
    ItemData['image'] = '/assets/images/news/webinar-placeholder.png';
  }

  if (ItemData.node && ItemData.node.lang && ItemData.node.lang === 'zh-cn') {
    ItemData['link'] = ItemData.node.link;
  }

  if (ItemData['readTime'] === undefined) {
    ItemData['readTime'] = ItemData.readtime;
  }

  const convertStringToTitleCase = str => {
    // Remove dashes and replace with spaces
    str = str.replace(/-/g, ' ');

    // Convert to title case
    str = str
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');

    return str;
  };

  const getPrimaryCateogory = postCategories => {
    const PrimaryCategories = BlogCategories.categories;
    const matchingSlugs = postCategories.filter(item => PrimaryCategories.includes(item.slug)).map(item => item.slug);

    if (matchingSlugs.length > 0) {
      return convertStringToTitleCase(matchingSlugs[0]);
    } else {
      return undefined;
    }
  };

  if (ItemData.categories) {
    ItemData.category = getPrimaryCateogory(ItemData.categories);
  }

  if (ItemData.link.endsWith('//')) {
    ItemData.link = ItemData.link.slice(0, -1);
  }
  if (!ItemData.link.endsWith('/')) {
    ItemData.link = ItemData.link + '/';
  }

  return (
    <ArticleLayout3
      className={'SingleArticleTile theme-' + Theme + ' key-' + Key}
      href={ItemData.link}
      target={target}
      key={Key}
    >
      <div className={'bgImg'} style={{ backgroundImage: 'url(' + ItemData.image + ')' }}></div>
      <div className={'text-wrap'}>
        <h4 dangerouslySetInnerHTML={createMarkUp(ItemData.title)}></h4>
        <Category className={'category-wrap'}>
          {ItemData.category && <span className={'category'}>{ItemData.category}</span>}
        </Category>
        <Category className={'date-wrap'}>
          {ItemData.publishedAt && <span className={'date'}>{ItemData.publishedAt}</span>}
        </Category>
      </div>
    </ArticleLayout3>
  );
};
