import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients} from "../../../GlobalStyles";

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
     @media (min-width: ${mediaQueriesSizes.md}px) {
       grid-template-columns: repeat(2, 1fr);
     }
     @media (min-width: ${mediaQueriesSizes.lg}px) {
       grid-template-columns: repeat(3, 1fr);
     }
     @media (min-width: ${mediaQueriesSizes.xl}px) {
       grid-template-columns: repeat(4, 1fr);
     }

`;

export const LinkedInListStyles = styled.div`
  margin: 64px 0;

  h3 {
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.8;
    color: ${colors.primary};
    margin-bottom: 16px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 30px;
    }
  }

  p {
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: ${colors.primary};
    margin-bottom: 64px;
  }

`;


export const Profile = styled.a`
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
  padding-right: 16px;
  margin-bottom: 32px;
  text-decoration: none;
  position: relative;
  &:last-of-type {
    &:after {
      display: none;
    }
  }

  img {
    border-radius: 50%;
    width: 60px;
    margin-right: 16px;
    position: relative;
    background-color: ${colors.primary};
    &.linkedin {
      position: absolute;
      width: 26px;
      background-color: ${colors.offWhite};;
      border-radius: 4px;
      bottom: 0;
      left: 42px;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.4;
    color: #59747A;
    margin: 0;
    font-family: centrano2-medium;

    span {
      font-family: centrano2-book;
      display: block;
      font-weight: 400;
    }
  }

    // @media (min-width: ${mediaQueriesSizes.md}px) {
  //   &:after {
  //     content: "";
  //     display: block;
  //     position: absolute;
  //     width: 1px;
  //     right: 0;
  //     height: 80%;
    //     background: ${colors.UILightBordersSecondary};
  //   }
  // }


`;
