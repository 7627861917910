import React, { useState } from 'react';

import { spacing } from '../../foundations';
import { VideoPlayerWrapper } from '../Videos';
import { Icon } from '../../subComponents/Icon';
import { CardProps } from '../../subComponents/Card';

import { createMarkUp } from '../../V2/helpers';
import { CardTitle, Text, Link, ButtonIcon, ButtonText } from './FeaturesStyles';

export type ContentProps = CardProps & {
  heading: string;
  description: React.ReactNode;
  showButton?: React.ReactNode;
  url?: string;
  buttonCopy?: string;
};

export const CardContent = ({ heading, description, showButton, url, buttonCopy }: ContentProps) => {
  const [openModal, setOpenModal] = useState(false);

  if (buttonCopy === undefined) {
    buttonCopy = 'Watch video';
  }

  return (
    <React.Fragment>
      <CardTitle>{heading}</CardTitle>
      <Text dangerouslySetInnerHTML={createMarkUp(description)}></Text>
      {showButton && (
        <Link onClick={() => setOpenModal(true)}>
          <ButtonText>{buttonCopy}</ButtonText>
          <ButtonIcon ml={`${spacing.xs}px`}>
            <Icon name="ArrowRight" width={20} height={20} />
          </ButtonIcon>
        </Link>
      )}
      {url && (
        <VideoPlayerWrapper
          shown={openModal}
          handleClose={() => {
            setOpenModal(false);
          }}
          url={url}
          title={heading}
          vidyard
        />
      )}
    </React.Fragment>
  );
};
