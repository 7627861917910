import styled from 'styled-components';
import {colors,mediaQueriesSizes} from "../../../GlobalStyles";

export const CopyWrapper = styled.div`
  text-align: center;
    h1 {
    font-size: 32px;
    font-weight: 300;
    b {
      font-weight: 700;
    }

    line-height: 1.1;
    color: ${colors.primary};
  }

  h2 {
    color: ${colors.primary};
    font-family: centrano2-book;
    letter-spacing: 0.01em;
    font-size: 20px;
    margin-top: 6px;
      text-align: center;
    b{
      font-family: centrano2-medium;
      font-weight: normal;
    }
  }

  h3{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.25;
    color: ${colors.primary};
    a{
      text-decoration: none;
      color: ${colors.teal};
    }
  }
  
  h4{
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.45;
    color: ${colors.tertiary};
  }
  
  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.1;
    margin: 16px 0 32px;
  }

  hr{
    width: 64px;
    border-color: ${colors.teal};
    border-width: 2px;
    margin: 32px 0;
  }
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    h1 {
      font-size: 46px;
    }

    h2 {
      font-size: 36px;
    }

    h3{
      font-size: 25px;
    }
    p {
      font-size: 18px;
      line-height: 1.3;
    }
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    h1 {
      font-size: 54px;
    }

    h2 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    h1 {
      font-size: 56px;
    }
    
    h2{
      font-size: 36px;
    }

    p {
      font-size: 16px;
      &.large{
        font-size: 24px;
      }
    }

      @media (min-height: 700px) {
          h1 {
              font-size: 72px;
          }
      }

  &.theme-manufacturing-os{
    hr{
      border-color: ${colors.solidMOS};
    }
  }
  &.theme-3dxpert{
    hr{
      border-color: ${colors.solid3DXpert};
    }
  }
  &.theme-amphyon{
    hr{
      border-color: ${colors.solidAmphyon};
    }
  }
  &.theme-geomagic-designx{
    hr{
      border-color: ${colors.solidGeomagicDesignx};
    }
  }
  &.theme-geomagic-controlx{
    hr{
      border-color: ${colors.solidGeomagicControlx};
    }
  }
  &.theme-freeform{
    hr{
      border-color: ${colors.solidFreeform};
    }
  }
  &.theme-geomagic-wrap{
    hr{
      border-color: ${colors.solidWrap};
    }
  }
  &.theme-geomagic-for-solidworks{
    hr{
      border-color: ${colors.solidGeomagicForSolidWorks};
    }
  }
  &.theme-d2p{
    hr{
      border-color: ${colors.solidD2P};
    }
  }
  
`;

export const CardWrapper = styled.div`
    width: 100%;
    position: absolute;
    bottom:0;
    
    .inner {
        width: 100%;
        margin: 0 auto;
        display: flex;
        gap: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 15px;

        a {
            display: block;
            transition: 0.35s;

            img {
                box-shadow: 0px 6.22092px 24px rgba(36, 65, 88, 0.2);
                transition: 0.35s;
                height: 190px;
            }
            
        }
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
        .inner {
            width: 100%;
            justify-content: center;
            padding: 0;

            overflow: visible;

            a {
                overflow-y: visible;
                img {
                    overflow-y: visible;
                    box-shadow: 0px 6.22092px 24px rgba(36, 65, 88, 0.2);
                    transition: 0.35s;
                    height: 140px;
                }

            }
        }
    }
    
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        .inner {
            a {
                img {
                    height: 190px;
                }
                &:hover {
                    transform: scale(1.05);
                }
            }
        }

    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        @media (min-height: 700px) {
            bottom: 32px;
            .inner {
                gap: 30px;

                a {
                    img {
                        height: 250px;
                    }
                }
            }
        }
    }



`;
export const LargeHeroStyles = styled.div`
    position: relative;
    padding: 25px 0 32px;
    height: 90vh;
    margin-top: -95px;

    .container {
        padding-top: 30px;
        position: relative;
        height: calc(107vh - 150px - 84px - 32px);
        display: flex;
        align-items: center;
    }

    .background-style-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
        opacity: 0.3;

        &:after {
            position: absolute;
            display: block;
            content: "";
            width: 100%;
            height: 250px;
            background: rgb(255, 255, 255);
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 56%);
        }

    }

    .secondaryLogo {
        width: auto;
        height: auto;
        max-width: 150px;
        max-height: 35px;
    }

    .copy-wrap {
        width: calc(100% - 36px);
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 950px;
        .prod-logo {
            margin-bottom: 32px;
            height: 60px;
        }
    }

    .Component-ProductLogo {
        margin-bottom: 16px;

        img {
            height: 24px;
        }

    }

    .button-wrap {
        margin: 16px auto;
        display: flex;
        justify-content: center;
        a, button {
            margin-bottom: 16px;
            margin-right: 16px;
        }
        p.Component-SalesTel{
            color: ${colors.primary};
        }
    }

    @media (min-width: ${mediaQueriesSizes.sm}px) {
        .button-wrap {
            align-items: center;

            a, button {
                margin-bottom: 0;
            }

        }

    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 64px 0 32px 0;
        .Component-ProductLogo {
            margin-bottom: 32px;

            img {
                height: 40px;
            }
        }
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        
        height: 105vh;
        
        @media (min-height: 700px) {
            min-height: calc(107vh - 95px);

            .container {
                height: calc(107vh - 250px - 84px - 16px);
            }

            }

            .background-style-cover {
            opacity: 1;
        }

        .prod-logo {
            &.right-aligned {
                position: absolute;
                right: 0;
                height: 60px;
                top: calc(50% - 30px);
            }
        }

        .copy-wrap {
            div {
                z-index: 1;
            }
        }

    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {

        .prod-logo {
            &.right-aligned {
                position: absolute;
                right: 80px;
                top: calc(50% - 60px);
                height: 120px;
            }
        }
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        min-height: 107vh;

        .prod-logo {
            &.right-aligned {
                position: absolute;
                right: 120px;

                top: calc(50% - 100px);
                height: 200px;


            }
        }
    }

    &.eventHero {

        align-items: end;
        flex-direction: column;
        display: block;

        .background {
            position: relative;
            background-size: contain;
            background-position: center 30%;
            background-repeat: no-repeat;
            opacity: 0.5;
            aspect-ratio: 1 / 0.4;
            height: auto;

            &:after {
                opacity: 0;
            }
        }

        @media (min-width: ${mediaQueriesSizes.md}px) {
            display: flex;
            flex-direction: row;
            align-items: center;

            .background {
                opacity: 1;
                position: absolute;
            }
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            margin-top: 75px;
            min-height: 65vh;
            .background {
                width: 100%;
                height: 100%;
                background-position: center right;
            }
        }


    }

    &.homepageHero {
        .background {
            background-position: center right;
            opacity: 1;

            &:after {
                display: none;
            }
        }

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            .copy-wrap {
                h1 {
                    margin-bottom: 16px;
                }
            }
            .button-wrap {
                margin-top: 32px;
            }
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            .background {
                background-attachment: fixed;
            }
        }
    }

    &.thankyou-gdx {
        background-image: url(/assets/images/download/gdx-hero-bg.png);
        background-position: 100% 30%;
        background-size: 80%;
        background-repeat: no-repeat;

        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            background-position: 80% 30%;
            background-size: 65%;
        }

        hr {
            border-color: ${colors.red};
        }

        h4 {
            a {
                color: ${colors.red};
            }
        }
    }

    &.thankyou-g4s {
        background-image: url(/assets/images/download/gdx-hero-bg.png);
        background-position: 100% 30%;
        background-size: 80%;
        background-repeat: no-repeat;

        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            background-position: 80% 30%;
            background-size: 65%;
        }

        hr {
            border-color: ${colors.darkRed};
        }

        h4 {
            a {
                color: ${colors.darkRed};
            }
        }

        .secondary-logo {
            display: none !important;
        }

    }

    &.thankyou-gcx {
        background-image: url(/assets/images/download/gcx-hero-bg.png);
        background-position: 100% 30%;
        background-size: 80%;
        background-repeat: no-repeat;

        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            background-position: 80% 30%;
            background-size: 65%;
        }

        hr {
            border-color: ${colors.green};
        }

        h4 {
            a {
                color: ${colors.green};
            }
        }
    }

`;

