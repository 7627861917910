import React from 'react';
import FreeformTemplate from './../../templates/FreeformHealthcare';

const FreeformHealthcare = () => {
  const Lang = 'en';
  const wordpressData = require(`../../../data/wordpress-data/1319.json`);

  return <FreeformTemplate Lang={Lang} wordpressData={wordpressData} />;
};

export default FreeformHealthcare;
