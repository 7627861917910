import React from 'react';
import { Card, CardProps } from '../../subComponents/Card';
import { CardContainer, Container, Title } from './styles';

interface RelatedProps {
  title: string;
  articles: Array<Omit<CardProps, 'image'> & { image: { src: string; alt: string } }>;
  inverted?: boolean;
}

export const Related = ({ title, articles, inverted }: RelatedProps) => {
  return (
    <Container inverted={inverted}>
      <Title>{title}</Title>
      <CardContainer>
        {articles.map(article => (
          <Card {...article} image={{ ...article.image, position: 'top' }} contentProps={{ width: '372px' }} />
        ))}
      </CardContainer>
    </Container>
  );
};
