import styled from 'styled-components';

import { colors, mediaQueriesSizes, bgGradients } from '../../../../GlobalStyles';

export const HighlightBlockStyles = styled.div`
    position: relative;
    z-index: 5;

    &.increased-outer-padding {
        padding: 32px 0;
    }

    &.scaled-right-image {
        @media (min-width: ${mediaQueriesSizes.md}px) {
            img {
                transform: scale(1.2);
                transform-origin: bottom right;
            }
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            img {
                transform: scale(1.25);
                transform-origin: bottom right;
            }
        }
        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            img {
                transform: scale(1.3);
                transform-origin: bottom right;
            }
        }
    }

    &.BlockWidth-full-width {
        &.theme-accent-red,
        &.theme-accent-red-clear {
            background: ${bgGradients.highlight};
        }

        &.theme-accent-green,
        &.theme-accent-green-clear {
            background: ${bgGradients.controlx};
        }

        &.theme-freeform {
            background: ${bgGradients.freeform};
        }

        &.theme-3dxpert {
            background: ${bgGradients.threeDXpert};
        }

        &.theme-geomagicSolidWorks {
            background: ${bgGradients.geomagicSolidWorks};
        }

        &.theme-Primary {
            background: ${bgGradients.dark};
        }

        &.theme-PrimaryGradient {
            background: ${bgGradients.primary};
        }

        &.theme-dark-teal {
            background: ${colors.darkTeal};
        }

        &.theme-manufacturing-os {
            background: ${bgGradients.mos};
        }

        .BlockWidth-full-width {
            padding: 0 !important;
            box-shadow: none !important;
            background: transparent !important;
            background-color: transparent !important;
        }

    }

`;

export const Inner = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  padding: 0 16px;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    &.flex-center {
      align-items: center;
    }
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: flex;
    align-items: flex-end;
    overflow: visible;
    &.flex-center {
      align-items: center;
    }
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 0 64px;
  }

  &.theme-accent-red {
    background: ${bgGradients.highlight};
  }

  &.theme-accent-green {
    background: ${bgGradients.controlx};
  }

  &.theme-freeform {
    background: ${bgGradients.freeform};
  }

  &.theme-3dxpert {
    background: ${bgGradients.threeDXpert};
  }

  &.theme-geomagicSolidWorks {
    background: ${bgGradients.geomagicSolidWorks};
  }

  &.theme-Primary {
    background: ${bgGradients.dark};
  }

  &.theme-PrimaryGradient {
    background: ${bgGradients.primary};
  }

  &.theme-dark-teal {
    background: ${colors.darkTeal};
  }

  &.theme-manufacturing-os {
    background: ${bgGradients.mos};
  }

  &.background-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.background-white {
    background-color: white;
  }

    &.build-quality-highlight{
        .bg-image {
            opacity: 0.3;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                opacity: 1;

            }
        }
    }


    &.energy-turbine {
    .bg-image {
      opacity: 0.7;
      right: 0;
      background-position: 95%;
      transform: scale(1.1);
      transform-origin: bottom right;
      
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        opacity: 1;

      }
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        right: 0;
        background-position: 95%;
        transform: scale(1.1);
        transform-origin: bottom right;
      }
    }
  }

    
`;

export const BackgroundImage = styled.div``;

export const ImgWrapHalfBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: auto 100%;
  background-position: center right;
  z-index: 0;
  background-repeat: no-repeat;
  opacity: 1;
  pointer-events: none;
`;

export const ImgWrapSplit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 400px;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    justify-content: flex-end;
  }
    
  &.polymers-freeform-tech{
      img{
          max-height: 200px;
          margin: 24px;
      }
  }  
`;

export const CopyWrap = styled.div`
    width: 350px;
    max-width: 100%;
    padding: 32px 16px 32px 0;
    position: relative;
    z-index: 1;

    h5 {
        font-family: centrano2-medium;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.09em;
        color: #ffffff;
        font-weight: 400;
        //text-transform: uppercase;
        margin-bottom: 16px;
    }

    h4 {
        color: #ffffff;
        font-family: centrano2-book;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.25;
        margin-bottom: 16px;
    }

    h3 {
        font-weight: 300;
        line-height: 1.2;
        color: #ffffff;
        font-size: 18px;
    }

    p {
        font-family: centrano2-book;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        margin-top: 8px;
        text-wrap: balance;
    }

    &.polymers-freeform-tech {
        h5 {
            color: ${colors.teal};
            font-size: 16px;
        }

        h3 {
            font-size: 22px;
        }

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            h3 {
                font-size: 26px;
            }

        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            padding-right: 0;
            padding-top: 66px;
            padding-bottom: 66px;
            h3{
                font-size: 32px;
            }
        }
    }

    &.haptic-announcement {
        h3 {
            span {
                color: ${colors.teal};
            }
        }
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
        min-width: 350px;
        p {
            font-size: 18px;
            margin-top: 16px;
        }

        h3 {
            font-size: 22px;
            padding-right: 1em;
        }
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 42px 64px 42px 0;
        min-width: 450px;
        p {
            font-size: 20px;
            margin-top: 32px;
        }

        h3 {
            font-size: 36px;
        }

        h4 {
            font-size: 24px;
        }

        &.build-quality-highlight {
            h3 {
                font-size: 28px;
            }

        }

        &.polymers-announcement {
            p {
                font-size: 24px;
            }
        }

        &.haptic-announcement {
            min-width: 350px;
            padding-right: 0;
        }

    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        min-width: 550px;
        padding: 86px 86px 86px 0;
        h5 {
            font-size: 20px;
        }

        h3 {
            font-size: 40px;
        }

        &.build-quality-highlight {
            h3 {
                font-size: 28px;
            }

        }

        &.build-quality-announcement {
            min-width: 700px;
            padding: 70px 0px 70px 0;
        }

        &.haptic-announcement {
            min-width: 600px;
            padding-right: 0;
        }
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        min-width: 750px;
        padding: 120px 86px 120px 0;
        h3 {
            font-size: 40px;
        }

        &.build-quality-highlight {
            h3 {
                font-size: 40px;
            }
        }

    }

    button,
    a {
        margin-top: 16px;
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            margin-top: 32px;
        }
    }

    &.size-extraText {
        @media (min-width: ${mediaQueriesSizes.md}px) {
            width: 400px;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            width: 610px;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            width: 820px;
            padding: 56px 80px 56px 0;
            h3 {
                font-size: 32px;
            }
        }
        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            width: 950px;
        }
    }

    &.theme-teal {
        h5 {
            color: ${colors.teal};
        }
    }

    &.theme-teal-dark {
        h5 {
            color: ${colors.teal};
        }

        h3 {
            color: ${colors.primary};
        }
    }

    &.theme-PrimaryGradient{
        h5{
            color: ${colors.teal};
            font-weight: 600;
            text-transform: uppercase;
        }
    }
    
    &.text-primary {
        h3,
        h4,
        h5 {
            color: ${colors.primary};
        }
    }

    &.theme-dark-teal {
        h5 {
            color: ${colors.white};
        }

        h3 {
            color: ${colors.white};
        }

        p {
            color: ${colors.white};
            font-size: 20px;
            margin-top: 1.2em;
        }
    }

    &.ImageType-Background {
        width: 100%;
    }

    &.homepage-feature {
        width: 100%;
        padding-right: 0;
        position: relative;
        z-index: 15;
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            padding: 60px 86px 60px 0;
        }
        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            padding: 60px 86px 60px 0;
        }
    }

    &.text-limit-width {
        max-width: 835px;
    }


`;

export const ImgWrap = styled.div`
  aspect-ratio: 4 / 3;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    max-width: 300px;
    display: flex;
    align-items: center;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    max-width: 450px;
    justify-content: end;
    margin-right: 16px;
  }

  img {
    width: 100%;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 100%;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      transform: scale(1.15);
      width: auto;
      max-width: 100%;
      max-height: 100%;
      transform-origin: top right;

      //background: yellow;
    }
    // @media (min-width: ${mediaQueriesSizes.xl}px) {
    //   position: absolute;
    //   top: 16px;
    //   right: 16px;
    //   height: 120%;
    //   width: auto;
    //   margin-bottom: 0;
    // }
  }

  //width: auto;
  //height: 100%;
  //max-width: 50%;
  //bottom: 75px;
  //top: auto;
  //right: 0%;

  &.top-right {
    img {
      width: 90%;
      margin: 0 5% -20%;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      img {
        width: auto;
        top: -15%;
        right: 5%;
      }
    }
  }
`;
