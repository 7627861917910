import moment from 'moment';

export const getPageLanguage: any = () => {
  if (typeof window !== 'undefined') {
    const path = window.location.pathname;
    const match = path.match(/^\/(it|de|fr|en|ja|ko)/i);
    if (match) {
      return match[1];
    }
  }
  return 'en';
};

export const removeSquareBrackets = text => {
  return text.replace(/\[[^\]]*\]/g, '');
};

export const sendGAEvent = (category: string, action: string, label: string, value?: string) => {
  if (typeof window !== 'undefined' && window.ga) {
    let gaEvent = {
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    };

    if (value !== undefined) {
      if (value === 'current-url') {
        gaEvent['eventValue'] = window.location.href;
      } else {
        gaEvent['eventValue'] = value;
      }
    }

    window.ga('send', 'event', gaEvent);
  }
};

export const getURLParameter = name => {
  if (typeof window !== 'undefined') {
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  } else {
    return false;
  }
};

export const removeURLParams = () => {
  const currentURL = window.location.toString();

  // Get the position of the '?' character to determine if there are any URL parameters
  const indexOfQuestionMark = currentURL.indexOf('?');

  // If there's a '?' in the URL, then remove all parameters after it
  if (indexOfQuestionMark > -1) {
    // Extract the base URL without any parameters
    const baseURL = currentURL.slice(0, indexOfQuestionMark);

    // Replace the current URL with the base URL (without causing a refresh)
    window.history.replaceState({}, document.title, baseURL);
  }
};

export const createSlug = title => {
  const accentMap = {
    á: 'a',
    à: 'a',
    â: 'a',
    ä: 'a',
    ã: 'a',
    é: 'e',
    è: 'e',
    ê: 'e',
    ë: 'e',
    í: 'i',
    ì: 'i',
    î: 'i',
    ï: 'i',
    ó: 'o',
    ò: 'o',
    ô: 'o',
    ö: 'o',
    õ: 'o',
    ú: 'u',
    ù: 'u',
    û: 'u',
    ü: 'u',
    ñ: 'n',
    ç: 'c'
    // Add more mappings as needed
  };

  let slug = title.toLowerCase();
  slug = slug.replace(/[áàâäãéèêëíìîïóòôöõúùûüñç]/g, match => accentMap[match]);

  // Replace spaces with -
  slug = slug.replace(/\s+/g, '-');

  // Remove special characters and accents
  slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  slug = slug.replace(/[^\w-]+/g, '');

  //Removes any instances where there is a double --
  slug = slug.replace(/-{2,}/g, '-');

  //Removes any trailing -
  slug = slug.replace(/-+$/, '');

  return slug;
};

export const formatDateTimeForGoogle = momentObj => {
  return momentObj.format('YYYYMMDDTHHmmss') + 'Z';
};

export const generateGoogleCalendarURL = event => {
  const startTime = formatDateTimeForGoogle(moment(event.fields.date.start).utc());
  const endTime = formatDateTimeForGoogle(moment(event.fields.date.end).utc());

  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${event.title}&dates=${startTime}/${endTime}&details=${event.description}&location=${event.location.address}&sf=true&output=xml`;
};

export const getProductURL = (product, lang) => {
  let globalCopy = require(`../../data/content/Global/${lang}.json`);

  if (product === 'geomagic-controlx') {
    return globalCopy.Navigation.ProductURLs.geoControlX;
  }
  if (product === 'geomagic-designx') {
    return globalCopy.Navigation.ProductURLs.geoDesignX;
  }
  if (product === 'freeform') {
    return globalCopy.Navigation.ProductURLs.geoFreeeform;
  }
  if (product === 'wrap') {
    return globalCopy.Navigation.ProductURLs.geoWrap;
  }
  if (product === '3dxpert') {
    return globalCopy.Navigation.ProductURLs.Threedxpert;
  }
  if (product === 'amphyon') {
    return globalCopy.Navigation.ProductURLs.amphyon;
  }
  if (product === 'd2p') {
    return globalCopy.Navigation.ProductURLs.d2p;
  }
  if (product === 'geomagic-for-solidworks') {
    return globalCopy.Navigation.ProductURLs.GeomagicForSolidworks;
  }
  if (product === 'manufacturing-os') {
    return globalCopy.Navigation.ProductURLs.manufacturingOs;
  }
};
