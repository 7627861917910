import React from 'react';

import { SkillCard } from '../../layouts/SkillCard/SkillCard';
import { CareersValuesContainer, ValuesTitle, ValuesSub } from './CareersStyles';

export const CareersValues = ({ content }) => {
  return (
    <CareersValuesContainer>
      {content.title && <ValuesTitle>{content.title}</ValuesTitle>}
      <ValuesSub>{content.sub}</ValuesSub>

      {content.cards.card_1 ? (
        <>
          <SkillCard title={content.cards.card_1.title} index={1} text={content.cards.card_1.text} />
          <SkillCard title={content.cards.card_2.title} index={2} text={content.cards.card_2.text} />
          <SkillCard title={content.cards.card_3.title} index={3} text={content.cards.card_3.text} />
          <SkillCard title={content.cards.card_4.title} index={4} text={content.cards.card_4.text} />
          <SkillCard title={content.cards.card_5.title} index={5} text={content.cards.card_5.text} />
        </>
      ) : (
        content.cards.map((card, index) => (
          <SkillCard title={card.title} index={index + 1} text={card.copy} key={index} />
        ))
      )}
    </CareersValuesContainer>
  );
};
