import React from 'react';

import { LocationContainer, City, Country, Address, ImageContainer } from './LocationCardStyles';

interface LocationProps {
  city: string;
  address: string;
  postalCode: string;
  country: string;
  image: React.ReactNode;
  index?: number;
}

export const LocationCard = ({ city, address, postalCode, country, image, index }: LocationProps) => {
  return (
    <LocationContainer index={index}>
      <City>{city}</City>
      <Address>
        {address}
        <br />
        {postalCode}
      </Address>
      <Country>{country}</Country>
      <ImageContainer>{image}</ImageContainer>
    </LocationContainer>
  );
};
