import styled from 'styled-components';

import { colors, mediaQueriesSizes, bgGradients } from '../../../GlobalStyles';

export const HighlightBlockStyles = styled.div`
  position: relative;
  z-index: 5;
`;

export const Inner = styled.div`
  background: ${bgGradients.highlight};
  position: relative;
  border-radius: 10px;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: flex;
  }

  &.theme-accent-green {
    background: ${bgGradients.controlx};
  }

  &.theme-freeform {
    background: ${bgGradients.freeform};
  }

  &.theme-accent-orange {
    background: ${bgGradients.wrap};
  }

  &.theme-3dxpert {
    background: ${bgGradients.threeDXpert};
  }

  &.theme-geomagicSolidWorks {
    background: ${bgGradients.geomagicSolidWorks};
  }

  &.theme-Primary {
    background: ${bgGradients.dark};
  }
`;

export const CopyWrap = styled.div`
  width: 350px;
  max-width: 100%;
  padding: 32px 16px;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 42px;
  }

  h3 {
    font-weight: 500;
    line-height: 1.2;
    color: #ffffff;
    font-size: 18px;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    h3 {
      font-size: 22px;
      padding-right: 1em;
    }
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 42px 64px;
    h3 {
      font-size: 36px;
    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    width: 550px;
    padding: 86px;
    h3 {
      font-size: 40px;
    }
  }
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 750px;
    padding: 120px 86px;
    h3 {
      font-size: 40px;
    }
  }

  button,
  a {
    margin-top: 16px;
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin-top: 32px;
    }
  }

  &.size-extraText {
    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 400px;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      width: 610px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 820px;
      padding: 56px 80px;
      h3 {
        font-size: 32px;
      }
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      width: 950px;
    }
  }
`;

export const ImgWrap = styled.div`
  img {
    width: 100%;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      position: absolute;
      top: 8px;
      right: 8px;
      height: 115%;
      width: auto;
      margin-bottom: 0;
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      position: absolute;
      top: 16px;
      right: 16px;
      height: 115%;
      width: auto;
      margin-bottom: 0;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      position: absolute;
      top: 16px;
      right: 16px;
      height: 120%;
      width: auto;
      margin-bottom: 0;
    }
  }

  //width: auto;
  //height: 100%;
  //max-width: 50%;
  //bottom: 75px;
  //top: auto;
  //right: 0%;

  &.top-right {
    img {
      width: 90%;
      margin: 0 5% -20%;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      img {
        width: auto;
        top: -15%;
        right: 5%;
      }
    }
  }
`;
