import styled from 'styled-components';
import {colors, mediaQueriesSizes, bgGradients} from "../../../GlobalStyles";

export const LinkedInListStyles = styled.div`
  margin: 64px 0;
`;

export const LogoGridStyles = styled.div`
  margin: 64px 0;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    .container {
      display: flex;
      justify-content: space-around;
    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    margin: 112px 0;
  }

`;

export const Inner = styled.div`
  h2 {
    font-weight: 400;

    b {
      font-weight: 600;
    }
  }
  
  p{
    
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 2;
    letter-spacing: 0.01em;
    margin-top: 12px;

    color: ${colors.UILightTertiary}

  }

  div.title-underline {
    margin: 24px 0;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    width: 50%;
    max-width: 450px;
    margin-right: 80px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  width: 100%;
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    grid-row-gap: 32px;
    grid-column-gap: 32px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  max-height: 48px;

  img {
    margin: 0 auto;
    max-height: 90%;
    max-width: 90%;
  }
`;

export const LinkItem = styled.a`
  display: flex;
  align-items: center;
  text-align: center;
  max-height: 64px;
  transition: 0.3s;

  img {
    margin: 0 auto;
    max-height: 90%;
    max-width: 90%;
  }
  
  &:hover{
    opacity: 0.4;
  }
`;

