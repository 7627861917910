import React from 'react';

import DownloadTemplate from '../../../templates/DownloadTemplateCX';

const DownloadGeomagicDX = () => {
  const Lang = 'fr';

  return <DownloadTemplate Lang={Lang} />;
};

export default DownloadGeomagicDX;
