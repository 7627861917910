import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const ComponentStyles = styled.div`

    position: relative;
    z-index: 20;

    .container {
        background: #FFFFFF;
        /* Light Background */
        border: 0.2px solid #F8F7F6;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 32px;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            padding: 42px 60px;
        }

        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 1.3em;
            text-wrap: balance;
            line-height: 1.85;
            letter-spacing: 0.01em;
            color: ${colors.primary};
            @media (min-width: ${mediaQueriesSizes.md}px) {
                font-size: 1.625em;
            }
        }

        hr.divider {
            background: ${colors.solidFreeform};
            margin: 16px 0 24px;
        }

        label {
            textarea, select, input[type="email"], input[type="text"] {
                margin: 0 0 12px;
                padding: 8px 0;
            }
        }
    }

`;