import React from 'react';

import { PageContainer } from '../components/containers/PageContainer';
import { Footer } from '../components/layouts/Footer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { Header } from '../components/layouts/HeaderComponent';
import { BackgroundGrey, BackgroundWhite, BackgroundGDXWave } from '../GlobalStyles';
import { PlanComparisonTable } from '../components/V2024/PlanComparisonTable/PlanComparisonTable';
import { Accordion } from '../components/V2/Accordion/Accordion';
import { MicroHero } from '../components/V2024/MicroHero/MicroHero';
import { GDXHighlightCardTop } from '../components/V2024/GDXHighlightCards/GDXHighlightCardTop';
import { GDXHighlightCardBottom } from '../components/V2024/GDXHighlightCards/GDXHighlightCardBottom';
import { GDXHighlightCard } from '../components/V2024/GDXHighlightCards/GDXHighlightCardMobile';

interface Props {
  Lang?: string;
}

const GeomagicDesignXPlansTemplate = ({ Lang = 'en', ...props }: Props) => {
  const data = require(`../../data/content/Pages/products/geomagic-designx/plans/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const sitemap = require(`../../data/sitemap-data.json`);

  const vertical = 'NewForm-GDX';

  return (
    <>
      <HelmetContainer
        currentPage={'geomagic-designx'}
        image="/assets/share/designx.jpg"
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.pageDescription}
      />

      <PageContainer lang={Lang}>
        <div className={`page-container geomagic-designx-plans lang-${Lang}`}>
          <BackgroundGrey>
            <BackgroundGDXWave>
              <Header lang={Lang} demoVertical={vertical} demoTitle={globalCopy.Buttons.RequestFreeTrial} />

              <MicroHero Product={'geomagic-designx'} Copy={data.content.micro_hero.copy} />

              <div className={'display-md-up'}>
                <GDXHighlightCardTop Items={data.content.micro_hero.cards} />
              </div>
            </BackgroundGDXWave>
            <div className={'display-md-up'}>
              <GDXHighlightCardBottom Items={data.content.micro_hero.cards} />
            </div>
            <div className={'display-md-down'}>
              <GDXHighlightCard Items={data.content.micro_hero.cards} />
            </div>
          </BackgroundGrey>
          <BackgroundWhite>
            <PlanComparisonTable {...data.content.plans} sitemap={sitemap} globalCopy={globalCopy} lang={Lang} />
          </BackgroundWhite>

          <BackgroundGrey>
            <Accordion {...data.content.Accordion} />
          </BackgroundGrey>

          <Footer />
        </div>
      </PageContainer>
    </>
  );
};

export default GeomagicDesignXPlansTemplate;
