import React from 'react';

import { SocialShareStyles } from './styles.jsx';

export const SocialShareButtons = () => {
  const handleFacebookShare = () => {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href,
      'facebook-share-dialog',
      'width=626,height=436'
    );
    return false;
  };

  const handleTwitterShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${window.location.href}`,
      'twitter-share-dialog',
      'width=626,height=436'
    );
    return false;
  };

  const handleLinkedInShare = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
      'linkedIn-share-dialog',
      'width=626,height=436'
    );
    return false;
  };

  return (
    <SocialShareStyles>
      Share:
      <a href="#" onClick={handleFacebookShare}>
        <img src="/assets/icons/facebook.svg" alt="facebook logo" />
        Facebook
      </a>
      <a href="#" onClick={handleTwitterShare}>
        <img src="/assets/icons/twitter.svg" alt="twitter logo" />
        Twitter
      </a>
      <a href="#" onClick={handleLinkedInShare}>
        <img src="/assets/icons/linkedIn.svg" alt="linkedIn logo" />
        Linkedin
      </a>
    </SocialShareStyles>
  );
};
