import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { Vertical } from '../../../../utils/types';
import { ContactModal } from '../../ContactModal/ContactModal';

import { Button, StyledLink } from '../styles';
import { spacing } from '../../../foundations';
import { Icon } from '../../../subComponents/Icon';

const ButtonWrapper = styled.div`
  grid-area: button;
  display: flex;
  gap: ${spacing.m}px;
  button:nth-child(2) {
    margin-right: 0;
    margin-left: auto;
  }
`;

export type ButtonsProps = { text: string; link?: string; vertical?: Vertical; color?: string }[];
interface ButtonsComponentProps {
  buttons?: ButtonsProps;

  buttonLink?: string;
  buttonText: string;

  setBookDemo: Dispatch<SetStateAction<boolean>>;
}

const ButtonsContainer = ({
  buttons,
  setBookDemo,
  buttonLink,
  buttonText,
  setOpenModal
}: ButtonsComponentProps & {
  setOpenModal: Dispatch<SetStateAction<{ vertical: Vertical | undefined; title: string | undefined } | undefined>>;
}) => {
  if (buttons) {
    return (
      <ButtonWrapper>
        {buttons?.map(button => {
          if (button.link) {
            return (
              <StyledLink link={button.link}>
                <Button color={button.color} noArea>
                  {button.text}
                </Button>
              </StyledLink>
            );
          }
          if (button.vertical) {
            return (
              <Button
                color={button.color}
                onClick={() => setOpenModal({ vertical: button.vertical, title: button.text })}
              >
                {button.text}
              </Button>
            );
          }
          return null;
        })}
      </ButtonWrapper>
    );
  }
  if (buttonLink) {
    return (
      <StyledLink link={buttonLink}>
        <Button>{buttonText}</Button>
      </StyledLink>
    );
  }

  return (
    <Button onClick={() => setBookDemo(true)} maxWidth="260px">
      {buttonText}
    </Button>
  );
};

export const Buttons = (props: ButtonsComponentProps) => {
  const [openModal, setOpenModal] = useState<
    { vertical: Vertical | undefined; title: string | undefined } | undefined
  >();

  return (
    <React.Fragment>
      <ButtonsContainer {...props} setOpenModal={setOpenModal} />
      {openModal && (
        <ContactModal
          handleClose={() => setOpenModal(undefined)}
          vertical={openModal.vertical}
          title={openModal.title}
        />
      )}
    </React.Fragment>
  );
};
