import React from 'react';
import { spacing } from '../../foundations';
import { Icon } from '../../subComponents/Icon';
import { ResourceProps } from './Resources';
import { HeaderSection, ResourceButton, ResourceContainer, ResourceLabel, ResourceTitle } from './styles';

export const Resource = ({ title, label, withBackground, link }: ResourceProps) => {
  return (
    <ResourceContainer withBackground={withBackground}>
      <HeaderSection>
        <ResourceTitle>{title}</ResourceTitle>
        <ResourceLabel>{label}</ResourceLabel>
      </HeaderSection>
      <a href={link} target="_blank" rel="noreferrer">
        <ResourceButton>
          <span>Download {label}</span>
          <Icon name="RDDownload" color="primary" ml={spacing.s} isRedesign />
        </ResourceButton>
      </a>
    </ResourceContainer>
  );
};
