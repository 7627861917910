import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import YouTubePlayer from 'react-player/youtube';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { createMarkUp, generateRandomStrong } from '../helpers';

import { ProductLogo } from '../ProductLogo/ProductLogo';
import { ButtonComponent } from '../Button/Button';
import { SplitColumnStyles, ImageWrap } from './styles.jsx';

interface Props {
  ID?: string;
  Copy?: string;
  ImagePosition?: string;
  Image?: object;
  Button?: object;
  FootnoteText?: string;
}

export const SplitColumnExtraText = ({ ID, Copy, Image, FootnoteText, ImagePosition, Button, ...props }: Props) => {
  return (
    <>
      <SplitColumnStyles id={ID} className={'Component-SplitColumnExtraText ImagePosition-' + ImagePosition} {...props}>
        <div className={'container'}>
          <div className={'row'}>
            <div className={'col copy-wrap'}>
              <div dangerouslySetInnerHTML={createMarkUp(Copy)}></div>
              {Button && (
                <ButtonComponent
                  Label={Button.copy}
                  Link={Button.fixedUrl}
                  Theme={Button.theme ? Button.theme : 'transparent-with-grey-outline'}
                  Target={Button.target ? Button.target : '_self'}
                />
              )}
            </div>
            <div className={'col image-wrap'}>
              <img loading="lazy" src={Image.src} alt={Image.alt} />
            </div>
          </div>
        </div>
        {FootnoteText && (
          <div className={'container'}>
            <div dangerouslySetInnerHTML={createMarkUp(FootnoteText)}></div>
          </div>
        )}
      </SplitColumnStyles>
    </>
  );
};
