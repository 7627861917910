export const colors = {
  primary: '#FFFFFF',
  secondary: '#092F45',
  tertiary: '#092F45B3',
  quaternary: '#FFFFFFB3',
  quinary: '#59747A',

  accent: '#47BACE',
  secondaryAccent: '#F02542',
  tertiaryAccent: '#48D2EA',
  quaternaryAccent: '#E72944',
  quinaryAccent: '#A0B3B8',

  background: '#032131',
  secondaryBackground: '#F8F7F6',
  tertiaryBackground: '#E7E7E7',
  teal: '#47BACE',
  gray85: '#D9D9D9'
};

//ReDesign----------------------------------------------------------------------------
export const colorsRD = {
  primary: '#234057',
  primaryText: '#23405780',
  secondary: '#A8B7BF',
  accent: {
    passion: '#F02542',
    sunnyWinterSky: '#3BB3DB',
    fossilGrey: '#F3F5F6',
    fossilGrey30: '#E2E7E9',
    fossilGrey50: '#C4CED4',
    dark: '#02022105',
    fossilGrey100: '#A8B7BF',
    starYellow: '#FDD663'
  },
  white: '#FFFFFF',
  black: '#000000',

  background: {
    primary: '#F7F6F5',
    secondary: 'linear-gradient(210.9deg, #106e8d 15.33%, #022436 100.81%)',
    tertiary: '#EFEFEF'
  },

  description: {
    primary: '#234057CC'
  },

  gradients: {
    primary: 'linear-gradient(210.9deg, #106E8D 15.33%, #022436 100.81%)',
    secondary: 'linear-gradient(231.49deg, #106E8D -10.02%, #022436 118.12%)',
    tertiary: 'linear-gradient(to bottom, #106e8d, #022436)',
    quaternary: 'linear-gradient(360deg, rgba(35, 64, 87, 0.2) 7.17%, rgba(35, 64, 87, 0) 100%)'
  }
};

export type Color = keyof typeof colors;
export type ColorRD = keyof typeof colorsRD;

export const getColor = (color: Color | undefined) => {
  if (color) {
    return colors[color] || colors.primary;
  }
};

export const getColorRD = (color: ColorRD | undefined) => {
  if (color) {
    return colorsRD[color] || colorsRD.primary;
  }
};
