import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { createMarkUp } from '../../helpers';

import { GDXVideoStyles } from './styles.jsx';

interface Props {
  Title?: string;
  Copy?: string;
  AdditionalClasses?: string;
  Items?: object;
}

export const GDXVideo = ({ Title = '', AdditionalClasses = '', Copy, ...props }: Props) => {
  return (
    <>
      <GDXVideoStyles className={`Component-GDXVideo ${AdditionalClasses}`} {...props}>
        <GlobalStyle />
        <div className={'container'}>
          <video loading="lazy" playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop">
            <source
              src="https://oqtonadmin.com/wp-content/uploads/2024/08/A07-website_06.mp4#_autoplay"
              type="video/mp4"
            />
          </video>
        </div>

        <div className={'copy-wrap'}>
          <div className={'inner'}>
            <div className={'fake-container'}>
              <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
              <p dangerouslySetInnerHTML={createMarkUp(Copy)}></p>
            </div>
          </div>
        </div>
      </GDXVideoStyles>
    </>
  );
};
