import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const IconGridComponentStyles = styled.div`
  padding: 64px 0;

  p {
    font-size: 24px;
    font-weight: 200;
    line-height: 1.3;
    letter-spacing: 0.01em;
    text-align: left;
  }

  h2 {

    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.01em;
    text-align: left;
    color: ${colors.primary};

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 48px;
    }
    
  }


`;

export const TableWrap = styled.div`
  margin: 64px 0;
  display: grid;
  grid-column-gap: 64px;
  grid-row-gap: 0;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ItemStyle = styled.div`
  border-top: 1px solid rgba(35, 64, 88, 0.2);
  border-bottom: 1px solid rgba(35, 64, 88, 0.2);
  padding: 24px 0;
  
  &:nth-child(n+1):nth-child(-n+3) {
    border-bottom: none;
  }
  
  img{
    height: 48px;
    margin-bottom: 24px;
  }
  
  h5{
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 8px;
    color: ${colors.primary};
  }
  
  p{
    font-size: 18px;
    font-weight: 300;
    line-height: 1.7;
    color:#59747A;
    margin-bottom: 48px;
  }
  
  .bgImg{
    width: 90%;
    aspect-ratio: 5 / 3;
    margin: 32px auto;
    background-size: cover;
    background-position: center center;
  }
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    position: relative;
    a{
      position: absolute;
      bottom: 0;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 28px 0;
  }


`;
