import React from 'react';

import { PageContainer } from '../../components/containers/PageContainer';
import { Footer } from '../../components/layouts/Footer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { Header } from '../../components/layouts/HeaderComponent';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { FeaturedNavigation } from '../../components/V2/FeaturedNavigation/FeaturedNavigation';
import { CapterraScore } from '../../components/V2/CapterraScore/CapterraScore';
import { BackgroundGrey, BackgroundHalfGrey } from '../../GlobalStyles';
import { Accordion } from '../../components/V2/Accordion/Accordion';
import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { FullScreenImage } from '../../components/V2/FullScreenImage/FullScreenImage';
import { SolutionBlock } from '../../components/V2/SolutionBlock/SolutionBlock';
import { HighlightBlock } from '../../components/V2/HighlightBlock/HighlightBlock';
import { ArticleBlock } from '../../components/V2/ArticleBlock/ArticleBlock';
import { IconGrid } from '../../components/V2/IconGrid/IconGrid';
import { Grid } from '../../components/V2/Grid/Grid';
import { ResourceDownloads } from '../../components/V2/ResourceDownloads/ResourceDownloads';

const GeomagicWrap = () => {
  const Lang = 'ja';
  const data = require(`../../../data/content/Pages/products/geomagic-wrap/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);
  const vertical = 'NewForm-Wrap';

  return (
    <PageContainer lang={Lang}>
      <HelmetContainer
        currentPage={'geomagic-wrap'}
        image="/assets/images/pages/geomagicWrap/car_with_computer.png"
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.description}
      />
      <Header lang={Lang} demoVertical={vertical} />

      <LargeHero
        Country={Lang}
        AddSalesTel={true}
        BackgroundStyle={'image'}
        BackgroundColour={'off-white'}
        Logo={'geomagic-wrap'}
        Copy={data.content.hero.copy}
        Background={'/assets/images/pages/geomagicWrap/car_with_computer.png'}
        Button={{
          ModelTitle: globalCopy.Buttons.RequestFreeTrial,
          ModelVertical: vertical,
          theme: 'gradient-orange',
          copy: globalCopy.Buttons.RequestFreeTrial,
          OpenDemoModel: true
        }}
      />

      <BackgroundGrey>
        <FeaturedNavigation
          AdditionalClass={'additional-margin-bottom'}
          Theme={'gradient-orange'}
          Title={data.content.FeaturedNavigation.Title}
          NavItems={[
            {
              Title: data.content.FeaturedNavigation.Nav[0],
              Link: '#overview'
            },
            {
              Title: data.content.FeaturedNavigation.Nav[1],
              Link: '#applications'
            },
            {
              Title: data.content.FeaturedNavigation.Nav[2],
              Link: '#highlights'
            },
            {
              Title: data.content.FeaturedNavigation.Nav[3],
              Link: '#resources'
            }
          ]}
          Copy={data.content.FeaturedNavigation.Copy}
          Image={{
            link: '/assets/images/pages/geomagicWrap/car_scanning.png',
            alt: data.content.FeaturedNavigation.ImageAlt
          }}
        />

        {/*<CapterraScore*/}
        {/*  Reviewer={'Ian S., Product Manager (Heavy Machinery Industry)'}*/}
        {/*  Review={*/}
        {/*    "“So much faster than any CAD plugin on the market. It's the only sensible choice if you are working with scan data several hours or more per week.”"*/}
        {/*  }*/}
        {/*  Image={'https://assets.capterra.com/badge/7efb3e2ab8d3bf7cfcdf2617994471f8.svg?v=2225314&p=276905'}*/}
        {/*/>*/}

        <SplitColumn
          id={'overview'}
          TextColor={'Grey'}
          ImagePosition={'Left'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SpltiCol1.Copy}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/geomagicWrap/wall-e_scanning_car.png',
            alt: data.content.SpltiCol1.ImgAlt
          }}
        />
        <SplitColumn
          TextColor={'Grey'}
          ImagePosition={'Right'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SpltiCol2.Copy}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/geomagicWrap/car_with_dots.png',
            alt: data.content.SpltiCol2.ImgAlt
          }}
        />
        <SplitColumn
          TextColor={'Grey'}
          ImagePosition={'Left'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SpltiCol3.Copy}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/geomagicWrap/car_with_solid_seats.png',
            alt: data.content.SpltiCol3.ImgAlt
          }}
        />
        <SplitColumn
          TextColor={'Grey'}
          ImagePosition={'Right'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SpltiCol4.Copy}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/geomagicWrap/car_with_yellow_parts.png',
            alt: data.content.SpltiCol4.ImgAlt
          }}
        />
      </BackgroundGrey>

      <FullScreenImage
        Title={data.content.FullScreenImage.Title}
        Subtitle={data.content.FullScreenImage.Subtitle}
        TextColour={'Red'}
        Background={'/assets/images/components/background-circles.webp'}
        Image={{
          alt: data.content.FullScreenImage.ImgAlt,
          url: '/assets/images/pages/geomagicWrap/car_model_with_aero.png'
        }}
      />

      <BackgroundGrey>
        <IconGrid
          id={'applications'}
          Theme={'light'}
          Title={data.content.IconGrid.Title}
          Subtitle={data.content.IconGrid.Subtitle}
          Items={data.content.IconGrid.Items}
        />

        <Grid
          ID={'highlights'}
          Title={data.content.Grid.Title}
          Subtitle={data.content.Grid.Subtitle}
          Items={data.content.Grid.Items}
        />

        <ResourceDownloads
          id={'resources'}
          Title={data.content.ResourceDownloads.Title}
          Items={data.content.ResourceDownloads.Items}
        />
      </BackgroundGrey>

      <ArticleBlock
        id={'customer-stories'}
        Title={"Don't take our word for it! Have a look at how successful our customers are:"}
        Layout={'layout-1'}
        Link={{
          copy: 'All Customer Stories',
          link: '/news/?&category=Customer-Story'
        }}
        Items={[
          {
            title: 'Geosciences and libraries turn to Geomagic Wrap from Oqton in wake of disaster',
            link: '/news/customer-stories/geosciences-turn-to-geomagic-wrap-in-wake-of-disaster/',
            image: '/assets/images/customerStories/usf/IMG-01.png',
            category: 'Customer Story',
            readTime: ''
          },
          {
            title: '3D reverse engineering software delivers results for Formula SAE team',
            link: '/news/customer-stories/reverse-engineering-delivers-results-for-formula-sae-team/',
            image: '/assets/images/customerStories/sae/car.png',
            category: 'Customer Story',
            readTime: ''
          }
        ]}
      />

      <BackgroundHalfGrey>
        <HighlightBlock
          Theme={'accent-orange'}
          Title={data.content.HighlightBlock.Title}
          Button={{
            ModelTitle: globalCopy.Buttons.RequestFreeTrial,
            ModelVertical: vertical,
            copy: globalCopy.Buttons.RequestFreeTrial,
            OpenDemoModel: true
          }}
          Image={{
            title: '',
            alt: '',
            url: '/assets/images/pages/geomagicWrap/car_with_computer.png'
          }}
        />
      </BackgroundHalfGrey>

      <BackgroundGrey>
        <Accordion id="faqs" Title={data.content.Accordion.title} Items={data.content.Accordion.Items} />
      </BackgroundGrey>

      <SolutionBlock
        Title={data.content.SolutionBlock.Title}
        Theme={'dark'}
        Items={[
          {
            logo: 'geomagic-designx',
            title: data.content.SolutionBlock.Items[1],
            link: globalCopy.Navigation.ProductURLs.geoDesignX
          },
          {
            logo: 'geomagic-controlx',
            title: data.content.SolutionBlock.Items[0],
            link: globalCopy.Navigation.ProductURLs.geoControlX
          },
          {
            logo: 'geomagic-for-solid',
            title: data.content.SolutionBlock.Items[2],
            link: globalCopy.Navigation.ProductURLs.GeomagicForSolidworks
          }
        ]}
      />

      <Footer lang={'ja'} />
    </PageContainer>
  );
};

export default GeomagicWrap;
