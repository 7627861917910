import styled from 'styled-components';
import { RDH2Bold } from '../../../styles/typography';
import { colorsRD, media, spacing } from '../../foundations';

export const Container = styled.section<{ inverted?: boolean }>`
  padding: ${spacing.xxxxxl}px;
  color: ${p => (p.inverted ? colorsRD.white : colorsRD.primary)};
  ${({ inverted }) => (inverted ? `background-image: ${colorsRD.gradients.primary}` : '')};

  @media screen and (max-width: ${media.lg}px) {
    padding: ${spacing.xxxxxl}px 0;
  }
`;

export const Title = styled.h1`
  ${RDH2Bold};
  text-align: center;
  margin-bottom: ${spacing.xxl}px;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${spacing['xl+']}px;
  flex-wrap: wrap;

  @media screen and (max-width: ${media.lg}px) {
    flex-direction: column;
    width: 452px;
    margin: 0 auto;
  }
`;
