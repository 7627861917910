import React, { useState } from 'react';
import { ContactModal } from '../../../layouts/ContactModal/ContactModal';
import { Icon } from '../../../subComponents/Icon';
import { ArticleContent, ArticleText, Button, ButtonContainer, WhiteButton } from '../styles';

export const GeomagicEssentialsContent = props => {
  const [openModal, setOpenModal] = useState(false);

  if (props.content) {
    return (
      <React.Fragment>
        <ArticleContent>
          <ArticleText>
            <p>{props.content.p1}</p>
          </ArticleText>
          <ButtonContainer>
            <Button onClick={() => setOpenModal(true)}>
              {props.content.button} <Icon ml="12px" name="Arrow" />
            </Button>
            <a
              href="/assets/files/brochures/geomagicessentials/geomagic-designx-essentials-brochure-2022-v1-en-digital.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <WhiteButton>
                {props.content.button2} <Icon ml="12px" name="Arrow" />
              </WhiteButton>
            </a>
          </ButtonContainer>
        </ArticleContent>
        {openModal && (
          <ContactModal
            handleClose={() => setOpenModal(false)}
            vertical="GeomagicDesignXEssentials"
            title="Request a free trial"
          />
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <ArticleContent>
          <ArticleText>
            <p>
              Geomagic Design X Essentials brings the industry’s most comprehensive reverse engineering software to more
              people. By reducing the barrier to entry for accurate and efficient history-based CAD and 3D scan data
              processing, Design X Essentials empowers users with limited budgets to create feature-based, editable
              solid models with their affordable scanners.
            </p>
          </ArticleText>
          <ButtonContainer>
            <Button onClick={() => setOpenModal(true)}>
              Request free trial <Icon ml="12px" name="Arrow" />
            </Button>
            <a
              href="/assets/files/brochures/geomagicessentials/geomagic-designx-essentials-brochure-2022-v1-en-digital.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <WhiteButton>
                Download Brochure <Icon ml="12px" name="Arrow" />
              </WhiteButton>
            </a>
          </ButtonContainer>
        </ArticleContent>
        {openModal && (
          <ContactModal
            handleClose={() => setOpenModal(false)}
            vertical="GeomagicDesignXEssentials"
            title="Request a free trial"
          />
        )}
      </React.Fragment>
    );
  }
};
