import React from 'react';

import GlobalStyle from '../../../../GlobalStyles';

import { HighlightBlockStyles, Inner, CopyWrap } from './styles.jsx';
import { ButtonComponent } from '../../Button/Button';

import { createMarkUp } from '../../helpers';

interface Props {
  Title?: string;
  Button?: object;
  AdditionalDescription?: string;
  Width?: 'Full' | 'Contained';
  Theme:
    | 'Light'
    | 'Primary'
    | 'Dark-Primary'
    | 'Tertiary'
    | 'Light-Tertiary'
    | 'Primary-Gradient'
    | '3dxpert'
    | 'Accent-Red'
    | 'Accent-Teal'
    | 'Accent-Red-Gradient'
    | 'Light-Gradient';
}

export const HighlightBlockNoImage = ({
  Width = 'Contained',
  Title,
  Button,
  AdditionalDescription,
  Theme = 'Primary',
  ...props
}: Props) => {
  const getButtonStyle = Theme => {
    switch (Theme) {
      case 'Accent-Red':
      case 'Accent-Red-Gradient':
        return 'white-and-red';
      case 'Accent-Teal':
        return 'white-and-teal';
      case '3dxpert':
        return 'white-and-teal';
      default:
        return 'white-and-primary';
    }
  };

  const getControlClass = AdditionalDescription => {
    if (AdditionalDescription) {
      return 'with-description';
    }
    return 'no-description';
  };

  return (
    <HighlightBlockStyles {...props} className={'width-' + Width}>
      <GlobalStyle />
      <div className={'container'}>
        <Inner className={'theme-' + Theme + ' ' + getControlClass(AdditionalDescription)}>
          <CopyWrap className={'copy-wrap theme-' + Theme}>
            <div dangerouslySetInnerHTML={createMarkUp(Title)}></div>
            {Button && Button !== {} && (
              <ButtonComponent
                ModelVertical={Button.ModelVertical}
                ModelTitle={Button.ModelTitle}
                OpenDemoModel={Button.OpenDemoModel}
                Theme={getButtonStyle(Theme)}
                Label={Button.copy}
                Link={Button.link}
                Target={Button.target}
              />
            )}
          </CopyWrap>
          {AdditionalDescription && (
            <p className="additional-description" dangerouslySetInnerHTML={createMarkUp(AdditionalDescription)}></p>
          )}
        </Inner>
      </div>
    </HighlightBlockStyles>
  );
};
