import React from 'react';

export const Freeform = (
  <svg width="33" height="32" viewBox="0 0 33 32" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="32" height="32" rx="2" fill="url(#paint0_linear_10571_49805)" />
    <path d="M10.9 17.28H15.96V15.1H10.9V11.54H16.32V9.36H8.5V23.36H10.9V17.28Z" fill="white" />
    <path
      d="M21.5925 12.96C21.5925 11.58 22.3525 11.04 23.3525 11.04C23.8325 11.04 24.2125 11.16 24.5125 11.32V9.28C24.1725 9.12 23.6725 9 23.0125 9C20.7925 9 19.2725 10.38 19.2725 12.98V13.56H17.8125V15.52H19.2725V23.36H21.5925V15.52H24.2325V13.56H21.5925V12.96Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10571_49805"
        x1="0.5"
        y1="32"
        x2="26.4375"
        y2="6.4375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#117A74" />
        <stop offset="1" stop-color="#19BBB0" />
      </linearGradient>
    </defs>
  </svg>
);
