import styled from 'styled-components';
import { colors, media, spacing, typography } from '../../foundations';
import { subStyle } from '../../../styles/typography';

export const ContactFormContainer = styled.article`
  display: flex;
  flex-direction: row;

  margin: 140px auto 100px;

  @media screen and (max-width: ${media.xl}px) {
    flex-direction: column;
  }
`;

export const FormContainer = styled.iframe`
  height: 1200px;
  margin: 0 0 0 100px;

  @media screen and (min-width: ${media.lg}px) {
    height: 800px;
    margin: ${spacing.xxxl}px 0 0 0;
  }
  @media screen and (max-width: ${media.xl}px) {
    margin: ${spacing.xxxl}px 0 0 0;
  }
`;

export const FormContainerTaller = styled.iframe`
  height: 1000px;
  margin: 0 0 0 100px;

  @media screen and (max-width: ${media.xl}px) {
    margin: ${spacing.xxxl}px 0 0 0;
  }
`;

export const FormTitle = styled.h2`
  width: 180px;

  color: ${colors.secondary};

  margin-bottom: ${spacing.xl}px;

  font-size: ${typography.fontSizes.xxxxl}px;
  font-weight: ${typography.fontWeights.thin};
  line-height: 1;

  @media screen and (max-width: ${media.lg}px) {
    width: auto;
  }
`;

export const LocationsLink = styled.a`
  display: block;

  width: 130px;

  margin-top: ${spacing.xxxl}px;
  padding-bottom: ${spacing.xs}px;

  color: ${colors.secondary};

  font-size: ${typography.fontSizes.l}px;
  font-weight: ${typography.fontWeights.medium};

  text-decoration: none;

  border-bottom: solid 2px ${colors.quaternaryAccent};

  transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), border 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    color: ${colors.quaternaryAccent};
    border-color: ${colors.secondary};
  }
`;

export const Text = styled.p`
  color: ${colors.tertiary};
`;

export const FormLink = styled.a`
  color: ${colors.secondary};

  width: 130px;
  padding-bottom: ${spacing.xs}px;

  font-size: ${typography.fontSizes.l}px;
  font-weight: ${typography.fontWeights.medium};

  border-bottom: 2px solid ${colors.quaternaryAccent};
`;

export const LocationsContainer = styled.section.attrs({ id: 'locations' })`
  display: grid;
  grid-template-rows: repeat(2, 450px);
  grid-template-columns: repeat(3, 355px);

  gap: 30px;

  width: 1130px;
  margin: 0 auto ${spacing.xxxl}px;

  @media screen and (max-width: ${media.lg}px) {
    grid-template-rows: 365px repeat(2, 450px);
    grid-template-columns: repeat(2, 355px);
  }

  @media screen and (max-width: ${media.md}px) {
    grid-template-columns: repeat(2, 1fr);
    width: auto;
  }

  @media screen and (max-width: ${media.md}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 365px repeat(4, 450px);
  }
`;

export const LocationsIntroContainer = styled.div`
  display: grid;
  grid-template-rows: ${spacing.xxxl}px 170px auto;
  grid-template-areas: 'locationsSub' 'locationsTitle' 'locationsText';

  @media screen and (max-width: ${media.lg}px) {
    grid-column: auto / span 2;
  }

  @media screen and (max-width: ${media.md}px) {
    grid-column: auto;
  }
`;

export const LocationsTitle = styled.h3`
  grid-area: locationsTitle;

  margin-bottom: 30px;

  color: ${colors.secondary};

  font-size: ${typography.fontSizes.xxxxl}px;
  font-weight: ${typography.fontWeights.thin};
  line-height: 1;

  @media screen and (max-width: ${media.lg}px) {
    width: 340px;
  }
`;

export const LocationsSub = styled.sub`
  grid-area: locationsSub;

  margin-bottom: 40px;

  color: ${colors.quaternaryAccent};

  ${subStyle};
`;
