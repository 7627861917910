import React, { useState } from 'react';
import { ContactModal } from '../../../layouts/ContactModal/ContactModal';
import { Icon } from '../../../subComponents/Icon';
import { ArticleContent, ArticleText, ButtonContainer, WhiteButton } from '../styles';
import { ListContainer } from '../../Styles/style';

import { createMarkUp } from '../../../V2/helpers';

export const GeomagicEssentialsComboContent = props => {
  const [openModal, setOpenModal] = useState(false);
  if (props.content) {
    return (
      <React.Fragment>
        <ArticleContent>
          <ArticleText>
            <p id="solid">{props.content.p1}</p>
            <br />
            <p>{props.content.p2}</p>
            <br />
            <ListContainer dangerouslySetInnerHTML={createMarkUp(props.content.list)}></ListContainer>
          </ArticleText>
          <ButtonContainer>
            <WhiteButton onClick={() => setOpenModal(true)}>
              {props.content.button1} <Icon ml="12px" name="Arrow" />
            </WhiteButton>
          </ButtonContainer>
        </ArticleContent>
        {openModal && (
          <ContactModal
            handleClose={() => setOpenModal(false)}
            vertical="GeomagicEssentials"
            title={props.content.button1}
          />
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <ArticleContent>
          <ArticleText>
            <p id="solid">
              In order to meet the demands of the surging market for affordable industrial scanners, the Essentials
              Bundle empowers anyone to carry out complete, accurate, Reverse Engineering and Inspection workflows at a
              low price point.
            </p>
            <br />
            <p>The Essentials Bundle is for anyone who:</p>
            <br />
            <ListContainer>
              <li>Needs 3D scanning tools for low-frequency projects</li>
              <li>Works on lower complexity components, so requires a reduced toolset</li>
              <li>Have tighter budgetary constraints, but still require accurate tools</li>
            </ListContainer>
          </ArticleText>
          <ButtonContainer>
            <WhiteButton onClick={() => setOpenModal(true)}>
              Request a demo <Icon ml="12px" name="Arrow" />
            </WhiteButton>
          </ButtonContainer>
        </ArticleContent>
        {openModal && (
          <ContactModal handleClose={() => setOpenModal(false)} vertical="GeomagicEssentials" title="Request a demo" />
        )}
      </React.Fragment>
    );
  }
};
