import styled from 'styled-components';
import { colors, media, spacing, typography } from '../../foundations';

export const MediaContainer = styled.section<{ noMargin?: boolean; noBackground?: boolean }>`
  height: 870px;

  ${({ noMargin }) =>
    !noMargin &&
    `
    margin-top: -850px;
    margin-bottom: 300px;
  `}

  display: grid;
  grid-template-columns: 190px 196px 387px 357px;
  grid-template-rows: 195px 272px 87px 33px 316px;
  grid-template-areas: 'media1 media1 . .' 'media1 media1 . media3' '. . . media3' '. media2 media2 media3' '. media2 media2 buttonContainer';

  p {
    ${({ noBackground }) => noBackground && `color: ${colors.secondary}`}
  }

  @media (max-width: ${media.xl}px) {
    grid-template-columns: 386px 197px 1fr 357px;
    grid-template-areas: 'media1 . . .' 'media1 . . media3' '. . . media3' 'media2 media2 . media3' 'media2 media2 . buttonContainer';

    margin-left: -60px;
    margin-right: -60px;
  }

  @media (max-width: ${media.lg}px) {
    grid-template-rows: max-content;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: '. media1 buttonContainer';

    height: auto;
    margin-left: 0;
    margin-right: 0;
    ${({ noMargin }) =>
      !noMargin &&
      `
    margin-top: -350px;
    margin-bottom: 200px;
  `}

    p {
      text-align: left;
      color: ${colors.secondary};
    }
  }
`;

export const MediaItem = styled.article<{ index: number; noBackground?: boolean }>`
  grid-area: ${({ index }) => `media${index};`};

  color: ${({ index }) => (index !== 2 ? colors.quaternary : colors.secondary)};

  font-size: ${typography.fontSizes.s}px;
  font-weight: ${typography.fontWeights.thin};
  letter-spacing: 0.5px;
  line-height: 1;
  text-align: right;

  p {
    margin-top: ${spacing.l}px;
  }

  @media (max-width: ${media.lg}px) {
    ${({ index }) => index !== 1 && `display: none;`};
  }
`;

export const ButtonContainer = styled.section`
  grid-area: buttonContainer;

  display: flex;
  flex-direction: row;

  height: 24px;
  align-self: flex-end;
  justify-content: flex-end;

  margin-bottom: ${spacing.xxxl}px;

  @media (max-width: ${media.lg}px) {
    margin-bottom: -100px;
  }
`;

export const ButtonItem = styled.button<{ selected: boolean }>`
  box-sizing: border-box;
  position: relative;
  height: 24px;
  width: 24px;
  border: 0 none;
  border-radius: 50%;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 6px;
    width: 6px;

    margin-top: -3px;
    margin-left: -3px;

    content: '';
    border-radius: 50%;
    background: ${({ selected }) => (selected ? colors.quaternaryAccent : colors.secondary)};
  }

  ${({ selected }) =>
    selected &&
    `&:after {
      box-sizing: border-box;
      position: absolute;
      height: 24px;
      width: 24px;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
      content: '';
      border: 1px solid ${colors.tertiary};
      border-radius: 50%;
    }`}
`;
