import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients, containerWidths} from '../../../GlobalStyles';

export const ComponentStyles = styled.div`

    padding: 62px 0 80px;
    background: linear-gradient(174.97deg, #106E8D -8.33%, #022436 116.79%);

    .title-bar {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-bottom: 32px;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: center;
        }

        img {
            height: 36px;
            margin-bottom: 16px;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                margin-bottom: 0px;
                height: 52px;

            }
        }

        h2 {
            font-style: normal;
            font-size: 36px;
            line-height: 1.2;
            color: #FFFFFF;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                font-size: 52px;
            }

            b {
                font-weight: 700;
            }

        }

    }

    p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 1.6;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: 24px;

        }
    }

    .grid {
        display: grid;
        column-gap: 32px;
        row-gap: 32px;
        grid-template-columns: repeat(1, 1fr);
        margin-top: 42px;

        @media (min-width: ${mediaQueriesSizes.md}px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            grid-template-columns: repeat(3, 1fr);
        }

        .item {
            /* UI-Light/Background */
            background: ${colors.white};
            border-radius: 6px;
            overflow: hidden;

            .text-wrap {
                padding: 24px 24px 12px;
                @media (min-width: ${mediaQueriesSizes.xl}px) {
                    padding: 42px 42px 12px;
                }

                h4 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 1;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    color: ${colors.primary};
                }

                p.copy {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 1;
                    color: ${colors.primary};
                    margin-top: 24px;
                    text-wrap:balance;
                }


                .statistic {
                    margin-top: 12px;
                    display: flex;
                    align-content: center;
                    align-items: center;

                    p.data {
                        text-wrap: balance;
                        color: ${colors.UILightSecondary};
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 1;
                        @media (min-width: ${mediaQueriesSizes.lg}px) {
                            font-size: 16px;
                        }
                        @media (min-width: ${mediaQueriesSizes.xl}px) {
                            font-size: 18px;
                        }
                    }

                    p.number {
                        color: ${colors.teal};
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        padding-right: 16px;
                        font-size: 64px;

                        @media (min-width: ${mediaQueriesSizes.lg}px) {
                            font-size: 38px;
                        }
                        @media (min-width: ${mediaQueriesSizes.xl}px) {
                            font-size: 64px;
                        }
                    }
                }
            }

            img {
                width: 100%;
                margin: 0;
                padding: 0;
                display: block;
            }

        }
    }
`;