import React from 'react';

export const Caret = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11.7 6.1"
    id="caret-down"
    width={12}
    height={6}
    fill="currentColor"
  >
    <path d="M5.3 6.1L0 .7.7 0l4.7 4.7L11 0l.7.8z"></path>
  </svg>
);
