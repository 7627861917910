import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../../GlobalStyles";

export const SideColumnStyles = styled.section`

  width: 100%;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    width: calc(40% - 62px);
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    width: calc(30% - 100px);
  }

  a.custom-button.theme-accent-teal ,
  a.custom-button.theme-accent-red {
    margin-bottom: 80px;
    width: 100%;

    .inner {
      justify-content: space-around;
    }
  }

  a.custom-button{
    margin-bottom: 16px;
  }
  
  a.simple {
    display: inline-block;
    font-family: centrano2-book;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 0.8;
    text-align: center;
    letter-spacing: 0.04em;
    color: ${colors.primary};
    text-decoration: none;
    border-bottom: solid 1px ${colors.primary};
    transition: 0.25s;

    svg.fa-plus {
      width: 10px;
    }

    &:hover {
      color: ${colors.highlight};
      border-color: ${colors.highlight};
    }

  }

  div.button-wrapper{
    a.custom-button.theme-stripped{
      display: inline-block;
    }
  }
  
`;
export const LogoWrap = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 80px;
  
  img{
    width: 100%;
    max-width: 160px;
  }
`;
