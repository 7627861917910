import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { ButtonComponent } from '../Button/Button';
import { createMarkUp } from '../helpers';
import { ProductLogo } from '../ProductLogo/ProductLogo';
import { SalesTel } from '../Atoms/SalesTel/SalesTel';

import { LargeHeroStyles, CopyWrapper } from './styles.jsx';

interface Props {
  AdditionalClasses?: string;
  Copy?: string;
  Button?: {};
  BackgroundColour?: 'white' | 'off-white';
  Country?: 'en' | 'fr' | 'uk' | 'de' | 'it' | 'ko';
  BackgroundStyle?: 'bottom-right' | 'center-right' | 'cover' | 'image';
  AddSalesTel?: boolean;
  AddSupportBTN?: boolean;
  Logo?:
    | null
    | 'manufacturing-os'
    | 'geomagic-designx'
    | 'geomagic-controlx'
    | 'geomagic-freeform'
    | 'geomagic-wrap'
    | '3dxpert'
    | 'amphyon'
    | 'd2p'
    | 'geomagic-for-solid';
  Background?: string;
  SecondaryLogo?: string;
}

export const LargeHero = ({
  AddSalesTel = false,
  AddSupportBTN = false,
  AdditionalClasses,
  SecondaryLogo,
  Button,
  Logo,
  Country = 'en',
  Background,
  BackgroundColour = 'white',
  BackgroundStyle = 'bottom-right',
  Copy = '<h1>Title</h1><p>Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy</p>',
  ...props
}: Props) => {
  const globalCopy = require(`../../../../data/content/Global/${Country}.json`);

  return (
    <LargeHeroStyles
      className={`Component_LargeHero background-colour-${BackgroundColour} background-style-${BackgroundStyle} ${AdditionalClasses}`}
      {...props}
    >
      {Background && BackgroundStyle !== 'image' && (
        <div
          className={'background background-style-' + BackgroundStyle}
          style={{ backgroundImage: 'url(' + Background + ')' }}
        ></div>
      )}
      <GlobalStyle />
      <div className={'container'}>
        <div className={'copy-wrap'}>
          {Logo && <ProductLogo Logo={Logo} />}
          <CopyWrapper className={AdditionalClasses} dangerouslySetInnerHTML={createMarkUp(Copy)}></CopyWrapper>
          <div className={'button-wrap'}>
            {Button && (
              <ButtonComponent
                AdditionalClass={'LargeHeroButton'}
                ModelTitle={Button.ModelTitle}
                ModelVertical={Button.ModelVertical}
                Target={Button.target}
                Theme={Button.theme}
                Label={Button.copy}
                Link={Button.link}
                OpenDemoModel={Button.OpenDemoModel}
              />
            )}
            {AddSalesTel && <SalesTel Theme={Button.theme} Country={Country} />}
            {(() => {
              switch (Logo) {
                case 'geomagic-for-solid':
                  return (
                    <img
                      className={'secondary-logo'}
                      src="/assets/images/pages/geomagic-for-solidworks/gold_solidworks_badge.png"
                    />
                  );
                default:
                  return '';
              }
            })()}
          </div>
          {AddSupportBTN && (
            <ButtonComponent
              IconPosition={'right'}
              Icon={'support'}
              AdditionalClass={''}
              Target={'_blank'}
              Theme={'tertiary'}
              Label={globalCopy.Navigation.Support}
              Link={'https://softwaresupport.oqton.com/s/article/Contact-Us?language=en_US'}
            />
          )}
        </div>

        {Background && BackgroundStyle === 'image' && (
          <div className={'image'}>
            {SecondaryLogo &&
              (SecondaryLogo.Link ? (
                <a className="secondaryLogo" href={SecondaryLogo.Link} target={'_blank'}>
                  <img src={SecondaryLogo.Logo} alt={SecondaryLogo.Alt} loading="lazy" />
                </a>
              ) : (
                <img className="secondaryLogo" src={SecondaryLogo.Logo} alt={SecondaryLogo.Alt} loading="lazy" />
              ))}
            <img className={''} src={Background} alt={''} />
          </div>
        )}
      </div>
    </LargeHeroStyles>
  );
};
