import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients} from "../../../GlobalStyles";

export const ReferencesStyles = styled.div`
  padding: 60px 0;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0.01em;
    //font-family: centrano2-bookitalic;
    color: ${colors.primary};
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 16px;
    }
  }
`;