import styled from 'styled-components';

import {colors, bgGradients, mediaQueriesSizes} from '../../../GlobalStyles';

export const Hero = styled.div`

    .container {
        padding-top: 32px; //TODO FIX
        padding-bottom: 32px;
        position: relative;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            padding-top: 30px; //TODO FIX
            padding-bottom: 100px;
            display: flex;
            align-items: center;
        }

        h1 {
            font-style: normal;
            font-weight: 700;
            line-height: 1.25;
            letter-spacing: 0.02em;
            color: white;
            text-wrap: balance;
            font-size: 32px;
            @media (min-width: ${mediaQueriesSizes.md}px) {
            font-size: 48px;
            }
            span {
                font-weight: 800;
                position: relative;

                &:after {
                    position: absolute;
                    bottom: -10px;
                    left: -5%;
                    width: 110%;
                    height: 20px;
                    content: "";
                    background-image: url("/assets/images/3dxpert/underline.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
        }

        p {
            margin: 18px 0;
            font-style: normal;
            font-size: 18px;
            line-height: 1.25;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            font-family: centrano2-book;
        }

        img.hero-image {
            width: 100%;
            margin: 32px 0 0;
            height: auto;
            @media (min-width: ${mediaQueriesSizes.md}px) {
                width: 50%;
                max-width: 650px;
                margin-left: 100px;
            }
        }
        img.capterra {
            position: absolute;
            width: 120px;
            bottom: 36px;
            right: 16px;
            @media (min-width: ${mediaQueriesSizes.sm}px) {
                right: 0px;
            }
        }


    }


    @media (min-width: ${mediaQueriesSizes.md}px) {
    }
`;

export const MainContent = styled.div`
    overflow: hidden;
`;
export const WhatToExpect = styled.div`
    background: white;
    padding: 64px 0;

    .key-visual {
        text-align: center;
        position: relative;

        &:after {
            content: "";
            pointer-events: none;
            display: block;
            top: -50%;
            opacity: 0.7;
            left: -40vw;
            width: calc(100vw + 50%);
            height: 150vh;
            position: absolute;
            background-image: url(/assets/images/3dxpert/circles-bg.png);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 11;
        }

        img {
            position: relative;
            z-index: 15;
            margin: 0 auto;
            width: auto;
            max-width: 100%;
            @media (min-width: ${mediaQueriesSizes.md}px) {
                position: relative;
                z-index: 15;
                margin: 0 auto;
                width: 100%;
                max-width: 550px;
            }
        }
    }

    .container {
        display: flex;
        gap: 32px;
        flex-direction: column;
        @media (min-width: ${mediaQueriesSizes.md}px) {
            flex-direction: row;
            .text-primary,
            .key-visual {
                width: 50%;
                display: flex;
                align-items: center;
            }
        }
    }

    .text-primary {
        .divider.teal {
            margin: 24px 0;
        }

        h2 {
            font-style: normal;
            font-size: 24px;
            line-height: 1.2;
            letter-spacing: 0.02em;
            color: ${colors.primary};
            font-family: centrano2-book;
            @media (min-width: ${mediaQueriesSizes.md}px) {
                font-size: 32px;
            }
        }

        hr {
            margin: 24px 0;
            background: ${colors.green};
        }

        p {
            font-family: centrano2-book;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            @media (min-width: ${mediaQueriesSizes.md}px) {
                font-size: 24px;
            }
            line-height: 1.5;
            letter-spacing: 0.2px;
            color: ${colors.primary};
        }

        ul {
            font-style: normal;
            font-weight: 400;
            line-height: 1.3;
            letter-spacing: 0.01em;
            color: ${colors.primary};
            margin: 36px 0;

            li {
                font-family: centrano2-book;
                list-style: none;
                margin-left: 32px;
                font-size: 16px;
                font-weight: 100;
                line-height: 1.4;
                margin-bottom: 16px;
                @media (min-width: ${mediaQueriesSizes.md}px) {
                    font-size: 22px;
                }

                ::marker {
                    color: ${colors.teal};
                    display: none;
                }

                &:before {
                    position: absolute;
                    content: '';
                    width: 10px;
                    margin-left: -1.4em;
                    line-height: inherit;
                    background: url(/assets/icons/ellipse-green.png) 50% 50% no-repeat;
                    list-style: none;
                    padding-left: 10px;
                    background-size: contain;
                    height: 8px;
                    margin-top: 4px;
                    @media (min-width: ${mediaQueriesSizes.md}px) {
                        height: 12px;
                        margin-top: 12px;
                    }
                }
            }
        }
    }

`;

export const Panel = styled.div`
    padding: 0 16px;
    position: relative;

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 50%;
        background: white;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
    }

    .container {
        background: #F8F7F6;
        justify-content: space-around;
        -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        position: relative;
        z-index: 20;

        .inner {
            max-width: 1250px;
            margin: 0 auto;
            padding: 24px 0;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                padding: 64px 0;
                gap: 64px;
                display: flex;
                align-items: center;
                width: 90%;
            }
        }

        .text-wrap {
            h2 {
                text-wrap: balance;
                font-style: normal;
                font-weight: 500;
                line-height: 1.25;
                letter-spacing: 0.02em;
                color: ${colors.primary};
                font-size: 24px;
                margin-bottom: 32px;
                @media (min-width: ${mediaQueriesSizes.md}px) {
                    font-size: 38px;
                }
            }

            p {
                font-family: centrano2-bookitalic;
                font-weight: 400;
                text-wrap: balance;
                font-size: 18px;
                line-height: 1.25;
                color: ${colors.light};
            }

            .review-wrap {
                margin-top: 30px;
                display: flex;
                align-items: center;
                gap: 20px;

                img {
                    height: 64px;
                }

                p {
                    color: ${colors.primary};
                    font-family: centrano2-book;
                    font-size: 16px;

                    b {
                        font-family: centrano2-medium;
                    }
                }
            }
        }

        .logo-wrap {
            margin: 32px 0 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 8px;
            grid-row-gap: 8px;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
                grid-column-gap: 32px;
                grid-row-gap: 32px;
            }

            img {
                width: 100%;
                max-width: 85px;
                margin: 0 auto;
                @media (min-width: ${mediaQueriesSizes.lg}px) {
                    width: auto;
                }
            }

            @media (min-width: ${mediaQueriesSizes.lg}px) {
                margin: 0;
            }
        }
    }
`;

export const DiscoverBlock = styled.div`
    padding: 42px 0;
    position: relative;
    z-index: 10;
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        margin-top: -250px;
    }
    background: ${bgGradients.designx};

    .container {
        @media (min-width: ${mediaQueriesSizes.md}px) {
            display: flex;
            flex-direction: row-reverse;
        }

        .text {
            @media (min-width: ${mediaQueriesSizes.md}px) {
                width: 50%;
            }
            position: relative;
            z-index: 12;

            h2 {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 1.2;
                color: #FFFFFF;
                margin-bottom: 18px;
                @media (min-width: ${mediaQueriesSizes.md}px) {
                    font-size: 40px;
                }
            }
        }
    }
`;

export const Wrap = styled.div`
    background: ${colors.primary};
    @media (min-width: ${mediaQueriesSizes.md}px) {
    }
`;
