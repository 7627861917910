import styled from 'styled-components';

import {colors,mediaQueriesSizes} from "../../../GlobalStyles";

export const ComponentSolutionBlock = styled.div`

  padding: 64px 0;

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 120px 0;
  }

  h2 {
    b {
      font-weight: 700;
    }
  }

  &.theme-dark {
    background: ${colors.primary};

    h2 {
      color: ${colors.white};
    }
  }



`;


export const ItemWrapper = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    margin-top: 64px;
    grid-column-gap: 32px;
    grid-row-gap: 32px;

    &.item-count-3,
    &.item-count-6 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

`;

export const LinkBlock = styled.a`
    background: linear-gradient(52.57deg, rgba(0, 0, 0, 0.17) -90.51%, rgba(255, 255, 255, 0) 146.87%),
    linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    border-radius: 4px;
    display: block;
    color: ${colors.primary};
    text-decoration: none;
    padding: 16px;
    min-height: 120px;
    transition: 0.3s;
    -webkit-box-shadow: 0px 10px 50px 5px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 10px 50px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 50px 5px rgba(0, 0, 0, 0);

    &:hover {
        transform: scale(1.02);
        -webkit-box-shadow: 0px 10px 50px 5px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 10px 50px 5px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 10px 50px 5px rgba(0, 0, 0, 0.15);
    }

    .Component-ProductLogo {
        margin-bottom: 16px;
    }

    h3 {
        color: ${colors.primary};
        font-size: 18px;
        font-weight: 500;
        line-height: 1.3;
    }

    p.copy {
        margin-top: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 2;
        color: ${colors.white};
    }

    .custom-button{
        margin: 16px 0 8px;
    }
    
    
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        min-height: 280px;
        padding: 40px 40px 80px;
        position: relative;
        
        .custom-button{
            margin-top: 0;
            position: absolute;
            bottom: 40px;
            left: 40px;
        }

        .Component-ProductLogo {
            margin-bottom: 32px;
        }

        h3 {
            font-size: 24px;
        }
    }


    &.theme-dark {
        background: linear-gradient(52.57deg, #142533 -90.51%, rgba(255, 255, 255, 0) 146.87%),
        linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));

        h3 {
            color: ${colors.white};
        }

    }

`;

