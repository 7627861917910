import React from 'react';

export const DesignX = (
  <svg width="33" height="32" viewBox="0 0 33 32" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="32" height="32" rx="2" fill="url(#paint0_linear_10571_49783)" />
    <path
      d="M17.4325 16C17.4325 12.04 14.2925 9 10.3925 9H5.8125V23H10.3925C14.2925 23 17.4325 19.96 17.4325 16ZM14.9725 16C14.9725 18.66 12.9925 20.76 10.3325 20.76H8.2125V11.24H10.3325C12.9925 11.24 14.9725 13.34 14.9725 16Z"
      fill="white"
    />
    <path
      d="M25.8592 23H28.5392L24.8992 17.96L28.3992 13.2H25.7792L23.5592 16.34L21.3592 13.2H18.6992L22.1792 18.02L18.5392 23H21.1192L23.4992 19.64L25.8592 23Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10571_49783"
        x1="3.5"
        y1="29.5"
        x2="26.875"
        y2="5.0625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#99181D" />
        <stop offset="1" stop-color="#DB2229" />
      </linearGradient>
    </defs>{' '}
  </svg>
);
