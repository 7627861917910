import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { ProductLogo } from '../ProductLogo/ProductLogo';

import { ComponentSolutionBlock, ItemWrapper, LinkBlock } from './styles.jsx';
import { ButtonComponent } from '../Button/Button';

interface Props {
  Title?: string;
  Items?: object;
  Theme?: 'light' | 'dark';
}

export const SolutionBlock = ({ Title = '', Items = [], Theme = 'light', ...props }: Props) => {
  const createMarkUp = content => {
    return { __html: content };
  };

  return (
    <ComponentSolutionBlock className={'Component-SolutionBlock theme-' + Theme} {...props}>
      <GlobalStyle />
      <div className={'container'}>
        <h2 dangerouslySetInnerHTML={createMarkUp(Title)}></h2>

        <ItemWrapper className={'item-count-' + Items.length}>
          {Items.map((item, index) => (
            <LinkBlock key={index} href={item.link} className={'theme-' + Theme}>
              <ProductLogo Size={'sm'} Logo={item.logo} Theme={Theme} />
              <h3>{item.title}</h3>
              {item.copy && <p className={'copy'}>{item.copy}</p>}
              {item.link_copy && (
                <ButtonComponent Link={item.link} Theme={`stripped-${item.logo}`} Label={item.link_copy} />
              )}
            </LinkBlock>
          ))}
        </ItemWrapper>
      </div>
    </ComponentSolutionBlock>
  );
};
