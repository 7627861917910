import React from 'react';
import { createMarkUp } from '../../helpers';
import { ProductLogo } from '../../V2/ProductLogo/ProductLogo';

import { MicroHeroStyles, CopyWrapper } from './styles.jsx';

interface Props {
  Theme?: string;
  Copy?: string;
  Logo?: string;
  Background?: string;
}

export const MicroHero = ({
  Theme = '',
  Logo,
  Background,
  Copy = '<h1>Title</h1><p>Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy</p>',
  ...props
}: Props) => {
  return (
    <MicroHeroStyles className={`Component_MicroHero`} {...props}>
      <div className={'container'}>
        {Logo && <ProductLogo Logo={Logo} Theme={'outline'} />}
        <ProductLogo Logo={'geomagic-designx'} Theme={'outline'} />
        <CopyWrapper dangerouslySetInnerHTML={createMarkUp(Copy)}></CopyWrapper>
      </div>
    </MicroHeroStyles>
  );
};
