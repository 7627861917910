import styled from 'styled-components';
import { spacing } from '../../foundations';

export const VideoWrapper = styled.iframe`
  height: 360px;
  width: 640px;
`;

export const Popup = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const PopupContent = styled.div`
  padding: ${spacing.l}px;
  height: 500px;
  width: 600px;
`;

export const VimeoWrapper = styled.div`
  position: relative;
`;
