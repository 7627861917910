import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { PageContainer } from '../../components/containers/PageContainer';
import { Footer } from '../../components/layouts/Footer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { Header } from '../../components/layouts/HeaderComponent';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { GDXVideo } from '../../components/V2024/GDXVideo/GDXVideo';
import { BackgroundGrey, BackgroundHalfGrey, BackgroundAI, BackgroundWhite } from '../../GlobalStyles';
import { PlansTable } from '../../components/V2024/Plan/PlansTable';
import { ArticleBlock } from '../../components/V2/ArticleBlock/ArticleBlock';
import { SolutionBlock } from '../../components/V2/SolutionBlock/SolutionBlock';
import { HighlightBlock } from '../../components/V2/HighlightBlock/HighlightBlock';
import { Accordion } from '../../components/V2/Accordion/Accordion';
import { SkinnyCTA } from '../../components/V2024/SkinnyCTA/SkinnyCTA';
import { Reviews } from '../../components/V2024/Reviews/Reviews';
import { AnnoucementBar } from '../../components/V2/AnnoucementBar/AnnoucementBar';
import { Grid } from '../../components/V2/Grid/Grid';

const GeomagicDesignX = ({ pageContext }) => {
  const Lang = 'de';

  const graphQuery = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "gdx-v2-de" }, extension: { eq: "json" } }) {
        edges {
          node {
            name
            childRawJson: internal {
              content
            }
          }
        }
      }
    }
  `);

  const content = graphQuery.allFile.edges[0].node.childRawJson.content;
  const data = JSON.parse(content);

  const globalCopy = pageContext.globalData;
  const sitemap = pageContext.sitemap;

  const vertical = 'NewForm-GDX';

  return (
    <>
      <HelmetContainer
        currentPage={'geomagic-designx'}
        image="/assets/share/designx.jpg"
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.description}
      />

      <PageContainer lang={Lang}>
        <BackgroundGrey>
          <Header lang={Lang} demoVertical={vertical} demoTitle={globalCopy.Buttons.RequestFreeTrial} />

          <LargeHero
            SecondaryLogo={{
              Link: 'https://www.capterra.com/p/276905/Geomagic-Design-X/',
              Logo: 'https://assets.capterra.com/badge/7efb3e2ab8d3bf7cfcdf2617994471f8.svg?v=2225314&p=276905',
              Alt: ''
            }}
            Country={Lang}
            AddSalesTel={true}
            AddSupportBTN={true}
            BackgroundStyle={'image'}
            BackgroundColour={'off-white'}
            Logo={'geomagic-designx'}
            Copy={data.content.hero.copy}
            Background={'/assets/images/pages/geomagicDesignX/3DReverseEngineeringSoftware.webp'}
            Button={{
              theme: 'geomagic-designx',
              copy: globalCopy.Buttons.DownloadFreeTrial,
              link: sitemap['geomagic-designx-download'][Lang]
            }}
          />
          <div className={'spacing-xl'}></div>
          <AnnoucementBar
            AdditionalClass={'offset-bottom increase-z header-margin-0'}
            Image={'https://oqtonadmin.com/wp-content/uploads/2024/10/Web-Civil.png'}
            Content={
              "<h3>Reverse Engineering the Golden Gate Bridge Monument</h3><p>This webinar will showcase how advanced 3D scanning technologies were used to capture the monument's intricate details, and accurately reverse engineer the complete geometry.</p>"
            }
            Link={{
              label: 'Register for webinar',
              target: '_blank',
              link: 'https://register.gotowebinar.com/register/7603401524958879326?source=Oqton+Website'
            }}
          />

          <GDXVideo
            AdditionalClasses={'top-announcement-bar'}
            Title={data.content.video_content.title}
            Copy={data.content.video_content.copy}
          />
        </BackgroundGrey>
        <BackgroundAI>
          <PlansTable
            Title={data.content.plans.title}
            Subtitle={data.content.plans.subtitle}
            Items={data.content.plans.plans}
          />

          <SkinnyCTA {...data.content.skinnyCTA} />
        </BackgroundAI>

        <BackgroundWhite>
          <Reviews {...data.content.reviews} />
        </BackgroundWhite>

        <BackgroundGrey>
          <Grid
            Items={data.content.highlight_features.Items}
            Copy={data.content.highlight_features.Copy}
            Title={data.content.highlight_features.Title}
            additionalClasses={'increased-height'}
            ShowDivider={true}
          />
        </BackgroundGrey>

        <ArticleBlock
          id={'customer-stories'}
          Title={'Geomagic Design X customer stories'}
          Layout={'layout-1'}
          Link={{
            copy: 'All Customer Stories',
            link: '/news/?&category=Customer-Story'
          }}
          MostRecentBlogs={true}
          Topic={'geomagic-designx-customer-stories'}
        />

        <BackgroundHalfGrey>
          <HighlightBlock
            Theme={'accent-red'}
            Title={data.content.HighlightBlock.Title}
            Button={{
              copy: globalCopy.Buttons.DownloadFreeTrial,
              link: sitemap['geomagic-designx-download'][Lang]
            }}
            Image={{
              title: '',
              alt: '',
              url: '/assets/2024/geomagic-designx/highlight-block.webp'
            }}
          />
        </BackgroundHalfGrey>

        <BackgroundGrey>
          <Accordion id="faqs" Title={data.content.Accordion.title} Items={data.content.Accordion.Items} />
        </BackgroundGrey>

        <SolutionBlock
          Title={data.content.SolutionBlock.Title}
          Theme={'dark'}
          Items={[
            {
              logo: 'geomagic-controlx',
              title: data.content.SolutionBlock.Items[0],
              link: globalCopy.Navigation.ProductURLs.geoControlX,
              link_copy: globalCopy.Buttons.Learn
            },
            {
              logo: 'geomagic-wrap',
              title: data.content.SolutionBlock.Items[1],
              link: globalCopy.Navigation.ProductURLs.geoWrap,
              link_copy: globalCopy.Buttons.Learn
            },
            {
              logo: 'geomagic-for-solid',
              title: data.content.SolutionBlock.Items[2],
              link: globalCopy.Navigation.ProductURLs.GeomagicForSolidworks,
              link_copy: globalCopy.Buttons.Learn
            }
          ]}
        />

        <Footer />
      </PageContainer>
    </>
  );
};

export default GeomagicDesignX;
