import React from 'react';

import AdditiveManufacturingTemplate from './../../templates/AdditiveManufacturingTemplate';

const Additive = () => {
  const Lang = 'pt-br';

  return <AdditiveManufacturingTemplate Lang={Lang} />;
};

export default Additive;
