import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { SimpleTitleStyles } from './styles.jsx';
import { createMarkUp } from '../helpers';

interface Props {
  Title?: string;
  AddHR?: boolean;
}

export const SimpleTitle = ({ Title = '', AddHR = true, ...props }: Props) => {
  const stripH2tags = string => {
    return string.replace(/<\/?h2>/g, '');
  };

  return (
    <SimpleTitleStyles className={'Component-SimpleTitle'} {...props}>
      <GlobalStyle />
      <div className={'container'}>
        <h2 dangerouslySetInnerHTML={createMarkUp(stripH2tags(Title))}></h2>
        {AddHR && <hr className={'divider center'} />}
      </div>
    </SimpleTitleStyles>
  );
};
