import React, { useEffect } from 'react';
import { Title } from './CookiePolicyStyles';

export const CookiePolicyContainer = () => {
  let isBrowser = () => typeof window !== 'undefined';

  useEffect(() => {
    // Inject Cookiebot declaration
    if (isBrowser()) {
      const cookiebotCookieDeclaratioScript = document.createElement('script');
      cookiebotCookieDeclaratioScript.src = 'https://consent.cookiebot.com/6436e449-2ff1-4dd3-902d-133f0615840e/cd.js';
      cookiebotCookieDeclaratioScript.type = 'text/javascript';
      cookiebotCookieDeclaratioScript.async = true;

      const cookiebotWrapperEl = document.getElementById('COOKIEBOT_DECLARATION_WRAPPER_ID');
      if (cookiebotWrapperEl) {
        cookiebotWrapperEl.appendChild(cookiebotCookieDeclaratioScript);
      }
    }
  }, []);

  return (
    <section>
      <Title>Cookie policy</Title>

      <div className={'container'}>
        <br />
        <br />
        <div id={'COOKIEBOT_DECLARATION_WRAPPER_ID'}></div>
      </div>
    </section>
  );
};
