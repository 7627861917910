import styled from 'styled-components';
import { colors, media, spacing, typography } from '../../foundations';

export const LocationContainer = styled.article<{ index?: number }>`
  ${({ index }) => index && `grid-area: locationCard${index};`};

  width: 356px;
  box-sizing: border-box;

  display: grid;
  grid-template-rows: 190px repeat(3, auto);
  grid-template-areas: 'img' 'city' 'address' 'country';

  padding: 70px 45px 48px;

  border-radius: 8px;
  box-shadow: 0 3px 15px rgb(0 0 0 / 7%);

  color: ${colors.secondary};
  background-color: ${colors.primary};

  @media (max-width: ${media.xl}px) {
    width: auto;
  }

  @media (max-width: ${media.sm}px) {
    box-sizing: border-box;
    width: 100%;

    padding-left: 0;
    padding-right: 0;

    grid-template-columns: 20px 1fr 20px;
    grid-template-areas: '. img .' '. city .' '. address .' '. country .';
  }
`;

export const ImageContainer = styled.div`
  grid-area: img;

  width: 266px;

  align-self: end;
  margin-bottom: 40px;

  @media (max-width: ${media.xl}px) {
    margin: 0 auto 40px auto;
  }

  @media screen and (max-width: ${media.lg}px) {
    max-width: 100%;
  }
`;

export const City = styled.h3`
  grid-area: city;

  margin-bottom: ${spacing.xxs}px;

  font-size: ${typography.fontSizes.xxl}px;
  font-weight: ${typography.fontWeights.medium};
`;

export const Address = styled.p`
  grid-area: address;

  color: ${colors.tertiary};

  font-size: ${typography.fontSizes.l}px;
  line-height: 1.71;
`;

export const Country = styled.sub`
  grid-area: country;

  margin-top: 10px;

  font-size: ${typography.fontSizes.l}px;
  font-weight: ${typography.fontWeights.bold};
`;
