import React from 'react';

import MedicalTemplate from './../../templates/MedicalTemplate';

const Tibbi = () => {
  const Lang = 'tr';

  return <MedicalTemplate Lang={Lang} />;
};

export default Tibbi;
