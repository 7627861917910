import styled from 'styled-components';

import {colors, mediaQueriesSizes, bgGradients} from "../../../GlobalStyles";

export const ContactNumbersStyles = styled.div`
  padding: 100px 0;

  h4 {
    font-size: 24px;
    font-family: centrano2-book;
    line-height: 1.2;
    text-align: left;
    font-weight: 400;
    color: ${colors.primary};
  }

  h5 {
    color: ${colors.primary};
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    font-family: centrano2-book;

    a {
      color: ${colors.highlight};
    }
  }

`;

export const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin: 64px auto 88px;
  max-width: 1100px;
  a {
    border-radius: 4px;
    background: #F8F7F6;
    display: inline-flex;
    padding: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    color: ${colors.primary};
    text-decoration: none;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    span{
      width: 170px;
      text-align: left;
    }
    img {
      margin-right: 16px;
      width: 45px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    grid-template-columns: repeat(3, 1fr);
    a{
      font-size: 20px;
      span{
        width: 215px;
      }

    }
  }


`;

