import React from 'react';
import { createMarkUp } from '../../helpers';
import { ComponentStyles, ItemContainer } from './styles.jsx';
import { ProductLogo } from '../../V2/ProductLogo/ProductLogo';
import { ButtonComponent } from '../../V2/Button/Button';

interface Props {
  content?: string;
  size?: string;
  cta?: object;
}

export const SkinnyCTA = ({ size = 'large', content, cta, ...props }: Props) => {
  return (
    <ComponentStyles className={`Component-SkinnyCTA size-${size}`} {...props}>
      <div className={'container'}>
        <div className={'inner'}>
          <h2 dangerouslySetInnerHTML={createMarkUp(content)}></h2>
          <div className={'btn-wrap'}>
            {cta && <ButtonComponent Label={cta.copy} Theme={cta.theme} Target={cta.target} Link={cta.href} />}
          </div>
        </div>
      </div>
    </ComponentStyles>
  );
};
